import React, { useState } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { aprovaTodosFormulario, avaliaFormulario } from "actions/BackofficeActions";
import DialogSelecao from "components/Utils/Dialogs/DialogSelecao";
import GlobalizadorService from "service/GlobalizadorService";
import { successMessage } from "../../../../../../../helpers/observables";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

const globalizadorService = new GlobalizadorService();

const Formulario = (props) => {
  const [openDialogMotivosReprovacao, setOpenDialogMotivosReprovacao] = useState(false);
  const [item, setItem] = useState(null);
  const [file, setFile] = useState(null);
  const [motivosReprovacao, setMotivosReprovacao] = useState([]);

  const aprovar = (item) => {
    props.avaliaFormulario(item.nomeCampo, true, "");
  };

  const reprovar = async (item) => {
    const resp = await globalizadorService.listaMotivosReprovacao();
    if (resp.listaMotivosReprovacao && resp.listaMotivosReprovacao.length > 0) {
      setMotivosReprovacao(resp.listaMotivosReprovacao);
      setFile(file);
      setItem(item);
      setOpenDialogMotivosReprovacao(true);
    } else {
      props.avaliaFormulario(item.nomeCampo, false, "");
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <DialogSelecao
          open={openDialogMotivosReprovacao}
          lista={motivosReprovacao}
          title="Motivo de Reprovação"
          id="id"
          descricao="nome"
          handleClose={() => setOpenDialogMotivosReprovacao(false)}
          handleSelect={(motivo) => {
            props.avaliaFormulario(item.nomeCampo, false, motivo.nome);
            setOpenDialogMotivosReprovacao(false);
            setItem(null);
          }}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            startIcon={<DoneIcon />}
            variant="outlined"
            style={{ borderColor: "green", color: "green" }}
            onClick={() => {
              props.aprovaTodosFormulario();
            }}
          >
            Aprovar todos
          </Button>
        </div>
        <Table size="small" style={{ marginTop: "1rem" }}>
          <TableBody>
            {props.task?.formulario
              ?.sort((a, b) => a.ordem - b.ordem)
              .map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    style={{
                      background:
                        item.aprovado === true
                          ? "rgba(0, 128, 0, 0.11)"
                          : item.aprovado === false
                          ? "rgba(255, 0, 0, 0.09)"
                          : "none",
                    }}
                  >
                    <TableCell align="right">{item.nomeCampo}</TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: item.aprovado === true ? "green" : item.aprovado === false ? "red" : "black",
                        }}
                      >
                        {item.valor}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: "9rem" }}>
                      {(item.aprovado === null || item.aprovado === false) && (
                        <IconButton style={{ color: "green" }} onClick={() => aprovar(item)}>
                          <DoneIcon />
                        </IconButton>
                      )}

                      {(item.aprovado === null || item.aprovado === true) && (
                        <IconButton style={{ color: "red" }} onClick={() => reprovar(item)}>
                          <CloseIcon />
                        </IconButton>
                      )}

                      <CopyToClipboard
                        text={item.valor}
                        onCopy={() => successMessage.next("Texto copiado para a área de transferência.")}
                      >
                        <IconButton>
                          <FileCopyOutlinedIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { task } = backofficeReducer;
  return { task };
};

export default connect(mapStateToProps, {
  avaliaFormulario,
  aprovaTodosFormulario,
})(Formulario);
