import { Button, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import InputMask from "react-input-mask";

const BuscarCliente = (props) => {
  const classes = useStyles();
  return (
    <form noValidate autoComplete="off">
      <Grid container>
        <Grid item>
          <TextField
            className={classes.inputGrid}
            variant="outlined"
            size="small"
            label="Nome"
            id="input-with-icon-grid"
          />
        </Grid>
        <Grid item>
          <InputMask mask="99/99/9999" value={props.value} onChange={props.onChange}>
            {(inputProps) => (
              <TextField
                {...inputProps}
                className={classes.inputGrid}
                style={{ width: "120px" }}
                variant="outlined"
                size="small"
                label="Data Nasc."
                id="input-with-icon-grid"
              />
            )}
          </InputMask>
        </Grid>
        <Grid item>
          <TextField
            className={classes.inputGrid}
            variant="outlined"
            size="small"
            label="Email"
            id="input-with-icon-grid"
          />
        </Grid>

        <Grid item>
          <TextField
            className={classes.inputGrid}
            variant="outlined"
            size="small"
            label="Cartão"
            id="input-with-icon-grid"
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.inputGrid}
            variant="outlined"
            size="small"
            label="orgão emissor"
            style={{ width: "140px" }}
            id="input-with-icon-grid"
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.inputGrid}
            defaultValue="Small"
            variant="outlined"
            size="small"
            label="Titular"
            id="input-with-icon-grid"
          />
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained">
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BuscarCliente;

const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "8px",
    marginRight: "8px",
  },
}));
