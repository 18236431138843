import React, { PureComponent } from "react";
import Servers from "../../../../../../../../constants/Servers";
import { validarEnvioArquivo } from "../../../../../../../../helpers/util";
import { errorMessage, successMessage } from "../../../../../../../../helpers/observables";
//import { Chat } from "../../../../../../../../../node_modules/@visual/video-atendimento-react";
//import VideoAtendimentoJs from "../../../../../../../../../node_modules/@visual/video-atendimento-js";
// import { Chat } from "@visual/video-atendimento-react";
// import VideoAtendimentoJs from "@visual/video-atendimento-js";
import { connect } from "react-redux";
import { Button, Card, IconButton, TableCell, Typography } from "@mui/material";
import LiveChatService from "../../../../../../../../service/LiveChatService";
import DoneIcon from "@mui/icons-material/Done";
import DialogConfirmation from "../../../../../../../Utils/Dialogs/DialogConfirmation";
import { finalizaLiveChat } from "../../../../../../../../actions/GuicheActions";
import moment from "moment";
import { desconectarLiveChats } from "../../../../../../../../helpers/observables";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import "./LiveChatConversa.css";
import NumberFormat from "react-number-format";

class LiveChatConversa extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mensagens: [],
      conexaoAtiva: false,
      senderStarted: false,
      peerConnectedWs: false,
      isPeerTyping: false,
      openDialogConfirmation: false,
      optionsDialogConfirmation: null,
      idChat: null,
      mensagensNaoLidas: false,
      tempoAtendimento: 0,
    };
    // this.videoAtendimento = new VideoAtendimentoJs();
    this.liveChatService = new LiveChatService();
    this.styles = {
      headerWrapper: {
        padding: "0.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      },
      columnWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
      },
    };
  }

  componentDidMount() {
    // this.videoAtendimento
    //   .init({
    //     urlLiveChat: Servers.url.LIVECHAT,
    //     wsLiveChat: Servers.url.WSLIVECHAT,
    //     type: "ATENDENTE",
    //     videoAudioDisabled: true,
    //     welcomeMessage: this.props.mensagemBoasVindas,
    //     waitAMomentMessage: this.props.mensagemAguardeUmMomento,
    //     waitAMomentSeconds: 90,
    //     handleWsStatus: (status) => this.setState({ conexaoAtiva: status }),
    //     handlePeerConnectedWs: (status) => this.setState({ peerConnectedWs: status }),
    //     handleChatMessages: (messages) => {
    //       this.setState({ mensagens: [] });
    //       this.setState({ mensagens: messages });
    //       if (
    //         !this.props.selected &&
    //         messages &&
    //         messages.length > 0 &&
    //         messages.filter((m) => !m.delivered).length > 0
    //       ) {
    //         this.setState({ mensagensNaoLidas: true });
    //         this.props.handleMensagensNaoLidas(true);
    //       }
    //     },
    //     handlePeerTyping: (isPeerTyping) => this.setState({ isPeerTyping }),
    //     nomeAtendente: this.props.nomeUsuario,
    //     urlEvaluation: "https://app.kquality.com.br/#/NmQwUXlydFppbkJoRVFFZEZuV1d1dz09",
    //   })
    //   .then(() => this.videoAtendimento.startLiveChat(this.props.idChat))
    //   .catch((err) => console.log(err));

    this.setState({ idChat: this.props.idChat, tempoAtendimento: this.props.tempoAtendimento });
    setInterval(() => this.setState({ tempoAtendimento: this.state.tempoAtendimento + 1 }), 1000);

    desconectarLiveChats.subscribe(() => {
      // this.videoAtendimento.disconnect();
    });
  }

  componentWillUnmount() {
    // this.videoAtendimento.disconnect();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selected && !prevProps.selected) {
      this.setState({ mensagensNaoLidas: false });
      this.props.handleMensagensNaoLidas(false);
    }
  }

  enviarArquivo(event) {
    if (validarEnvioArquivo(event)) {
      const file = event.target.files[0];
      this.sendFile(file, file.name, file.name.split(".").pop());
    }
    event.target.value = null;
  }

  sendFile(file, nome, extensao) {
    this.setState({ senderStarted: true });
    this.props.integradorService
      .uploadArquivo(file, nome, extensao, 1, 1, this.props.idChat)
      .then((resp) => {
        // this.videoAtendimento.sendFile({ fileId: resp.id_arquivo, fileName: nome });
        this.setState({ senderStarted: false });
        successMessage.next("Arquivo enviado com sucesso.");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ senderStarted: false });
        errorMessage.next("Erro ao enviar arquivo.");
      });
  }

  finalizar() {
    this.setState({
      optionsDialogConfirmation: {
        title: "Finalização",
        description: "Deseja finalizar este chat?",
        handleClose: () => {
          this.setState({ openDialogConfirmation: false });
        },
        handleConfirm: async () => {
          try {
            this.setState({ openDialogConfirmation: false });
            // this.videoAtendimento.finish();
            this.props.finalizaLiveChat(this.props.idChat);
            this.props.integradorService.excluirArquivos(1, 1, this.props.idChat).then();
          } catch (err) {
            console.log(err);
            errorMessage.next("Erro ao finalizar chat.");
          }
        },
      },
      openDialogConfirmation: true,
    });
  }

  getCopyIcon(texto) {
    return (
      <CopyToClipboard
        text={texto}
        onCopy={() => successMessage.next("Texto copiado para a área de transferência.")}
      >
        <IconButton style={{ color: "white", padding: 0, marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
      </CopyToClipboard>
    );
  }

  render() {
    return (
      <div
        style={{
          display: this.props.selected ? "block" : "none",
          marginTop: "0.2rem",
        }}
      >
        <DialogConfirmation
          open={this.state.openDialogConfirmation}
          options={this.state.optionsDialogConfirmation}
        />
        <Card
          style={{
            ...this.styles.headerWrapper,
            background: this.props.backgroundColorList[this.props.index]
              ? this.props.backgroundColorList[this.props.index]
              : this.props.backgroundColorList[0],
          }}
        >
          <div style={this.styles.columnWrapper}>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              <Typography variant="body2" style={{ color: "white" }}>
                {this.state.peerConnectedWs ? <span class="green-dot" /> : <span class="red-dot" />}
              </Typography>
              <Typography variant="body1" style={{ color: "white", marginLeft: "0.2rem" }}>
                {this.props.cliente.nome}
              </Typography>
              {this.getCopyIcon(this.props.cliente.nome)}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Typography variant="body1" style={{ color: "white" }}>
                Email: {this.props.cliente.email}
              </Typography>
              {this.getCopyIcon(this.props.cliente.email)}
              <Typography variant="body1" style={{ color: "white" }}>
                - Serviço: {this.props.tag}
              </Typography>
              {this.getCopyIcon(this.props.tag)}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Typography variant="body1" style={{ color: "white" }}>
                {this.props.cliente.cpf.length > 11 ? "CNPJ" : "CPF"}: {this.props.cliente.cpf}
              </Typography>
              {this.getCopyIcon(this.props.cliente.cpf)}
              <Typography variant="body1" style={{ color: "white" }}>
                - Telefone:{" "}
                <NumberFormat
                  value={this.props.cliente.telefone}
                  displayType={"text"}
                  format={
                    this.props.cliente.telefone && this.props.cliente.telefone.length <= 10
                      ? "(##) ####-#####"
                      : "(##) #####-####"
                  }
                />
              </Typography>
              {this.getCopyIcon(this.props.cliente.telefone)}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <div style={this.styles.columnWrapper}>
              <Typography variant="body1" style={{ color: "white", marginRight: "1rem" }}>
                {moment
                  .utc(moment.duration(this.state.tempoAtendimento, "seconds").asMilliseconds())
                  .format("HH:mm:ss")}
              </Typography>
            </div>
            <Button
              variant="outlined"
              startIcon={<DoneIcon />}
              style={{ color: "white", borderColor: "#b7b7b7" }}
              onClick={() => this.finalizar()}
            >
              Finalizar
            </Button>
          </div>
        </Card>
        {/* <Chat
          type="ATENDENTE"
          conexaoAtiva={this.state.conexaoAtiva}
          senderStarted={this.state.senderStarted}
          mensagens={this.state.mensagens}
          enviarMensagem={(msg) => this.videoAtendimento.sendChatMessage(msg)}
          enviarArquivo={(event) => this.enviarArquivo(event)}
          linkDownloadArquivo={(fileId) => this.props.integradorService.getLinkDownloadArquivo(fileId)}
          sendFile={(file, nome, extensao) => this.sendFile(file, nome, extensao)}
          idChat={this.props.idChat}
          isTypingChange={(status, idChat) => {
            if (idChat === this.props.idChat) {
              this.videoAtendimento.chatTypingStatus(status);
            }
          }}
          isPeerTyping={this.state.isPeerTyping}
          showCameraIcon={true}
          mensagensProntas={this.props.mensagensProntas}
          handleSelectMensagemPronta={(msg) => this.videoAtendimento.sendChatMessage(msg)}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => {
  const { nomeUsuario } = loginReducer;
  return { nomeUsuario };
};

export default connect(mapStateToProps, {
  finalizaLiveChat,
})(LiveChatConversa);
