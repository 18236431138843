import React, { useState } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { avaliaArquivo } from "actions/BackofficeActions";
import DialogSelecao from "components/Utils/Dialogs/DialogSelecao";
import DialogArquivo from "./components/DialogArquivo";
import GlobalizadorService from "service/GlobalizadorService";
import { successMessage } from "../../../../../../../../helpers/observables";
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

const globalizadorService = new GlobalizadorService();

const Arquivos = (props) => {
  const [openDialogArquivo, setOpenDialogArquivo] = useState(false);
  const [selectedArquivo, setSelectedArquivo] = useState(null);
  const [file, setFile] = useState(null);
  const [openDialogMotivosReprovacao, setOpenDialogMotivosReprovacao] = useState(false);
  const [motivosReprovacao, setMotivosReprovacao] = useState([]);
  const [downloadLink, setDownloadLink] = useState(null);

  const aprovar = (file) => {
    props.avaliaArquivo(file.key, true, "");
  };

  const reprovar = async (file) => {
    const resp = await globalizadorService.listaMotivosReprovacao();
    if (resp.listaMotivosReprovacao && resp.listaMotivosReprovacao.length > 0) {
      setMotivosReprovacao(resp.listaMotivosReprovacao);
      setFile(file);
      setOpenDialogMotivosReprovacao(true);
    } else {
      props.avaliaArquivo(file.key, false, "");
    }
  };

  const verArquivo = async (file) => {
    setSelectedArquivo(file);
    setDownloadLink(await globalizadorService.getDownloadLink(props.task.id, file.key));
    setOpenDialogArquivo(true);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <DialogSelecao
          open={openDialogMotivosReprovacao}
          lista={motivosReprovacao}
          title="Motivo de Reprovação"
          id="id"
          descricao="nome"
          handleClose={() => setOpenDialogMotivosReprovacao(false)}
          handleSelect={(motivo) => {
            props.avaliaArquivo(file.key, false, motivo.nome);
            setOpenDialogMotivosReprovacao(false);
            setFile(null);
          }}
        />

        <DialogArquivo
          open={openDialogArquivo}
          nomeArquivo={selectedArquivo?.label}
          downloadLink={downloadLink}
          handleClose={() => setOpenDialogArquivo(false)}
        />

        <Table size="small" style={{ marginTop: "1rem" }}>
          <TableBody>
            {props.task?.associatedFiles?.map((file) => {
              return (
                <TableRow
                  key={file.key}
                  style={{
                    background:
                      file.aprovado === true
                        ? "rgba(0, 128, 0, 0.11)"
                        : file.aprovado === false
                        ? "rgba(255, 0, 0, 0.09)"
                        : "none",
                  }}
                >
                  <TableCell align="right">{file.reference}</TableCell>

                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: file.aprovado === true ? "green" : file.aprovado === false ? "red" : "black",
                      }}
                    >
                      {file.label}
                    </Typography>
                  </TableCell>

                  <TableCell align="right" style={{ minWidth: "9rem" }}>
                    {(file.aprovado === null || file.aprovado === false) && (
                      <IconButton style={{ color: "green" }} onClick={() => aprovar(file)}>
                        <DoneIcon />
                      </IconButton>
                    )}

                    {(file.aprovado === null || file.aprovado === true) && (
                      <IconButton style={{ color: "red" }} onClick={() => reprovar(file)}>
                        <CloseIcon />
                      </IconButton>
                    )}

                    <IconButton onClick={() => verArquivo(file)}>
                      <VisibilityIcon />
                    </IconButton>

                    <CopyToClipboard
                      text={file.label}
                      onCopy={() => successMessage.next("Texto copiado para a área de transferência.")}
                    >
                      <IconButton>
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { estacao, task } = backofficeReducer;
  return {
    estacao,
    task,
  };
};

export default connect(mapStateToProps, { avaliaArquivo })(Arquivos);
