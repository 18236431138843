import { getStatusEstacao } from "actions/BackofficeActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DialogConfirmation from "components/Utils/Dialogs/DialogConfirmation";
import { eliminaSenhas } from "actions/BackofficeActions";
import { Button, CardActions, Typography, Card, CardContent, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    width: 600,
    minWidth: 450,
    padding: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 15,
  },
  pos: {
    marginBottom: 30,
    marginTop: 15,
    fontSize: 15,
  },
  cardActionsAlign: {
    justifyContent: "flex-end",
  },
});
const EliminarSenhas = (props) => {
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [optionsDialogConfirmation, setOptionsDialogConfirmation] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    props.getStatusEstacao();
  }, []);

  const eliminar = () => {
    setOptionsDialogConfirmation({
      title: "Supervisor",
      description: (
        <Alert className={classes.pos} severity="warning">
          Este é o último aviso! Ao clicar em 'Sim' todas as senhas do sistema serão canceladas! Esta ação
          também desconectará todos os usuários
        </Alert>
      ),

      handleClose: () => {
        setOpenDialogConfirmation(false);
      },
      handleConfirm: () => {
        setOpenDialogConfirmation(false);
        props.eliminaSenhas();
      },
    });
    setOpenDialogConfirmation(true);
  };

  return (
    <div style={{ display: "flex", paddingTop: "10px" }}>
      <DialogConfirmation open={openDialogConfirmation} options={optionsDialogConfirmation} />
      <div style={{ padding: 10 }}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              Cancelar todas as senhas do sistema.
            </Typography>
            <Alert className={classes.pos} severity="info">
              Esta ação afetará todas as senhas do sistema, inclusive as senhas em atendimento, todas serão
              canceladas! Esta ação também desconectará todos os usuários.
            </Alert>
          </CardContent>
          <CardActions className={classes.cardActionsAlign}>
            <Button onClick={eliminar} variant="contained" color="secondary" size="small">
              Eliminar Senhas
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { supervisor } = backofficeReducer;
  return {
    supervisor,
  };
};
export default connect(mapStateToProps, {
  getStatusEstacao,
  eliminaSenhas,
})(EliminarSenhas);
