import React, { useState, useEffect, Fragment } from "react";
import Formulario from "./components/Formulario";
import Arquivos from "./components/Arquivos/Arquivos";
import { connect } from "react-redux";
import styles from "./TarefasStyle";
import Comentarios from "./components/Comentarios";
import ArquivosExternos from "./components/ArquivosExternos";
import Uploads from "./components/Uploads";
import { withStyles } from "@mui/styles";
import { IconButton, Typography, Paper } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import CommentIcon from "@mui/icons-material/Comment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Tarefas = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [qtdReprovadosFormulario, setQtdReprovadosFormulario] = useState(0);
  const [qtdReprovadosArquivo, setQtdReprovadosArquivo] = useState(0);
  const [qtdReprovadosArquivoExterno, setQtdReprovadosArquivoExterno] = useState(0);
  const { classes } = props;

  useEffect(() => {
    let qtdReprovadosFormulario = 0;
    let qtdReprovadosArquivo = 0;
    let qtdReprovadosArquivoExterno = 0;
    if (props?.task?.formulario) {
      qtdReprovadosFormulario = props.task.formulario.filter((item) => item.aprovado === false).length;
    }
    if (props?.task?.associatedFiles) {
      qtdReprovadosArquivo = props.task.associatedFiles.filter((item) => item.aprovado === false).length;
    }
    if (props?.task?.externalFiles) {
      qtdReprovadosArquivoExterno = props.task.externalFiles.filter((item) => item.aprovado === false).length;
    }
    setQtdReprovadosFormulario(qtdReprovadosFormulario);
    setQtdReprovadosArquivo(qtdReprovadosArquivo);
    setQtdReprovadosArquivoExterno(qtdReprovadosArquivoExterno);
  }, []);

  useEffect(() => {
    setQtdReprovadosFormulario(props.task.formulario.filter((item) => item.aprovado === false).length);
  }, [props.task.formulario]);

  useEffect(() => {
    setQtdReprovadosArquivo(
      props.task.associatedFiles
        ? props.task.associatedFiles.filter((item) => item.aprovado === false).length
        : 0
    );
  }, [props?.task.associatedFiles]);

  useEffect(() => {
    setQtdReprovadosArquivoExterno(
      props.task.externalFiles ? props.task.externalFiles.filter((item) => item.aprovado === false).length : 0
    );
  }, [props?.task.externalFiles]);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "var(--theme-primary)",
        }}
      >
        <IconButton onClick={() => setSelectedTab(0)}>
          <span className={classes.circuloQuantidadeItens}>{props.task?.formulario?.length}</span>
          <DescriptionIcon className={selectedTab === 0 ? classes.iconSelected : classes.icon} />
          {qtdReprovadosFormulario > 0 && (
            <span className={classes.circuloQuantidadeItensReprovados}>{qtdReprovadosFormulario}</span>
          )}
        </IconButton>
        {props.task?.associatedFiles && (
          <IconButton onClick={() => setSelectedTab(1)}>
            <span className={classes.circuloQuantidadeItens}>{props.task?.associatedFiles?.length}</span>
            <FolderIcon className={selectedTab === 1 ? classes.iconSelected : classes.icon} />
            {qtdReprovadosArquivo > 0 && (
              <span className={classes.circuloQuantidadeItensReprovados}>{qtdReprovadosArquivo}</span>
            )}
          </IconButton>
        )}
        {props.task?.externalFiles && (
          <IconButton onClick={() => setSelectedTab(2)}>
            <span className={classes.circuloQuantidadeItens}>{props.task?.externalFiles?.length}</span>
            <FolderIcon className={selectedTab === 2 ? classes.iconSelected : classes.icon} />
            {qtdReprovadosArquivoExterno > 0 && (
              <span className={classes.circuloQuantidadeItensReprovados}>{qtdReprovadosArquivoExterno}</span>
            )}
          </IconButton>
        )}
        {props.permissoesBackoffice?.perm_comentario && (
          <IconButton onClick={() => setSelectedTab(3)}>
            <CommentIcon className={selectedTab === 3 ? classes.iconSelected : classes.icon} />
          </IconButton>
        )}
        {props.permissoesBackoffice?.perm_upload_arquivo && (
          <IconButton onClick={() => setSelectedTab(4)}>
            <CloudUploadIcon className={selectedTab === 4 ? classes.iconSelected : classes.icon} />
          </IconButton>
        )}
        <Typography variant="h6" className={classes.titleItemSelected}>
          {selectedTab === 0 && "Formulário"}
          {selectedTab === 1 && "Arquivos"}
          {selectedTab === 2 && "Arquivos Externos"}
          {selectedTab === 3 && "Comentários"}
          {selectedTab === 4 && "Uploads"}
        </Typography>
      </div>
      <Paper>
        {selectedTab === 0 && <Formulario />}
        {selectedTab === 1 && <Arquivos />}
        {selectedTab === 2 && <ArquivosExternos />}
        {selectedTab === 3 && <Comentarios />}
        {selectedTab === 4 && <Uploads />}
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({ backofficeReducer, loginReducer }) => {
  const { task } = backofficeReducer;
  const { permissoesBackoffice } = loginReducer;

  return { task, permissoesBackoffice };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Tarefas));
