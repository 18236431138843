import React, { useEffect } from "react";
import { connect } from "react-redux";
import Status from "constants/StatusSenha";
import { useState } from "react";
import { listaSenhas } from "actions/BackofficeActions";

import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import { Button, ButtonGroup } from "@mui/material";

const columns = [
  { field: "senha", align: "center", headerAlign: "center", headerName: "Senha", width: 120 },
  { field: "prioridade", align: "center", headerAlign: "center", headerName: "Prioridade", width: 150 },
  { field: "atividade", align: "center", headerAlign: "center", headerName: "Atividade", width: 150 },
  { field: "categoria", align: "center", headerAlign: "center", headerName: "Categoria", width: 150 },
  { field: "secao", align: "center", headerAlign: "center", headerName: "Seção", width: 150 },
  { field: "status", align: "center", headerAlign: "center", headerName: "Status", width: 200 },
  {
    field: "action",
    headerName: "Ações",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
        const api = params.api;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

        return alert(JSON.stringify(thisRow, null, 4));
      };
      return (
        <ButtonGroup size="small" variant="text" aria-label="text button group">
          <Button onClick={onClick}>Click</Button>
          <Button onClick={onClick}>Click</Button>
        </ButtonGroup>
      );
    },
  },
];

const EditarSenhas = (props) => {
  const [senhasLista, setSenhasLista] = useState([]);

  useEffect(() => {
    props.listaSenhas();
    return () => {
      props.listaSenhas();
    };
  }, []);

  useEffect(() => {
    let arr = [];
    props.listaSenhasSup?.map((element) => {
      arr.push({
        id: element.id,
        senha: element.numero,
        prioridade: element?.prioridade?.nome,
        atividade:
          element.indiceAtividade <= element.atividades.length
            ? element.atividades[0].atividade.nome
            : element.atividades[element.indiceAtividade].atividade.nome,
        categoria: element?.categoria?.nome,
        secao: element?.secao?.nome,
        status: Status[element.status],
      });
      setSenhasLista(arr);
    });
  }, [props.listaSenhasSup]);

  return (
    <div style={{ paddingTop: "10px", width: "90%" }}>
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          // localeText={ptBR.props.MuiDataGrid.localeText}
          rows={senhasLista}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { supervisor, listaSenhasSup } = backofficeReducer;
  return {
    supervisor,
    listaSenhasSup,
  };
};
export default connect(mapStateToProps, { listaSenhas })(EditarSenhas);
