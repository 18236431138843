import React from "react";
import styles from "./DialogStyle";
import {
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const DialogSelecao = (props) => {
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const lista = options ? options.lista : props.lista;
  const id = options ? options.id : props.id;
  const descricao = options ? options.descricao : props.descricao;
  const destaqueOption = options
    ? options.destaqueOption
    : props.destaqueOption;
  const destaqueStyle = options ? options.destaqueColor : props.destaqueColor;
  const destaqueParam = options ? options.destaqueParam : props.destaqueParam;
  const destaqueValue = options ? options.destaqueValue : props.destaqueValue;
  const handleClose = options ? options.handleClose : props.handleClose;
  const handleSelect = options ? options.handleSelect : props.handleSelect;

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <List>
          {/* {console.log(lista)} */}
          {lista?.map((item, index) => {
            return (
              <ListItem button key={index} onClick={() => handleSelect(item)}>
                {(destaqueOption === 1 &&
                  item[destaqueParam] === destaqueValue && (
                    <ListItemText style={destaqueStyle}>
                      {item[descricao]}
                    </ListItemText>
                  )) ||
                  (destaqueOption === 1 && (
                    <ListItemText>{item[descricao]}</ListItemText>
                  ))}

                {(destaqueOption === 2 &&
                  item[destaqueParam] !== destaqueValue && (
                    <ListItemText style={destaqueStyle}>
                      {item[descricao]}
                    </ListItemText>
                  )) ||
                  (destaqueOption === 2 && (
                    <ListItemText>{item[descricao]}</ListItemText>
                  ))}

                {!destaqueOption && (
                  <ListItemText>{item[descricao]}</ListItemText>
                )}
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DialogSelecao);
