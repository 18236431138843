import React, { Fragment, useEffect, useState } from "react";
import styles from "../BotoesAcaoStyle";
import { connect } from "react-redux";
import CustomButton from "components/Utils/CustomButton";
import GlobalizadorService from "service/GlobalizadorService";
import { Menu, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  adicionarServico,
  alteraAtividadeSenha,
  alterarServico,
  atividadeAdicional,
  cancelaAtendimento,
  chamadaEspecifica,
  congelarSenha,
  continuarAtendimento,
  criarSenha,
  descongelarSenhaGuiche,
  encerrarIndisponibilidadeGuiche,
  finalizaAtendimento,
  finalizarApoio,
  indisponibilizarGuiche,
  iniciaAtendimento,
  iniciarApoio,
  liberaGuicheAguardandoAoFinalizar,
  pausarAtendimento,
  proximaSenha,
  senhaEspecifica,
  proximoPasso,
  reativarSenha,
  rechamarSenha,
  retornarSuspensao,
  salvarComentarioSenha,
  sincronizarTerminal,
  transferirSenhaGuiche,
  recusarAvaliacao,
  alteraPrioridadeGuiche,
  congelarSenhaGuiche,
  alteraCategoriaGuiche,
} from "actions/GuicheActions";
import DialogSelecao from "components/Utils/Dialogs/DialogSelecao";
import { errorMessage, solicitarAvaliacao } from "helpers/observables";
import DialogSenhas from "components/Utils/Dialogs/DialogSenhas";
import DialogConfirmation from "components/Utils/Dialogs/DialogConfirmation";
import DialogTextField from "components/Utils/Dialogs/DialogTextField";
import Botoes from "constants/Botoes";
import DialogMultiSelect from "components/Utils/Dialogs/DialogMultiSelect";
import moment from "moment";
import DialogSelectTransistions from "components/Utils/Dialogs/DialogSelectTransitions";

const globalizadorService = new GlobalizadorService();

const BotoesGuiche = (props) => {
  const { classes } = props;
  const acoes = Object.values(Botoes);
  const [botoes, setBotoes] = useState(props.listaBotoesGuiches);
  const [botoesOutrasAcoes, setBotoesOutrasAcoes] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openDialogSelecao, setOpenDialogSelecao] = useState(false);
  const [optionsDialogSelecao, setOptionsDialogSelecao] = useState(null);
  const [openDialogSenhas, setOpenDialogSenhas] = useState(false);
  const [optionsDialogSenhas, setOptionsDialogSenhas] = useState(null);
  const [openDialogMultiSelect, setOpenDialogMultiSelect] = useState(false);
  const [optionsDialogMultiSelect, setOptionsDialogMultiSelect] =
    useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [optionsDialogConfirmation, setOptionsDialogConfirmation] =
    useState(null);
  const [openDialogTextField, setOpenDialogTextField] = useState(false);
  const [optionsDialogTextField, setOptionsDialogTextField] = useState(null);
  const [openDialogSelectTransistions, setOpenDialogSelectTransistions] =
    useState(false);
  const [openDialogSelectTransistionsOpt, setOpenDialogSelectTransistionsOpt] =
    useState(null);

  useEffect(() => {
    setBotoes(props.listaBotoesGuiches);
    //adicionar o botao proxima senha na lista abaixo
    setBotoesOutrasAcoes(
      props.listaBotoesGuiches
        .filter(
          (botao) =>
            botao.tipoBotao !== Botoes.CANCELAR_SENHA &&
            botao.tipoBotao !== Botoes.CONTINUAR_ATENDIMENTO &&
            botao.tipoBotao !== Botoes.INICIAR_ATENDIMENTO &&
            botao.tipoBotao !== Botoes.FINALIZAR_ATENDIMENTO &&
            botao.tipoBotao !== Botoes.FINALIZAR_APOIO &&
            botao.tipoBotao !== Botoes.SOLICITAR_AVALIACAO &&
            botao.tipoBotao !== Botoes.RECUSAR_AVALIACAO
        )
        .filter((botao) => acoes.includes(botao.tipoBotao))
    );
  }, [props.listaBotoesGuiches]);

  const handleClick = (tipoBotao) => {
    setAnchorEl(null);
    switch (tipoBotao) {
      case Botoes.CHAMADA_ESPECIFICA:
        chamadaEspecifica().then();
        break;
      case Botoes.FILTRAR_ATIVIDADE:
        filtroAtividade().then();
        break;
      case Botoes.FILTRAR_PRIORIDADE:
        filtroPrioridade().then();
        break;
      case Botoes.ADICIONAR_COMENTARIO:
        adicionarComentario().then();
        break;
      case Botoes.ALTERAR_SERVICO:
        alterarServico().then();
        break;
      case Botoes.ALTERA_PRIORIDADE:
        alterarPrioridade().then();
        break;
      case Botoes.PAUSAR_ATENDIMENTO:
        pausarAtendimento().then();
        break;
      case Botoes.ADICIONAR_SERVICO:
        adicionarServico().then();
        break;
      case Botoes.CONGELAR_SENHA:
        congelarSenha().then();
        break;
      case Botoes.CRIAR_SENHA:
        criarSenha().then();
        break;
      case Botoes.DESCONGELAR_SENHA:
        descongelarSenha().then();
        break;
      case Botoes.CONTINUAR_ATENDIMENTO:
        continuarAtendimento();
        break;
      case Botoes.RECHAMAR_SENHA:
        rechamarSenha();
        break;
      case Botoes.INDISPONIBILIZAR_GUICHE:
        indisponibilizarGuiche().then();
        break;
      case Botoes.ENCERRAR_INDISPONIBILIDADE_GUICHE:
        encerrarIndisponibilidadeGuiche();
        break;
      case Botoes.INICIAR_APOIO:
        iniciarApoio().then();
        break;
      case Botoes.FINALIZAR_APOIO:
        finalizarApoio();
        break;
      case Botoes.LIBERAR_GUICHE_AGUARDANDO_FINALIZAR:
        liberarGuicheAguardandoAoFinalizar().then();
        break;
      case Botoes.TRANSFERIR_SENHA:
        transferirSenha().then();
        break;
      case Botoes.REATIVAR_SENHA:
        reativarSenha().then();
        break;
      case Botoes.SINCRONIZA_TERMINAL:
        sincronizaTerminal().then();
        break;
      case Botoes.ATIVIDADE_ADICIONAL:
        inserirAtividadeAdicional().then();
        break;
      case Botoes.ALTERAR_ATIVIDADE:
        alterarAtividade().then();
        break;
      case Botoes.ALTERA_CATEGORIA:
        alterarCategoria().then();
        break;
      case Botoes.PROXIMO_PASSO:
        proximoPasso();
    }
  };

  const cancelaAtendimento = async () => {
    const resp = await globalizadorService.listaMotivosCancelamento();
    if (resp.listaMotivosCancelamento.length > 0) {
      setOptionsDialogSelecao({
        lista: resp.listaMotivosCancelamento,
        title: "Motivos de Cancelamento",
        id: "id",
        descricao: "nome",
        handleClose: () => setOpenDialogSelecao(false),
        handleSelect: (motivo) => {
          props.cancelaAtendimento(
            props.idGuiche,
            props.idSenha,
            motivo.id,
            getInfoChat()
          );
          setOpenDialogSelecao(false);
        },
      });
      setOpenDialogSelecao(true);
    } else {
      props.cancelaAtendimento(
        props.idGuiche,
        props.idSenha,
        null,
        getInfoChat()
      );
    }
  };

  const getClasses = (list) => {
    const classes = new Map();
    list.forEach((item) => {
      if (item.classe && !classes.get(item.classe)) {
        classes.set(item.classe.id, {
          id: item.classe.id,
          nome: item.classe.nome,
          group: true,
        });
      } else {
        classes.set(item.id, { id: item.id, nome: item.nome, group: false });
      }
    });
    return classes;
  };

  const criarSenha = async () => {
    try {
      const resp = await globalizadorService.listaServicosCriaSenha(
        props.idGuiche
      );
      if (resp.listaServicos.length > 0) {
        const classes = getClasses(resp.listaServicos);
        if (classes && classes.size > 0) {
          setOpenDialogSelectTransistionsOpt({
            lista: resp.listaServicos,
            title: "Selecione uma opção:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelectTransistions(false),
            handleSelect: (servico) => {
              setOpenDialogSelectTransistions(false);
              if (props.idSenha) {
                setOptionsDialogConfirmation({
                  title: "Atendimento",
                  description:
                    "Deseja finalizar a senha atual e criar uma nova senha?",
                  handleClose: () => {
                    setOpenDialogConfirmation(false);
                  },
                  handleConfirm: () => {
                    setOpenDialogConfirmation(false);
                    props.criarSenha(servico.id);
                  },
                });
                setOpenDialogConfirmation(true);
              } else {
                props.criarSenha(servico.id);
                setOpenDialogConfirmation(false);
              }
            },
          });
          setOpenDialogSelectTransistions(true);
        }
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const congelarSenha = async () => {
    setOpenDialogSelectTransistions(true);
    try {
      const resp = await globalizadorService.guicheListaMotivosCongelamento();
      console.log(resp);
      if (resp.listaMotivosCongelamento.length > 0) {
        setOpenDialogSelectTransistionsOpt({
          lista: resp.listaMotivosCongelamento,
          title: "Selecione uma opção:",
          id: "id",
          descricao: "nome",
          handleClose: () => setOpenDialogSelectTransistions(false),
          handleSelect: (motivo) => {
            setOpenDialogSelectTransistions(false);
            props.congelarSenhaGuiche(motivo.id);
          },
        });
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const descongelarSenha = async () => {
    try {
      const resp = await globalizadorService.guicheListaSenhasCongeladas();
      console.log(resp);
      if (resp.length > 0) {
        setOpenDialogSenhas(true);
        setOptionsDialogSenhas({
          title: "Selecione a senha que deseja descongelar:",
          lista: resp,
          statusGuiche: props.statusGuiche,
          handleClose: () => setOpenDialogSenhas(false),
          handleSelect: (senha, atImediato) => {
            const obj = {
              idSenha: senha.id,
              atendimentoImediato: atImediato,
            };
            props.descongelarSenhaGuiche(obj);
            setOpenDialogSenhas(false);
          },
        });
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const rechamarSenha = () => {
    try {
      props.rechamarSenha(props.idGuiche, props.idSenha);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const filtroAtividade = async () => {
    try {
      const resp = await globalizadorService.listaFiltroAtividadesGuiche(
        props.idGuiche
      );
      if (resp.lista_filtro_atividades.length > 0) {
        console.log(resp);
        resp.lista_filtro_atividades.forEach((item) => {
          const momentEspera = moment.duration(item.espera_real * 1000);
          const espera = moment
            .utc(momentEspera.asMilliseconds())
            .format("HH:mm:ss");
          item.label = `${item.nome_atividade} (${item.qte_senhas}) (${espera})`;
        });
        const classes = getClasses(resp.lista_filtro_atividades);
        if (classes.size) {
          setOptionsDialogSelecao({
            lista: Array.from(classes.values()),
            title: "Selecione a classe da atividade:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelecao(false),
            handleSelect: (classe) => {
              setOptionsDialogSelecao({
                lista: resp.lista_filtro_atividades.filter(
                  (item) => item.id_classe === classe.id
                ),
                title: "Selecione a atividade:",
                id: "id_atividade",
                descricao: "label",
                handleClose: () => setOpenDialogSelecao(false),
                handleSelect: (item) => {
                  setOpenDialogSelecao(false);
                  props.proximaSenha(props.idGuiche, "", item.id_atividade, 0);
                },
              });
            },
          });
        } else {
          setOptionsDialogSelecao({
            lista: resp.lista_filtro_atividades,
            title: "Selecione a atividade:",
            id: "id_atividade",
            descricao: "label",
            handleClose: () => setOpenDialogSelecao(false),
            handleSelect: (item) => {
              setOpenDialogSelecao(false);
              props.proximaSenha(props.idGuiche, "", item.id_atividade, 0);
            },
          });
        }
        setOpenDialogSelecao(true);
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const filtroPrioridade = async () => {
    try {
      const resp = await globalizadorService.listaFiltroPrioridadesGuiche(
        props.idGuiche
      );
      resp.listaFiltroPrioridades.forEach((item) => {
        const momentEspera = moment.duration(item.esperaReal * 1000);
        const espera = moment
          .utc(momentEspera.asMilliseconds())
          .format("HH:mm:ss");
        item.label = `${item.nomePrioridade} (${item.qteSenhas}) (${espera})`;
      });
      if (resp.listaFiltroPrioridades.length > 0) {
        setOptionsDialogSelecao({
          lista: resp.listaFiltroPrioridades,
          title: "Selecione a prioridade:",
          id: "idPrioridade",
          descricao: "label",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            props.proximaSenha(props.idGuiche, "", 0, item.idPrioridade);
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há prioridades cadastradas.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const adicionarComentario = async () => {
    setOptionsDialogTextField({
      handleConfirm: (text) => {
        props.salvarComentarioSenha(text);
        setOpenDialogTextField(false);
      },
      handleClose: () => setOpenDialogTextField(false),
      emptyMessage: "Comentário não pode ser vazio.",
      title: "Comentário",
      textPlaceholder: "Digite aqui o comentário...",
      confirmTitle: "Confirmar",
    });
    setOpenDialogTextField(true);
  };

  const pausarAtendimento = async () => {
    try {
      const resp = await globalizadorService.listaMotivosPausa();
      if (resp.listaMotivosPausa.length > 0) {
        setOptionsDialogSelecao({
          lista: resp.listaMotivosPausa,
          title: "Selecione o motivo de pausa:",
          id: "id",
          descricao: "nome",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            props.pausarAtendimento(item.id);
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há motivos de pausa cadastrados.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const continuarAtendimento = () => {
    try {
      props.continuarAtendimento(props.idGuiche);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const indisponibilizarGuiche = async () => {
    try {
      const resp =
        await globalizadorService.listaMotivosIndisponibilidadeGuiche();
      if (resp.lista_motivos_indisponibilidade_guiche.length > 0) {
        setOptionsDialogSelecao({
          lista: resp.lista_motivos_indisponibilidade_guiche,
          title: "Selecione o motivo de indisponibilidade:",
          id: "id",
          descricao: "nome",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            props.indisponibilizarGuiche(props.idGuiche, item.id);
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há motivos de indisponibilidade cadastrados.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const encerrarIndisponibilidadeGuiche = () => {
    try {
      props.encerrarIndisponibilidadeGuiche(props.idGuiche);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const iniciarApoio = async () => {
    try {
      const resp = await globalizadorService.listaGuichesDisponiveisSenha(
        props.idSenha
      );
      const guichesDisponiveis = resp.lista_guiches_disponiveis.filter(
        (guiche) => guiche.status_guiche === 1
      );
      if (guichesDisponiveis.length > 0) {
        setOptionsDialogMultiSelect({
          lista: guichesDisponiveis,
          title: "Selecione os guichês para iniciar apoio:",
          id: "id_guiche",
          descricao: "nome_guiche",
          handleClose: () => setOpenDialogMultiSelect(false),
          handleSelect: (list) => {
            setOpenDialogMultiSelect(false);
            list.forEach((item) => {
              if (item.selected) {
                props.iniciarApoio(props.idGuiche, item.id_guiche);
              }
            });
          },
        });
        setOpenDialogMultiSelect(true);
      } else {
        errorMessage.next("Não há guichês disponíveis.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const finalizarApoio = () => {
    try {
      props.finalizarApoio(props.idAtendente);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const chamadaEspecifica = async () => {
    try {
      const resp = await globalizadorService.listaSenhasFilaGuicheAtende(
        props.idGuiche,
        props.idAtendente
      );
      if (resp.listaSenhas.length > 0) {
        setOptionsDialogSenhas({
          title: "Selecione a senha que deseja chamar:",
          lista: resp.listaSenhas,
          handleClose: () => setOpenDialogSenhas(false),
          handleSelect: (senha) => {
            props.chamadaEspecifica(senha.id);
            setOpenDialogSenhas(false);
          },
        });
        setOpenDialogSenhas(true);
      } else {
        errorMessage.next("Não há senhas nesta fila.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const liberarGuicheAguardandoAoFinalizar = async () => {
    try {
      liberaGuicheAguardandoAoFinalizar(props.idGuiche);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const transferirSenha = async () => {
    try {
      const resp = await globalizadorService.listaGuichesDisponiveisSenha(
        props.idSenha
      );
      const guichesDisponiveis = resp.lista_guiches_disponiveis.filter(
        (guiche) => guiche.status_guiche === 1
      );
      if (guichesDisponiveis.length > 0) {
        guichesDisponiveis.forEach(
          (guiche) =>
            (guiche.nome = `${guiche.nome_atendente} - ${guiche.nome_guiche} (${guiche.nome_secao})`)
        );
        setOptionsDialogSelecao({
          lista: guichesDisponiveis,
          title: "Selecione o guichê para transferir a senha:",
          id: "id_guiche",
          descricao: "nome",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            props.transferirSenhaGuiche(
              props.idGuiche,
              props.idSenha,
              props.idAtendente,
              item.id_guiche
            );
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há guichês disponíveis.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const reativarSenha = async () => {
    try {
      const resp = await globalizadorService.listaSenhasFinalizadas(
        props.idGuiche
      );
      if (resp.listaSenhas.length > 0) {
        setOptionsDialogSenhas({
          title: "Selecione a senha que deseja reativar:",
          lista: resp.listaSenhas,
          numeroSenhaField: "numeroSenha",
          nomeServicoField: "nomeServico",
          nomeAtividadeField: "nomeAtividade",
          orderProperties: [
            { name: "numeroSenha", label: "Número da Senha" },
            { name: "nomeServico", label: "Serviço" },
            { name: "nomeAtividade", label: "Atividade" },
          ],
          handleClose: () => setOpenDialogSenhas(false),
          handleSelect: (senha) => {
            props.reativarSenha(props.idGuiche, senha.idSenha);
            setOpenDialogSenhas(false);
          },
        });
        setOpenDialogSenhas(true);
      } else {
        errorMessage.next("Não há senhas finalizadas.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const sincronizaTerminal = async () => {
    try {
      const resp = await globalizadorService.listaPalavrasSincronismoAval();
      if (resp.listaPalavras.length > 0) {
        setOptionsDialogSelecao({
          lista: resp.listaPalavras,
          title: "Selecione a palavra:",
          id: "id",
          descricao: "palavra",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            props.sincronizarTerminal(props.idGuiche, item.indicePalavra);
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há palavras cadastradas.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const inserirAtividadeAdicional = async () => {
    setOpenDialogSelectTransistions(true);
    try {
      const resp = await globalizadorService.listaAdicionaAtividadesGuiche();
      if (resp.length > 0) {
        const classes = getClasses(resp);
        if (classes && classes.size > 0) {
          setOpenDialogSelectTransistionsOpt({
            lista: resp,
            title: "Selecione uma opção:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelectTransistions(false),
            handleSelect: (atividade) => {
              setOpenDialogSelectTransistions(false);
              props.atividadeAdicional(atividade.id);
            },
          });
        }
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const alterarAtividade = async () => {
    setOpenDialogSelectTransistions(true);
    try {
      const resp = await globalizadorService.listaFiltroAtividadesGuiche(
        props.idGuiche
      );
      if (resp.length > 0) {
        const classes = getClasses(resp);
        if (classes && classes.size > 0) {
          setOpenDialogSelectTransistionsOpt({
            lista: resp,
            title: "Selecione uma opção:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelectTransistions(false),
            handleSelect: (atividade) => {
              setOpenDialogSelectTransistions(false);
              props.alteraAtividadeSenha(atividade.id);
            },
          });
        }
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const alterarCategoria = async () => {
    setOpenDialogSelectTransistions(true);
    try {
      const resp = await globalizadorService.guicheListaCategorias();
      if (resp.length > 0) {
        const classes = getClasses(resp);
        if (classes && classes.size > 0) {
          setOpenDialogSelectTransistionsOpt({
            lista: resp,
            title: "Selecione uma opção:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelectTransistions(false),
            handleSelect: (categoria) => {
              setOpenDialogSelectTransistions(false);
              props.alteraCategoriaGuiche(categoria.id);
            },
          });
        }
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const alterarServico = async () => {
    setOpenDialogSelectTransistions(true);
    try {
      const resp = await globalizadorService.listaServicosGuiche();
      console.log(resp);
      if (resp.listaServicos.length > 0) {
        const classes = getClasses(resp.listaServicos);
        if (classes && classes.size > 0) {
          setOpenDialogSelectTransistionsOpt({
            lista: resp.listaServicos,
            title: "Selecione uma opção:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelectTransistions(false),
            handleSelect: (servico) => {
              console.log(servico);
              setOpenDialogSelectTransistions(false);
              props.alterarServico(servico.id);
            },
          });
        }
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const alterarPrioridade = async () => {
    setOpenDialogSelectTransistions(true);
    try {
      const resp = await globalizadorService.guicheListaPrioridades();
      if (resp.length > 0) {
        setOpenDialogSelectTransistionsOpt({
          lista: resp,
          title: "Selecione uma opção:",
          id: "id",
          descricao: "nome",
          handleClose: () => setOpenDialogSelectTransistions(false),
          handleSelect: (prioridade) => {
            setOpenDialogSelectTransistions(false);
            props.alteraPrioridadeGuiche(prioridade.id);
          },
        });
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const adicionarServico = async () => {
    try {
      const resp = await globalizadorService.listaServicosGuiche(
        props.idGuiche
      );

      if (resp.listaServicos.length > 0) {
        const classes = getClasses(resp.listaServicos);
        if (classes && classes.size > 0) {
          setOpenDialogSelectTransistionsOpt({
            lista: resp.listaServicos,
            title: "Selecione uma opção:",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelectTransistions(false),
            handleSelect: (servico) => {
              setOpenDialogSelectTransistions(false);
              props.adicionarServico(servico.id);
            },
          });
        }
        setOpenDialogSelectTransistions(true);
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const proximoPasso = () => {
    try {
      props.proximoPasso(props.idGuiche, props.idSenha);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const solicitaAvaliacao = () => {
    solicitarAvaliacao.next();
  };

  const recusarAvaliacao = () => {
    props.recusarAvaliacao(props.idGuiche);
  };

  const verificaBotaoVisivel = (tipoBotao) => {
    return botoes.find((botao) => botao.tipoBotao == tipoBotao);
  };

  const getInfoChat = () => {
    if (props.isVideoAtendimento) {
      return {
        idSenha: props.idSenha,
        idAtividade: props.idAtividade,
        idAtendente: props.idAtendente,
        mensagens: props.mensagensChat,
      };
    }
    return null;
  };

  return (
    <div className={classes.divBotoes}>
      <DialogSelectTransistions
        open={openDialogSelectTransistions}
        options={openDialogSelectTransistionsOpt}
      />
      <DialogSelecao open={openDialogSelecao} options={optionsDialogSelecao} />
      <DialogSenhas open={openDialogSenhas} options={optionsDialogSenhas} />
      <DialogConfirmation
        open={openDialogConfirmation}
        options={optionsDialogConfirmation}
      />
      <DialogTextField
        open={openDialogTextField}
        options={optionsDialogTextField}
      />
      <DialogMultiSelect
        open={openDialogMultiSelect}
        options={optionsDialogMultiSelect}
      />
      <Fragment>
        {verificaBotaoVisivel(Botoes.INICIAR_ATENDIMENTO) && (
          <CustomButton onClick={() => props.iniciaAtendimento(props.idGuiche)}>
            Iniciar
          </CustomButton>
        )}
        {verificaBotaoVisivel(Botoes.FINALIZAR_ATENDIMENTO) && (
          <CustomButton
            onClick={() =>
              props.finalizaAtendimento(props.idGuiche, getInfoChat())
            }
          >
            Finalizar
          </CustomButton>
        )}
        {verificaBotaoVisivel(Botoes.SOLICITAR_AVALIACAO) && (
          <CustomButton onClick={() => solicitaAvaliacao()}>
            Avaliação
          </CustomButton>
        )}
        {verificaBotaoVisivel(Botoes.CONTINUAR_ATENDIMENTO) && (
          <CustomButton onClick={() => continuarAtendimento()}>
            Continuar
          </CustomButton>
        )}
        {verificaBotaoVisivel(Botoes.CANCELAR_SENHA) && (
          <CustomButton onClick={() => cancelaAtendimento()}>
            Cancelar
          </CustomButton>
        )}
        {verificaBotaoVisivel(Botoes.FINALIZAR_APOIO) && (
          <CustomButton onClick={() => finalizarApoio()}>
            Finalizar Apoio
          </CustomButton>
        )}
        {verificaBotaoVisivel(Botoes.RECUSAR_AVALIACAO) && (
          <CustomButton onClick={() => recusarAvaliacao()}>
            Recusar Avaliação
          </CustomButton>
        )}
        {props.statusGuiche === 6 && (
          <CustomButton
            onClick={() => props.retornarSuspensao(props.idAtendente)}
          >
            Retornar
          </CustomButton>
        )}
        {botoesOutrasAcoes &&
          botoesOutrasAcoes.length > 0 &&
          !props.isVideoAtendimento && (
            <Fragment>
              <CustomButton
                background="#898b8a"
                hover="#353535"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                Outras ações
              </CustomButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {botoesOutrasAcoes.map((botao, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => handleClick(botao.tipoBotao)}
                    >
                      {botao.nomeBotao}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Fragment>
          )}
      </Fragment>
    </div>
  );
};

const mapStateToProps = ({ guicheReducer }) => {
  const {
    idSecao,
    idGuiche,
    idSenha,
    idAtendente,
    idAtividade,
    statusGuiche,
    listaBotoesGuiches,
    listaAvaliacoes,
    isVideoAtendimento,
    mensagensChat,
    nomeStatusGuiche,
  } = guicheReducer;
  return {
    idSecao,
    idGuiche,
    idSenha,
    idAtendente,
    idAtividade,
    statusGuiche,
    listaBotoesGuiches,
    listaAvaliacoes,
    isVideoAtendimento,
    mensagensChat,
    nomeStatusGuiche,
  };
};

export default connect(mapStateToProps, {
  iniciaAtendimento,
  finalizaAtendimento,
  cancelaAtendimento,
  proximaSenha,
  senhaEspecifica,
  criarSenha,
  congelarSenha,
  descongelarSenhaGuiche,
  rechamarSenha,
  adicionarServico,
  alterarServico,
  salvarComentarioSenha,
  pausarAtendimento,
  continuarAtendimento,
  indisponibilizarGuiche,
  encerrarIndisponibilidadeGuiche,
  iniciarApoio,
  finalizarApoio,
  chamadaEspecifica,
  liberaGuicheAguardandoAoFinalizar,
  transferirSenhaGuiche,
  reativarSenha,
  retornarSuspensao,
  sincronizarTerminal,
  atividadeAdicional,
  alteraAtividadeSenha,
  proximoPasso,
  recusarAvaliacao,
  alteraPrioridadeGuiche,
  congelarSenhaGuiche,
  alteraCategoriaGuiche,
})(withStyles(styles)(BotoesGuiche));
