import {
  APROVA_TODOS_FORMULARIO_SUCCESS,
  AVALIA_ARQUIVO_EXTERNO_SUCCESS,
  AVALIA_ARQUIVO_SUCCESS,
  AVALIA_FORMULARIO_SUCCESS,
  GUICHE_BACKOFFICE_ERROR,
  GUICHE_BACKOFFICE_SUCCESS,
  INSERIR_COMENTARIO_SUCCESS,
  GET_LISTA_SENHAS,
} from "constants/types";
import GlobalizadorService from "service/GlobalizadorService";
import { push } from "connected-react-router";
import { conectaWebSocket } from "service/websocket/WebSocketActions";
import { errorMessage, successMessage } from "helpers/observables";

const globalizadorService = new GlobalizadorService();

export const getStatusEstacao = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .statusEstacao()
        .then((resp) => {
          dispatch({
            type: GUICHE_BACKOFFICE_SUCCESS,
            payload: resp,
          });
          // dispatch(conectaWebSocket());
          resolve();
        })
        .catch((err) => {
          // dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          // errorMessage.next(err.mensagem);
          // reject(err.mensagem);

          if (err.mensagem === "Usuário não logado!") {
            sessionStorage.clear();
            dispatch(push("/login"));
          }
        });
    });
  };
};

export const proximaDemanda = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .proximaDemanda()
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const selecionaDecisao = (idAtividade, idResposta, protocolo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .selecionaDecisao(idAtividade, idResposta)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          dispatch(finalizaDemanda(protocolo));
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const finalizaDemanda = (protocolo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .finalizaDemanda(protocolo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda finalizada com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const solicitaAgendamento = (
  idServico,
  comentario,
  horaRetorno,
  categoriaId
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .solicitaAgendamento(idServico, comentario, horaRetorno, categoriaId)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda finalizada com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const registraPendencia = (descricao, comentario, horaRetorno) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .registraPendencia(descricao, comentario, horaRetorno)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda finalizada com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const avaliaFormulario = (chave, aprovado, motivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .avaliaFormulario(chave, aprovado, motivo)
        .then((resp) => {
          dispatch({ type: AVALIA_FORMULARIO_SUCCESS, payload: resp });
          successMessage.next("Item avaliado com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_ERROR, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const avaliaArquivo = (chave, aprovado, motivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .avaliaArquivo(chave, aprovado, motivo)
        .then((resp) => {
          dispatch({ type: AVALIA_ARQUIVO_SUCCESS, payload: resp });
          successMessage.next("Item avaliado com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const avaliaArquivoExterno = (chave, aprovado, motivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .avaliaArquivoExterno(chave, aprovado, motivo)
        .then((resp) => {
          dispatch({ type: AVALIA_ARQUIVO_EXTERNO_SUCCESS, payload: resp });
          successMessage.next("Item avaliado com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const inserirComentario = (comentario) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .inserirComentario(comentario)
        .then((resp) => {
          dispatch({ type: INSERIR_COMENTARIO_SUCCESS, payload: resp });
          successMessage.next("Comentário enviado com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const uploadArquivo = (
  taskId,
  filename,
  contentLength,
  author,
  arquivo
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .getUploadLink(taskId, filename, contentLength, author, arquivo.type)
        .then((resp) => {
          const filenameAmazon = resp.filename;
          globalizadorService
            .enviarArquivo(resp.url, arquivo)
            .then(() => {
              globalizadorService
                .uploadSuccess(
                  taskId,
                  filename,
                  contentLength,
                  author,
                  "upload",
                  filenameAmazon
                )
                .then(() => {
                  successMessage.next("Arquivo enviado com sucesso!");
                  dispatch(getStatusEstacao());
                  resolve();
                })
                .catch((err) => {
                  dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
                  errorMessage.next(err.mensagem);
                  reject(err.mensagem);
                });
            })
            .catch((err) => {
              dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
              errorMessage.next(err.mensagem);
              reject(err.mensagem);
            });
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const devolveDemanda = (idUsuario, idUnidade) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .devolveDemanda(idUsuario, idUnidade)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda devolvida com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const aprovaTodosFormulario = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .aprovaTodosFormulario()
        .then((resp) => {
          dispatch({ type: APROVA_TODOS_FORMULARIO_SUCCESS, payload: resp });
          successMessage.next("Itens aprovados com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const iniciaDemandaEspecifica = (protocolo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .specificTask(protocolo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda específica iniciada com sucesso.");
          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const imprimeDemanda = (taskId, impressoraId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .imprimeDemanda(taskId, impressoraId)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda impressa com sucesso.");
          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const encerraDemanda = (protocolo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .encerraDemanda(protocolo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda encerrada com sucesso.");
          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const eliminaSenhas = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .eliminaSenhas()
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senhas excluídas com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const listaSenhas = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .listaSenhas()
        .then((resp) => {
          dispatch({ type: GET_LISTA_SENHAS, payload: resp });
          // successMessage.next("Senhas listadas com sucesso.");
          return resp;
        })
        .catch((err) => {
          dispatch({ type: GET_LISTA_SENHAS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const presetContadores = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .presetContadores()
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Contadores zerados com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};
