const styles = (theme) => ({
  [theme.breakpoints.up("md")]: {
    divPrincipal: {
      display: "flex",
      flexDirection: "column",
    },
    paper: {
      padding: "2rem 1rem",
      textAlign: "center",
      marginBottom: "0.2rem",
      borderRadius: 0,
    },
  },
  [theme.breakpoints.down("md")]: {
    divPrincipal: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0.2rem",
      width: "100%",
    },
    paper: {
      flex: 1,
      padding: "2rem 1rem",
      textAlign: "center",
      marginRight: "0.2rem",
      borderRadius: 0,
    },
  },
  paperSenha: {
    background: "#2674a9",
    padding: "1rem",
    textAlign: "center",
    borderRadius: 0,
    marginBottom: "0.2rem",
  },
  labelInfo: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "var(--theme-grey-dark)",
  },
  labelInfoTitle: {
    fontSize: "1rem",
    color: "var(--theme-grey-dark)",
  },
  labelSenha: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
});

export default styles;
