const styles = (theme) => ({
  [theme.breakpoints.up("md")]: {
    mainDiv: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "0.2rem",
    },
  },
  [theme.breakpoints.down("md")]: {
    mainDiv: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 0,
    textAlign: "center",
    padding: "1rem 2.5rem 1rem 2.5rem",
    marginBottom: "0.2rem",
  },
  mainDivHorizontal: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  paperHorizontal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    borderRadius: 0,
    padding: "1rem 2.5rem 1rem 2.5rem",
    margin: "0.2rem",
  },
  labelTitle: {
    color: "white",
    fontWeight: "bold",
  },
  labelTitleYellow: {
    color: "#e9bf0b",
    fontWeight: "bold",
  },
  labelInfo: {
    color: "#f5fafea3",
  },
});

export default styles;
