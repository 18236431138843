import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  agendaAtividadeSenha,
  excluiAtividadeSenha,
  reordenaAtividadesSenha,
} from "actions/GuicheActions";
import styles from "../InfoClienteStyle";
import DialogConfirmation from "components/Utils/Dialogs/DialogConfirmation";
import DialogTextField from "../../../../../../Utils/Dialogs/DialogTextField";
import { errorMessage } from "../../../../../../../helpers/observables";
import moment from "moment";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CommentOutlined from "@mui/icons-material/CommentOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogComentarios from "components/Utils/Dialogs/DialogComentarios";

const Atividades = (props) => {
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [optionsDialogConfirmation, setOptionsDialogConfirmation] =
    useState(null);
  const [openDialogTextField, setOpenDialogTextField] = useState(false);
  const [optionsDialogTextField, setOptionsDialogTextField] = useState(null);
  const [openDialogComentarios, setOpenDialogComentarios] = useState(false);
  const [optionsDialogComentarios, setOptionsDialogComentarios] =
    useState(false);
  const styles = {
    labelAtividade: {
      color: "var(--theme-grey-dark)",
      fontWeight: "normal",
      flexGrow: 1,
    },
    divAtividade: {
      display: "flex",
      alignItems: "center",
    },
  };

  const excluirAtividade = (atividade) => {
    setOptionsDialogConfirmation({
      title: "Exclusão",
      description: `Confirma a exclusão da atividade \"${atividade.nome_atividade}\"?`,
      handleClose: () => {
        setOpenDialogConfirmation(false);
      },
      handleConfirm: () => {
        setOpenDialogConfirmation(false);
        props.excluiAtividadeSenha(props.idSenha, atividade.indice_real);
      },
    });
    setOpenDialogConfirmation(true);
  };

  const agendarAtividade = (atividade) => {
    setOptionsDialogTextField({
      type: "TIME",
      previousValue: moment(
        atividade.hora_agendada,
        "DD/MM/YYYY HH:mm:ss"
      ).format("HH:mm"),
      handleConfirm: (horario) => {
        if (
          horario.length &&
          (horario.length < 5 ||
            horario == "Invalid date" ||
            parseInt(horario.split(":")[0], 10) > 23 ||
            parseInt(horario.split(":")[1], 10) > 59)
        ) {
          errorMessage.next("Horário inválido.");
        } else {
          props.agendaAtividadeSenha(
            props.idSenha,
            atividade.indice_real,
            horario
          );
          setOpenDialogTextField(false);
        }
      },
      handleClose: () => setOpenDialogTextField(false),
      emptyMessage: "Horário não pode ser vazio.",
      title: "Horário Agendamento",
      textPlaceholder: "Horário",
      confirmTitle: "Confirmar",
    });
    setOpenDialogTextField(true);
  };

  const ordenarAtividade = (atividade, sentido) => {
    const atividades = props?.listaAtividades.filter(
      (atividade) => !atividade.atendida && !atividade.atual
    );
    const index = atividades.findIndex(
      (a) => a.id_atividade === atividade.id_atividade
    );
    let destIndex;
    if (sentido === "UP") {
      destIndex = index - 1;
    } else {
      destIndex = index + 1;
    }
    props.reordenaAtividadesSenha(
      props.idSenha,
      reorder(atividades, index, destIndex)
    );
  };

  const statusAtividade = (atividade, index) => {
    let status;
    if (atividade.atendida == true) {
      status = "Finalizada";
    } else if (atividade.atendida == false && props.indiceAtividade == index) {
      status = "Em andamento";
    } else {
      status = "Pendente";
    }
    return status;
  };

  const reorder = (array, indexA, indexB) => {
    let newArray = Array(array.length);

    newArray[indexB] = array[indexA];

    for (let i = 0; i < array.length; i++) {
      if (i !== indexA) {
        for (let x = 0; x < array.length; x++) {
          if (newArray[x] === undefined) {
            newArray[x] = array[i];
            x = array.length;
          }
        }
      }
    }
    return newArray.map((atividade) => atividade.id_atividade);
  };

  const comentarios = (comentarios) => {
    setOpenDialogComentarios(true);
    setOptionsDialogComentarios({
      title: "Comentários",
      lista: comentarios,
      handleClose: () => setOpenDialogComentarios(false),
      handleSelect: () => {
        setOpenDialogComentarios(false);
      },
    });
  };

  return (
    <Fragment>
      <DialogConfirmation
        open={openDialogConfirmation}
        options={optionsDialogConfirmation}
      />
      <DialogTextField
        open={openDialogTextField}
        options={optionsDialogTextField}
      />
      {props?.listaAtividades.map((atividade, index) => (
        <li style={styles.divAtividade} key={`atividade-${index}`}>
          <div>
            <Typography variant="h6" style={styles.labelAtividade}>
              {atividade.atividade.nome} - {statusAtividade(atividade, index)}
              {atividade.horaAgendada && (
                <Fragment>
                  {" "}
                  - {moment(new Date(atividade.horaAgendada)).format("HH:mm")}
                </Fragment>
              )}
            </Typography>
            {!atividade.atual && !atividade.atendida && (
              <Fragment>
                {props.permissoesAtendente?.perm_reordena_atividades &&
                  index > 0 &&
                  index < props.listaAtividades.length - 1 && (
                    <Tooltip title="Descer atividade">
                      <IconButton
                        onClick={() => ordenarAtividade(atividade, "DOWN")}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                {props.permissoesAtendente?.perm_reordena_atividades &&
                  index > 0 &&
                  !props.listaAtividades[index - 1].atual &&
                  !props.listaAtividades[index - 1].atendida && (
                    <Tooltip title="Subir atividade">
                      <IconButton
                        onClick={() => ordenarAtividade(atividade, "UP")}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                {props.permissoesAtendente?.perm_agenda_atividade && (
                  <Tooltip title="Agendar atividade">
                    <IconButton onClick={() => agendarAtividade(atividade)}>
                      <ScheduleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {props.permissoesAtendente?.perm_excluir_atividade && (
                  <Tooltip title="Excluir atividade">
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => excluirAtividade(atividade)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Fragment>
            )}
          </div>
          {atividade?.comentarios?.length > 0 && (
            <IconButton
              title="Há comentários"
              style={{ marginLeft: 10 }}
              onClick={() => comentarios(atividade?.comentarios)}
            >
              <CommentOutlined />
            </IconButton>
          )}
        </li>
      ))}
      <DialogComentarios
        open={openDialogComentarios}
        options={optionsDialogComentarios}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ guicheReducer, loginReducer }) => {
  const { idSenha, indiceAtividade, listaAtividades } = guicheReducer;
  const { permissoesAtendente } = loginReducer;
  return { idSenha, indiceAtividade, listaAtividades, permissoesAtendente };
};
export default connect(mapStateToProps, {
  excluiAtividadeSenha,
  agendaAtividadeSenha,
  reordenaAtividadesSenha,
})(withStyles(styles)(Atividades));
