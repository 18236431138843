import React, { useEffect, useState } from "react";
import styles from "./DialogStyle";
import {
  Button,
  DialogActions,
  DialogContent,
  List,
  Dialog,
  DialogTitle,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const DialogMultiSelect = (props) => {
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const lista = options ? options.lista : props.lista;
  const id = options ? options.id : props.id;
  const descricao = options ? options.descricao : props.descricao;
  const handleClose = options ? options.handleClose : props.handleClose;
  const handleSelect = options ? options.handleSelect : props.handleSelect;

  const [list, setList] = useState([]);

  useEffect(() => {
    if (lista) {
      lista.forEach((item) => {
        item.selected = false;
      });
      setList(lista);
    }
  }, [lista]);

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <List>
          {list.map((item) => {
            return (
              <MenuItem
                button
                key={item[id]}
                onClick={() => {
                  item.selected = !item.selected;
                  const array = [];
                  list.forEach((i) => array.push(i));
                  setList(array);
                }}
                selected={item.selected}
              >
                <ListItemText>{item[descricao]}</ListItemText>
              </MenuItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} style={{ color: "red" }}>
          Cancelar
        </Button>
        <Button color="primary" autoFocus onClick={() => handleSelect(list)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogMultiSelect);
