import { getStatusEstacao } from "actions/BackofficeActions";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const PadraoAtendimento = (props) => {
  const history = useHistory();

  useEffect(() => {
    props.getStatusEstacao();
  }, []);

  return <div style={{ display: "flex", paddingTop: "10px" }}></div>;
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { supervisor } = backofficeReducer;
  return {
    supervisor,
  };
};
export default connect(mapStateToProps, {
  getStatusEstacao,
})(PadraoAtendimento);
