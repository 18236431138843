import React, { Fragment, useState } from "react";
import styles from "../BotoesAcaoStyle";
import { connect } from "react-redux";
import CustomButton from "components/Utils/CustomButton";
import GlobalizadorService from "service/GlobalizadorService";
import { Menu, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import DialogTextField from "components/Utils/Dialogs/DialogTextField";
import DialogConfirmation from "components/Utils/Dialogs/DialogConfirmation";
import DialogDemandas from "components/Utils/Dialogs/DialogDemandas";
import {
  chamadaEspecifica,
  finalizarAtendimentoDespachante,
  iniciarAtendimentoDespachante,
  imprimirDemandaAtual,
  imprimirDemandasDespachante,
  internalizaDemanda,
  cancelaDespachante,
} from "actions/DespachanteActions";
import { imprimeDemanda } from "../../../../../../../actions/BackofficeActions";
import { errorMessage } from "../../../../../../../helpers/observables";
import DialogSelecao from "../../../../../../Utils/Dialogs/DialogSelecao";
import OmnisigaAuthPopup from "../../../../../../Utils/OmnisigaAuthPopup";
import jwt_decode from "jwt-decode";

const globalizadorService = new GlobalizadorService();

const BotoesDespachante = (props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openDialogTextField, setOpenDialogTextField] = useState(false);
  const [optionsDialogTextField, setOptionsDialogTextField] = useState(null);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [optionsDialogConfirmation, setOptionsDialogConfirmation] =
    useState(null);
  const [openDialogDemandas, setOpenDialogDemandas] = useState(false);
  const [optionsDialogDemandas, setOptionsDialogDemandas] = useState(null);
  const [openDialogSelecao, setOpenDialogSelecao] = useState(false);
  const [optionsDialogSelecao, setOptionsDialogSelecao] = useState(null);

  const finalizarAtendimento = async () => {
    props.finalizarAtendimentoDespachante();
  };

  const cancelarAtendimento = async () => {
    setAnchorEl(null);
    setOptionsDialogConfirmation({
      title: "Confirmação",
      description: `Confirma o cancelamento da sessão do despachante ${props.despachante[0]?.taskBackOffice?.owner}?`,
      handleClose: () => {
        setOpenDialogConfirmation(false);
      },
      handleConfirm: () => {
        setOpenDialogConfirmation(false);
        props.cancelaDespachante();
      },
    });
    setOpenDialogConfirmation(true);
  };

  const printDemanda = async () => {
    setOptionsDialogConfirmation({
      title: "Confirmação",
      description: "Confirma a impressão da demanda atual?",
      handleClose: () => {
        setOpenDialogConfirmation(false);
      },
      handleConfirm: () => {
        setOpenDialogConfirmation(false);
        props.imprimirDemandaAtual();
      },
    });
    setOpenDialogConfirmation(true);
  };

  const printDemandasDespachante = async () => {
    setAnchorEl(null);
    setOptionsDialogConfirmation({
      title: "Confirmação",
      description: `Confirma a impressão das demandas internalizadas do despachante ${props.despachante[0]?.taskBackOffice?.owner}?`,
      handleClose: () => {
        setOpenDialogConfirmation(false);
      },
      handleConfirm: () => {
        setOpenDialogConfirmation(false);
        props.imprimirDemandasDespachante();
      },
    });
    setOpenDialogConfirmation(true);
  };

  const selecionaDemanda = async () => {
    setAnchorEl(null);
    try {
      const lista = props.despachante;
      if (props.despachante.length > 0) {
        setOptionsDialogDemandas({
          title: "Selecione a demanda que deseja atender:",
          lista: lista.map((item) => {
            if (item.taskBackOffice && item.taskBackOffice.formulario) {
              item.taskBackOffice.formulario.forEach((f) => {
                if (f.nomeCampo === "PLACA" || f.nomeCampo === "Placa") {
                  item.taskBackOffice.formulario.push({
                    ...f,
                    nomeCampo: "placa",
                  });
                }
                if (f.nomeCampo === "CHASSI" || f.nomeCampo === "Chassi") {
                  item.taskBackOffice.formulario.push({
                    ...f,
                    nomeCampo: "chassi",
                  });
                }
              });
            }
            return item;
          }),
          orderProperties: [
            { name: "protocolo", label: "Protocolo" },
            { name: "atendida", label: "Não Atendidas" },
            { name: "horaEmissao", label: "Hora de Emissão" },
            { name: "nomeAtividade", label: "Atividade / Serviço" },
            { name: "nomeCidadao", label: "Nome do Cidadão" },
          ],
          handleClose: () => setOpenDialogDemandas(false),
          handleSelect: (demanda) => {
            props.chamadaEspecifica(
              demanda.idAtividadeDemandaBo,
              demanda.idDemandaBo
            );
            setOpenDialogDemandas(false);
          },
          handleCancelar: (demanda) => {
            props.internalizaDemanda(false, demanda.taskBackOffice.id);
            setOpenDialogDemandas(false);
          },
          handleImprimir: (demanda) => {
            const impressoraId = localStorage.getItem("impressora");
            if (impressoraId) {
              props.imprimeDemanda(demanda.taskBackOffice.id, impressoraId);
            } else {
              errorMessage.next("Sem impressora vinculada.");
            }
            setOpenDialogDemandas(false);
          },
        });
        setOpenDialogDemandas(true);
      } else {
        errorMessage.next("Não há senhas nesta fila.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const selecionaFingerprint = async () => {
    try {
      const resp = await globalizadorService.listaFingerprint();
      if (resp.lista.length > 0) {
        setOptionsDialogSelecao({
          lista: resp.lista,
          title: "Selecione o dispositivo de leitura biometrica:",
          id: "id",
          descricao: "label",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            localStorage.setItem("fingerprint", item.id);
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há dispositivos disponiveis.");
      }
    } catch (err) {
      errorMessage.next(err.message);
    }
  };

  const selecionaImpressora = async () => {
    try {
      const resp = await globalizadorService.listaImpressoras();
      if (resp.lista_impressoras.length > 0) {
        setOptionsDialogSelecao({
          lista: resp.lista_impressoras,
          title: "Selecione a impressora:",
          id: "id",
          descricao: "nome",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (item) => {
            setOpenDialogSelecao(false);
            localStorage.setItem("impressora", item.id);
          },
        });
        setOpenDialogSelecao(true);
      } else {
        errorMessage.next("Não há impressoras disponiveis.");
      }
    } catch (err) {
      errorMessage.next(err.message);
    }
  };

  const onCode = (code, params) => {
    let user = jwt_decode(code);
    if (user.username) {
      props.iniciarAtendimentoDespachante(user.username);
    }
  };

  const onClose = () => console.log("closed!");

  return (
    <div className={classes.divBotoes}>
      <DialogTextField
        open={openDialogTextField}
        options={optionsDialogTextField}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        options={optionsDialogConfirmation}
      />
      <DialogDemandas
        open={openDialogDemandas}
        options={optionsDialogDemandas}
      />
      <DialogSelecao open={openDialogSelecao} options={optionsDialogSelecao} />

      <Fragment>
        <Fragment>
          {!localStorage.getItem("fingerprint") && (
            <CustomButton
              background="#898b8a"
              hover="#353535"
              onClick={() => selecionaFingerprint()}
            >
              Biometria
            </CustomButton>
          )}
          {(props.estacao.imprimirDemanda || props.estacao.imprimirDemanda) &&
            !localStorage.getItem("impressora") && (
              <CustomButton
                background="#898b8a"
                hover="#353535"
                onClick={() => selecionaImpressora()}
              >
                Impressora
              </CustomButton>
            )}
          {!props.despachante && localStorage.getItem("fingerprint") && (
            <div>
              <OmnisigaAuthPopup
                title="Autorizacao"
                onCode={onCode}
                onClose={onClose}
              >
                <CustomButton background="#898b8a" hover="#353535">
                  Despachante
                </CustomButton>
              </OmnisigaAuthPopup>
            </div>
          )}
          {!!props.idDemanda &&
            props.estacao.imprimirDemanda &&
            localStorage.getItem("impressora") && (
              <CustomButton
                background="#898b8a"
                hover="#353535"
                onClick={() => printDemanda()}
              >
                Imprimir
              </CustomButton>
            )}
          {props.despachante && (
            <Fragment>
              <CustomButton
                background="#898b8a"
                hover="#353535"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                {props.despachante[0]?.taskBackOffice?.owner.length > 16
                  ? props.despachante[0]?.taskBackOffice?.owner.substring(
                      0,
                      14
                    ) + "..."
                  : props.despachante[0]?.taskBackOffice?.owner ||
                    "Despachante"}
              </CustomButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <OmnisigaAuthPopup
                  title="Autorizacao"
                  onCode={() => finalizarAtendimento()}
                  onClose={onClose}
                  username={props.despachante[0]?.taskBackOffice?.cliente?.cpf}
                >
                  <MenuItem key="1" onClick={() => setAnchorEl(null)}>
                    Finalizar
                  </MenuItem>
                </OmnisigaAuthPopup>
                <MenuItem key="2" onClick={() => cancelarAtendimento()}>
                  Cancelar
                </MenuItem>
                <MenuItem key="3" onClick={() => selecionaDemanda()}>
                  Selecionar Demanda
                </MenuItem>
                {props.estacao.imprimirDemanda &&
                  localStorage.getItem("impressora") && (
                    <MenuItem
                      key="1"
                      onClick={() => printDemandasDespachante()}
                    >
                      Imprimir Internalizadas
                    </MenuItem>
                  )}
              </Menu>
            </Fragment>
          )}
        </Fragment>
      </Fragment>
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer, loginReducer }) => {
  const { despachante, estacao, idDemanda } = backofficeReducer;
  const { permissoesBackoffice } = loginReducer;
  return { despachante, estacao, idDemanda, permissoesBackoffice };
};

export default connect(mapStateToProps, {
  iniciarAtendimentoDespachante,
  finalizarAtendimentoDespachante,
  chamadaEspecifica,
  imprimirDemandaAtual,
  imprimirDemandasDespachante,
  internalizaDemanda,
  imprimeDemanda,
  cancelaDespachante,
})(withStyles(styles)(BotoesDespachante));
