import {
  CANCELA_ATENDIMENTO_SUCCESS,
  FINALIZA_ATENDIMENTO_SUCCESS,
  GET_LISTA_CIDADAOS_SENHA_SUCCESS,
  GUICHE_BACKOFFICE_SUCCESS,
  LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS,
  LOGOFF_SUCCESS,
  MENSAGENS_CHAT,
} from "../constants/types";
import moment from "moment";
import "moment/locale/pt-br";

const INITIAL_STATE = {
  guiche: null, // 1
  idGuiche: null, //2
  nomeGuiche: "", //3
  idSecao: 0, //4
  nomeSecao: "", //8
  idSenha: undefined, //9
  idUnidadeSenha: 0, //10
  nomeUnidadeSenha: "",
  idAtendente: 0, //11
  statusGuiche: 0, //5
  nomeStatusGuiche: "", //6
  listaBotoesGuiches: [], //7
  listaAtividades: [], //13
  listaAvaliacoes: [], // ainda nao implementado
  listaGuichesApoiando: [], // ainda nao implementado
  comentarios: [], // verificar
  isVideoAtendimento: false, // pular videosatendimentos
  senhasFila: 0, // ainda nao implementado
  nomeAtendente: "", //12
  idCidadao: 0, // ainda nao implementado
  nomeCidadao: "", // ainda nao implementado
  qteCidadaos: 0, // ainda nao implementado
  nomeCategoria: "", //17
  nomePrioridade: "", //18
  idPrioridade: "", //18
  idCategoria: "",
  corPrioridade: "", //19
  nomeServico: "", //20
  idAtividade: 0, //14 - o ponteiro do array atividades é o indiceatividade que esta dentro da senha (pra pegar o id);
  indiceAtividade: null,
  nomeAtividade: "", // 15 - o ponteiro do array atividades é o indiceatividade que esta dentro da senha (pra pegar o id);
  numeroSenha: "", // 16
  horaEmissao: "", //17
  dataEmissao: "", //17
  isFinalizaAtendimento: false, // ainda nao implementado
  tempoStatusGuiche: 0,
  listaChats: [], // ainda nao implementado
  listaCidadaos: [], // ainda nao implementado
  saidaLiveChatSolicitada: false, // ainda nao implementado
  situacaoLiveChat: null, // ainda nao implementado
  mensagensChat: [], // ainda nao implementado
  wsVideoAtendimentoRecorder: null, // ainda nao implementado
  senhasFila: null,
};

const guicheActionSuccess = (action) => {
  moment.locale("pt-br");
  // console.log(action.payload);
  const guiche = action?.payload?.atendimento?.guiche;
  const listaBotoesGuiches = action?.payload?.atendimento?.listaBotoesGuiches;
  const statusGuiche = action?.payload?.atendimento?.statusGuiche;
  const indiceAtividade = action?.payload?.atendimento?.senha?.indiceAtividade;
  const horaEmissao = action?.payload?.atendimento?.senha?.dataHoraEmissao;
  return {
    guiche: guiche,
    idGuiche: guiche?.id,
    nomeGuiche: guiche?.nome,
    idSecao: guiche?.secao?.id,
    nomeStatusGuiche: statusGuiche?.nomeStatus,
    statusGuiche: statusGuiche?.status,
    listaBotoesGuiches: listaBotoesGuiches,
    nomeSecao: action?.payload?.atendimento?.guiche?.secao?.nome,
    idSenha: action?.payload?.atendimento?.senha?.id,
    idAtendente: action?.payload?.usuario?.config?.id,
    nomeAtendente: action?.payload?.usuario?.config?.nome,
    listaAtividades: action?.payload?.atendimento?.senha?.atividades,
    idAtividade:
      action?.payload?.atendimento?.senha?.atividades[indiceAtividade]
        ?.atividade?.id,
    indiceAtividade: indiceAtividade,
    nomeAtividade:
      action?.payload?.atendimento?.senha?.atividades[indiceAtividade]
        ?.atividade?.nome,
    numeroSenha: action?.payload?.atendimento?.senha?.numero,
    nomeCategoria: action?.payload?.atendimento?.senha?.categoria?.nome,
    nomePrioridade: action?.payload?.atendimento?.senha?.prioridade?.nome,
    idPrioridade: action?.payload?.atendimento?.senha?.prioridade?.id,
    idCategoria: action?.payload?.atendimento?.senha?.categoria?.id,
    corPrioridade: action?.payload?.atendimento?.senha?.prioridade?.cor,
    nomeServico:
      action?.payload?.atendimento?.senha?.atividades[indiceAtividade]?.servico
        ?.nome,
    horaEmissao: horaEmissao
      ? moment(new Date(horaEmissao)).format("LTS")
      : "-",
    dataEmissao: horaEmissao ? moment(new Date(horaEmissao)).format("L") : "-",
    tempoStatusGuiche: statusGuiche?.tempoStatus,
    senhasFila: statusGuiche?.senhasFila,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOFF_SUCCESS:
      return INITIAL_STATE;

    case GUICHE_BACKOFFICE_SUCCESS:
    case LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS:
      return {
        ...state,
        ...guicheActionSuccess(action),
      };

    case FINALIZA_ATENDIMENTO_SUCCESS:
    case CANCELA_ATENDIMENTO_SUCCESS:
      if (state.isVideoAtendimento) {
        return {
          ...state,
          listaCidadaos: [],
          isFinalizaAtendimento: true,
          mensagensChat: [],
        };
      } else {
        return {
          ...state,
          listaCidadaos: [],
          ...guicheActionSuccess(action),
        };
      }

    case GET_LISTA_CIDADAOS_SENHA_SUCCESS:
      return {
        ...state,
        listaCidadaos: action.payload,
      };

    case MENSAGENS_CHAT:
      return {
        ...state,
        mensagensChat: action.payload,
      };

    default:
      return state;
  }
};
