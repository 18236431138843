import { LOGOFF_SUCCESS, STATUS_USUARIO_SUCCESS } from "../constants/types";

const INITIAL_STATE = {
  idUnidade: null,
  nomeUnidade: "",

  idUsuario: null,
  nomeUsuario: "",

  permissaoAtendente: null,
  permissaoBackoffice: null,
  permissaoSupervisor: null,
  permissaoEmissor: null,
  permissaoChatInterno: null,
  isVirtualBackground: null,
  iceServers: null,

  permissoesAtendente: null,
  permissoesBackoffice: null,

  isLoaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOFF_SUCCESS:
      return INITIAL_STATE;

    case STATUS_USUARIO_SUCCESS:
      return {
        ...state,
        idUnidade: action.payload.unidade.id,
        nomeUnidade: action.payload.unidade.nome,
        idUsuario: action.payload.usuario.id,
        nomeUsuario: action.payload.usuario.nome,
        permissaoAtendente: action.payload.usuario.permissaoAtendente,
        permissaoBackoffice: action.payload.usuario.permissaoBackOffice,
        permissaoSupervisor: action.payload.usuario.permissaoSupervisor,
        permissaoEmissor: action.payload.usuario.permissaoEmissor,
        permissoesAtendente: action.payload.usuario.perfilAtendente,
        permissoesBackoffice: action.payload.usuario.perfilBackoffice,
        permissaoChatInterno: action.payload.usuario.usarChat,
        isVirtualBackground: action.payload.usuario.isVirtualBackground,
        iceServers: action.payload.iceServers,
        isLoaded: true,
      };

    default:
      return state;
  }
};
