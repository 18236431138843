import { DADOS_SENHAS_FAILED, DADOS_SENHAS_SUCCESS } from "constants/types";
import GlobalizadorService from "service/GlobalizadorService";
import { errorMessage, successMessage } from "helpers/observables";

const globalizadorService = new GlobalizadorService();

export const emitirSenha = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .supervisorEmiteSenha(params)
        .then((resp) => {
          dispatch({ type: DADOS_SENHAS_SUCCESS, payload: resp });
          successMessage.next("Senha emitida com sucesso.");
          resolve(resp);
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: DADOS_SENHAS_FAILED, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};
