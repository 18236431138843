import { getStatusEstacao } from "actions/BackofficeActions";
import SubMenuSupervisao from "components/Utils/SubMenuSupervisao";
import React, { useEffect } from "react";
import { connect } from "react-redux";

const Suspensao = (props) => {
  useEffect(() => {
    props.getStatusEstacao();
  }, []);

  return <></>;
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { supervisor } = backofficeReducer;
  return {
    supervisor,
  };
};
export default connect(mapStateToProps, {
  getStatusEstacao,
})(Suspensao);
