import {
  GUICHE_BACKOFFICE_SUCCESS,
  APROVA_TODOS_FORMULARIO_SUCCESS,
  AVALIA_ARQUIVO_EXTERNO_SUCCESS,
  AVALIA_ARQUIVO_SUCCESS,
  AVALIA_FORMULARIO_SUCCESS,
  INSERIR_COMENTARIO_SUCCESS,
  LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS,
  LOGOFF_SUCCESS,
  GET_LISTA_SENHAS,
} from "../constants/types";

const INITIAL_STATE = {
  estacao: null,
  idEstacao: 0,
  statusEstacao: 0,
  nomeEstacao: "",
  idDemanda: 0,
  nomeStatusEstacao: "",
  nomeAtividadeDemanda: "",
  tempoStatus: 0,
  protocoloDemandaAtual: "",
  horarioDemandaAtual: "",
  demandasFila: 0,
  aprovacaoObrigatoria: true,
  listaComentariosDemanda: [],
  listaOpcoesDecisao: null,
  listaSenhasSup: [],
  desempenhoAtendente: null,
  cliente: null,
  task: null,
  desempenho: null,
  despachante: null,
  urlLoginDespachante: null,
  supervisor: null,
};

const backofficeActionSuccess = (action) => {
  const desempenho = {
    indiceSatisfacao: 0,
    metaAtendimento: 0,
    atendimentos: 0,
    desempenho: 0,
    cancelamentos: 0,
  };
  return {
    estacao: action.payload.backoffice?.exibicaoEstacaoBo,
    idEstacao: action.payload.backoffice?.exibicaoEstacaoBo?.idEstacaoBo,
    statusEstacao:
      action.payload.backoffice?.exibicaoEstacaoBo?.statusEstacaoBo,
    nomeEstacao: action.payload.backoffice?.exibicaoEstacaoBo?.nomeEstacaoBo,
    idDemanda: action.payload.backoffice?.exibicaoEstacaoBo?.idDemandaAtual,
    nomeStatusEstacao:
      action.payload.backoffice?.exibicaoEstacaoBo?.nomeStatusEstacaoBo,
    nomeAtividadeDemanda:
      action.payload.backoffice?.exibicaoEstacaoBo?.nomeAtividadeDemandaAtual,
    tempoStatus: action.payload.backoffice?.exibicaoEstacaoBo?.tempoStatus,
    protocoloDemandaAtual:
      action.payload.backoffice?.exibicaoEstacaoBo?.protocoloDemandaAtual,
    horarioDemandaAtual:
      action.payload.backoffice?.exibicaoEstacaoBo?.horarioDemandaAtual,
    demandasFila: action.payload.backoffice?.exibicaoEstacaoBo?.demandasFila,
    aprovacaoObrigatoria:
      action.payload.backoffice?.exibicaoEstacaoBo?.aprovacaoObrigatoria,
    imprimirFinalizar:
      action.payload.backoffice?.exibicaoEstacaoBo?.imprimirFinalizar,
    listaComentariosDemanda: action.payload.backoffice?.listaComentariosDemanda,
    listaOpcoesDecisao: action.payload.backoffice?.listaOpcoesDecisaoAtividades,
    cliente:
      action.payload.backoffice?.listaCidadaosVinculados &&
      action.payload.backoffice?.listaCidadaosVinculados.length > 0
        ? action.payload.backoffice?.listaCidadaosVinculados[0]
        : null,
    task: action.payload.task,
    desempenho: desempenho,
    despachante: action.payload.despachante,
    urlLoginDespachante: action.payload.urlLoginDespachante,
    supervisor: action.payload.supervisor,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOFF_SUCCESS:
      return INITIAL_STATE;

    case GUICHE_BACKOFFICE_SUCCESS:
    case LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS:
      return {
        ...state,
        ...backofficeActionSuccess(action),
      };

    case AVALIA_FORMULARIO_SUCCESS:
    case AVALIA_ARQUIVO_SUCCESS:
    case AVALIA_ARQUIVO_EXTERNO_SUCCESS:
    case APROVA_TODOS_FORMULARIO_SUCCESS:
      return { ...state, task: action.payload.task };
    case GET_LISTA_SENHAS:
      return { ...state, listaSenhasSup: action.payload?.listaSenhas };

    case INSERIR_COMENTARIO_SUCCESS:
      return {
        ...state,
        ...backofficeActionSuccess(action),
        listaComentariosDemanda:
          action.payload.backoffice.listaComentariosDemanda,
      };

    default:
      return state;
  }
};
