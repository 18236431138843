import React, { Fragment, useEffect, useState } from "react";
import styles from "components/Utils/Dialogs/DialogStyle";
import GlobalizadorService from "service/GlobalizadorService";
import {
  desvinculaClienteSenha,
  vinculaClienteSenha,
  getListaCidadaosSenha,
} from "actions/GuicheActions";
import { getStatusEstacao } from "actions/BackofficeActions";
import { connect } from "react-redux";
import { errorMessage, successMessage } from "helpers/observables";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "components/Utils/CustomButton";
const globalizadorService = new GlobalizadorService();

const DialogCliente = (props) => {
  const { classes } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [dados, setDados] = useState({});
  const [listaClientes, setListaClientes] = useState([]);
  const [listaCamposBusca, setListaCamposBusca] = useState([]);

  const styles = {
    button: {
      width: "15rem",
      margin: "1rem",
    },
    divTab: {
      display: "flex",
      flexDirection: "column",
    },
    divBotoes: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    colAcoes: {
      minWidth: "8.5rem",
    },
  };

  const estados = [
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ];

  useEffect(() => {
    const info = {};
    props.listaCamposCliente.forEach((campo) => {
      info[campo.nomeCampo] = "";
    });
    setListaCamposBusca(
      props.listaCamposCliente.filter((campo) => campo.busca)
    );
    setDados(info);
    setSelectedTab(0);
    setListaClientes([]);
  }, [props.listaCamposCliente]);

  const buscarCliente = async () => {
    let isEmpty = true;
    for (let key of Object.keys(dados)) {
      if (dados[key]) {
        isEmpty = false;
        break;
      }
    }

    if (isEmpty) {
      errorMessage.next("Nenhum campo foi informado.");
    } else {
      const resp = await globalizadorService.buscaCliente({
        dadosCliente: dados,
      });
      if (resp.listaClientes && resp.listaClientes.length > 0) {
        setListaClientes(
          resp.listaClientes.filter((cliente) => {
            if (
              props.listaCidadaos.find(
                (c) => c.idCliente === cliente.dados.idCliente
              )
            ) {
              return false;
            }
            return true;
          })
        );
      } else {
        errorMessage.next("Cliente não encontrado.");
      }
    }
  };

  const salvarCliente = async (vincular, desvincular) => {
    if (!dados.nomeCliente) {
      errorMessage.next("Todos os campos obrigatórios devem ser informados.");
      return;
    }

    const camposObrigatorios = props.listaCamposCliente.filter(
      (campo) => campo.obrigatorio
    );
    for (const campo of camposObrigatorios) {
      if (!dados[campo.nomeCampo]) {
        errorMessage.next("Todos os campos obrigatórios devem ser informados.");
        return;
      }
    }

    const resp = await globalizadorService.salvaCliente({
      dadosCliente: dados,
    });

    if (vincular) {
      props.vinculaClienteSenha(
        resp.idCliente,
        props.idSenha,
        props.idAtendente
      );
    } else {
      successMessage.next("Cliente salvo com sucesso.");
    }

    if (desvincular) {
      props.desvinculaClienteSenha(
        resp.idCliente,
        props.idSenha,
        props.idAtendente
      );
    }

    props.getListaCidadaosSenha(props.idSenha);
    props.getStatusEstacao();

    props.handleClose();
  };

  const limpar = () => {
    let temp = { ...dados };
    Object.keys(temp).forEach((key) => (temp = { ...temp, [key]: "" }));
    setDados(temp);
    setListaClientes([]);
  };

  const editarCliente = async (cliente) => {
    const resp = await globalizadorService.buscaCliente({
      dadosCliente: { idCliente: cliente.idCliente },
    });
    setDados(resp.listaClientes[0].dados);
    setSelectedTab(1);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose()}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>Vincular Cliente</span>
      </DialogTitle>
      <DialogContent>
        {props.listaCidadaos && props.listaCidadaos.length > 0 && (
          <TableContainer component={Paper} style={{ maxHeight: 240 }}>
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                color: "var(--theme-primary)",
                marginBottom: "0.5rem",
              }}
            >
              Clientes vinculados
            </Typography>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={styles.colAcoes}>Ações</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Nome</TableCell>

                  {listaCamposBusca.find(
                    (campo) => campo.nomeCampo === "documento1" && campo.exibir
                  ) && (
                    <TableCell style={{ fontWeight: "bold" }}>CPF</TableCell>
                  )}

                  {listaCamposBusca.find(
                    (campo) => campo.nomeCampo === "email" && campo.exibir
                  ) && (
                    <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                  )}

                  {listaCamposBusca.find(
                    (campo) => campo.nomeCampo === "telefone" && campo.exibir
                  ) && (
                    <TableCell style={{ fontWeight: "bold" }}>
                      Telefone
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.listaCidadaos.map((cliente, index) => {
                  return (
                    <TableRow key={`row-cliente-${index}`}>
                      <TableCell>
                        <Tooltip title="Editar">
                          <IconButton
                            onClick={() => editarCliente(cliente).then()}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Desvincular">
                          <IconButton
                            onClick={() => {
                              props.desvinculaClienteSenha(
                                cliente.idCliente,
                                props.idSenha,
                                props.idAtendente
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{cliente.nomeCliente}</TableCell>
                      {listaCamposBusca.find(
                        (campo) =>
                          campo.nomeCampo === "documento1" && campo.exibir
                      ) && <TableCell>{cliente.documento1}</TableCell>}
                      {listaCamposBusca.find(
                        (campo) => campo.nomeCampo === "email" && campo.exibir
                      ) && <TableCell>{cliente.email}</TableCell>}
                      {listaCamposBusca.find(
                        (campo) =>
                          campo.nomeCampo === "telefone" && campo.exibir
                      ) && <TableCell>{cliente.telefone}</TableCell>}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          centered
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          <Tab label="Busca" />
          <Tab label="Inclusão/Edição" />
        </Tabs>
        {selectedTab === 0 && (
          <div style={styles.divTab}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "40vh",
                overflowY: "auto",
              }}
            >
              {listaCamposBusca.map((campo, index) => {
                return campo.nomeCampo === "sexo" ? (
                  <Fragment>
                    <InputLabel id="select-label-sexo">Sexo</InputLabel>
                    <Select
                      labelId="select-label-sexo"
                      value={dados[campo.nomeCampo]}
                      onChange={(e) => {
                        setDados({
                          ...dados,
                          [campo.nomeCampo]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={null}>Nenhum</MenuItem>
                      <MenuItem value={true}>Masculino</MenuItem>
                      <MenuItem value={false}>Feminino</MenuItem>
                    </Select>
                  </Fragment>
                ) : (
                  <TextField
                    key={`campo-cliente-${index}`}
                    label={campo.nomeExibicao}
                    className={classes.input}
                    value={dados[campo.nomeCampo]}
                    onChange={(e) => {
                      setDados({ ...dados, [campo.nomeCampo]: e.target.value });
                    }}
                  />
                );
              })}
            </div>

            <div style={styles.divBotoes}>
              <CustomButton style={styles.button} onClick={() => limpar()}>
                Limpar
              </CustomButton>
              <CustomButton
                style={styles.button}
                onClick={() => buscarCliente()}
              >
                Buscar
              </CustomButton>
            </div>

            {listaClientes && listaClientes.length > 0 && (
              <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.colAcoes}>Ações</TableCell>
                      {listaCamposBusca.map((campo, index) => {
                        return (
                          <TableCell
                            key={`col-cliente-${index}`}
                            style={{ fontWeight: "bold" }}
                          >
                            {campo.nomeExibicao}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaClientes.map((cliente, index) => {
                      return (
                        <TableRow key={`row-cliente-${index}`}>
                          <TableCell>
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={() => {
                                  setDados(cliente.dados);
                                  setSelectedTab(1);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Vincular">
                              <IconButton
                                onClick={() => {
                                  props.vinculaClienteSenha(
                                    cliente.dados.idCliente,
                                    props.idSenha,
                                    props.idAtendente
                                  );
                                  props.handleClose();
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          {listaCamposBusca.map((campo, index) => {
                            return (
                              <TableCell key={`cell-cliente-${index}`}>
                                {cliente.dados[campo.nomeCampo]}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
        {selectedTab === 1 && (
          <div style={styles.divTab}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "40vh",
                overflowY: "auto",
              }}
            >
              {props.listaCamposCliente.map((campo, index) => {
                return (
                  <Fragment>
                    {campo.nomeCampo === "sexo" && (
                      <Fragment>
                        <InputLabel id="select-label-sexo">
                          {campo.nomeExibicao}
                        </InputLabel>
                        <Select
                          labelId="select-label-sexo"
                          value={dados[campo.nomeCampo]}
                          onChange={(e) => {
                            setDados({
                              ...dados,
                              [campo.nomeCampo]: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value={null}>Nenhum</MenuItem>
                          <MenuItem value={true}>Masculino</MenuItem>
                          <MenuItem value={false}>Feminino</MenuItem>
                        </Select>
                      </Fragment>
                    )}
                    {(campo.nomeCampo === "estado" ||
                      campo.nomeCampo === "estadoNascimento") && (
                      <Fragment>
                        <InputLabel id={"select-label-" + campo.nomeCampo}>
                          {campo.nomeExibicao}
                        </InputLabel>
                        <Select
                          labelId={"select-label-" + campo.nomeCampo}
                          value={dados[campo.nomeCampo]}
                          onChange={(e) => {
                            setDados({
                              ...dados,
                              [campo.nomeCampo]: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value={null}>Nenhum</MenuItem>
                          {estados.map((estado, index) => (
                            <MenuItem key={index} value={estado.value}>
                              {estado.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Fragment>
                    )}
                    {campo.nomeCampo !== "sexo" &&
                      campo.nomeCampo !== "estado" &&
                      campo.nomeCampo !== "estadoNascimento" && (
                        <TextField
                          key={`campo-cliente-${index}`}
                          label={
                            campo.nomeExibicao + (campo.obrigatorio ? " *" : "")
                          }
                          className={classes.input}
                          value={dados[campo.nomeCampo]}
                          onChange={(e) => {
                            setDados({
                              ...dados,
                              [campo.nomeCampo]: e.target.value,
                            });
                          }}
                        />
                      )}
                  </Fragment>
                );
              })}
            </div>
            <div style={styles.divBotoes}>
              <CustomButton
                style={styles.button}
                onClick={() => salvarCliente(false)}
              >
                Salvar
              </CustomButton>
              {dados &&
                !props.listaCidadaos.find(
                  (c) => c.idCliente === dados.idCliente
                ) && (
                  <CustomButton
                    style={styles.button}
                    onClick={() => salvarCliente(true)}
                  >
                    Salvar e Vincular
                  </CustomButton>
                )}
              {dados &&
                props.listaCidadaos.find(
                  (c) => c.idCliente === dados.idCliente
                ) && (
                  <CustomButton
                    style={styles.button}
                    onClick={() => salvarCliente(false, true)}
                  >
                    Salvar e Desvincular
                  </CustomButton>
                )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = ({ guicheReducer }) => {
  const { idSenha, idAtendente, listaCidadaos } = guicheReducer;
  return { idSenha, idAtendente, listaCidadaos };
};
export default connect(mapStateToProps, {
  vinculaClienteSenha,
  desvinculaClienteSenha,
  getListaCidadaosSenha,
  getStatusEstacao,
})(withStyles(styles)(DialogCliente));
