import React from "react";
import {
  Button,
  CardActions,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 45 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  createData(
    "(02/02/2023 - 09:55:16)",
    "Emissão da senha em supervisor pelo usuário Administrador"
  ),
  createData(
    "(02/02/2023 - 09:55:16)",
    "Emissão da senha em supervisor pelo usuário Administrador"
  ),
  createData(
    "(02/02/2023 - 09:55:16)",
    "Emissão da senha em supervisor pelo usuário Administrador"
  ),
  createData(
    "(02/02/2023 - 09:55:16)",
    "Emissão da senha em supervisor pelo usuário Administrador"
  ),
];

const ModalRastreamento = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.grey[200],
    },
    body: {
      fontSize: 15,
    },
  }))(TableCell);

  return (
    <>
      <Modal
        children
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Card color="primary" variant="elevation">
            <CardHeader
              style={{ subheader: "color: white" }}
              className={classes.cardHeader}
              title={
                <Typography className={classes.titleCard}>
                  RASTREAMENTO DA SENHA 1
                </Typography>
              }
              subheader={
                <Typography className={classes.subColor}>
                  Atualizado em 02/02/2023 - 09:56:30
                </Typography>
              }
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Data e hora</StyledTableCell>
                      <StyledTableCell align="right">Descrição</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions className={classes.cardActions} disableSpacing>
              <ColorButton
                onClick={props?.handleClose}
                size="small"
                color="primary"
                variant="contained"
              >
                Fechar
              </ColorButton>
            </CardActions>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ModalRastreamento;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    minWidth: 350,
    maxWidth: 750,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid grey",
    borderRadius: "9px",
    boxShadow: theme.shadows[1],
  },
  table: {
    minWidth: 350,
  },
  cardHeader: {
    margin: "-2px",
    borderTopRightRadius: "9px",
    borderTopLefttRadius: "9px",
    background: "#00598b",
  },
  subColor: {
    color: "rgb(255 255 255 / 65%)",
    fontWeight: "300",
    fontSize: "0.9rem",
  },
  titleCard: {
    fontSize: "1.2rem",
    color: "white",
  },
  cardActions: {
    flexDirection: "row-reverse",
    paddingRight: "15px",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  width: "130px",
  fontSize: "0.7rem",
  fontWeight: "400",
  textAlign: "center",
  textTransform: "lowercase",
  borderRadius: "3px",
  justifyContent: "center",
  marginRight: "5px",
  boxShadow: "none",
  marginBottom: "5px",
  marginRight: "5px",
}));
