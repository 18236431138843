import {
  WEBSOCKET_CLOSE,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_REQUEST,
  WEBSOCKET_SEND,
  WEBSOCKET_TOKEN,
} from "../../constants/types";
import Servers from "../../constants/Servers";
import { webSocketConectadoService, webSocketErroService, webSocketMessageService } from "./WebSocketService";

const INITIAL_STATE = {
  websocket: null,
  token: null,
};

const baseUrl = Servers.url.GLOBALIZADOR.replace("http", "ws").replace("/v1", "/ws/");

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBSOCKET_MESSAGE:
      const data = JSON.parse(action.payload.data);
      return { ...state, ...data };
    case WEBSOCKET_TOKEN:
      // Configure the object
      const token = action.payload;
      return { ...state, ...token };
    // User request to connect
    case WEBSOCKET_REQUEST:
      if (state.websocket && (state.websocket.readyState === 0 || state.websocket.readyState === 1)) {
        return { ...state };
      }
    // Configure the object
    // const websocket = new WebSocket(baseUrl + state.token);

    // Attach the callbacks
    // websocket.onopen = () => webSocketConectadoService();
    // websocket.onerror = () => webSocketErroService(websocket);
    //websocket.onclose = () => webSocketCloseService(state.token);
    // websocket.onmessage = (event) => webSocketMessageService(event);

    // return { ...state, websocket };

    // User request to send a message
    case WEBSOCKET_SEND:
      state.websocket.send(JSON.stringify(action.payload));
      break;

    // User request to disconnect
    case WEBSOCKET_CLOSE:
      if (state.websocket) {
        state.websocket.close();
      }
      return { ...state, websocket: null, token: null };

    default:
      return state;
  }
};
