import {
  CANCELA_ATENDIMENTO_SUCCESS,
  FINALIZA_ATENDIMENTO_SUCCESS,
  GET_LISTA_CIDADAOS_SENHA_SUCCESS,
  GUICHE_BACKOFFICE_SUCCESS,
  MENSAGENS_CHAT,
} from "constants/types";
import GlobalizadorService from "service/GlobalizadorService";
import { errorMessage, successMessage } from "helpers/observables";
import { store } from "index";

const globalizadorService = new GlobalizadorService();

export const statusFilaUpdated = (data) => {
  store.dispatch({
    type: GUICHE_BACKOFFICE_SUCCESS,
    payload: data,
  });
};

export const iniciaAtendimento = (idUsuario, idGuiche, idUnidade) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .iniciaAtendimento(idUsuario, idGuiche, idUnidade)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento iniciado com sucesso.");
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const finalizaAtendimento = (idGuiche, infoChat) => {
  return async (dispatch) => {
    try {
      if (infoChat) {
        await globalizadorService.vinculaTextoChatSenha(
          infoChat.idSenha,
          infoChat.idAtividade,
          infoChat.idAtendente,
          infoChat.mensagens
        );
      }
      const resp = await globalizadorService.finalizaAtendimento(idGuiche);
      dispatch({ type: FINALIZA_ATENDIMENTO_SUCCESS, payload: resp });
      successMessage.next("Atendimento finalizado com sucesso.");
    } catch (err) {
      dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
      errorMessage.next(err.message);
      throw err;
    }
  };
};

export const cancelaAtendimento = (idGuiche, idSenha, idMotivo, infoChat) => {
  return async (dispatch) => {
    try {
      if (infoChat) {
        await globalizadorService.vinculaTextoChatSenha(
          infoChat.idSenha,
          infoChat.idAtividade,
          infoChat.idAtendente,
          infoChat.mensagens
        );
      }
      const resp = await globalizadorService.cancelaSenha(
        idGuiche,
        idSenha,
        idMotivo
      );
      dispatch({ type: CANCELA_ATENDIMENTO_SUCCESS, payload: resp });
      successMessage.next("Atendimento cancelado com sucesso.");
    } catch (err) {
      dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
      errorMessage.next(err.message);
      throw err;
    }
  };
};

export const proximaSenha = (
  idGuiche,
  senhaEspecifica,
  idAtividade,
  idPrioridade
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .proximaSenha(idGuiche, senhaEspecifica, idAtividade, idPrioridade)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Próxima senha chamada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const criarSenha = (idServico, idGuiche, idAtendente) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .criarSenha(idServico, idGuiche, idAtendente)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha criada com sucesso.");
        })
        .catch((err) => {
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const congelarSenha = (idGuiche, idSenha, idUsuario, idMotivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .congelaSenha(idGuiche, idSenha, idUsuario, idMotivo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha congelada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const descongelarSenha = (
  idGuiche,
  idSenha,
  idUsuario,
  idGuicheDestino
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .descongelaSenha(idGuiche, idSenha, idUsuario, idGuicheDestino)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha descongelada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const rechamarSenha = (idGuiche, idSenha) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .rechamaSenha(idGuiche, idSenha)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha rechamada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const adicionarServico = (idServico) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .adicionarServico(idServico)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Serviço adicionado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const alterarServico = (idServico) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .alterarServico(idServico)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Serviço alterado com sucesso.");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const excluiAtividadeSenha = (idSenha, indiceRealAtividade) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .excluiAtividadeSenha(idSenha, indiceRealAtividade)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atividade excluída com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const suspenderUsuario = (idMotivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .suspendeUsuario(idMotivo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento suspenso com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const retornarSuspensao = (idUsuario) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .retornaUsuarioSuspensao(idUsuario)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento retomado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const senhaEspecifica = (senhaId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .senhaEspecifica(senhaId)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha selecionada.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const salvarComentarioSenha = (textoComentario) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .salvaComentarioSenha(textoComentario)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Comentário adicionado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const pausarAtendimento = (idMotivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .pausarAtendimento(idMotivo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Pausa no atendimento realizada sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const continuarAtendimento = (idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .continuarAtendimento(idGuiche)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento retomado sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const indisponibilizarGuiche = (idGuiche, idMotivo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .indisponibilizarGuiche(idGuiche, idMotivo)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Guichê indisponibilizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const encerrarIndisponibilidadeGuiche = (idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .encerrarIndisponibilidadeGuiche(idGuiche)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Indisponibilidade encerrada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const iniciarApoio = (idGuiche, idGuicheApoio) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .iniciaApoioGuiche(idGuiche, idGuicheApoio)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Apoio iniciado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const finalizarApoio = (idAtendenteApoiador) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .finalizaApoioGuiche(idAtendenteApoiador)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Apoio finalizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const chamadaEspecifica = (senha) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .chamadaEspecifica(senha)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Chamada específica realizada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const liberaGuicheAguardandoAoFinalizar = (idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .liberaGuicheAguardandoAoFinalizar(idGuiche)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Guichê liberado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const transferirSenhaGuiche = (
  idGuiche,
  idSenha,
  idUsuario,
  idGuicheDestino
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .transfereSenhaGuiche(idGuiche, idSenha, idUsuario, idGuicheDestino)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha transferida com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const reativarSenha = (idGuiche, idSenha) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .reativaSenha(idGuiche, idSenha)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha reativada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const sincronizarTerminal = (idGuiche, indicePalavra) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .selecionaPalavraSincronismoAval(idGuiche, indicePalavra)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Terminal sincronizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const agendaAtividadeSenha = (
  idSenha,
  indiceRealAtividade,
  horaAgendada
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .agendaAtividadeSenha(idSenha, indiceRealAtividade, horaAgendada)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atividade agendada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const reordenaAtividadesSenha = (idSenha, array) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .reordenaAtividadesSenha(idSenha, array)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atividade reordenada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const atividadeAdicional = (idGuiche, idSenha, idAtividade) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .atividadeAdicional(idGuiche, idSenha, idAtividade)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atividade adicional inserida com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const alteraAtividadeSenha = (idAtividade) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .alteraAtividadeSenha(idAtividade)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atividade alterada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const proximoPasso = (idGuiche, idSenha) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .proximoPasso(idGuiche, idSenha)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Próximo passo realizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const avaliacaoGuiche = (idGuiche, stringAvaliacao) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .avaliacaoGuiche(idGuiche, stringAvaliacao)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Avaliação realizada com sucesso.");
          if (resp.guiche.exibicao_guiche.finaliza_avaliacao) {
            dispatch(finalizaAtendimento(idGuiche));
          }
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const vinculaClienteSenha = (idCliente, idSenha, idAtendente) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .vinculaClienteSenha(idCliente, idSenha, idAtendente)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Cliente vinculado com sucesso.");
          dispatch(getListaCidadaosSenha(idSenha));
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const desvinculaClienteSenha = (idCliente, idSenha, idAtendente) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .desvinculaClienteSenha(idCliente, idSenha, idAtendente)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Cliente desvinculado com sucesso.");
          dispatch(getListaCidadaosSenha(idSenha));
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const getListaCidadaosSenha = (idSenha) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .listaCidadaosSenha(idSenha)
        .then((resp) => {
          dispatch({
            type: GET_LISTA_CIDADAOS_SENHA_SUCCESS,
            payload: resp.listaCidadaos,
          });
        })
        .catch((err) => {
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const recusarAvaliacao = (idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .recusarAvaliacao(idGuiche)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Avaliação recusada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const finalizaLiveChat = (idLiveChat) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .finalizaLiveChat(idLiveChat)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Chat finalizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const finalizaAtendimentoLiveChat = (idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .finalizaAtendimentoLiveChat(idGuiche)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento de chat finalizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const solicitaSairModoLiveChat = (idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .solicitaSairModoLiveChat(idGuiche)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Saída do atendimento solicitada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const alteraPrioridadeGuiche = (id) => {
  console.log(id);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .guicheAlteraPrioridade(id)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Prioridade alterada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const alteraCategoriaGuiche = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .guicheAlteraCategoria(id)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Categoria alterada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const congelarSenhaGuiche = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .guicheCongela(id)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha congelada.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const descongelarSenhaGuiche = (obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .guicheDescongelarSenha(obj)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Senha descongelada.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.message);
          reject(err.message);
        });
    });
  };
};

export const mensagensChat = (mensagens) => {
  return (dispatch) => {
    dispatch({ type: MENSAGENS_CHAT, payload: mensagens });
  };
};
