import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./LoginStyle";
import { errorMessage } from "helpers/observables";
import GlobalizadorService from "../../service/GlobalizadorService";
import { websocketToken } from "../../service/websocket/WebSocketActions";
import packageJson from "../../../package.json";
import {
  Paper,
  FormControl,
  TextField,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import SseService from "service/sse/SseService";

const globalizadorService = new GlobalizadorService();

const Login = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [unidade, setUnidade] = useState(null);
  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("bo.token")) {
      props.history.push("/home");
    } else {
      sessionStorage.clear();

      globalizadorService
        .getUnidades()
        .then((resp) => {
          setUnidades(resp);
          const u = resp.find((u) => u.local === true);
          if (typeof u != "undefined") {
            setUnidade(u);
          }
        })
        .catch((err) => {
          errorMessage.next(err);
        });
    }
  }, [props]);

  const login = async () => {
    if (usuario && senha) {
      try {
        const resp = await globalizadorService.loginUsuario(
          usuario,
          senha,
          unidade.id
        );
        sessionStorage.setItem("bo.token", resp.token);
        sessionStorage.setItem("key", resp.key);
        props.websocketToken(resp.token);
        SseService(resp.key);
        props.history.push("/home");
      } catch (err) {
        errorMessage.next(err.Message);
      }
    } else {
      errorMessage.next(t("Usuário e senha são obrigatórios."));
    }
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        className={classes.logo}
        alt="logo"
      />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          login().then();
        }}
        style={{ width: "250px", textAlign: "center" }}
      >
        <Typography variant="body2" style={{ color: "#aeaeae" }}>
          {packageJson.version}
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          style={{ textAlign: "center", marginBottom: 20 }}
        >
          SigaNet
        </Typography>
        <TextField
          fullWidth
          style={{ textAlign: "left", marginBottom: 5 }}
          id="outlined-select-unidade"
          select
          label={t("Selecione a unidade")}
          size="small"
          value={unidade || ""}
          onChange={(event) => setUnidade(event.target.value)}
        >
          {unidades.map((unidade) => {
            return (
              <MenuItem key={unidade.id} value={unidade}>
                {unidade.nome}
              </MenuItem>
            );
          })}
        </TextField>

        {unidade && (
          <Fragment>
            <FormControl margin="normal">
              <TextField
                label={t("Usuário")}
                className={classes.input}
                autoComplete="username"
                onChange={(event) => setUsuario(event.target.value)}
              />
            </FormControl>
            <FormControl margin="normal">
              <TextField
                label={t("Senha")}
                className={classes.input}
                type="password"
                autoComplete="current-password"
                onChange={(event) => setSenha(event.target.value)}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
              >
                Login
              </Button>
            </FormControl>
          </Fragment>
        )}
      </form>
    </Paper>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps, { websocketToken })(
  withStyles(styles)(Login)
);
