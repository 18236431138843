import {
  WEBSOCKET_FAILURE,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_REQUEST,
  WEBSOCKET_SUCCESS,
  WEBSOCKET_TOKEN,
} from "../../constants/types";
import { getStatusEstacao } from "../../actions/BackofficeActions";

export const conectaWebSocket = () => {
  return (dispatch) => {
    dispatch({ type: WEBSOCKET_REQUEST });
  };
};

export const websocketToken = (token) => {
  return (dispatch) => {
    dispatch({ type: WEBSOCKET_TOKEN, payload: { token: token } });
  };
};

export const webSocketConectado = () => {
  return (dispatch) => {
    dispatch({ type: WEBSOCKET_SUCCESS });
  };
};

export const webSocketErro = (websocket) => {
  websocket.close();
  return (dispatch) => {
    dispatch({ type: WEBSOCKET_FAILURE });
  };
};

export const webSocketClose = (websocket) => {
  return (dispatch, getState) => {
    dispatch({ type: WEBSOCKET_FAILURE });
    if (getState().token) {
      getStatusEstacao(getState().token);
    }
  };
};

export const webSocketMessage = (event) => {
  return (dispatch) => {
    dispatch({ type: WEBSOCKET_MESSAGE, payload: event });
  };
};
