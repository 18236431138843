import React, { Component } from "react";
import { errorMessage } from "../../../../helpers/observables";
import GlobalizadorService from "../../../../service/GlobalizadorService";
import { connect } from "react-redux";
import styles from "../../HomeStyle";
import WebSocketChatInterno from "../../../../service/websocket/WebSocketChatInterno";
import Servers from "../../../../constants/Servers";

import {
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Send as SendIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";

const customStyle = {
  paperChat: {
    borderTop: "1px solid var(--theme-grey)",
    borderLeft: "1px solid var(--theme-grey)",
    borderRight: "1px solid var(--theme-grey)",
    width: "22rem",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    right: "2rem",
  },
  divTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    padding: "0.5rem",
    background: "var(--theme-primary)",
  },
  divChatOpened: {
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
  },
  divMensagens: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  mensagemWrapper: {
    borderRadius: 10,
    backgroundColor: "#e5eff8",
    margin: "0.2rem",
    padding: "0.4rem 0.75rem",
  },
};

const globalizadorService = new GlobalizadorService();

class ChatInterno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mensagens: [],
      usuarios: [],
      grupos: [],
      destinatario: null,
      mensagem: "",
      statusWebsocket: false,
      alertMensagemNaoLida: false,
    };
    this.divMensagensRef = React.createRef();
  }

  handleListaDestinatarios = (resp) => {
    let listaGrupos = [];
    if (resp.todos) {
      listaGrupos = [{ id: -1, nome: "Todos os Grupos", tipo: "todos" }];
    }
    this.setState({
      usuarios: resp.listaUsuarios
        .map((u) => ({ ...u, tipo: "usuario" }))
        .concat(resp.listaSupervisores.map((s) => ({ ...s, tipo: "usuario" }))),
      grupos: listaGrupos.concat(
        resp.listaGrupos.map((g) => ({ ...g, tipo: "grupo" }))
      ),
    });
    if (this.state.destinatario) {
      if (this.state.destinatario.tipo === "usuario") {
        setTimeout(() => {
          this.setState({
            destinatario: this.state.usuarios.find((u) => u.id === u.id),
          });
        }, 10);
      } else {
        setTimeout(() => {
          this.setState({
            destinatario: this.state.grupos.find((g) => g.id === g.id),
          });
        }, 10);
      }
    }
  };

  handleMensagensInicial = (resp) => {
    this.setState({ mensagens: resp.listaMensagens });
    this.scrollToBottom();
  };

  handleNovaMensagem = (resp) => {
    this.setState({
      mensagens: this.state.mensagens.concat(resp.listaMensagens),
    });
    this.scrollToBottom();
    if (!this.state.open) {
      this.setState({ alertMensagemNaoLida: true });
    }
  };

  handleStatusWebsocket = (statusWebsocket) => {
    this.setState({ statusWebsocket });
  };

  scrollToBottom() {
    setTimeout(() => {
      document.getElementById("div-mensagens").scrollTop =
        document.getElementById("div-mensagens").scrollHeight;
    }, 1);
  }

  componentDidMount() {
    this.websocket = new WebSocketChatInterno(
      Servers.url.WSCHATINTERNO,
      this.props.idUsuario,
      this.props.permissaoSupervisor,
      (resp) => this.handleListaDestinatarios(resp),
      (resp) => this.handleMensagensInicial(resp),
      (resp) => this.handleNovaMensagem(resp),
      (status) => this.handleStatusWebsocket(status)
    );
    document.addEventListener("keydown", this.keydownHandler.bind(this));
  }

  componentWillUnmount() {
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }
  }

  enviar = async () => {
    try {
      if (this.state.mensagem && this.state.destinatario) {
        const msg = {
          idUsuarioRemetente: this.props.idUsuario,
          mensagem: this.state.mensagem,
        };
        if (this.state.destinatario.tipo === "todos") {
          msg.todos = true;
        } else if (this.state.destinatario.tipo === "usuario") {
          msg.idUsuarioDestinatario = this.state.destinatario.id;
        } else {
          msg.idGrupoDestinatario = this.state.destinatario.id;
        }
        await globalizadorService.enviaMsgChat(msg);
        this.setState({ mensagem: "" });
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  urlify = (text) => {
    if (text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '">' + url + "</a>";
      });
    }
    return text;
  };

  keydownHandler = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && e.target.type === "textarea") {
      this.enviar().then();
      e.preventDefault();
    }
  };

  render() {
    return (
      <Paper
        style={{
          ...customStyle.paperChat,
          display: this.state.statusWebsocket ? "" : "none",
        }}
      >
        <div
          style={customStyle.divTitle}
          onClick={() => {
            this.setState({
              open: !this.state.open,
              alertMensagemNaoLida: false,
            });
          }}
        >
          <Typography variant="body1" style={{ color: "white" }}>
            Mensagens
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            {this.state.alertMensagemNaoLida && (
              <PriorityHighIcon style={{ color: "red" }} />
            )}
            {this.state.open ? (
              <KeyboardArrowDownIcon style={{ color: "white" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "white" }} />
            )}
          </div>
        </div>
        <Collapse in={this.state.open}>
          <div style={customStyle.divChatOpened}>
            <div
              id="div-mensagens"
              style={customStyle.divMensagens}
              ref={this.divMensagensRef}
            >
              {this.state.mensagens.map((msg) => (
                <div
                  style={{
                    ...customStyle.mensagemWrapper,
                    background:
                      msg.idRemetente === this.props.idUsuario
                        ? "#e5eff8"
                        : "#bfdbf0",
                    textAlign:
                      msg.idRemetente === this.props.idUsuario
                        ? "right"
                        : "left",
                  }}
                >
                  <Typography
                    key={`mensagem-user-${msg.id}`}
                    variant="body2"
                    style={{
                      whiteSpace: "pre-line",
                      fontWeight: "bold",
                      color: "var(--theme-primary)",
                    }}
                  >
                    {msg.nomeRemetente} para {msg.nomeDestinatario}
                  </Typography>
                  <Typography
                    key={`mensagem-${msg.id}`}
                    variant="body2"
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    <span
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{
                        __html: this.urlify(msg.texto),
                      }}
                    />
                  </Typography>
                  <div className="mensagem-horario-wrapper">
                    <Typography
                      key={`horario-${msg.id}`}
                      color="textSecondary"
                      variant="body2"
                      style={{
                        textAlign:
                          msg.idRemetente === this.props.idUsuario
                            ? "right"
                            : "left",
                      }}
                    >
                      {msg.horario}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <FormControl fullWidth>
              <InputLabel>Destinatário</InputLabel>
              <Select
                value={this.state.destinatario}
                onChange={(e) =>
                  this.setState({ destinatario: e.target.value })
                }
              >
                {this.state.grupos.map((g) => (
                  <MenuItem value={g}>{g.nome}</MenuItem>
                ))}
                {this.state.usuarios.map((u) => (
                  <MenuItem value={u}>{u.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                value={this.state.mensagem}
                variant="outlined"
                size="small"
                multiline={true}
                onChange={(e) => this.setState({ mensagem: e.target.value })}
                style={{ flex: 1 }}
              />
              <IconButton
                onClick={() => this.enviar()}
                disabled={!this.state.mensagem || !this.state.destinatario}
              >
                <SendIcon />
              </IconButton>
            </div>
          </div>
        </Collapse>
      </Paper>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => {
  const { idUsuario, permissaoSupervisor } = loginReducer;
  return { idUsuario, permissaoSupervisor };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ChatInterno));
