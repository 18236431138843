const styles = () => ({
  paper: {
    padding: "1rem",
    borderRadius: 0,
  },
  divCliente: {
    display: "flex",
    alignItems: "center",
  },
  divServico: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  tag: {
    padding: "0.2rem",
    margin: "0.1rem",
  },
  icon: {
    fontSize: "2rem",
    color: "var(--theme-primary)",
  },
  labelCliente: {
    color: "var(--theme-primary)",
    fontWeight: "bold",
  },
  labelQteCidadaos: {
    color: "var(--theme-primary)",
    fontWeight: "bold",
    marginLeft: "0.5rem",
  },
  labelServico: {
    color: "var(--theme-grey-dark)",
  },
  labelDemandaAtual: {
    color: "var(--theme-grey-dark)",
    fontWeight: "normal",
  },
  divAtividade: {
    display: "flex",
    alignItems: "center",
  },
});

export default styles;
