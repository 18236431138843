import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btnFiltro: {
    textTransform: "lowercase",
    marginRight: "30px",
  },
}));

const SubMenuSupervisaoFiltros = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [btnArr] = useState([
    {
      id: 1,
      nome: "Total",
    },
    {
      id: 2,
      nome: "Atendimento Geral",
    },
    {
      id: 3,
      nome: "Backoffice",
    },
    {
      id: 4,
      nome: "Personalizado",
    },
  ]);

  const handleProfileMenuOpen = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {btnArr.map((res, index) => {
        return (
          <MenuItem
            key={index}
            size="small"
            color="secondary"
            variant="contained"
            // onClick={handleMenuClose}
          >
            {res.nome}
          </MenuItem>
        );
      })}
    </Menu>
  );
  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        onClick={handleProfileMenuOpen}
        startIcon={<FilterListIcon />}
        className={classes.btnFiltro}
      >
        Filtros
      </Button>

      {renderMenu}
    </>
  );
};

export default SubMenuSupervisaoFiltros;
