import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginGuicheEstacao } from "../../../../../actions/LoginActions";
import styles from "../../../../Login/LoginStyle";
import GlobalizadorService from "../../../../../service/GlobalizadorService";
import { errorMessage } from "../../../../../helpers/observables";
import DialogConfirmation from "../../../../Utils/Dialogs/DialogConfirmation";
import {
  Button,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Card,
  TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const globalizadorService = new GlobalizadorService();

const LoginGuicheEstacao = (props) => {
  const [secoes, setSecoes] = useState([]);
  const [guiches, setGuiches] = useState([]);
  const [estacoes, setEstacoes] = useState([]);
  const [listaGuiches, setListaGuiches] = useState([]);
  const [secao, setSecao] = useState(null);
  const [guiche, setGuiche] = useState(null);
  const [estacao, setEstacao] = useState(null);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [optionsDialogConfirmation, setOptionsDialogConfirmation] =
    useState(null);

  useEffect(() => {
    if (props.isLoaded) {
      load().then();
    }
  }, [props.isLoaded]);

  const load = async () => {
    try {
      if (props.permissaoAtendente) {
        const guiches = await globalizadorService.listaGuichesAtendente(
          props.idUnidade
        );
        setListaGuiches(guiches.listaGuiches);
        const listaSecoes = [];
        guiches.listaGuiches.forEach((item) => {
          if (!listaSecoes.find((s) => s.id === item.secao.id)) {
            listaSecoes.push({ id: item.secao.id, nome: item.secao.nome });
          }
        });
        setSecoes(listaSecoes);
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const entrar = () => {
    if (!guiche && !estacao) {
      errorMessage.next(
        "É necessário selecionar pelo menos um guichê e/ou estação."
      );
    } else if (
      guiche &&
      guiche.id_atendente_logado &&
      guiche.id_atendente_logado !== props.idUsuario
    ) {
      setOptionsDialogConfirmation({
        title: "Confirmação",
        description: `O guichê ${guiche.nome} está ocupado, deseja desconectar o usuário?`,
        handleClose: () => {
          setOpenDialogConfirmation(false);
        },
        handleConfirm: () => {
          setOpenDialogConfirmation(false);
          props.loginGuicheEstacao(estacao?.id, guiche?.id);
        },
      });
      setOpenDialogConfirmation(true);
    } else {
      props.loginGuicheEstacao(estacao?.id, guiche?.id);
    }
  };

  const handleGuiches = (event) => {
    const secaoEscolhida = event.target.value;
    setSecao(secaoEscolhida);
    if (secaoEscolhida) {
      const ghichesFiltrados = listaGuiches.filter(
        (guiche) => guiche.secao.id == secaoEscolhida.id
      );
      setGuiches(ghichesFiltrados);
    } else {
      setGuiches([]);
      setGuiche(null);
    }
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
    >
      <DialogConfirmation
        open={openDialogConfirmation}
        options={optionsDialogConfirmation}
      />
      {/* {(estacoes.length > 0 || secoes.length > 0) && ( */}
      <Card>
        <CardContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              entrar();
            }}
            style={{ width: "250px" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Login
            </Typography>
            {/* {estacoes.length > 0 && (
              <FormControl className="form-control" fullWidth>
                <InputLabel id="select-label-estacao">Selecione a estação</InputLabel>
                <Select
                  labelId="select-label-estacao"
                  value={estacao || ""}
                  onChange={(event) => setEstacao(event.target.value)}
                >
                  <MenuItem value={null}>Nenhuma</MenuItem>
                  {estacoes.map((estacao) => {
                    return (
                      <MenuItem key={estacao.idEstacao} value={estacao}>
                        {estacao.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )} */}
            {/* {listaGuiches.length > 0 && ( */}
            <Fragment>
              <TextField
                fullWidth
                style={{ textAlign: "left", marginBottom: 10, marginTop: 15 }}
                id="outlined-select-unidade"
                select
                label="Selecione a seção"
                value={secao || ""}
                onChange={handleGuiches}
              >
                <MenuItem value={null}>Nenhum</MenuItem>
                {secoes.map((secao) => {
                  return (
                    <MenuItem key={secao.id} value={secao}>
                      {secao.nome}
                    </MenuItem>
                  );
                })}
              </TextField>

              <TextField
                fullWidth
                style={{ textAlign: "left", marginBottom: 5 }}
                id="outlined-select-unidade"
                select
                label="Selecione o guichê"
                value={guiche || ""}
                onChange={(event) => setGuiche(event.target.value)}
              >
                <MenuItem value={null}>Nenhum</MenuItem>
                {/* checar com guilherme se é necessario inserir o  id_atendente_logado*/}
                {guiches.map((guiche) => {
                  return (
                    <MenuItem
                      key={guiche.id}
                      value={guiche}
                      style={{
                        color: guiche.id_atendente_logado ? "red" : "black",
                      }}
                    >
                      {guiche.nome}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Fragment>
            {/* )} */}
            <FormControl margin="normal" fullWidth>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
              >
                Entrar
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>
      {/* )} */}
    </div>
  );
};

const mapStateToProps = ({ loginReducer }) => {
  const {
    isLoaded,
    idUnidade,
    idUsuario,
    permissaoAtendente,
    permissaoBackoffice,
    permissoesAtendente,
    permissoesBackoffice,
  } = loginReducer;
  return {
    isLoaded,
    idUnidade,
    idUsuario,
    permissaoAtendente,
    permissaoBackoffice,
    permissoesAtendente,
    permissoesBackoffice,
  };
};

export default connect(mapStateToProps, {
  loginGuicheEstacao,
})(withStyles(styles)(LoginGuicheEstacao));
