import React, { Fragment } from "react";
import styles from "../InfoClienteStyle";
import { connect } from "react-redux";
import { Divider, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import GroupIcon from "@mui/icons-material/Group";

const GuichesApoiando = (props) => {
  const { classes } = props;
  return (
    <Fragment>
      {props.listaGuichesApoiando && props.listaGuichesApoiando.length > 0 && (
        <Paper className={classes.paper} style={{ marginTop: "0.2rem" }}>
          <div className={classes.divServico}>
            <GroupIcon className={classes.icon} />
            <Typography variant="h6" className={classes.labelServico}>
              Guichês Apoiando
            </Typography>
          </div>
          <Divider />
          <ul>
            {props.listaGuichesApoiando.map((guiche) => {
              return (
                <li key={guiche.id_guiche}>
                  <div className={classes.divAtividade}>
                    <Typography variant="h6" className={classes.labelDemandaAtual}>
                      {guiche.nome_usuario} - {guiche.nome_guiche} ({guiche.nome_secao})
                    </Typography>
                  </div>
                </li>
              );
            })}
          </ul>
        </Paper>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ guicheReducer }) => {
  const { listaGuichesApoiando } = guicheReducer;
  return {
    listaGuichesApoiando,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(GuichesApoiando));
