import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import DataTable from "components/Utils/Table/DataTable";
import AdicionarCliente from "./AdicionarCliente";
import EditarCliente from "./EditarCliente";
import BuscarCliente from "./BuscarCliente";

import {
  Box,
  Button,
  CardActions,
  Modal,
  styled,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

const headCells = [
  { id: "id", disablePadding: false, label: "" },
  { id: "nome", disablePadding: false, label: "Nome" },
  { id: "nascimento", disablePadding: false, label: "Data Nasc." },
  { id: "cartao", disablePadding: false, label: "Cartão" },
  { id: "titular", disablePadding: false, label: "Titular" },
  { id: "email", disablePadding: false, label: "Email" },
  { id: "actions", disablePadding: false, label: "Actions" },
];

function createData(id, nome, nascimento, cartao, titular, email) {
  return { id, nome, nascimento, cartao, titular, email };
}

const rows = [
  createData(1, "Teste 01", 13121975, 3.7, 67, 4.3),
  createData(2, "Teste 02", 452, 25.0, 51, 4.9),
  createData(3, "Teste 03", 262, 16.0, 24, 6.0),
  createData(4, "Teste 04", 159, 6.0, 24, 4.0),
  createData(5, "Teste 05", 356, 16.0, 49, 3.9),
  createData(6, "Teste 06", 408, 3.2, 87, 6.5),
  createData(7, "Teste 07", 237, 9.0, 37, 4.3),
  createData(8, "Teste 08", 375, 0.0, 94, 0.0),
  createData(9, "KitKat", 518, 26.0, 65, 7.0),
  createData(10, "Lollipop", 392, 0.2, 98, 0.0),
  createData(11, "Marshmallow", 318, 0, 81, 2.0),
  createData(12, "Nougat", 360, 19.0, 9, 37.0),
  createData(13, "Oreo", 437, 18.0, 63, 4.0),
];

const ModalCliente = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("buscar");
  const [callback, setCalllback] = React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!!callback.row) {
      setValue("editar");
    }
  }, [callback]);

  const abaBuscar = () => (
    <div style={{ paddingTop: "10 px", paddingLeft: "30px" }}>
      <BuscarCliente />
      ,
      <DataTable
        data={{
          title: "teste",
          columns: headCells,
          list: rows,
          callbackMethod: setCalllback,
        }}
      />
    </div>
  );

  return (
    <>
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <div className={classes.paper}>
          <Card color="primary" variant="elevation">
            <CardHeader
              style={{ subheader: "color: white" }}
              className={classes.cardHeader}
              title={
                <Typography className={classes.titleCard}>CLIENTES</Typography>
              }
            />
            <CardContent>
              <Paper position="static" color="transparent">
                <Tabs
                  className={classes.root}
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                >
                  <Tab
                    value="buscar"
                    label="Buscar clientes"
                    wrapped
                    {...a11yProps("buscar")}
                  />
                  <Tab
                    value="adicionar"
                    label="Adicionar cliente"
                    {...a11yProps("adicionar")}
                  />
                  <Tab
                    value="editar"
                    label="Editar cliente"
                    {...a11yProps("editar")}
                  />
                </Tabs>
              </Paper>
              <TabPanel value={value} index="buscar">
                {abaBuscar()}
              </TabPanel>
              <TabPanel value={value} index="adicionar">
                {AdicionarCliente(props)}
              </TabPanel>
              <TabPanel value={value} index="editar">
                {EditarCliente(props, callback.row)}
              </TabPanel>
            </CardContent>
            <CardActions className={classes.cardActions} disableSpacing>
              <ColorButton
                onClick={handleChange}
                size="small"
                color="secondary"
                variant="contained"
              >
                Salvar
              </ColorButton>
              <ColorButton
                onClick={props?.handleClose}
                size="small"
                color="primary"
                variant="contained"
              >
                Fechar
              </ColorButton>
            </CardActions>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ModalCliente;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTab-root ": {
      fontSize: "0.80rem",
      lineHeight: "0.5",
      textTransform: "capitalize",
      fontWeight: "400",
      maxWidth: "140px",
    },
    margin: "auto",
    justifyContent: "center",
  },
  paper: {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    padding: "2rem",
    paddingLeft: "2rem",
    position: "fixed",
    maxWidth: "95rem",
  },

  cardHeader: {
    margin: "-2px",
    borderTopRightRadius: "9px",
    borderTopLefttRadius: "9px",
    background: "#00598b",
  },
  subColor: {
    color: "rgb(255 255 255 / 65%)",
    fontWeight: "300",
    fontSize: "0.9rem",
  },
  titleCard: {
    fontSize: "1.2rem",
    color: "white",
  },
  cardActions: {
    flexDirection: "row-reverse",
    paddingRight: "15px",
  },

  inputGrid: {
    marginBottom: "8px",
    marginRight: "8px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  width: "130px",
  fontSize: "0.7rem",
  fontWeight: "400",
  textAlign: "center",
  textTransform: "lowercase",
  borderRadius: "3px",
  justifyContent: "center",
  marginRight: "5px",
  boxShadow: "none",
  marginBottom: "5px",
  marginRight: "5px",
}));
