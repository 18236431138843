export default {
  1: "senha",
  2: "panoramaSenhaSecao",
  3: "panoramaGuicheSecao",
  4: "statusEmissores",
  5: "panoramaGuiche",
  6: "panoramaAtendente",
  7: "panoramaAtividade",
  8: "demandaAtividade",
  9: "panoramaServico",
  10: "panoramaSuspensao",
  11: "panoramaPrioridade",
  12: "panoramaCategoria ",
  13: "panoramaFila",
  14: "panoramaAvaliacao",
  15: "editarSenhas",
  16: "editarAvaliacao",
  17: "cancelamento",
  18: "alarmes",
  19: "codigoAtendente",
  20: "padraoAtendimento",
  21: "ativarAgendamento",
  22: "descongelarSenhas",
  23: "ativarTransferencia",
  24: "reativarSenha",
  25: "desligarServidor",
  26: "eliminarSenhas",
  27: "presetContadores",
  28: "senhasFila",
  29: "loginManual",
  30: "listaSenhas",
  31: "agendaDia",
  32: "panoramaSenhas",
  33: "panoramaGeral",
  34: "panoramaPrevisoes",
  35: "eficienciaUnidade",
  36: "eficienciaAtendentes",
  37: "eficienciaAtividades",
  38: "auditoria",
  39: "desativarEmissao",
};
