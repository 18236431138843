export default {
  0: "Senha Livre",
  1: "Senha em Fila",
  2: "Senha Visualizando Guiche",
  3: "Senha Aguardando Guiche",
  4: "Senha Atendendo Guiche",
  5: "Senha Atendimento Pausado",
  6: "Senha Visualizando Supervisor",
  7: " Senha em Chamada",
  8: "Senha Finalizada",
  9: "Senha Cancelada",
  10: "Senha Agendada",
  11: "Senha Congelada",
  12: "Senha Agendada Automatica",
  13: "Senha Aguardando Multisservico",
  14: "SenhaAguardando Transferencia",
  15: "Senha Transferida",
  16: "Senha Reativada",
  17: "Senha Agendada Local",
  18: "Senha Cancelada Agenda",
  19: "Senha Desativada",
};
