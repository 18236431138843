import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useState } from "react";
import styles from "../Utils/Dialogs/DialogStyle";
import { errorMessage, successMessage } from "../../helpers/observables";
import GlobalizadorService from "../../service/GlobalizadorService";
import { connect } from "react-redux";

const globalizadorService = new GlobalizadorService();

const DialogAlteracaoSenha = (props) => {
  const { classes } = props;
  const [senhaAtual, setSenhaAtual] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState("");

  const handleConfirm = async () => {
    try {
      if (
        !senhaAtual.trim() ||
        !novaSenha.trim() ||
        !confirmacaoNovaSenha.trim()
      ) {
        errorMessage.next("Todos os campos são obrigatórios.");
      } else if (novaSenha !== confirmacaoNovaSenha) {
        errorMessage.next(
          "Nova Senha e Confirmação de Nova senha devem ser iguais."
        );
      } else {
        await globalizadorService.alteraSenhaUsuario(
          props.idUsuario,
          senhaAtual,
          novaSenha
        );
        successMessage.next("Senha alterada com sucesso.");
        handleClose();
      }
    } catch (err) {
      errorMessage.next(err.mensagem);
    }
  };

  const handleClose = () => {
    setSenhaAtual("");
    setNovaSenha("");
    setConfirmacaoNovaSenha("");
    props.handleClose();
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>Alteração de Senha</span>
      </DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          type="password"
          label="Senha Atual"
          variant="outlined"
          value={senhaAtual}
          style={{ marginTop: "1rem", width: "20rem" }}
          onChange={(event) => setSenhaAtual(event.target.value)}
        />
        <TextField
          type="password"
          label="Nova Senha"
          variant="outlined"
          value={novaSenha}
          style={{ marginTop: "1rem", width: "20rem" }}
          onChange={(event) => setNovaSenha(event.target.value)}
        />
        <TextField
          type="password"
          label="Confirmação de Nova Senha"
          variant="outlined"
          value={confirmacaoNovaSenha}
          style={{ marginTop: "1rem", width: "20rem" }}
          onChange={(event) => setConfirmacaoNovaSenha(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} style={{ color: "red" }}>
          Cancelar
        </Button>
        <Button color="primary" autoFocus onClick={() => handleConfirm()}>
          Alterar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loginReducer }) => {
  const { idUsuario } = loginReducer;
  return { idUsuario };
};
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(DialogAlteracaoSenha));
