import React, { useEffect, useState, Fragment } from "react";
import styles from "./DialogStyle";
// import MUIDataTable from "mui-datatables";
import DatatableLabels from "../../../constants/DatatableLabels";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  IconButton,
  Dialog,
  DialogTitle,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
let timeoutDebounce;

const DialogDemandas = (props) => {
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const [lista, setLista] = useState([]);
  const [count, setCount] = useState(0);
  const [pesquisa, setPesquisa] = useState("");
  const handleClose = options ? options.handleClose : props.handleClose;
  const handleSelect = options ? options.handleSelect : props.handleSelect;
  const handleCancelar = options ? options.handleCancelar : props.handleCancelar;
  const handleImprimir = options ? options.handleImprimir : props.handleImprimir;
  const [orderProperty, setOrderProperty] = useState("");
  const isPaginacao = options && options.isPaginacao ? options.isPaginacao : false;
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (options?.lista) {
      setLista(options.lista);
    }
  }, [options?.lista]);

  useEffect(() => {
    if (options?.count) {
      setCount(options.count);
    }
  }, [options?.count]);

  useEffect(() => {
    if (lista) {
      const temp = lista;
      temp.forEach((item, index) => {
        item.index = index;
      });
      setLista(temp);
    }
  }, [lista]);

  useEffect(() => {
    if (!props.open) {
      setOrderProperty("");
      setRowsPerPage(10);
      setPage(0);
    }
  }, [props.open]);

  useEffect(() => {
    if (orderProperty) {
      const temp = lista;
      temp.sort((a, b) => (a[orderProperty] > b[orderProperty] ? 1 : -1));
      setLista(temp);
    }
  }, [orderProperty]);

  const isField = (field) => {
    if (lista) {
      for (const item of lista) {
        if (item.taskBackOffice && item.taskBackOffice.formulario) {
          for (const t of item.taskBackOffice.formulario) {
            if (t.nomeCampo === field && t.valor) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiDialogContent: {
          root: {
            overflowY: "none",
          },
        },
      },
    });

  const tableOptions = {
    filter: true,
    elevation: 0,
    expandableRows: true,
    selectableRows: "none",
    rowsExpanded,
    onRowExpansionChange: (_, rows) => {
      setRowsExpanded(rows.map((row) => row.dataIndex));
    },
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <Typography variant="h6">Formulário</Typography>
            {lista[rowMeta.dataIndex].taskBackOffice?.formulario
              ?.filter(
                (f) =>
                  f.nomeCampo !== "PLACA" &&
                  f.nomeCampo !== "Placa" &&
                  f.nomeCampo !== "CHASSI" &&
                  f.nomeCampo !== "Chassi"
              )
              ?.map((campo, index) => {
                return (
                  <Typography key={`${campo.nomeCampo}-${index}`}>
                    {campo.nomeCampo.length > 30 ? campo.nomeCampo.substring(0, 30) + "..." : campo.nomeCampo}
                    :&nbsp;
                    {campo.valor.length > 30 ? campo.valor.substring(0, 30) + "..." : campo.valor}
                  </Typography>
                );
              })}
          </TableCell>
        </TableRow>
      );
    },
    textLabels: DatatableLabels,
  };
  if (isPaginacao) {
    tableOptions.serverSide = true;
    tableOptions.rowsPerPage = rowsPerPage;
    tableOptions.page = page;
    tableOptions.onChangeRowsPerPage = async (numberOfRows) => {
      setRowsPerPage(numberOfRows);
      setPage(0);
      if (options.getLista) {
        const resp = await options.getLista(numberOfRows, page, pesquisa);
        setLista(resp.lista);
        setCount(resp.count);
      }
    };
    tableOptions.onChangePage = async (currentPage) => {
      setPage(currentPage);
      if (options.getLista) {
        const resp = await options.getLista(rowsPerPage, currentPage, pesquisa);
        setLista(resp.lista);
        setCount(resp.count);
      }
    };
    tableOptions.count = count;
    tableOptions.sort = false;
    tableOptions.onSearchChange = async (searchText) => {
      setPesquisa(searchText);
      if (timeoutDebounce) {
        clearTimeout(timeoutDebounce);
      }
      timeoutDebounce = setTimeout(async () => {
        if (!searchText) {
          setRowsPerPage(10);
          setPage(0);
          const resp = await options.getLista(10, 0);
          setLista(resp.lista);
          setCount(resp.count);
        } else if (searchText.length >= 3) {
          setRowsPerPage(10);
          setPage(0);
          const resp = await options.getLista(10, 0, searchText);
          setLista(resp.lista);
          setCount(resp.count);
        }
      }, 2000);
    };
  }

  return (
    <Dialog open={props.open} onClose={handleClose} fullScreen={true}>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        style={{ display: "flex", paddingTop: 0, paddingBottom: 0 }}
      >
        <h2 className={classes.fontTitle} style={{ flexGrow: 1 }}>
          {title}
        </h2>
        <IconButton color="inherit" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: "0px", paddingTop: "0px", overflowY: "initial" }}>
        {lista && (
          <ThemeProvider theme={getMuiTheme()}>
            {/* <MUIDataTable
              title="Demandas"
              data={lista?.map((item) => {
                return {
                  protocolo: item.atividadeDemanda.protocoloDemandaBo,
                  servico: item.taskBackOffice?.serviceName,
                  atividade: item.atividadeDemanda.nomeAtividadeBo,
                  placa: item.taskBackOffice?.formulario.find((i) => i.nomeCampo === "placa")?.valor,
                  chassi: item.taskBackOffice?.formulario.find((i) => i.nomeCampo === "chassi")?.valor,
                };
              })}
              columns={[
                {
                  name: "protocolo",
                  label: "Protocolo",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "servico",
                  label: "Serviço",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "atividade",
                  label: "Atividade",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "placa",
                  label: "Placa",
                  options: {
                    filter: true,
                    sort: true,
                    display: isField("placa") ? true : "excluded",
                  },
                },
                {
                  name: "chassi",
                  label: "Chassi",
                  options: {
                    filter: true,
                    sort: true,
                    display: isField("chassi") ? true : "excluded",
                  },
                },
                {
                  name: "acao",
                  label: "Ação",
                  options: {
                    customBodyRenderLite: (dataIndex, rowIndex) => {
                      if (lista[dataIndex].atendida) {
                        return <Typography>Atendida</Typography>;
                      } else {
                        if (lista[dataIndex].internalizada) {
                          return (
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => handleCancelar(lista[dataIndex])}
                            >
                              CANCELAR
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => handleSelect(lista[dataIndex])}
                            >
                              ATENDER
                            </Button>
                          );
                        }
                      }
                    },
                  },
                },
                {
                  name: "impressao",
                  label: "Impressão",
                  options: {
                    customBodyRenderLite: (dataIndex, rowIndex) => {
                      if (lista[dataIndex].atendida || lista[dataIndex].internalizada) {
                        return (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleImprimir(lista[dataIndex])}
                          >
                            IMPRIMIR
                          </Button>
                        );
                      } else {
                        return <Fragment />;
                      }
                    },
                  },
                },
              ]}
              options={tableOptions}
            /> */}
          </ThemeProvider>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DialogDemandas);
