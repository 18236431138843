import BasicService from "./BasicService";
import config from "../constants/Servers";

class LiveChatService extends BasicService {
  constructor() {
    super(config.url.LIVECHAT);
    this.interfaceKey = "D54sd4a87xcA213dxaAvsa42QE==";
  }

  getChat(idChat) {
    return this.request("get", "chat/" + idChat, {}, true, this.interfaceKey);
  }

  getTempoEsperaChats() {
    return this.request("post", "chat/tempoEsperaChats", { tags: [] }, true, this.interfaceKey);
  }

  login(idUnidade, idSenha, data) {
    return this.request("post", "user/login", { idUnidade, idSenha, data, userType: "SUPERVISOR" }, true);
  }

  loginLiveChat(idChat, idAtendente) {
    return this.request("post", "user/loginLiveChat", { idChat, idAtendente, userType: "SUPERVISOR" }, true);
  }

  getVideoAtendimentos() {
    return this.request("get", "video/atendimentos", {}, true, this.interfaceKey);
  }

  getVideoAtendimento(id) {
    return this.request("get", "video/atendimento/" + id, {}, true, this.interfaceKey);
  }

  getChatsEmAtendimento() {
    return this.request("get", "chat/emAtendimento", {}, true, this.interfaceKey);
  }
}

export default LiveChatService;
