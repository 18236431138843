import React, { Fragment } from "react";
import { AppBar, Button, DialogContent, IconButton, Slide, Toolbar, Typography, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogArquivo = (props) => {
  return (
    <Dialog fullScreen={true} open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" style={{ flex: 1 }}>
            {props.nomeArquivo}
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => {
              props.handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.nomeArquivo && props.downloadLink && (
          <Fragment>
            {(props.nomeArquivo.includes(".jpg") ||
              props.nomeArquivo.includes(".png") ||
              props.nomeArquivo.includes(".jpeg") ||
              props.nomeArquivo.includes(".bmp")) && <img src={props.downloadLink} alt="Arquivo" />}

            {props.nomeArquivo.includes(".pdf") && (
              <embed
                src={props.downloadLink}
                type="application/pdf"
                style={{ width: "100%", height: "100%" }}
              />
            )}

            {!props.nomeArquivo.includes(".jpg") &&
              !props.nomeArquivo.includes(".png") &&
              !props.nomeArquivo.includes(".jpeg") &&
              !props.nomeArquivo.includes(".bmp") &&
              !props.nomeArquivo.includes(".pdf") && (
                <Button href={props.downloadLink} target="_blank" variant="contained" color="primary">
                  Download
                </Button>
              )}
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogArquivo;
