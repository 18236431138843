import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  devolveDemanda,
  finalizaDemanda,
  proximaDemanda,
  registraPendencia,
  solicitaAgendamento,
  imprimeDemanda,
  encerraDemanda,
} from "actions/BackofficeActions";
import { chamadaEspecifica, internalizaDemanda } from "actions/DespachanteActions";
import { retornarSuspensao } from "actions/GuicheActions";
import DialogSelecao from "components/Utils/Dialogs/DialogSelecao";
import DateFnsUtils from "@date-io/date-fns";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DialogProtocolo from "components/Utils/Dialogs/DialogProtocolo";
import CustomButton from "components/Utils/CustomButton";
import BotoesGuiche from "./components/BotoesGuiche";
import styles from "./BotoesAcaoStyle";
import GlobalizadorService from "service/GlobalizadorService";
import { errorMessage } from "helpers/observables";
import Decisao from "../Decisao";
import BotoesDespachante from "./components/BotoesDespachante";
import brLocale from "date-fns/locale/pt-BR";
import DialogDemandas from "../../../../../Utils/Dialogs/DialogDemandas";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const globalizadorService = new GlobalizadorService();

const BotoesAcao = (props) => {
  const [openDecisao, setOpenDecisao] = useState(false);
  const [openDialogSelecao, setOpenDialogSelecao] = useState(false);
  const [optionsDialogSelecao, setOptionsDialogSelecao] = useState(null);
  const [openDialogProtocolo, setOpenDialogProtocolo] = useState(false);
  const [openDialogProtocoloEncerraDemanda, setOpenDialogProtocoloEncerraDemanda] = useState(false);
  const [openDialogPendencia, setOpenDialogPendencia] = useState(false);
  const [openDialogSolicitaAgendamento, setOpenDialogSolicitaAgendamento] = useState(false);
  const [comentarioPendencia, setComentarioPendencia] = useState("");
  const [dataRetornoPendencia, setDataRetornoPendencia] = useState(null);
  const [openDialogDemandas, setOpenDialogDemandas] = useState(false);
  const [optionsDialogDemandas, setOptionsDialogDemandas] = useState(null);
  const { classes } = props;

  const verificarItensPendentes = () => {
    if (props.aprovacaoObrigatoria) {
      const { task } = props;

      if (task) {
        if (task.formulario) {
          const itensPendentes = task.formulario.filter((item) => {
            return item.aprovado === null;
          });
          if (itensPendentes.length > 0) {
            return true;
          }
        }

        if (task.associatedFiles) {
          const itensPendentes = task.associatedFiles.filter((item) => {
            return item.aprovado === null;
          });
          if (itensPendentes.length > 0) {
            return true;
          }
        }

        if (task.externalFiles) {
          const itensPendentes = task.externalFiles.filter((item) => {
            return item.aprovado === null;
          });
          if (itensPendentes.length > 0) {
            return true;
          }
        }
      }
    }
  };

  const verificarItensReprovados = () => {
    const { task } = props;

    if (task) {
      if (task.formulario) {
        const itensFormularioReprovados = task.formulario.filter((item) => {
          return item.aprovado === false;
        });
        if (itensFormularioReprovados.length > 0) {
          return true;
        }
      }

      if (task.associatedFiles) {
        const itensArquivosReprovados = task.associatedFiles.filter((item) => {
          return item.aprovado === false;
        });
        if (itensArquivosReprovados.length > 0) {
          return true;
        }
      }

      if (task.externalFiles) {
        const itensArquivosExternosReprovados = task.externalFiles.filter((item) => {
          return item.aprovado === false;
        });
        if (itensArquivosExternosReprovados.length > 0) {
          return true;
        }
      }
    }
  };

  const finalizarDemanda = () => {
    if (verificarItensPendentes()) {
      errorMessage.next("Todos os itens devem ser verificados antes de finalizar.");
    } else if (verificarItensReprovados()) {
      errorMessage.next("Não é possível finalizar a demanda com itens reprovados.");
    } else {
      verificarDecisao();
    }
  };

  const devolveDemanda = () => {
    props.devolveDemanda(props.idUsuario, props.idUnidade);
  };

  const verificarDecisao = () => {
    if (props.listaOpcoesDecisao && props.listaOpcoesDecisao.length > 0) {
      setOpenDecisao(true);
    } else if (props.permissoesBackoffice?.perm_envio_protocolo) {
      setOpenDialogProtocolo(true);
    } else {
      props.finalizaDemanda();
    }
  };

  const solicitaAgendamentoVideo = async () => {
    const solicitar = (servico, categoria) => {
      props.solicitaAgendamento(
        servico.id,
        comentarioPendencia,
        dataRetornoPendencia ? moment(dataRetornoPendencia).format("DD/MM/YYYY") : "",
        categoria.id
      );
      setOpenDialogSelecao(false);
      setOpenDialogPendencia(false);
      setOpenDialogSolicitaAgendamento(false);
    };

    const selecionaServico = (servicos, categoria) => {
      if (servicos && servicos.length > 0) {
        if (servicos.length === 1) {
          solicitar(servicos[0], categoria);
        } else {
          setOptionsDialogSelecao({
            lista: servicos,
            title: "Serviços",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelecao(false),
            handleSelect: (servico) => {
              solicitar(servico, categoria);
            },
          });
          setOpenDialogSelecao(true);
          setOpenDialogPendencia(false);
          setOpenDialogSolicitaAgendamento(false);
        }
      } else {
        errorMessage.next("Sem serviços cadastrados.");
      }
    };

    try {
      const respCategorias = await globalizadorService.listaCategorias();
      const categorias = respCategorias.lista_categorias.filter((c) => c.video);

      const respServicos = await globalizadorService.listaServicos(props.task?.cityId);
      const servicos = respServicos.value;

      if (categorias && categorias.length > 0) {
        if (categorias.length === 1) {
          selecionaServico(servicos, categorias[0]);
        } else {
          setOptionsDialogSelecao({
            lista: categorias,
            title: "Categorias",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelecao(false),
            handleSelect: async (categoria) => {
              selecionaServico(servicos, categoria);
            },
          });
          setOpenDialogSelecao(true);
        }
      } else {
        errorMessage.next("Nenhuma categoria de vídeo encontrada.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const solicitaAgendamentoPresencial = async () => {
    const solicitar = (servico) => {
      props.solicitaAgendamento(
        servico.id,
        comentarioPendencia,
        dataRetornoPendencia ? moment(dataRetornoPendencia).format("DD/MM/YYYY") : ""
      );
      setOpenDialogSelecao(false);
      setOpenDialogPendencia(false);
      setOpenDialogSolicitaAgendamento(false);
    };

    try {
      const resp = await globalizadorService.listaServicos(props.task?.cityId);
      if (resp.value && resp.value.length > 0) {
        if (resp.value.length === 1) {
          solicitar(resp.value[0]);
        } else {
          setOptionsDialogSelecao({
            lista: resp.value,
            title: "Serviços",
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelecao(false),
            handleSelect: (servico) => {
              solicitar(servico);
            },
          });
          setOpenDialogSelecao(true);
        }
      } else {
        errorMessage.next("Sem serviços cadastrados.");
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const registraPendencia = () => {
    props.registraPendencia(
      comentarioPendencia,
      comentarioPendencia,
      dataRetornoPendencia ? moment(dataRetornoPendencia).format("DD/MM/YYYY") : ""
    );
    setOpenDialogPendencia(false);
    setComentarioPendencia("");
    setDataRetornoPendencia(null);
  };

  const retornarSuspensao = () => {
    try {
      props.retornarSuspensao(props.idAtendente);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const demandaEspecifica = async () => {
    const resp = await getListaDemandas(10, 0);
    const lista = resp.lista;
    if (lista && lista.length > 0) {
      setOptionsDialogDemandas({
        title: "Selecione a demanda que deseja atender:",
        lista,
        count: resp.count,
        orderProperties: [
          { name: "protocolo", label: "Protocolo" },
          { name: "atendida", label: "Não Atendidas" },
          { name: "horaEmissao", label: "Hora de Emissão" },
          { name: "nomeAtividade", label: "Atividade / Serviço" },
          { name: "nomeCidadao", label: "Nome do Cidadão" },
        ],
        handleClose: () => setOpenDialogDemandas(false),
        handleSelect: (demanda) => {
          props.chamadaEspecifica(demanda.idAtividadeDemandaBo, demanda.idDemandaBo);
          setOpenDialogDemandas(false);
        },
        isPaginacao: true,
        getLista: async (rowsPerPage, page, pesquisa) => {
          return await getListaDemandas(rowsPerPage, page, pesquisa);
        },
      });
      setOpenDialogDemandas(true);
    } else {
      errorMessage.next("Não há senhas nesta fila.");
    }
  };

  const getListaDemandas = async (rowsPerPage, page, pesquisa) => {
    const resp = await globalizadorService.listaDemandasDisponiveisEstacao(rowsPerPage, page, pesquisa);
    const lista = resp.listaDemandasDisponiveis;
    const count = resp.totalDisponivel;
    if (lista && lista.length > 0) {
      lista.map((item) => {
        if (item.task && item.task.formulario) {
          item.task.formulario.forEach((f) => {
            if (f.nomeCampo === "PLACA" || f.nomeCampo === "Placa") {
              item.task.formulario.push({
                ...f,
                nomeCampo: "placa",
              });
            }
            if (f.nomeCampo === "CHASSI" || f.nomeCampo === "Chassi") {
              item.task.formulario.push({
                ...f,
                nomeCampo: "chassi",
              });
            }
          });
        }

        item.atividadeDemanda = item;
        item.taskBackOffice = item.task;

        return item;
      });
      return {
        lista,
        count,
      };
    }
    return {
      lista: [],
      count: 0,
    };
  };

  const encerrarDemanda = async () => {
    if (props.permissoesBackoffice?.perm_envio_protocolo) {
      setOpenDialogProtocoloEncerraDemanda(true);
    } else {
      await props.encerraDemanda();
    }
    setOpenDialogProtocoloEncerraDemanda(true);
  };

  return (
    <Fragment>
      <Decisao open={openDecisao} handleClose={() => setOpenDecisao(false)} />

      <DialogDemandas open={openDialogDemandas} options={optionsDialogDemandas} />

      <Dialog open={openDialogSolicitaAgendamento} onClose={() => setOpenDialogSolicitaAgendamento(false)}>
        <DialogTitle>Tipo de Agendamento</DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ fontWeight: 400 }}>
            Você deseja realizar um agendamento por vídeo conferência ou presencial?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => solicitaAgendamentoVideo()}
          >
            Vídeo Conferência
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => solicitaAgendamentoPresencial()}
          >
            Presencial
          </Button>
        </DialogActions>
      </Dialog>

      <DialogSelecao open={openDialogSelecao} options={optionsDialogSelecao} />

      <DialogProtocolo
        open={openDialogProtocolo}
        handleClose={(protocolo) => {
          setOpenDialogProtocolo(false);
          if (protocolo) {
            props.finalizaDemanda(protocolo);
          } else if (protocolo === null) {
            props.finalizaDemanda();
          }
        }}
      />

      <DialogProtocolo
        open={openDialogProtocoloEncerraDemanda}
        handleClose={(protocolo) => {
          setOpenDialogProtocoloEncerraDemanda(false);
          if (protocolo) {
            props.encerraDemanda(protocolo);
          } else if (protocolo === null) {
            props.encerraDemanda();
          }
        }}
      />

      <Dialog open={openDialogPendencia} onClose={() => setOpenDialogPendencia(false)}>
        <DialogTitle>Pendência</DialogTitle>
        <DialogContent className={classes.divDialogPendencia}>
          <LocalizationProvider locale={brLocale} utils={DateFnsUtils}>
            <DatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Data de retorno"
              format="dd/MM/yyyy"
              value={dataRetornoPendencia}
              onChange={(date) => setDataRetornoPendencia(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              cancelLabel={"CANCELA"}
            />
          </LocalizationProvider>
          <TextField
            label="Digite aqui o comentário"
            variant="outlined"
            multiline
            rows="6"
            value={comentarioPendencia}
            className={classes.inputComentario}
            onChange={(event) => setComentarioPendencia(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setOpenDialogSolicitaAgendamento(true)}
          >
            Solicitar agendamento
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => registraPendencia()}
          >
            Devolver processo
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.divBotoes}>
        {props.idGuiche ? (
          <Fragment>
            {(props.statusGuiche === 1 || (props.statusGuiche === 3 && !props.isVideoAtendimento)) && (
              <CustomButton onClick={() => props.proximaDemanda()}>Próxima</CustomButton>
            )}
            <BotoesGuiche />
          </Fragment>
        ) : (
          <Fragment>
            <Fragment>
              {(!props.idGuiche || props.statusGuiche === 1 || props.statusGuiche === 3) &&
                (props.permissoesBackoffice?.proximaDemanda ||
                  (props.idGuiche && props.permissoesAtendente?.proximaSenha)) && (
                  <CustomButton onClick={() => props.proximaDemanda()}>Próxima</CustomButton>
                )}
              {props.idGuiche && <BotoesGuiche />}
            </Fragment>

            <Fragment>
              <CustomButton background="#a21b05" hover="#5a0d00" onClick={() => finalizarDemanda()}>
                Finalizar
              </CustomButton>
              <CustomButton onClick={() => devolveDemanda()}>Devolver</CustomButton>
              <CustomButton onClick={() => setOpenDialogPendencia(true)}>Pendência</CustomButton>
              {props.permissoesBackoffice?.perm_finalizar_demanda && (
                <CustomButton onClick={() => encerrarDemanda()}>Encerrar</CustomButton>
              )}
            </Fragment>

            {props.statusGuiche === 6 && (
              <CustomButton onClick={() => retornarSuspensao()}>Retornar</CustomButton>
            )}

            {props.permissoesBackoffice?.perm_demanda_especifica && (
              <CustomButton onClick={() => demandaEspecifica()}>Específica</CustomButton>
            )}

            {props.permissoesBackoffice?.perm_atender_despachante && <BotoesDespachante />}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ guicheReducer, backofficeReducer, loginReducer }) => {
  const { idGuiche, statusGuiche, isVideoAtendimento } = guicheReducer;
  const { idEstacao, statusEstacao, task, listaOpcoesDecisao, despachante, aprovacaoObrigatoria } =
    backofficeReducer;
  const { idUsuario, idUnidade, permissoesBackoffice, permissoesAtendente } = loginReducer;
  return {
    idGuiche,
    statusGuiche,
    isVideoAtendimento,
    idEstacao,
    statusEstacao,
    task,
    listaOpcoesDecisao,
    idUsuario,
    idUnidade,
    permissoesBackoffice,
    permissoesAtendente,
    despachante,
    aprovacaoObrigatoria,
  };
};

export default connect(mapStateToProps, {
  proximaDemanda,
  finalizaDemanda,
  solicitaAgendamento,
  registraPendencia,
  devolveDemanda,
  retornarSuspensao,
  chamadaEspecifica,
  internalizaDemanda,
  imprimeDemanda,
  encerraDemanda,
})(withStyles(styles)(BotoesAcao));
