import MenuSupervisorRoutesListBtn from "constants/MenuSupervisorRoutesListBtn";
import React, { useState } from "react";
import { useHistory } from "react-router";
import ListIcon from "@mui/icons-material/List";
import { IconButton, MenuItem, MenuList, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "15px",
  },
}))(MenuItem);
const SubMenuSupervisao = (props) => {
  const history = useHistory();
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div style={{ height: "50px", marginLeft: "-46px", marginRight: "5px" }}>
        <IconButton onClick={toggleClass}>
          <ListIcon />
        </IconButton>
      </div>
      <div
        className={isActive ? "menuClosed" : "menuOpen"}
        style={{ width: "200px", paddingBottom: "20px" }}
      >
        <Paper>
          <MenuList>
            {props.supervisor?.listaBotoesSupervisor.map((res, index) => {
              return (
                <StyledMenuItem
                  key={index}
                  onClick={() =>
                    history.push(
                      `/home/supervisor/${
                        MenuSupervisorRoutesListBtn[res.tipo]
                      }`
                    )
                  }
                >
                  {res.nome}
                </StyledMenuItem>
              );
            })}
          </MenuList>
        </Paper>
      </div>
    </>
  );
};

export default SubMenuSupervisao;
