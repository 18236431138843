import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./DialogStyle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import * as React from "react";

const DialogComentarios = (props) => {
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const lista = options ? options.lista : props.lista;
  const handleSelect = options ? options.handleSelect : props.handleSelect;
  const handleClose = options ? options.handleClose : props.handleClose;
  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <List
          key="lista"
          sx={{
            width: "100%",
            maxWidth: 500,
            minWidth: 300,
            bgcolor: "background.paper",
            overflow: "auto",
            maxHeight: 450,
          }}
        >
          {lista?.map((item, index) => {
            return (
              <div key={index + "h"}>
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    key={index + "c"}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component={"span"}
                          variant="body2"
                          color="text.primary"
                          fontSize={16}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {item.texto}
                        </Typography>
                        <br />
                        <Typography
                          component={"span"}
                          variant="body2"
                          color="#797979de"
                          fontSize={12}
                        >
                          {item.nomeUsuario} - {item.horario}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {lista.length > 1 && <Divider key={index + "g"} />}
              </div>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};
export default withStyles(styles)(DialogComentarios);
