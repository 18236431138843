import React, { Fragment, useEffect, useState } from "react";
import Atendimento from "./components/Atendimento/Atendimento";
import { matchPath, Route, Switch } from "react-router";
import { useHistory } from "react-router";
import styles from "./HomeStyle";
import { connect } from "react-redux";
import { logoff, statusUsuario } from "../../actions/LoginActions";
import { websocketToken } from "../../service/websocket/WebSocketActions";
import clsx from "clsx";
import Supervisor from "./components/Supervisor/Supervisor";
import Emissor from "./components/Emissor/Emissor";
import { errorMessage } from "../../helpers/observables";
import packageJson from "../../../package.json";
import ChatInterno from "./components/Chat/ChatInterno";
import DialogAlteracaoSenha from "../DialogAlteracaoSenha/DialogAlteracaoSenha";
import { useTranslation } from "react-i18next";

import {
  CssBaseline,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  Container,
  useTheme,
  Drawer,
  Hidden,
  IconButton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from "@mui/icons-material/Menu";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import Recepcao from "./components/Recepcao/Recepcao";

const Home = (props) => {
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDialogAlteracaoSenha, setOpenDialogAlteracaoSenha] =
    useState(false);
  const history = useHistory();
  const { classes } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuConfiguracoes = Boolean(anchorEl);
  const { t } = useTranslation();

  useEffect(() => {
    const token = sessionStorage.getItem("bo.token");
    props.statusUsuario();
    props.websocketToken(token);
  }, []);

  const routes = [
    {
      path: "/home",
      title: "ATENDIMENTO",
      component: Atendimento,
      exact: true,
      icon: <ReceiptOutlinedIcon />,
      isVisible: props.permissaoAtendente || props.permissaoBackoffice,
    },
    {
      path: "/home/supervisor",
      title: "SUPERVISOR",
      component: Supervisor,
      exact: false,
      icon: <AssessmentOutlinedIcon />,
      isVisible: props.permissaoSupervisor ? true : false,
      history,
    },
    {
      path: "/home/emissor",
      title: "EMISSOR",
      component: Emissor,
      exact: false,
      icon: <AddBoxOutlinedIcon />,
      isVisible: props.permissaoEmissor ? true : false,
      history,
    },
    {
      path: "/home/recepcao",
      title: "RECEPÇÃO",
      component: Recepcao,
      exact: true,
      icon: <ReceiptOutlinedIcon />,
      isVisible: props.permissaoAtendente || props.permissaoBackoffice,
    },
    /*{
      path: "/home",
      title: "TV CORPORATIVA",
      component: TvCorporativa,
      exact: true,
      icon: <LiveTvIcon />,
    },
    {
      path: "/home/perfil",
      title: "PERFIL",
      component: Perfil,
      exact: true,
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      path: "/home/atendimento",
      title: "ATENDIMENTO",
      component: Atendimento,
      exact: true,
      icon: <ReceiptOutlinedIcon />,
      isVisible: props.permissaoAtendente || props.permissaoBackoffice,
    },
    {
      path: "/home/supervisor",
      title: "SUPERVISOR",
      component: Supervisor,
      exact: false,
      icon: <AssessmentOutlinedIcon />,
    },
    {
      path: "/home/conta",
      title: "CONTA",
      component: Conta,
      exact: true,
      : <DescriptionOutlinedIcon />,
    },
    {
      path: "/home/ajuda",
      title: "AJUDA",
      component: Ajuda,
      exact: true,
      icon: <HelpOutlineOutlinedIcon />,
    },
    {
      path: "/home/configuracoes",
      title: "CONFIGURAÇÕES",
      component: Configuracoes,
      exact: true,
      icon: <SettingsOutlinedIcon />,
    },*/
  ];

  const currentRoute = () =>
    routes.find((route) =>
      matchPath(history.location.pathname, {
        path: route.path,
        exact: false,
      })
    );
  const [selectedRoute, setSelectedRoute] = useState(currentRoute());

  const renderItemMenu = (route, index) => {
    return (
      <Fragment key={index}>
        <ListItem
          button
          className={classes.listItem}
          onClick={() => {
            setSelectedRoute(route);
            history.push(route.path);
          }}
        >
          <ListItemIcon
            className={
              route &&
              ((route.path !== "/home" &&
                history.location.pathname.includes(route.path)) ||
                (route.path === "/home" &&
                  history.location.pathname === route.path))
                ? classes.iconItemSelected
                : classes.iconItem
            }
          >
            {route.icon}
          </ListItemIcon>
          {open && (
            <ListItemText
              primary={route.title}
              className={
                route &&
                ((route.path !== "/home" &&
                  history.location.pathname.includes(route.path)) ||
                  (route.path === "/home" &&
                    history.location.pathname === route.path))
                  ? classes.titleItemSelected
                  : classes.titleItem
              }
            />
          )}
        </ListItem>
        {open && <Divider className={classes.dividerMenu} />}
      </Fragment>
    );
  };

  const renderRoute = (route, i) => {
    return (
      <Route
        key={i}
        exact={route.exact}
        path={route.path}
        component={route.component}
      />
    );
  };

  const matchMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const drawer = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="logo"
          style={{ maxHeight: "3rem" }}
        />
        <Typography
          variant="body2"
          style={{ color: "var(--theme-grey-light)" }}
        >
          {packageJson.version}
        </Typography>
      </div>

      <Divider className={classes.dividerTitle} />

      {matchMdUp && (
        <div
          className={classes.toolbarIcon}
          style={{ alignSelf: open ? "flex-end" : "center" }}
        >
          <IconButton
            className={classes.iconMenu}
            onClick={() => setOpen(!open)}
          >
            <MenuIcon />
          </IconButton>
        </div>
      )}

      {routes
        .filter(
          (route) => route.isVisible === undefined || route.isVisible === true
        )
        .map((route, index) => {
          return renderItemMenu(route, index);
        })}
    </div>
  );

  const logoff = () => {
    if (props.despachante) {
      errorMessage.next(
        t(
          "É necessário finalizar a sessão do despachante antes de suspender o atendimento."
        )
      );
    } else {
      props.logoff(props.idEstacao, props.idGuiche);
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <DialogAlteracaoSenha
          open={openDialogAlteracaoSenha}
          handleClose={() => setOpenDialogAlteracaoSenha(false)}
        />
        <Fragment>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(
                  classes.drawerPaper,
                  !open && classes.drawerPaperClose
                ),
                docked: classes.drawerDocked,
              }}
              open={open}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={() => setMobileOpen(!mobileOpen)}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </Fragment>

        <main className={classes.content}>
          <Container maxWidth="xl">
            <div className={classes.divMain}>
              <Hidden mdUp implementation="css">
                <IconButton
                  onClick={() => setMobileOpen(!mobileOpen)}
                  className={classes.iconMenu}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Typography variant="h6" className={classes.txtUnidade}>
                {props.nomeUnidade}
                {props.nomeUnidadeSenha &&
                  props.nomeUnidadeSenha !== props.nomeUnidade && (
                    <span style={{ color: "red" }}>
                      {" "}
                      ({props.nomeUnidadeSenha})
                    </span>
                  )}
              </Typography>

              {matchMdUp && (
                <Fragment>
                  <Typography variant="h6" className={classes.txtInfo}>
                    {selectedRoute?.title}
                  </Typography>
                  <span className={classes.spanBar} />
                  <Typography variant="h6" className={classes.txtInfo}>
                    {props.nomeUsuario}
                  </Typography>
                </Fragment>
              )}

              <Tooltip title={t("Configurações")}>
                <IconButton
                  className={classes.iconLogoff}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <AccountCircleOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={openMenuConfiguracoes}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenDialogAlteracaoSenha(true);
                  }}
                >
                  <LockIcon />
                  {t("Alterar Senha")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    logoff();
                  }}
                >
                  <PowerSettingsNewIcon />
                  Logoff
                </MenuItem>
              </Menu>
            </div>

            <Switch>
              {routes
                .filter(
                  (route) =>
                    route.isVisible === undefined || route.isVisible === true
                )
                .map((route, index) => {
                  return renderRoute(route, index);
                })}
            </Switch>
          </Container>
        </main>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({
  loginReducer,
  guicheReducer,
  backofficeReducer,
}) => {
  const {
    nomeUsuario,
    nomeUnidade,
    idUsuario,
    idUnidade,
    permissaoAtendente,
    permissaoBackoffice,
    permissaoSupervisor,
    permissaoEmissor,
    permissaoChatInterno,
  } = loginReducer;
  const { idEstacao, despachante } = backofficeReducer;
  const { idGuiche, nomeUnidadeSenha } = guicheReducer;
  return {
    nomeUsuario,
    nomeUnidade,
    idUsuario,
    idUnidade,
    permissaoAtendente,
    permissaoBackoffice,
    permissaoSupervisor,
    permissaoChatInterno,
    idEstacao,
    despachante,
    idGuiche,
    nomeUnidadeSenha,
  };
};

export default connect(mapStateToProps, {
  logoff,
  websocketToken,
  statusUsuario,
})(withStyles(styles)(Home));
