import React, { useState } from "react";
import { errorMessage } from "../../../helpers/observables";
import styles from "./DialogStyle";
import { Button, Dialog, DialogContent, DialogTitle, TextField, DialogActions } from "@mui/material";
import { withStyles } from "@mui/styles";

const DialogProtocolo = (props) => {
  const [protocolo, setProtocolo] = useState("");
  const { classes } = props;

  const enviarProtocolo = () => {
    if (protocolo) {
      props.handleClose(protocolo);
      setProtocolo("");
    } else {
      errorMessage.next("Protocolo não informado.");
    }
  };

  const naoEnviarProtocolo = () => {
    props.handleClose(null);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>Informe o protocolo</span>
      </DialogTitle>
      <DialogContent
        style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
      >
        <TextField
          label="Digite aqui o protocolo"
          variant="outlined"
          size="small"
          value={protocolo}
          onChange={(event) => setProtocolo(event.target.value)}
          style={{ margin: "1rem", width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          style={{ marginTop: "1rem", marginBottom: "1rem", background: "#a21b05", color: "white" }}
          onClick={() => naoEnviarProtocolo()}
        >
          Não enviar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          onClick={() => enviarProtocolo()}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogProtocolo);
