import axios from "axios";
import { removeEncoding, removeEncodingJson } from "../helpers/util";
import {
  loadingStatus,
  errorMessage,
  successMessage,
} from "../helpers/observables";

class BasicService {
  constructor(url) {
    this.baseUrl = url;
  }

  request(method, endpoint, params, noAuth, key) {
    return new Promise((resolve, reject) => {
      let baseUrl = this.baseUrl;
      if (!baseUrl.includes("http")) {
        baseUrl = "http://" + baseUrl;
      }

      if (endpoint) {
        baseUrl = baseUrl + "/" + endpoint;
      }

      let headers = { "Content-Type": "application/json" };
      const token = sessionStorage.getItem("bo.token");
      if (token && !noAuth) {
        headers.Authorization = `Bearer ${token}`;
      }
      if (key) {
        headers.Authorization = key;
      }

      loadingStatus.next(true);
      axios({
        method: method,
        url: baseUrl,
        data: params,
        headers,
      })
        .then((resp) => {
          resolve(removeEncodingJson(resp.data));
          // codigo foi comentado por necessitar corrigir alguns detalhes no back
          // if (resp.status === 200) {

          //   // if (resp.data.success === false) {
          //   //   if (resp.data.status === 40) {
          //   //     successMessage.next(removeEncoding(resp.data.mensagem));
          //   //     reject();
          //   //   } else {
          //   //     reject(removeEncodingJson(resp.data));
          //   //   }
          //   // } else if (resp.data.status && resp.data.status.code !== 0) {
          //   //   reject(removeEncoding(resp.data.mensagem));
          //   // } else if (resp.data.resposta) {
          //   //   resolve(removeEncodingJson(resp.data.resposta));
          //   // } else {
          //   //   resolve(removeEncodingJson(resp.data));
          //   // }
          // } else if (resp.status === 204) {
          //   resolve();
          // } else {
          //   reject(resp.data);
          // }
        })
        .catch((err) => {
          let errorObject = JSON.parse(JSON.stringify(err));

          if (
            errorObject.message.includes("401") ||
            errorObject.message.includes("403")
          ) {
            if (errorObject.config.url.includes("login")) {
              reject("Usuário e/ou senha inválidos.");
            } else {
              errorMessage.next(
                "Login expirado, favor realizar o login novamente."
              );
              reject("Login expirado, favor realizar o login novamente.");
              setTimeout(() => {
                sessionStorage.clear();
                window.location.reload();
              }, 1500);
            }
          } else if (err.response && err.response.data) {
            reject(err.response.data);
          } else {
            reject(err);
          }
        })
        .finally(() => {
          loadingStatus.next(false);
        });
    });
  }

  upload(endpoint, params) {
    return new Promise((resolve, reject) => {
      let baseUrl = this.baseUrl;
      if (!baseUrl.includes("http")) {
        baseUrl = "http://" + baseUrl;
      }

      if (endpoint) {
        baseUrl = baseUrl + "/" + endpoint;
      }

      let headers = { "Content-Type": "multipart/form-data" };
      const token = sessionStorage.getItem("integrador.token");
      if (token) {
        headers.Authorization = `${token}`;
      }

      axios
        .post(baseUrl, params, { headers })
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data.status) {
              reject(removeEncoding(resp.data.mensagem));
            } else if (resp.data.resposta) {
              resolve(resp.data.resposta);
            } else {
              resolve(resp.data);
            }
          } else {
            reject(resp.data);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            reject(removeEncoding(err.response.data));
          } else {
            reject(err);
          }
        });
    });
  }
}

export default BasicService;
