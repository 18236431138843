import React from "react";
import { useHistory } from "react-router";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
const useStyles = () =>
  makeStyles({
    mainDiv: {
      height: "46.35px",
      display: "flex",
      alignItems: "center",
      background: "var(--theme-primary)",
      color: "var(--theme-grey-light)",
      padding: "0.2rem",
      marginTop: "0.2rem",
      marginBottom: "0.2rem",
      paddingLeft: window.location.hash == "#/home/supervisor" ? "20px" : 0,
    },
    backButton: {
      color: "var(--theme-grey-light)",
    },
  });

const TitleBar = (props) => {
  const history = useHistory();
  const classes = useStyles()();
  return (
    <div className={classes.mainDiv}>
      {window.location.hash != "#/home/supervisor" && (
        <IconButton
          className={classes.backButton}
          onClick={() => history.replace("/home/supervisor")}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <Typography variant="h6">{props.title}</Typography>
    </div>
  );
};

export default TitleBar;
