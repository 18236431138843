import BasicService from "./BasicService";
import config from "../constants/Servers";

class IntegradorService extends BasicService {
  constructor() {
    super(config.url.INTEGRADOR);
    // this.autenticacao().then((token) => {
    //   sessionStorage.setItem("integrador.token", token);
    // });
  }

  // autenticacao() {
  //   return this.request(
  //     "post",
  //     "autenticacao",
  //     {
  //       usuario: "siganet-video-atendimento-atendente",
  //       senha: "yK78x1psaAxGJ",
  //     },
  //     true
  //   );
  // }

  getLinkDownloadArquivo(idArquivo) {
    return this.baseUrl + "/arquivo/download/" + idArquivo;
  }

  uploadArquivo(file, nomeArquivo, extensaoArquivo, idUnidade, idSenha, idChat) {
    const info = {
      nome_arquivo: nomeArquivo,
      extensao_arquivo: extensaoArquivo,
      id_unidade: idUnidade,
      id_senha: idSenha,
    };
    if (idChat) {
      info.id_chat = idChat;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("info", JSON.stringify(info));
    return this.upload("arquivo", formData);
  }

  excluirArquivos(idUnidade, idSenha, idChat) {
    const params = {
      id_unidade: idUnidade,
      id_senha: idSenha,
    };
    if (idChat) {
      params.id_chat = idChat;
    }
    return this.request("post", "excluirArquivos", params, true, sessionStorage.getItem("integrador.token"));
  }
}

export default IntegradorService;
