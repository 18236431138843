import React from "react";
import { Button, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const BtnAtualizacao = (props) => {
  return (
    <Button
      startIcon={<RefreshIcon />}
      onClick={() => props.onClick()}
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Typography variant="body2" style={{ textAlign: "center" }}>
        Última atualização: {props.ultimaAtualizacao}
      </Typography>
    </Button>
  );
};
export default BtnAtualizacao;
