const drawerWidth = 240;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  divMain: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    borderBottom: "solid 1px #b0b1b3",
  },
  txtUnidade: {
    color: "var(--theme-primary)",
    flexGrow: 1,
  },
  txtInfo: {
    color: "var(--theme-grey)",
    fontSize: "1.1rem",
  },
  spanBar: {
    color: "var(--theme-grey)",
    borderRight: "solid 2px var(--divider)",
    minHeight: "1.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  iconLogoff: {
    color: "var(--theme-primary)",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  iconMenu: {
    color: "var(--theme-grey)",
  },
  iconItem: {
    justifyContent: "center",
    color: "var(--theme-grey-light)",
  },
  iconItemSelected: {
    justifyContent: "center",
    color: "var(--theme-secondary)",
  },
  titleItem: {
    color: "var(--theme-grey-light)",
    flexGrow: 1,
  },
  titleItemSelected: {
    color: "var(--theme-secondary)",
    flexGrow: 1,
  },
  dividerTitle: {
    width: "100%",
    background: "white",
    height: "2px",
  },
  dividerMenu: {
    width: "100%",
    background: "var(--theme-grey)",
    marginLeft: "4.3rem",
  },
  listItem: {
    justifyContent: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${theme.spacing(7)}px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(9)}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerDocked: {
    height: "100%",
  },
  drawerPaper: {
    background: "var(--theme-primary)",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    overflow: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    background: "var(--theme-primary)",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
});

export default useStyles;
