import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { withStyles } from "@mui/styles";
import styles from "./DialogStyle";
import { filter } from "lodash";

const DialogSelectTransistions = (props) => {
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const lista = options ? options.lista : props.lista;
  const handleSelect = options ? options.handleSelect : props.handleSelect;
  const handleClose = options ? options.handleClose : props.handleClose;
  const [hasFilter, setHasFilter] = React.useState(false);

  const getClasses = (list) => {
    const classes = new Map();
    list?.map((item, index) => {
      if (item.classe && !classes.get(item?.classe)) {
        classes.set("C" + item.classe.id, {
          id: item.classe?.id,
          nome: item.classe?.nome,
          group: true,
        });
      } else {
        classes.set(item.id, {
          id: item.id,
          nome: item.nome,
          group: false,
        });
      }
    });
    return classes;
  };
  const [listaFiltrada, setListaFiltrada] = React.useState(lista);

  const filterData = (lista) => {
    let comClasse = [];
    let semClasse = [];
    let todos = [];
    const source = filter(lista, function (params) {
      if (params.classe) {
        comClasse.push(params);
      } else {
        semClasse.push(params);
      }
    });
    source;
    todos = todos.concat(comClasse, semClasse);
    setListaFiltrada(todos);
  };

  React.useEffect(() => {
    filterData(lista);
  }, [lista]);

  const ItemGrouped = ({ item, hasFilter }) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
      if (hasFilter) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [hasFilter, setOpen]);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <ListItemButton
          onClick={handleClick}
          style={{
            borderBottom: "1px solid #93a0a84f",
          }}
        >
          <ListItemText primary={item.nome} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listaFiltrada
              .filter((atividade) => atividade?.classe?.id === item?.id)
              .map((res, index) => {
                return (
                  <ListItemButton
                    key={index}
                    onClick={() => handleSelect(res)}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={res?.nome} />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </>
    );
  };

  const search = (query) => {
    const values = lista;
    const re = RegExp(`.*${query.toLowerCase().split("").join(".*")}.*`);
    if (query && query.length > 2) {
      setListaFiltrada(values.filter((v) => v.nome.toLowerCase().match(re)));
      setHasFilter(true);
    } else {
      filterData(lista);
      setHasFilter(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle
        style={{
          borderTop: "2px solid #b0b1b3",
          borderLeft: "2px solid #b0b1b3",
          borderRight: "2px solid #b0b1b3",
        }}
        className={classes.dialogTitle}
      >
        <span className={classes.fontTitle}>{title}</span>
      </DialogTitle>
      <DialogContent style={{ border: "2px solid #b0b1b3", minHeight: 500 }}>
        <div>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              marginTop: 2,
            }}
          >
            <InputBase
              onChange={(event) => {
                search(event.target.value);
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquisar"
              inputProps={{ "aria-label": "Pesquisar" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {Array.from(getClasses(listaFiltrada).values())?.map(
              (item, index) => {
                return item.group === true ? (
                  <ItemGrouped key={index} item={item} hasFilter={hasFilter} />
                ) : (
                  <ListItemButton
                    onClick={() => handleSelect(item)}
                    key={index}
                  >
                    <ListItemText key={index} primary={item.nome} />
                  </ListItemButton>
                );
              }
            )}
          </List>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withStyles(styles)(DialogSelectTransistions);
