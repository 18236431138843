import React, { useEffect, useState } from "react";
import GlobalizadorService from "../../../../../service/GlobalizadorService";
// import MUIDataTable from "mui-datatables";
import { errorMessage } from "../../../../../helpers/observables";
import moment from "moment";
import BtnAtualizacao from "./BtnAtualizacao";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
const globalizadorService = new GlobalizadorService();

const styles = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
  },
};

const SenhasFila = () => {
  const [senhasFila, setData] = useState([]);
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState(null);

  useEffect(() => {
    load().then();
  }, []);

  const load = async () => {
    try {
      const resp = await globalizadorService.listaSenhas();
      const array = [];
      resp.listaSenhas.forEach((senha) => {
        console.log(senha);
        console.log(senha.indiceAtividade);
        console.log(senha.atividades[0]);
        // array.push([
        //   senha.numero,
        //   senha.atividades[senha.indiceAtividade].atividade.nome,
        //   senha.prioridade.nome,
        //   "senha.nome_cidadao",
        //   senha.dataHoraEmissao,
        //   senha.status,
        // ]);
      });
      setData(array);

      const respHorario = await globalizadorService.consultaRelogio();
      setUltimaAtualizacao(
        moment(respHorario.horario).format("DD/MM/YYYY HH:mm:ss")
      );
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const columns = [
    {
      field: "senha",
      align: "center",
      headerAlign: "center",
      headerName: "Senha",
      width: 120,
    },
    {
      field: "prioridade",
      align: "center",
      headerAlign: "center",
      headerName: "Prioridade",
      width: 150,
    },
    {
      field: "atividade",
      align: "center",
      headerAlign: "center",
      headerName: "Atividade",
      width: 150,
    },
    {
      field: "categoria",
      align: "center",
      headerAlign: "center",
      headerName: "Categoria",
      width: 150,
    },
    {
      field: "secao",
      align: "center",
      headerAlign: "center",
      headerName: "Seção",
      width: 150,
    },
    {
      field: "status",
      align: "center",
      headerAlign: "center",
      headerName: "Status",
      width: 200,
    },
    {
      field: "action",
      headerName: "Ações",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          return alert(JSON.stringify(thisRow, null, 4));
        };
        return (
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={onClick}>Click</Button>
            <Button onClick={onClick}>Click</Button>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <div style={styles.mainDiv}>
      <BtnAtualizacao
        onClick={() => load().then()}
        ultimaAtualizacao={ultimaAtualizacao}
      />
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          // localeText={ptBR.props.MuiDataGrid.localeText}
          rows={senhasFila}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
};

export default SenhasFila;
