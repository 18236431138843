import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import styles from "../../../../Login/LoginStyle";
import { selecionaDecisao } from "../../../../../actions/BackofficeActions";
import DialogProtocolo from "../../../../Utils/Dialogs/DialogProtocolo";
import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";
import { withStyles } from "@mui/styles";

const Decisao = (props) => {
  const [opcao, setOpcao] = useState(null);
  const [openDialogProtocolo, setOpenDialogProtocolo] = useState(false);

  return (
    <Fragment>
      <DialogProtocolo
        open={openDialogProtocolo}
        handleClose={(protocolo) => {
          setOpenDialogProtocolo(false);
          if (protocolo) {
            props.selecionaDecisao(opcao.idPergunta, opcao.idAtividadeDemandaBo, protocolo);
          } else if (protocolo === null) {
            props.selecionaDecisao(opcao.idPergunta, opcao.idAtividadeDemandaBo);
          }
        }}
      />

      {props.listaOpcoesDecisao && props.listaOpcoesDecisao.length > 0 && (
        <Dialog
          open={props.open}
          onClose={() => props.handleClose()}
          style={{
            margin: "0.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DialogTitle>{props.listaOpcoesDecisao[0].pergunta}</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {props.listaOpcoesDecisao.map((opcao, index) => {
                return (
                  <Button
                    key={index}
                    variant={opcao.selecionada ? "contained" : "outlined"}
                    color="primary"
                    style={{ margin: "1rem" }}
                    onClick={() => {
                      if (props.permissoesBackoffice?.perm_envio_protocolo) {
                        setOpenDialogProtocolo(true);
                        setOpcao(opcao);
                      } else {
                        props.handleClose();
                        props.selecionaDecisao(opcao.idPergunta, opcao.idAtividadeDemandaBo);
                      }
                    }}
                  >
                    {opcao.textoOpcao}
                  </Button>
                );
              })}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ backofficeReducer, loginRedicer }) => {
  const { estacao, listaOpcoesDecisao } = backofficeReducer;
  const { permissoesBackoffice } = backofficeReducer;
  return { estacao, listaOpcoesDecisao, permissoesBackoffice };
};

export default connect(mapStateToProps, {
  selecionaDecisao,
})(withStyles(styles)(Decisao));
