import {
  LISTA_TERMINAIS_SUCCESS,
  LISTA_TERMINAIS_FAILED,
} from "constants/types";
import GlobalizadorService from "service/GlobalizadorService";
import { errorMessage, successMessage } from "helpers/observables";

const globalizadorService = new GlobalizadorService();

export const listarTerminais = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .listarTerminais()
        .then((resp) => {
          dispatch({ type: LISTA_TERMINAIS_SUCCESS, payload: resp });
          // successMessage.next("Terminais listados com sucesso");
          resolve(resp);
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: LISTA_TERMINAIS_FAILED, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};
