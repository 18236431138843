import React from "react";
import {
  Button,
  CardActions,
  FormControlLabel,
  IconButton,
  Modal,
  styled,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { makeStyles } from "@mui/styles";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 45 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const ModalAtividades = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [checkedRight, setCheckedRight] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([
    {
      id: 1,
      title: "serviço 1",
      checked: false,
    },
    {
      id: 2,
      title: "serviço 2",
      checked: false,
    },
    ,
    {
      id: 3,
      title: "serviço 3",
      checked: false,
    },
  ]);

  const [isChecked, setIsChecked] = React.useState(() =>
    right.map((i) => false)
  );
  const rightChecked = intersection(checkedRight, right);

  const isLeftChecked = (index, checked) => {
    left.map((res, i) => {
      if (i == index) {
        return (res.checked = checked);
      }
    });
    setIsChecked((isChecked) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked;
        return c;
      });
    });
  };

  const handleAllRight = () => {
    setLeft([]);
  };

  const handleToggleRight = (value) => () => {
    const currentIndex = checkedRight.indexOf(value);
    const newChecked = [...checkedRight];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedRight(newChecked);
  };

  const handleCheckedRight = () => {
    var filteredArray = left.filter(function (e) {
      return e.checked !== true;
    });
    setLeft(filteredArray);
    setIsChecked([]);
  };

  const handleCheckedLeft = () => {
    rightChecked.map((res) => {
      left.push({ ...res });
    });
    setLeft(left);
    setCheckedRight([]);
  };

  const listLeft = (items) => (
    <Paper className={classes.paperTransfer}>
      <List dense component="div" role="list">
        {items.map((value, index) => {
          return (
            <ListItem key={index} role="listitem">
              <FormControlLabel
                key={index + value.title}
                className="twocolelement"
                control={
                  <Checkbox
                    checked={isChecked[index]}
                    disableRipple
                    tabIndex={-1}
                    onChange={(e) => isLeftChecked(index, e.target.checked)}
                  />
                }
                label={value.title}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const mensagemSemItens = (items) => (
    <Paper className={classes.paperTransfer}>
      <List dense component="div" role="list">
        <Typography className={classes.msgNaoHaItens}>
          Não há itens selecionados
        </Typography>
        <ListItem />
      </List>
    </Paper>
  );

  const listRight = (items) => (
    <Paper className={classes.paperTransfer}>
      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={handleToggleRight(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checkedRight.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.title} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <Modal
        children
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Card color="primary" variant="elevation">
            <CardHeader
              style={{ subheader: "color: white" }}
              className={classes.cardHeader}
              title={
                <Typography className={classes.titleCard}>
                  MONTAGEM DE SERVIÇO
                </Typography>
              }
            />
            <CardContent justify="center">
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="center"
                className={classes.root}
              >
                <Grid item>
                  {left.length > 0 ? listLeft(left) : mensagemSemItens()}
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <IconButton
                      size="small"
                      className={classes.buttonTransfer}
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                      title="Limpar tudo"
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={classes.buttonTransfer}
                      onClick={handleCheckedRight}
                      // disabled={isChecked.length === 0}
                      aria-label="move all right"
                      title="Remover item selecionado"
                    >
                      <ChevronRightIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={classes.buttonTransfer}
                      onClick={handleCheckedLeft}
                      // disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                      title="Remover item selecionado"
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item>{listRight(right)}</Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.cardActions} disableSpacing>
              <ColorButton
                onClick={props?.handleClose}
                size="small"
                color="secondary"
                variant="contained"
              >
                Salvar
              </ColorButton>
              <ColorButton
                onClick={props?.handleClose}
                size="small"
                color="primary"
                variant="contained"
              >
                Fechar
              </ColorButton>
            </CardActions>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ModalAtividades;

const useStyles = makeStyles((theme) => ({
  buttonTransfer: {
    margin: theme.spacing(0.5, 0),
  },
  root: {
    margin: "auto",
    justifyContent: "center",
  },
  paperTransfer: {
    width: "180px",
    overflow: "auto",
    maxHeight: "180px",
    overflow: "auto",
  },
  paper: {
    position: "absolute",
    width: "60%",
    minWidth: "370px",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid grey",
    borderRadius: "9px",
    boxShadow: theme.shadows[1],
  },

  cardHeader: {
    margin: "-2px",
    borderTopRightRadius: "9px",
    borderTopLefttRadius: "9px",
    background: "#00598b",
  },
  subColor: {
    color: "rgb(255 255 255 / 65%)",
    fontWeight: "300",
    fontSize: "0.9rem",
  },
  titleCard: {
    fontSize: "1.2rem",
    color: "white",
  },
  cardActions: {
    flexDirection: "row-reverse",
    paddingRight: "15px",
  },
  msgNaoHaItens: {
    fontSize: "0.7rem",
    paddingLeft: "10px",
    paddingTop: "10px",
    fontWeight: "200",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  width: "130px",
  fontSize: "0.7rem",
  fontWeight: "400",
  textAlign: "center",
  textTransform: "lowercase",
  borderRadius: "3px",
  justifyContent: "center",
  marginRight: "5px",
  boxShadow: "none",
  marginBottom: "5px",
  marginRight: "5px",
}));
