import BasicService from "./BasicService";
import config from "../constants/Servers";
import axios from "axios";
import Servers from "../constants/Servers";

class GlobalizadorService extends BasicService {
  constructor() {
    super(config.url.GLOBALIZADOR);
  }

  getUnidades() {
    return this.request("get", "unidade/list", {});
  }

  loginUsuario(username, credentials, unidadeId) {
    return this.request("post", "user/login", {
      username,
      credentials,
      unidadeId,
    });
  }

  statusUsuario() {
    return this.request("get", "user/dados", {});
  }

  getEstacoes(unidadeId) {
    return this.request("post", "backoffice/listaEstacoes", {
      unidadeId,
    });
  }

  loginEstacao(estacaoBoId) {
    return this.request("post", "backoffice/login", {
      estacaoBoId,
    });
  }

  logoffEstacao() {
    return this.request("post", "backoffice/logoff", {});
  }

  statusEstacao() {
    return this.request("get", "user/status", {});
  }

  proximaDemanda() {
    // return this.request("post", "backoffice/join", {});
    return this.request("post", "guiche/join", {});
  }

  selecionaDecisao(atividadeId, respostaId) {
    return this.request("post", "backoffice/selectOption", {
      atividadeId,
      respostaId,
    });
  }

  finalizaDemanda(protocolo) {
    let params = {};
    if (protocolo) {
      params.protocolo = protocolo;
    }
    return this.request("post", "backoffice/finish", params);
  }

  getDownloadLink(taskId, filename) {
    return this.request("post", "anexo/downloadLink", {
      taskId,
      filename,
    });
  }

  solicitaAgendamento(servicoId, comentario, horaRetorno, categoriaId) {
    let params = {
      servicoId,
    };
    if (comentario) {
      params.comentario = comentario;
    }
    if (horaRetorno) {
      params.horaRetorno = horaRetorno + " 00:00:00";
    }
    if (categoriaId) {
      params.categoriaId = categoriaId;
    }
    return this.request("post", "backoffice/solicitaAgendamento", params);
  }

  registraPendencia(descricao, comentario, horaRetorno) {
    let params = {
      descricao,
    };
    if (comentario) {
      params.comentario = comentario;
    }
    if (horaRetorno) {
      params.horaRetorno = horaRetorno + " 00:00:00";
    }
    return this.request("post", "backoffice/registraPendencia", params);
  }

  listaMotivosReprovacao() {
    return this.request("post", "backoffice/listaMotivosReprovacao", {});
  }

  listaServicos(cidadeId) {
    return this.request("get", "agendamento/servicos/" + cidadeId, {});
  }

  iniciaAtendimento(idGuiche) {
    return this.request("post", "guiche/inicia", {
      idGuiche,
    });
  }

  finalizaAtendimento(idGuiche) {
    return this.request("post", "guiche/finaliza", {
      idGuiche,
    });
  }

  recusarAvaliacao(idGuiche) {
    return this.request("post", "siganet/call/finalizaAtendimento", {
      id_guiche: idGuiche,
      libera_avaliacao: true,
    });
  }

  cancelaAtendimento(idGuiche, senhaId, idMotivo) {
    return this.request("post", "guiche/cancela", {
      idGuiche,
      senhaId,
      idMotivo,
    });
  }

  listaGuiches(unidadeId) {
    return this.request("post", "guiche/listaGuiches", {
      unidadeId,
    });
  }

  listaGuichesAtendente(unidadeId) {
    return this.request("get", "guiche/listaGuichesAtendente", {
      unidadeId,
    });
  }

  loginGuiche(idGuiche) {
    return this.request("post", "guiche/login", {
      idGuiche,
    });
  }

  avaliaFormulario(chave, aprovado, motivo) {
    return this.request("post", "backoffice/avaliaFormulario", {
      chave,
      aprovado,
      motivo,
    });
  }

  avaliaArquivo(chave, aprovado, motivo) {
    return this.request("post", "backoffice/avaliaArquivo", {
      chave,
      aprovado,
      motivo,
    });
  }

  avaliaArquivoExterno(chave, aprovado, motivo) {
    return this.request("post", "backoffice/avaliaArquivoExterno", {
      chave,
      aprovado,
      motivo,
    });
  }

  inserirComentario(comentario) {
    return this.request("post", "backoffice/comment", {
      comentario,
    });
  }

  getUploadLink(taskId, filename, contentLength, author, contentType) {
    return this.request("post", "anexo/uploadLink", {
      taskId,
      filename,
      contentLength,
      author,
      contentType,
    });
  }

  enviarArquivo(url, file) {
    return axios({
      method: "put",
      url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  }

  uploadSuccess(taskId, label, size, author, reference, key) {
    return this.request("post", "anexo/uploadSuccess", {
      taskId,
      label,
      size,
      author,
      reference,
      key,
    });
  }

  devolveDemanda(usuarioId, unidadeId) {
    return this.request("post", "backoffice/retorna", {
      usuarioId,
      unidadeId,
    });
  }

  aprovaTodosFormulario() {
    return this.request("post", "backoffice/aprovaTodosFormulario", {});
  }

  listaMotivosCancelamento() {
    return this.request("get", "guiche/listaMotivosCancelamento", {});
  }

  cancelaSenha(idGuiche, idSenha, idMotivo) {
    let parametros = { idGuiche: idGuiche, senhaId: idSenha };
    if (idMotivo) {
      parametros.motivoId = idMotivo;
    }
    return this.request("post", "guiche/cancela", parametros);
  }

  listaServicosCriaSenha() {
    return this.request("get", "guiche/listaServicos");
  }

  criarSenha(idServico) {
    return this.request("post", "guiche/criaSenha", {
      idServico: idServico,
    });
  }

  eliminaSenhas() {
    return this.request("post", "supervisor/elimina");
  }

  presetContadores() {
    return this.request("post", "supervisor/preset");
  }

  listaMotivosCongelamentoSecao(idSecao) {
    return this.request(
      "post",
      "siganet/call/lista_motivos_congelamento_secao",
      { id_secao: idSecao }
    );
  }

  congelaSenha(idGuiche, idSenha, idUsuario, idMotivo) {
    let parametros = {
      id_guiche: idGuiche,
      id_senha: idSenha,
      id_usuario: idUsuario,
    };

    if (idMotivo) {
      parametros.id_motivo = idMotivo;
    }

    return this.request("post", "siganet/call/congela_senha", parametros);
  }

  listaSenhas() {
    return this.request("post", "supervisor/listaSenhas");
  }

  listaSenhasCongeladas(idSecao, idUsuario) {
    return this.request("post", "siganet/call/lista_senhas_congeladas", {
      id_secao: idSecao,
      id_usuario: idUsuario,
    });
  }

  descongelaSenha(idGuiche, idSenha, idUsuario, idGuicheDestino) {
    return this.request("post", "siganet/call/descongela_senha", {
      id_guiche: idGuiche,
      id_senha: idSenha,
      id_usuario: idUsuario,
      id_guiche_destino: idGuicheDestino,
    });
  }

  rechamaSenha(idGuiche, idSenha) {
    return this.request("post", "guiche/rechama");
  }

  listaServicosGuiche() {
    return this.request("get", "guiche/listaServicos");
  }

  adicionarServico(idServico) {
    return this.request("post", "guiche/adicionaServico", {
      idServico: idServico,
    });
  }

  alterarServico(idServico) {
    return this.request("post", "guiche/alteraServico", {
      idServico,
    });
  }

  excluiAtividadeSenha(idSenha, indiceRealAtividade) {
    return this.request("post", "siganet/call/exclui_atividades_senha", {
      id_senha: idSenha,
      indice_real_atividade: indiceRealAtividade,
    });
  }

  logoffGuiche(idGuiche) {
    return this.request("post", "guiche/logoff", {
      idGuiche,
    });
  }

  proximaSenha(idGuiche, senhaEspecifica, idAtividade, idPrioridade) {
    return this.request("post", "siganet/call/proxima_senha", {
      id_guiche: idGuiche,
      id_atividade: idAtividade,
      id_prioridade: idPrioridade,
      id_categoria: 0,
      senha_especifica: senhaEspecifica,
      manual: 0,
    });
  }

  chamadaEspecifica(senhaEspecifica) {
    return this.request("post", "guiche/senhaEspecifica", {
      idSenha: senhaEspecifica,
    });
  }

  listaMotivosSuspensao(idUsuario) {
    return this.request("get", "user/listaMotivosSuspensao", {
      id_usuario: idUsuario,
    });
  }

  suspendeGuiche(idGuiche, idMotivo) {
    return this.request("post", "siganet/call/suspende_usuario_guiche", {
      id_guiche: idGuiche,
      id_motivo: idMotivo,
      forcado: 0,
    });
  }

  suspendeUsuario(idMotivo) {
    return this.request("post", "user/solicitaSuspensao", {
      idMotivo: idMotivo,
    });
  }

  retornaUsuarioSuspensao() {
    return this.request("post", "user/retornaSuspensao");
  }

  listaFiltroPrioridadesGuiche(idGuiche) {
    return this.request("post", "siganet/call/listaFiltroPrioridadesGuiche", {
      idGuiche: idGuiche,
    });
  }

  salvaComentarioSenha(comentario) {
    return this.request("post", "guiche/comentario", { comentario });
  }

  listaMotivosPausa() {
    return this.request("get", "guiche/listaMotivosPausa");
  }

  pausarAtendimento(idMotivo) {
    return this.request("post", "guiche/pausa", {
      idMotivo,
    });
  }

  continuarAtendimento() {
    return this.request("post", "guiche/retorna");
  }

  listaMotivosIndisponibilidadeGuiche() {
    return this.request(
      "post",
      "siganet/call/lista_motivos_indisponibilidade_guiche",
      {}
    );
  }

  indisponibilizarGuiche(idGuiche, idMotivo) {
    return this.request("post", "siganet/call/indisponibiliza_guiche", {
      id_guiche: idGuiche,
      id_motivo_indisponibilidade: idMotivo,
    });
  }

  encerrarIndisponibilidadeGuiche(idGuiche) {
    return this.request("post", "siganet/call/indisponibiliza_guiche", {
      id_guiche: idGuiche,
      id_motivo_indisponibilidade: 0,
    });
  }

  listaGuichesDisponiveisSenha(idSenha) {
    return this.request(
      "post",
      "siganet/call/lista_guiches_disponiveis_senha",
      {
        id_senha: idSenha,
      }
    );
  }

  iniciaApoioGuiche(idGuiche, idGuicheApoio) {
    return this.request("post", "siganet/call/inicia_apoio_guiche", {
      id_guiche: idGuiche,
      id_guiche_apoio: idGuicheApoio,
    });
  }

  finalizaApoioGuiche(idAtendenteApoiador) {
    return this.request("post", "siganet/call/finaliza_apoio_guiche", {
      id_atendente_apoiador: idAtendenteApoiador,
    });
  }

  listaSenhasFilaGuicheAtende() {
    return this.request("get", "guiche/listaSenhasFila");
  }

  liberaGuicheAguardandoAoFinalizar(idGuiche) {
    return this.request(
      "post",
      "siganet/call/libera_guiche_aguardando_ao_finalizar",
      {
        id_guiche: idGuiche,
      }
    );
  }

  transfereSenhaGuiche(idGuiche, idSenha, idUsuario, idGuicheDestino) {
    return this.request("post", "siganet/call/transfere_senha_guiche", {
      id_guiche: idGuiche,
      id_senha: idSenha,
      id_usuario: idUsuario,
      id_guiche_destino: idGuicheDestino,
    });
  }

  listaSenhasFinalizadas(idGuiche) {
    return this.request("post", "siganet/call/listaSenhasFinalizadas", {
      id_guiche: idGuiche,
    });
  }

  reativaSenha(idGuiche, idSenha) {
    return this.request("post", "siganet/call/reativaSenhaFinalizada", {
      idGuiche,
      idSenha,
    });
  }

  listaPalavrasSincronismoAval() {
    return this.request(
      "post",
      "siganet/call/listaPalavrasSincronismoAval",
      {}
    );
  }

  selecionaPalavraSincronismoAval(idGuiche, indicePalavra) {
    return this.request(
      "post",
      "siganet/call/selecionaPalavraSincronismoAval",
      {
        idGuiche,
        indicePalavra,
      }
    );
  }

  agendaAtividadeSenha(idSenha, indiceRealAtividade, horaAgendada) {
    return this.request("post", "siganet/call/agenda_atividade_senha", {
      id_senha: idSenha,
      indice_real_atividade: indiceRealAtividade,
      hora_agendada: horaAgendada,
    });
  }

  reordenaAtividadesSenha(idSenha, array) {
    return this.request("post", "siganet/call/reordena_atividades_senha", {
      id_senha: idSenha,
      lista_id_atividades: array,
    });
  }

  listaAdicionaAtividadesGuiche(idGuiche) {
    return this.request("get", "guiche/listaAtividadesAdicionais");
  }

  atividadeAdicional(idAtividade) {
    return this.request("post", "guiche/atividadeAdicional", {
      idAtividade,
    });
  }

  listaFiltroAtividadesGuiche() {
    return this.request("get", "guiche/listaAtividades");
  }

  alteraAtividadeSenha(idAtividade) {
    return this.request("post", "guiche/alteraAtividade", {
      idAtividade,
    });
  }

  proximoPasso(idGuiche, idSenha) {
    return this.request("post", "siganet/call/proximoPasso", {
      id_guiche: idGuiche,
      id_senha: idSenha,
    });
  }

  avaliacaoGuiche(idGuiche, stringAvaliacao) {
    return this.request("post", "siganet/call/avaliacao_guiche", {
      id_guiche: idGuiche,
      string_avaliacao: stringAvaliacao,
    });
  }

  listaCamposClientes() {
    return this.request("get", "cliente/campos", {});
  }

  buscaCliente(params) {
    return this.request("post", "siganet/call/buscaCliente", params);
  }

  salvaCliente(params) {
    return this.request("post", "siganet/call/salvaCliente", params);
  }

  vinculaClienteSenha(idCliente, idSenha, idAtendente) {
    return this.request("post", "siganet/call/vinculaClienteSenha", {
      idCliente,
      idSenha,
      idAtendente,
    });
  }

  desvinculaClienteSenha(idCliente, idSenha, idAtendente) {
    return this.request("post", "siganet/call/desvinculaClienteSenha", {
      idCliente,
      idSenha,
      idAtendente,
    });
  }

  listaCidadaosSenha(idSenha) {
    return this.request("post", "siganet/call/listaCidadaosSenha", { idSenha });
  }

  iniciarAtendimentoDespachante(owner) {
    return this.request("post", "backoffice/iniciaDespachante", {
      owner: owner,
    });
  }

  finalizarAtendimentoDespachante() {
    return this.request("post", "backoffice/finalizaDespachante", {});
  }

  demandaEspecifica(atividadeId, demandaId) {
    return this.request("post", "backoffice/specificTask", {
      atividadeId,
      demandaId,
    });
  }

  listaFingerprint() {
    return this.request("get", "auth/listDevices", {});
  }

  getAuthtoken() {
    return this.request("get", "auth/token", {});
  }

  listaImpressoras() {
    return this.request("post", "siganet/call/lista_impressoras", {});
  }

  imprimeDemandaAtual(impressoraId) {
    return this.request("post", "backoffice/imprimeDemandaAtual", {
      impressoraId,
    });
  }

  imprimeDemandasDespachante(impressoraId) {
    return this.request("post", "backoffice/imprimeDemandasDespachante", {
      impressoraId,
    });
  }

  specificTask(protocolo) {
    return this.request("post", "backoffice/specificTask", {
      protocolo,
    });
  }

  listaSenhasUnidade() {
    return this.request("post", "siganet/call/lista_senhas_unidade", {});
  }

  internalizaDemanda(status, taskId, impressoraId) {
    let params = {
      status,
      taskId,
    };
    if (impressoraId) {
      params.impressoraId = impressoraId;
    }
    return this.request("post", "backoffice/internaliza", params);
  }

  imprimeDemanda(taskId, impressoraId) {
    return this.request("post", "backoffice/imprimeDemanda", {
      taskId,
      impressoraId,
    });
  }

  cancelaDespachante() {
    return this.request("post", "backoffice/cancelaDespachante", {});
  }

  finalizaLiveChat(idLiveChat) {
    return this.request("post", "siganet/call/finalizaLiveChat", {
      idLiveChat,
    });
  }

  finalizaAtendimentoLiveChat(idGuiche) {
    return this.request("post", "siganet/call/finalizaAtendimentoLiveChat", {
      idGuiche,
    });
  }

  solicitaSairModoLiveChat(idGuiche) {
    return this.request("post", "siganet/call/solicitaSairModoLiveChat", {
      idGuiche,
    });
  }

  listaMensagensPadrao() {
    return this.request("post", "siganet/call/listaMensagensPadrao", {});
  }

  listaDemandasDisponiveisEstacao(itensPorPagina, numeroPagina, pesquisa) {
    const params = {};
    if (itensPorPagina !== undefined) {
      params.itensPorPagina = itensPorPagina;
    }
    if (numeroPagina !== undefined) {
      params.numeroPagina = numeroPagina + 1;
    }
    if (pesquisa !== undefined) {
      params.pesquisa = pesquisa;
    }
    return this.request("post", "backoffice/listaDemandas", params);
  }

  encerraDemanda(protocolo) {
    let params = {};
    if (protocolo) {
      params.protocolo = protocolo;
    }
    return this.request("post", "backoffice/completaDemanda", params);
  }

  panoramaLiveChat() {
    return this.request("post", "siganet/call/panoramaLiveChat", {});
  }

  panoramaCategorias() {
    return this.request("post", "siganet/call/panoramaCategorias", {});
  }

  getListaAtivosLiveChat() {
    return this.request("post", "siganet/call/listaAtivosLiveChat", {});
  }

  getListaGuichesDisponiveisLiveChat(tags) {
    return this.request(
      "post",
      "siganet/call/listaGuichesDisponiveisLiveChat",
      {
        itensPorPagina: 1000,
        numeroPagina: 0,
        tags,
      }
    );
  }

  transfereLiveChat(
    idChat,
    idUsuario,
    idGuicheDestino,
    idUsuarioOrigem,
    idUsuarioDestino
  ) {
    return this.request("post", "guiche/transfereLiveChat", {
      id: idChat,
      idUsuario,
      idGuicheDestino,
      idUsuarioOrigem,
      idUsuarioDestino,
    });
  }

  vinculaTextoChatSenha(idSenha, idAtividade, idAtendente, mensagens) {
    mensagens.forEach((mensagem, index) => {
      mensagem.id = index;

      if (mensagem.type === "ATENDENTE") {
        mensagem.tipo = "E";
      } else {
        mensagem.tipo = "R";
      }
      delete mensagem.type;

      mensagem.mensagem = mensagem.message;
      delete mensagem.message;

      mensagem.horario = mensagem.dateTime;
      delete mensagem.dateTime;

      if (mensagem.arquivo) {
        mensagem.arquivo =
          Servers.url.INTEGRADOR + "/arquivo/download/" + mensagem.idArquivo;
        delete mensagem.idArquivo;
      }

      delete mensagem.delivered;

      if (mensagem.fileId) {
        mensagem.mensagem = mensagem.fileName;
        delete mensagem.fileId;
        delete mensagem.fileName;
      }
    });

    return this.request("post", "siganet/call/vinculaTextoChatSenha", {
      idSenha,
      idAtividade,
      idAtendente,
      compactado: false,
      textoChat: JSON.stringify(mensagens),
    });
  }

  consultaRelogio() {
    return this.request("post", "siganet/call/consultaRelogio", {});
  }

  panoramaCategoriasVideo() {
    return this.request("post", "siganet/call/panoramaCategoriasVideo", {});
  }

  panoramaGuichesVideo() {
    return this.request("post", "siganet/call/panoramaGuichesVideo", {});
  }

  panoramaCategoriasServicoVideo() {
    return this.request(
      "post",
      "siganet/call/panoramaCategoriasServicoVideo",
      {}
    );
  }

  panoramaGuichesLiveChat() {
    return this.request("post", "siganet/call/panoramaGuichesLiveChat", {});
  }

  enviaMsgChat(params) {
    return this.request("post", "siganet/call/enviaMsgChat", params);
  }

  alteraSenhaUsuario(idUsuario, senhaAtual, novaSenha) {
    return this.request("post", "siganet/call/altera_senha_usuario", {
      id_usuario: idUsuario,
      senha_atual: senhaAtual,
      nova_senha: novaSenha,
    });
  }

  supervisorListaCategorias() {
    return this.request("get", "supervisor/listaCategorias", {});
  }

  supervisorListaPrioridades() {
    return this.request("get", "supervisor/listaPrioridades", {});
  }

  supervisorListaServicos() {
    return this.request("post", "supervisor/listaServicos");
  }

  supervisorEmiteSenha(params) {
    return this.request("post", "supervisor/emiteSenha", params);
  }

  supervisorGetSenhaById(params) {
    return this.request("get", "supervisor/senha/" + params);
  }

  listarTerminais() {
    return this.request("get", "emissor/listaTerminais");
  }

  guicheListaPrioridades() {
    return this.request("get", "guiche/listaPrioridades");
  }

  guicheAlteraPrioridade(id) {
    return this.request("post", "guiche/alteraPrioridade", {
      idPrioridade: id,
    });
  }

  guicheListaCategorias() {
    return this.request("get", "guiche/listaCategorias");
  }

  guicheAlteraCategoria(id) {
    return this.request("post", "guiche/alteraCategoria", {
      idCategoria: id,
    });
  }

  guicheListaMotivosCongelamento() {
    return this.request("get", "guiche/listaMotivosCongelamento");
  }

  guicheCongela(idMotivo) {
    return this.request("post", "guiche/congela", { idMotivo });
  }

  guicheListaSenhasCongeladas() {
    return this.request("get", "guiche/listaSenhasCongeladas");
  }

  guicheDescongelarSenha(obj) {
    return this.request("post", "guiche/descongela", obj);
  }
}

export default GlobalizadorService;
