import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "../../HomeStyle";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { listarTerminais } from "actions/EmissorActions";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Botoes from "constants/BotoesTerminalEmissor";
import { telas } from "./data";
import { successMessage } from "helpers/observables";

const Emissor = (props) => {
  const [lista, setLista] = useState([]);
  const [listaBK, setListaBK] = useState(telas);
  // const [telas, setTelas] = useState([]);
  const [subtitulo, setSubTitulo] = useState();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const anchorRef = React.useRef(null);

  useEffect(() => {
    // props.listarTerminais().then((res) => {
    //   setTelas(res);
    //   setListaBK(res);
    // });
  }, []);

  const colorConstrast = (hex) => {
    const hexToRgb = (hex) =>
      hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    return 0.299 * hexToRgb(hex)[0] +
      0.587 * hexToRgb(hex)[1] +
      0.114 * hexToRgb(hex)[2] >
      186
      ? "#000"
      : "#fff";
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setListaBK(telas[index]?.telas);
    setOpen(false);
    setSubTitulo(null);
    telas[index]?.telas.map((tela, i) => {
      if (tela.principal) {
        setLista(tela.botoes);
        setSubTitulo(tela.botoes.nome);
      } else {
        setLista(telas[index]?.telas[0].botoes);
        setSubTitulo(telas[index]?.telas[0].nome);
      }
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const listaBotoes = (botoes) => {
    return botoes?.map((tela, i) => {
      console.log(tela);
      return (
        <Button
          key={i}
          variant="contained"
          style={{ backgroundColor: tela.cor }}
          size="large"
          onClick={() => botoesActions(tela)}
        >
          <Typography style={{ color: colorConstrast(tela.cor) }}>
            {tela.nome}
          </Typography>
        </Button>
      );
    });
  };

  const selectTerminal = () => {
    return (
      <div>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          color="secondary"
        >
          <Button style={{ width: 250 }}>
            {!!telas[selectedIndex]?.nome
              ? telas[selectedIndex]?.nome
              : "Selecione um terminal"}
          </Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {telas?.map((option, index) => (
                      <MenuItem
                        key={option.id}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.nome}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const alteraParametros = (parametros) => {
    let obj = {
      idServico: parametros.idServico ? [parametros.idServico] : [],
      idPrioridade: parametros.idPrioridade ? parametros.idPrioridade : null,
      idCategoria: parametros.idCategoria ? parametros.idCategoria : null,
    };
    let sessionData = sessionStorage.getItem("emissorParametros");
    if (sessionData) {
      let dataSession = JSON.parse(sessionData);
      console.log(dataSession);
      if (parametros.idServico) {
        dataSession.idServico.push(parametros.idServico);
      }
      if (parametros.idPrioridade) {
        dataSession["idPrioridade"] = parametros.idPrioridade;
      }
      if (parametros.idCategoria) {
        dataSession["idCategoria"] = parametros.idCategoria;
      }
      sessionStorage.setItem("emissorParametros", JSON.stringify(dataSession));
      console.log(dataSession);
    } else {
      sessionStorage.setItem("emissorParametros", JSON.stringify(obj));
    }
  };

  const adicionaServicos = (parametros) => {
    let obj = {
      idServico: parametros.idServico ? [parametros.idServico] : [],
      idPrioridade: parametros.idPrioridade ? parametros.idPrioridade : null,
      idCategoria: parametros.idCategoria ? parametros.idCategoria : null,
    };
    let sessionData = sessionStorage.getItem("emissorParametros");
    if (sessionData) {
      let dataSession = JSON.parse(sessionData);
      if (parametros.idServico) {
        dataSession.idServico.push(parametros.idServico);
      }
      sessionStorage.setItem("emissorParametros", JSON.stringify(dataSession));
    } else {
      sessionStorage.setItem("emissorParametros", JSON.stringify(obj));
    }
  };

  const emiteImpressao = (parametros) => {
    console.log("emiteImpressao", parametros);
    successMessage.next("Os dados foram para impressão");
    sessionStorage.removeItem("emissorParametros");
    setLista([]);
    setSubTitulo(null);
  };

  const navegar = (tela) => {
    if (tela.telaSeginteId) {
      setLista(listaBK.filter((res) => res.id == tela.telaSeginteId)[0].botoes);
      setSubTitulo(
        listaBK.filter((res) => res.id == tela.telaSeginteId)[0].nome
      );
    } else {
      setLista(tela.botoes);
      setSubTitulo(tela.nome);
    }
  };

  const botoesActions = (tela) => {
    switch (tela.funcionalidade) {
      case Botoes.TIPO_BOTAO_EMISSOR_EMITE_IMPRIME:
        emiteImpressao(tela);
        break;
      case Botoes.TIPO_BOTAO_EMISSOR_ADICIONA_SERVICO:
        adicionaServicos(tela);
        break;
      case Botoes.TIPO_BOTAO_EMISSOR_ALTERA_PARAMETROS:
        alteraParametros(tela);
        break;
      case Botoes.TIPO_BOTAO_EMISSOR_NAVEGACAO:
        navegar(tela);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingTop: 15 }}>
      <Typography style={{ marginBottom: 20 }} variant="h6">
        Emissor
      </Typography>
      {selectTerminal()}
      {subtitulo && (
        <Typography
          style={{ marginTop: 20, borderBottom: "1px solid #00598b40" }}
          variant="h6"
        >
          {subtitulo}
        </Typography>
      )}
      <Stack
        style={{ marginTop: 20 }}
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
      >
        {listaBotoes(lista)}
      </Stack>
    </div>
  );
};

const mapStateToProps = ({ emissorReducer }) => {
  const { terminais } = emissorReducer;
  return {
    terminais,
  };
};

export default connect(mapStateToProps, { listarTerminais })(
  withStyles(styles)(Emissor)
);
