import React, { useEffect, useState, useRef } from "react";
import { errorMessage } from "../../helpers/observables";
import GlobalizadorService from "../../service/GlobalizadorService";
import { connect } from "react-redux";
// import {
//   cancelaDespachante,
//   chamadaEspecifica,
//   finalizarAtendimentoDespachante,
//   imprimirDemandaAtual,
//   imprimirDemandasDespachante,
//   iniciarAtendimentoDespachante,
//   internalizaDemanda,
// } from "../../actions/DespachanteActions";
import { imprimeDemanda } from "../../actions/BackofficeActions";
import { withStyles } from "@mui/styles";
import styles from "../Home/components/Atendimento/components/BotoesAcao/BotoesAcaoStyle";

const globalizadorService = new GlobalizadorService();

const OmnisigaAuthPopup = (props) => {
  const [externalWindow, setExternalWindow] = useState(null);
  const intervalRef = useRef();

  const title = props.title;
  const width = props.width ? props.width : 800;
  const height = props.height ? props.height : 800;
  const onCode = props.onCode;
  const onClose = props.onClose;
  const children = props.children;
  const username = props.username;

  const atualizaToken = async () => {
    try {
      const resp = await globalizadorService.getAuthtoken();
      let urlCalc = "";
      if (username && username !== "") {
        urlCalc =
          props.urlLoginDespachante +
          "/fingerprint/request-bio?device=" +
          localStorage.getItem("fingerprint") +
          "&token=" +
          resp.token +
          "&username=" +
          username +
          "&redirectUri=" +
          urlDespachante;
      } else {
        urlCalc =
          props.urlLoginDespachante +
          "/fingerprint/request-username?device=" +
          localStorage.getItem("fingerprint") +
          "&token=" +
          resp.token +
          "&redirectUri=" +
          urlDespachante;
      }
      setExternalWindow(createPopup(urlCalc, title, width, height));
    } catch (err) {
      errorMessage.next(err.message);
    }
    return null;
  };

  const urlDespachante = window.location.href.split("#")[0] + "ok.html";

  const createPopup = (url, title, height, width) => {
    let left = window.screenX + (window.outerWidth - width) / 2;
    let top = window.screenY + (window.outerHeight - height) / 2.5;
    let externalPopup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    return externalPopup;
  };

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const onContainerClick = () => {
    atualizaToken();
    // setExternalWindow(createPopup({
    //   urlOmniAuth, title, width, height,
    // }));
  };

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const params = new URL(currentUrl).searchParams;
          const code = params.get("token");
          if (!code) {
            return;
          }
          setExternalWindow(null);
          onCode(code, params);
          clearTimer();
        } catch (error) {
          // eslint-ignore-line
        } finally {
          if (!externalWindow || externalWindow.closed) {
            setExternalWindow(null);
            onClose();
            clearTimer();
          }
        }
      }, 700);
    }
    return () => {
      if (externalWindow) externalWindow.close();
      if (onClose) onClose();
    };
  }, [externalWindow]);

  return (
    <div
      onClick={() => {
        onContainerClick();
      }}
    >
      {children}
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { urlLoginDespachante } = backofficeReducer;
  return { urlLoginDespachante };
};

export default connect(mapStateToProps, {})(withStyles(styles)(OmnisigaAuthPopup));
