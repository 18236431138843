import React, { useState } from "react";
import { Button, ButtonGroup, styled } from "@mui/material";
import ModalRastreamento from "components/Home/components/Supervisor/components/EmissaoSenha/components/ModalRastreamento";
import ModalAtividades from "components/Home/components/Supervisor/components/EmissaoSenha/components/ModalAtividades";
import ModalAgendamento from "components/Home/components/Supervisor/components/EmissaoSenha/components/ModalAgendamento";
import ModalCliente from "components/Home/components/Supervisor/components/EmissaoSenha/components/ModalCliente/ModalCliente";
import ModalAtendente from "components/Home/components/Supervisor/components/EmissaoSenha/components/ModalAtendente";

const SubMenuSupervisaoAtividades = (props) => {
  var [modalOpen, setOpenModal] = React.useState({
    rastreamento: false,
    atividades: false,
    agendamento: false,
    atendente: false,
    cliente: false,
    enviafila: false,
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    width: "130px",
    fontSize: "0.7rem",
    fontWeight: "400",
    textAlign: "center",
    textTransform: "lowercase",
    borderRadius: "3px",
    justifyContent: "center",
    marginRight: "5px",
    boxShadow: "none",
    marginBottom: "5px",
  }));

  const handleClose = () => {
    setOpenModal({
      rastreamento: false,
      atividades: false,
      agendamento: false,
      atendente: false,
      cliente: false,
      enviafila: false,
    });
  };

  const openModal = (nome) => {
    switch (nome) {
      case "Rastreamento":
        setOpenModal({ ...modalOpen, ["rastreamento"]: true });
        break;
      case "Atividades":
        setOpenModal({ ...modalOpen, ["atividades"]: true });
        break;
      case "Agendamento":
        setOpenModal({ ...modalOpen, ["agendamento"]: true });
        break;
      case "Atendente":
        setOpenModal({ ...modalOpen, ["atendente"]: true });
        break;
      case "Cliente":
        setOpenModal({ ...modalOpen, ["cliente"]: true });
        break;
      case "Envia Fila":
        console.log("envia fila");
        break;
    }
  };

  const [btnArr] = useState([
    {
      id: 1,
      nome: "Rastreamento",
    },
    {
      id: 2,
      nome: "Atividades",
    },
    {
      id: 3,
      nome: "Agendamento",
    },
    {
      id: 4,
      nome: "Atendente",
    },
    {
      id: 4,
      nome: "Cliente",
    },
    {
      id: 4,
      nome: "Envia Fila",
    },
  ]);

  return (
    <>
      <div style={{ width: "100%", paddingTop: "5px" }}>
        <ModalRastreamento open={modalOpen.rastreamento} handleClose={handleClose} />
        <ModalAtividades open={modalOpen.atividades} handleClose={handleClose} />
        <ModalAgendamento open={modalOpen.agendamento} handleClose={handleClose} />
        <ModalCliente open={modalOpen.cliente} handleClose={handleClose} />
        <ModalAtendente open={modalOpen.atendente} handleClose={handleClose} />
        {btnArr.map((res, index) => {
          return (
            <ButtonGroup
              key={index}
              orientation="horizontal"
              aria-label="horizontal contained button group"
              color="primary"
            >
              <ColorButton
                onClick={() => {
                  openModal(res.nome);
                }}
                key={index}
                size="small"
                color="secondary"
                variant="contained"
              >
                {res.nome}
              </ColorButton>
            </ButtonGroup>
          );
        })}
      </div>
    </>
  );
};

export default SubMenuSupervisaoAtividades;
