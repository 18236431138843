import React from "react";
import styles from "./DialogStyle";
import { Typography, DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@mui/material";
import { withStyles } from "@mui/styles";

const DialogConfirmation = (props) => {
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const description = options ? options.description : props.description;
  const handleClose = options ? options.handleClose : props.handleClose;
  const handleConfirm = options ? options.handleConfirm : props.handleConfirm;

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <span className={classes.fontTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ fontWeight: 400 }}>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} style={{ color: "red" }}>
          Não
        </Button>
        <Button color="primary" autoFocus onClick={() => handleConfirm()}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogConfirmation);
