export default {
  CHAMADA_ESPECIFICA: 0,
  FILTRAR_ATIVIDADE: 1,
  FILTRAR_PRIORIDADE: 2,
  CANCELAR_SENHA: 4,
  ADICIONAR_COMENTARIO: 8,
  ALTERAR_ATIVIDADE: 11,
  ALTERA_PRIORIDADE: 12,
  ALTERA_CATEGORIA: 13,
  ATIVIDADE_ADICIONAL: 14,
  ALTERAR_SERVICO: 15,
  PAUSAR_ATENDIMENTO: 17,
  ADICIONAR_SERVICO: 18,
  INICIAR_APOIO: 20,
  TRANSFERIR_SENHA: 21,
  CONGELAR_SENHA: 24,
  CRIAR_SENHA: 25,
  DESCONGELAR_SENHA: 26,
  SINCRONIZA_TERMINAL: 32,
  CONTINUAR_ATENDIMENTO: 48,
  INICIAR_ATENDIMENTO: 50,
  FINALIZAR_ATENDIMENTO: 51,
  RECHAMAR_SENHA: 52,
  PROXIMO_PASSO: 53,
  FINALIZAR_APOIO: 56,
  LIBERAR_GUICHE_AGUARDANDO_FINALIZAR: 59,
  INDISPONIBILIZAR_GUICHE: 60,
  ENCERRAR_INDISPONIBILIDADE_GUICHE: 61,
  REATIVAR_SENHA: 62,
  SOLICITAR_AVALIACAO: 65,
  RECUSAR_AVALIACAO: 66,
};
