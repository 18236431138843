import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = () =>
  makeStyles({
    paper: {
      padding: "2rem 1rem",
      textAlign: "center",
      marginBottom: "0.2rem",
      borderRadius: 0,
    },
  });

const CustomCard = (props) => {
  const classes = useStyles(props)();
  return (
    <Paper className={classes.paper} {...props}>
      {props.children}
    </Paper>
  );
};

export default CustomCard;
