import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { uploadArquivo } from "actions/BackofficeActions";
import DialogArquivo from "./Arquivos/components/DialogArquivo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GlobalizadorService from "service/GlobalizadorService";
import { errorMessage, successMessage } from "helpers/observables";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

const globalizadorService = new GlobalizadorService();

const Uploads = (props) => {
  const [arquivo, setArquivo] = useState(null);
  const [openDialogArquivo, setOpenDialogArquivo] = useState(false);
  const [selectedArquivo, setSelectedArquivo] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);

  const enviarArquivo = () => {
    if (arquivo) {
      props.uploadArquivo(props.task.id, arquivo.name, arquivo.size, props.nomeUsuario, arquivo);
    } else {
      errorMessage.next("Nenhum arquivo foi selecionado.");
    }
  };

  const verArquivo = async (file) => {
    setDownloadLink(await globalizadorService.getDownloadLink(props.task.id, file.key));
    setOpenDialogArquivo(true);
    setSelectedArquivo(file);
  };

  return (
    <Fragment>
      <DialogArquivo
        open={openDialogArquivo}
        nomeArquivo={selectedArquivo?.label}
        downloadLink={downloadLink}
        handleClose={() => setOpenDialogArquivo(false)}
      />

      <Card variant="outlined">
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant={"outlined"}
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  const arquivo = event.target.files[0];
                  if (arquivo) {
                    setArquivo(arquivo);
                  }
                }
              }}
            />
            <Button variant="contained" color="primary" onClick={() => enviarArquivo()}>
              Enviar arquivo
            </Button>
          </div>
          <div style={{ textAlign: "center" }}>
            {props?.task?.uploadedFiles && props.task.uploadedFiles.length > 0 && (
              <Fragment>
                <Typography variant="h6" style={{ marginTop: "1rem" }}>
                  Arquivos
                </Typography>
                <Table size="small">
                  <TableBody>
                    {props.task.uploadedFiles.map((file, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle2">{file.reference}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">{file.label}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => verArquivo(file)}>
                              <VisibilityIcon />
                            </IconButton>
                            <CopyToClipboard
                              text={file.reference}
                              onCopy={() =>
                                successMessage.next("Texto copiado para a área de transferência.")
                              }
                            >
                              <IconButton>
                                <FileCopyOutlinedIcon />
                              </IconButton>
                            </CopyToClipboard>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Fragment>
            )}
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = ({ loginReducer, backofficeReducer }) => {
  const { idUsuario, idUnidade, nomeUsuario } = loginReducer;
  const { estacao, task } = backofficeReducer;
  return { idUsuario, idUnidade, nomeUsuario, estacao, task };
};

export default connect(mapStateToProps, { uploadArquivo })(Uploads);
