import React, { Fragment, useEffect } from "react";
import Tarefas from "./components/Tarefas/Tarefas";
import VideoAtendimento from "./components/VideoAtendimento/VideoAtendimento";
import { connect } from "react-redux";
import { getStatusEstacao } from "actions/BackofficeActions";
import { internalizaDemanda } from "../../../../actions/DespachanteActions";
import { websocketToken } from "service/websocket/WebSocketActions";
import { avaliacaoGuiche } from "actions/GuicheActions";
import styles from "./AtendimentoStyle";
import BotoesAcao from "./components/BotoesAcao/BotoesAcao";
import InfoCliente from "./components/InfoCliente/InfoCliente";
import InfoDemanda from "./components/InfoDemanda/InfoDemanda";
import InfoAtendente from "./components/InfoAtendente/InfoAtendente";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LoginGuicheEstacao from "./components/LoginGuicheEstacao";
import { iniciaDemandaEspecifica } from "actions/BackofficeActions";
import Cabecalho from "./components/Cabecalho";
import BarcodeReader from "react-barcode-reader";
import { errorMessage } from "helpers/observables";
import LiveChat from "./components/LiveChat/LiveChat";
import DemandasDespachante from "./components/DemandasDespachante";
import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const Atendimento = (props) => {
  const { t } = useTranslation();

  const { classes } = props;

  useEffect(() => {
    props.getStatusEstacao();
    if (props.idSenha && props.popupSenhas) {
      window.Notification.requestPermission().then((r) => {
        new window.Notification(t("Nova Senha"), {
          body: t("Uma senha foi alocada ao seu guichê"),
        });
      });
    }
  }, [props.idSenha]);

  const isVideoAtendimentoEnabled =
    (props.statusGuiche === 3 || props.statusGuiche === 4) &&
    props.isVideoAtendimento &&
    props.idUnidade;
  // const isOcupado = (props.idEstacao && props.idDemanda !== 0) || (props.idGuiche && props.idSenha !== 0);
  const isOcupado = props.idSenha;
  const isLiveChat = props.statusGuiche === 14;

  const handleScan = (digitosAvaliacao) => {
    if (props.idEstacao && !isOcupado) {
      if (props.despachante !== null) {
        if (props.despachante.length > 0) {
          const demanda = props.despachante.find(
            (demanda) => demanda?.taskBackOffice?.protocol === digitosAvaliacao
          );
          if (demanda) {
            props.internalizaDemanda(
              true,
              demanda.taskBackOffice.id,
              props.imprimirFinalizar
                ? localStorage.getItem("impressora")
                : null
            );
          } else {
            errorMessage.next(
              t("Protocolo não encontrado para o despachante.")
            );
          }
        } else {
          errorMessage.next(
            t("Não há demandas cadastradas para o despachante.")
          );
        }
      } else if (!props.permissoesBackoffice?.perm_atender_despachante) {
        props.iniciaDemandaEspecifica(digitosAvaliacao);
      }
    } else if (props.idGuiche && props.idSenha !== 0) {
      const lastDigit = digitosAvaliacao.substring(6, 7);
      if (
        lastDigit === localStorage.getItem("digit1") ||
        lastDigit === localStorage.getItem("digit2") ||
        lastDigit === localStorage.getItem("digit3") ||
        lastDigit === localStorage.getItem("digit4")
      ) {
        errorMessage.next(t("Avaliação inválida."));
      } else {
        if (!localStorage.getItem("digitNext")) {
          localStorage.setItem("digit1", lastDigit);
          localStorage.setItem("digitNext", "2");
        } else {
          if (localStorage.getItem("digitNext") === "1") {
            localStorage.setItem("digit1", lastDigit);
            localStorage.setItem("digitNext", "2");
          } else if (localStorage.getItem("digitNext") === "2") {
            localStorage.setItem("digit2", lastDigit);
            localStorage.setItem("digitNext", "3");
          } else if (localStorage.getItem("digitNext") === "3") {
            localStorage.setItem("digit3", lastDigit);
            localStorage.setItem("digitNext", "4");
          } else if (localStorage.getItem("digitNext") === "4") {
            localStorage.setItem("digit4", lastDigit);
            localStorage.setItem("digitNext", "1");
          }
        }

        if (
          digitosAvaliacao.length === 7 &&
          (props.statusGuiche === 4 || props.statusGuiche === 10)
        ) {
          props.avaliacaoGuiche(props.idGuiche, digitosAvaliacao);
        }
      }
    }
  };

  return (
    <Fragment>
      <BarcodeReader avgTimeByChar={100} minLength={7} onScan={handleScan} />
      {!props.idGuiche ? (
        <LoginGuicheEstacao />
      ) : (
        <Fragment>
          <Cabecalho />

          {isLiveChat ? (
            <LiveChat />
          ) : (
            <Fragment>
              <div className={classes.divStatusBotoes}>
                <PowerSettingsNewIcon
                  className={
                    props.idSenha || props.idDemanda
                      ? classes.iconEmAtendimento
                      : classes.iconOcioso
                  }
                />

                {isOcupado ? (
                  <Typography variant="h6" className={classes.titleStatus}>
                    {props.nomeStatusGuiche}
                  </Typography>
                ) : (
                  <Typography variant="h6" className={classes.titleStatus}>
                    {props.nomeStatusGuiche}
                  </Typography>
                )}

                <BotoesAcao />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {!props.task && isOcupado && isVideoAtendimentoEnabled && (
                  <div style={{ flex: 4 }}>
                    <VideoAtendimento idUnidade={props.idUnidadeSenha} />
                  </div>
                )}

                <div style={{ flex: 2, marginRight: "0.2rem" }}>
                  {isOcupado && (
                    <Fragment>
                      {!props.task && isVideoAtendimentoEnabled && (
                        <InfoDemanda />
                      )}
                      <InfoCliente />
                      {props.task && <Tarefas />}
                    </Fragment>
                  )}
                  {!isOcupado && props.despachante && <DemandasDespachante />}
                </div>

                {isOcupado && (props.task || !isVideoAtendimentoEnabled) && (
                  <InfoDemanda />
                )}

                {!isVideoAtendimentoEnabled && (
                  <InfoAtendente isVideoAtendimento={false} />
                )}

                {props.task && isOcupado && isVideoAtendimentoEnabled && (
                  <VideoAtendimento idUnidade={props.idUnidadeSenha} />
                )}
              </div>

              {isVideoAtendimentoEnabled && (
                <InfoAtendente isVideoAtendimento={true} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  guicheReducer,
  backofficeReducer,
  loginReducer,
}) => {
  const {
    statusGuiche,
    idGuiche,
    idSenha,
    isVideoAtendimento,
    nomeStatusGuiche,
    listaChats,
    idUnidadeSenha,
    popupSenhas,
  } = guicheReducer;
  const {
    task,
    idEstacao,
    idDemanda,
    nomeStatusEstacao,
    statusEstacao,
    despachante,
    imprimirFinalizar,
  } = backofficeReducer;
  const { idUnidade, idUsuario, permissoesBackoffice } = loginReducer;
  return {
    statusGuiche,
    idGuiche,
    idSenha,
    isVideoAtendimento,
    nomeStatusGuiche,
    listaChats,
    idUnidadeSenha,
    popupSenhas,
    task,
    idEstacao,
    idDemanda,
    nomeStatusEstacao,
    statusEstacao,
    despachante,
    imprimirFinalizar,
    idUnidade,
    idUsuario,
    permissoesBackoffice,
  };
};

export default connect(mapStateToProps, {
  getStatusEstacao,
  websocketToken,
  avaliacaoGuiche,
  iniciaDemandaEspecifica,
  internalizaDemanda,
})(withStyles(styles)(Atendimento));
