import React, { useEffect, useState } from "react";
import { Dialog, CircularProgress, DialogContent } from "@mui/material";
import { loadingStatus } from "helpers/observables";

const Loading = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadingStatus.subscribe((status) => {
      setOpen(status);
    });
    //return loadingStatus.unsubscribe;
  }, []);

  return (
    <Dialog open={open}>
      <DialogContent style={{ padding: 30 }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default Loading;
