import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureStore, { history } from "./configureStore";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import "./i18n";
export const store = configureStore();
const container = document.getElementById("root");
const root = createRoot(container);
const theme = createTheme({
  /*typography: {
      fontFamily: [
        '"Segoe UI"'
      ]
    },*/
  palette: {
    primary: {
      main: "#00598b",
    },
    secondary: {
      main: "#018fd7",
    },
  },
});
root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App history={history} />
      </ThemeProvider>
    </Provider>
  </StyledEngineProvider>
);

serviceWorker.unregister();

/*window.onbeforeunload = function () {
  return "Tem certeza que deseja fecha a página?";
};

window.onunload = function () {
  return "Tem certeza que deseja fecha a página?";
};*/
