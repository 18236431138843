import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  loginGuicheEstacao,
  logoffEstacao,
  logoffGuiche,
} from "actions/LoginActions";
import { suspenderUsuario } from "actions/GuicheActions";
import GlobalizadorService from "service/GlobalizadorService";
import { errorMessage } from "helpers/observables";
import DialogSelecao from "components/Utils/Dialogs/DialogSelecao";
import moment from "moment";
import dialogStyle from "components/Utils/Dialogs/DialogStyle";
import DialogConfirmation from "../../../../Utils/Dialogs/DialogConfirmation";
import { dynamicSort } from "../../../../../helpers/util";
import { useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";

const styles = {
  divCabecalho: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    borderBottom: "solid 1px var(--divider)",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
  },
  txtCabecalho: {
    color: "var(--theme-grey)",
    fontSize: "1rem",
  },
  spanBar: {
    color: "var(--theme-grey)",
    borderRight: "solid 1px var(--divider)",
    minHeight: "3rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
};

const Cabecalho = (props) => {
  const [openDialogSelecao, setOpenDialogSelecao] = useState(false);
  const [optionsDialogSelecao, setOptionsDialogSelecao] = useState(null);
  const [openDialogSenhas, setOpenDialogSenhas] = useState(false);
  const [listaSenhasFila, setListaSenhasFila] = useState([]);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [optionsDialogConfirmation, setOptionsDialogConfirmation] =
    useState(null);
  const { t } = useTranslation();

  const { classes } = props;
  const globalizadorService = new GlobalizadorService();
  useEffect(() => {
    if (props.idSenha && props.popupSenhas) {
      window.Notification.requestPermission().then((r) => {
        new window.Notification(t("Nova Senha"), {
          body: t("Uma senha foi alocada ao seu guichê"),
        });
      });
    }
  }, [props.idSenha]);
  const loginEstacao = async () => {
    try {
      const resp = await globalizadorService.getEstacoes(props.idUnidade);
      setOptionsDialogSelecao({
        lista: resp.listaEstacoesBo,
        title: t("Selecione a estação:"),
        id: "id",
        descricao: "nome",
        handleClose: () => setOpenDialogSelecao(false),
        handleSelect: (estacao) => {
          props.loginGuicheEstacao(estacao.idEstacao, null);
          setOpenDialogSelecao(false);
        },
      });
      setOpenDialogSelecao(true);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const getSecoes = (list) => {
    const secoes = new Map();
    list.forEach((item) => {
      if (item.secao && !secoes.get(item.secao.id)) {
        secoes.set(item.secao.id, { id: item.secao.id, nome: item.secao.nome });
      }
    });
    return secoes;
  };
  const loginGuicheDialog = (listaGuiches) => {
    setOptionsDialogSelecao({
      lista: listaGuiches.sort(dynamicSort("nome")),
      title: t("Selecione o guichê:"),
      id: "id",
      descricao: "nome",
      destaqueOption: 2,
      destaqueParam: "id_atendente_logado",
      destaqueValue: 0,
      destaqueColor: { color: "red" },
      handleClose: () => setOpenDialogSelecao(false),
      handleSelect: (guiche) => {
        setOpenDialogSelecao(false);
        if (
          guiche &&
          guiche.id_atendente_logado &&
          guiche.id_atendente_logado !== props.idUsuario
        ) {
          setOptionsDialogConfirmation({
            title: "Confirmação",
            description:
              t("O guichê está ocupado, deseja desconectar o usuário ") +
              guiche.nome +
              "?",
            handleClose: () => {
              setOpenDialogConfirmation(false);
            },
            handleConfirm: () => {
              setOpenDialogConfirmation(false);
              props.loginGuicheEstacao(null, guiche.id);
            },
          });
          setOpenDialogConfirmation(true);
        } else {
          props.loginGuicheEstacao(null, guiche.id);
        }
      },
    });
    setOpenDialogSelecao(true);
  };
  const loginGuiche = async () => {
    try {
      const resp = await globalizadorService.listaGuichesAtendente(
        props.idUnidade
      );
      const secoes = getSecoes(resp.listaGuiches);
      if (secoes && secoes.size > 1) {
        setOptionsDialogSelecao({
          lista: Array.from(secoes.values()),
          title: t("Selecione a seção do guichê:"),
          id: "id",
          descricao: "nome",
          handleClose: () => setOpenDialogSelecao(false),
          handleSelect: (secao) => {
            loginGuicheDialog(
              resp.listaGuiches.filter((guiche) => guiche.secao.id === secao.id)
            );
          },
        });
      }
      setOpenDialogSelecao(true);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const suspenderUsuario = async () => {
    if (props.despachante) {
      errorMessage.next(
        t(
          "É necessário finalizar a sessão do despachante antes de suspender o atendimento."
        )
      );
    } else {
      try {
        const resp = await globalizadorService.listaMotivosSuspensao(
          props.idAtendente
        );
        if (resp.listaMotivosSuspensao.length > 0) {
          setOptionsDialogSelecao({
            lista: resp.listaMotivosSuspensao,
            title: t("Selecione o motivo do suspensão:"),
            id: "id",
            descricao: "nome",
            handleClose: () => setOpenDialogSelecao(false),
            handleSelect: (motivo) => {
              setOpenDialogSelecao(false);
              props.suspenderUsuario(motivo.id);
            },
          });
          setOpenDialogSelecao(true);
        } else {
          errorMessage.next(t("Não há motivos de suspensão cadastrados."));
        }
      } catch (err) {
        errorMessage.next(err);
      }
    }
  };

  const verSenhasFila = async () => {
    try {
      const resp = await globalizadorService.listaSenhasFilaGuicheAtende(
        props.idGuiche,
        props.idAtendente
      );
      if (resp.lista_senhas.length > 0) {
        setListaSenhasFila(resp.lista_senhas);
        setOpenDialogSenhas(true);
      } else {
        errorMessage.next(t("Não há senhas nesta fila."));
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const logoffGuiche = () => {
    if (props.despachante) {
      errorMessage.next(
        t(
          "É necessário finalizar a sessão do despachante antes de sair do guichê."
        )
      );
    } else {
      props.logoffGuiche(props.idGuiche);
    }
  };

  const logoffEstacao = () => {
    if (props.despachante) {
      errorMessage.next(
        t(
          "É necessário finalizar a sessão do despachante antes de sair da estação."
        )
      );
    } else {
      props.logoffEstacao();
    }
  };

  return (
    <Fragment>
      <DialogSelecao open={openDialogSelecao} options={optionsDialogSelecao} />
      <DialogConfirmation
        open={openDialogConfirmation}
        options={optionsDialogConfirmation}
      />

      <Dialog
        open={openDialogSenhas}
        onClose={() => setOpenDialogSenhas(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle className={classes.dialogTitle}>
          <span className={classes.fontTitle}>Senhas na fila</span>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} style={{ maxHeight: 440 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Senha</TableCell>
                  <TableCell align="center">Prioridade</TableCell>
                  <TableCell align="center">Serviço</TableCell>
                  <TableCell align="center">Tempo Espera</TableCell>
                  <TableCell align="center">Hora Emissão</TableCell>
                  <TableCell align="center">Hora Agendada/Prevista</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaSenhasFila.map((senha) => (
                  <TableRow key={senha.id}>
                    <TableCell align="center">{senha.numero}</TableCell>
                    <TableCell align="center">{senha.nomePrioridade}</TableCell>
                    <TableCell align="center">{senha.nomeServico}</TableCell>
                    <TableCell align="center">
                      {moment
                        .utc(
                          moment
                            .duration(senha.tempoEspera, "seconds")
                            .asMilliseconds()
                        )
                        .format("HH:mm:ss")}
                    </TableCell>
                    <TableCell align="center">{senha.horaEmissao}</TableCell>
                    <TableCell align="center">{senha.horaAgendada}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <div style={styles.divCabecalho}>
        <Fragment>
          {!!props.idEstacao && (
            <Fragment>
              <Typography variant="h6" style={styles.txtCabecalho}>
                {props.nomeEstacao}
              </Typography>
              <Tooltip title="Logoff Estação">
                <IconButton onClick={() => logoffEstacao()}>
                  <PowerSettingsNewIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
          {!props.idEstacao && props.permissaoBackoffice && (
            <Button
              startIcon={<ExitToAppIcon />}
              style={styles.txtCabecalho}
              onClick={() => loginEstacao()}
            >
              Login Estação
            </Button>
          )}
          {props.permissaoBackoffice && <span style={styles.spanBar} />}
        </Fragment>

        <Fragment>
          {!!props.idGuiche && (
            <Fragment>
              <Typography variant="h6" style={styles.txtCabecalho}>
                {props.nomeGuiche}
              </Typography>
              <Tooltip title="Logoff Guichê">
                <IconButton onClick={() => logoffGuiche()}>
                  <PowerSettingsNewIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
          {!props.idGuiche && props.permissaoAtendente && (
            <Button
              startIcon={<ExitToAppIcon />}
              style={styles.txtCabecalho}
              onClick={() => loginGuiche()}
            >
              Login Guichê
            </Button>
          )}
          {props.permissaoAtendente && <span style={styles.spanBar} />}
        </Fragment>

        {props.statusGuiche !== 6 && props.statusGuiche !== 14 && (
          <Fragment>
            <Tooltip title={t("Suspender Atendimento")}>
              <IconButton onClick={() => suspenderUsuario()}>
                <PauseCircleOutlineIcon />
              </IconButton>
            </Tooltip>
            <span style={styles.spanBar} />
          </Fragment>
        )}

        {!!props.idGuiche && (
          <Fragment>
            <Typography variant="h6" style={styles.txtCabecalho}>
              {props.nomeSecao}
            </Typography>
            <span style={styles.spanBar} />
          </Fragment>
        )}

        {!!props.idGuiche && (
          <Fragment>
            <Typography variant="h6" style={styles.txtCabecalho}>
              <b>{props.senhasFila}</b> senhas na fila
            </Typography>
            {props.permissoesAtendente?.visualizarFila && (
              <Tooltip title={t("Ver senhas na fila")}>
                <IconButton onClick={() => verSenhasFila()}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
            <span style={styles.spanBar} />
          </Fragment>
        )}

        {!!props.idGuiche && props.statusGuiche === 14 && (
          <Fragment>
            <Typography variant="h6" style={styles.txtCabecalho}>
              {props.situacaoLiveChat ? (
                <Fragment>
                  {props.situacaoLiveChat.quantidade === 1 && (
                    <span>
                      <b>{props.situacaoLiveChat.quantidade}</b> chat na fila
                    </span>
                  )}
                  {props.situacaoLiveChat.quantidade > 1 && (
                    <span>
                      <b>{props.situacaoLiveChat.quantidade}</b> chats na fila
                    </span>
                  )}
                </Fragment>
              ) : (
                <span>
                  <b>0</b> chats na fila
                </span>
              )}
            </Typography>
            <span style={styles.spanBar} />
          </Fragment>
        )}

        {!!props.idEstacao && (
          <Fragment>
            {
              <Typography variant="h6" style={styles.txtCabecalho}>
                <b>{props.demandasFila}</b> demandas na fila
              </Typography>
            }
            {props.despachante && (
              <Fragment>
                <span style={styles.spanBar} />
                <Typography variant="h6" style={styles.txtCabecalho}>
                  <b>
                    {props.despachante.filter((it) => it.internalizada).length}
                  </b>{" "}
                  {t("demandas internalizadas")}
                </Typography>
              </Fragment>
            )}
          </Fragment>
        )}

        {props.saidaLiveChatSolicitada && (
          <Typography variant="h6" style={styles.txtCabecalho}>
            {t("Saída Solicitada")}
          </Typography>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({
  guicheReducer,
  backofficeReducer,
  loginReducer,
}) => {
  const {
    idGuiche,
    statusGuiche,
    nomeSecao,
    nomeGuiche,
    senhasFila,
    situacaoLiveChat,
    saidaLiveChatSolicitada,
  } = guicheReducer;
  const { idEstacao, demandasFila, nomeEstacao, despachante } =
    backofficeReducer;
  const {
    idUnidade,
    idUsuario,
    permissaoAtendente,
    permissaoBackoffice,
    permissoesAtendente,
  } = loginReducer;
  return {
    idGuiche,
    statusGuiche,
    nomeSecao,
    nomeGuiche,
    senhasFila,
    situacaoLiveChat,
    saidaLiveChatSolicitada,
    idEstacao,
    demandasFila,
    nomeEstacao,
    despachante,
    idUnidade,
    idUsuario,
    permissaoAtendente,
    permissaoBackoffice,
    permissoesAtendente,
  };
};

export default connect(mapStateToProps, {
  loginGuicheEstacao,
  logoffGuiche,
  logoffEstacao,
  suspenderUsuario,
})(withStyles(dialogStyle)(Cabecalho));
