import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStatusEstacao } from "actions/BackofficeActions";
import { internalizaDemanda } from "../../../../actions/DespachanteActions";
import { websocketToken } from "service/websocket/WebSocketActions";
import {
  alteraPrioridadeGuiche,
  avaliacaoGuiche,
  alteraCategoriaGuiche,
} from "actions/GuicheActions";
import styles from "./RecepcaoStyle";
import BotoesAcao from "./components/BotoesAcao/BotoesAcao";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LoginGuicheEstacao from "./components/LoginGuicheEstacao";
import { iniciaDemandaEspecifica } from "actions/BackofficeActions";
import Cabecalho from "./components/Cabecalho";
import { errorMessage } from "helpers/observables";
import {
  List,
  Typography,
  ListItemButton,
  ListItemText,
  Collapse,
  Grid,
  Chip,
  ListItem,
  IconButton,
  Card,
  CardContent,
  Paper,
  InputBase,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  CardHeader,
} from "@mui/material";
import { Delete, Done, AccessTime, Edit } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import GlobalizadorService from "service/GlobalizadorService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

const Recepcao = (props) => {
  const { t } = useTranslation();
  const globalizadorService = new GlobalizadorService();
  const [atividades, setAtividades] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [atividadesBk, setAtividadesBk] = useState([]);
  const [openSemClasse, setOpenSemClasse] = React.useState(true);
  const [atividadesSelecionadas, setAtividadesSelecionadas] = useState([]);
  const [tempoStatus, setTempoStatus] = useState(0);
  const isOcupado = props.idSenha;
  const { classes } = props;

  useEffect(() => {
    const tempoStatusInterval = setInterval(() => {
      setTempoStatus((tempoStatus) => tempoStatus + 1);
    }, 1000);
    return () => clearInterval(tempoStatusInterval);
  }, []);

  useEffect(() => {
    if (props.tempoStatusGuiche && props.tempoStatus) {
      setTempoStatus(Math.min(props.tempoStatusGuiche, props.tempoStatus));
    } else if (props.tempoStatusGuiche) {
      setTempoStatus(props.tempoStatusGuiche);
    } else if (props.tempoStatus) {
      setTempoStatus(props.tempoStatus);
    }
  }, [props.tempoStatus, props.tempoStatusGuiche]);

  const listaAtividades = async () => {
    try {
      const atividades =
        await globalizadorService.listaFiltroAtividadesGuiche();
      const prioridades = await globalizadorService.guicheListaPrioridades();
      if (atividades.length > 0) {
        setAtividades(atividades);
        setAtividadesBk(atividades);
      }
      if (prioridades.length > 0) {
        setPrioridades(prioridades);
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const listaPrioridades = async () => {
    try {
      const prioridades = await globalizadorService.guicheListaPrioridades();
      if (prioridades.length > 0) {
        setPrioridades(prioridades);
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const listaCategorias = async () => {
    try {
      const categorias = await globalizadorService.guicheListaCategorias();
      if (categorias.length > 0) {
        setCategorias(categorias);
      }
    } catch (err) {
      errorMessage.next(err);
    }
  };
  const getClasses = (list) => {
    const classes = new Map();
    list.forEach((item) => {
      if (item.classe && !classes.get(item.classe)) {
        classes.set(item.classe.id, {
          id: item.classe.id,
          nome: item.classe.nome,
          group: true,
        });
      } else {
        classes.set(item.id, { id: item.id, nome: item.nome, group: false });
      }
    });
    return classes;
  };

  const handleClickSemClasse = () => {
    setOpenSemClasse(!openSemClasse);
  };

  const ItemGrouped = ({ item }) => {
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <ListItemButton
          style={{ marginBottom: 15, borderBottom: "1px solid #bebebe" }}
          onClick={handleClick}
        >
          <ListItemText
            primaryTypographyProps={{ fontSize: "10px" }}
            primary={item.nome}
          />
          {/* {open ? <ExpandLess /> : <ExpandMore />} */}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {atividades
              .filter((atividade) => atividade?.classe?.id === item?.id)
              .map((item, index) => {
                return (
                  <Chip
                    component="button"
                    style={{ minWidth: 150, marginBottom: 5 }}
                    label={item?.nome}
                    color="default"
                    onClick={() => {
                      setAtividades(atividades.filter((a) => a.id !== item.id));
                      setAtividadesSelecionadas([
                        ...atividadesSelecionadas,
                        item,
                      ]);
                    }}
                    key={index}
                    size="small"
                    variant="outlined"
                    deleteIcon={<Done />}
                  />
                );
              })}
          </List>
        </Collapse>
      </>
    );
  };

  useEffect(() => {
    console.log(props);
    props.getStatusEstacao();
    listaAtividades();
    listaPrioridades();
    listaCategorias();
    if (props.idSenha && props.popupSenhas) {
      window.Notification.requestPermission().then((r) => {
        new window.Notification(t("Nova Senha"), {
          body: t("Uma senha foi alocada ao seu guichê"),
        });
      });
    }

    return () => {
      listaAtividades();
      listaPrioridades();
      listaCategorias();
    };
  }, [props.idSenha]);

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const getItems = (count) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`,
      content: `item ${k}`,
    }));

  const [itemsDragDrop, setItemsDragDrop] = useState(getItems(10));

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    paddingLeft: "15px",
    paddingRight: "15px",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",
    border: "1px solid #d3d3d3",
    borderRadius: "15px",
    marginBottom: "5px",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    padding: grid,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setAtividadesSelecionadas(
      reorder(
        atividadesSelecionadas,
        result.source.index,
        result.destination.index
      )
    );
  };

  const handleChangePrioridade = async ({ target: { value } }) => {
    console.log(props);
    try {
      await props.alteraPrioridadeGuiche(value);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const handleChangeCategoria = async ({ target: { value } }) => {
    console.log(props);
    try {
      await props.alteraCategoriaGuiche(value);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  return (
    <Fragment>
      {!props.idGuiche ? (
        <LoginGuicheEstacao />
      ) : (
        <Fragment>
          <Cabecalho />
          <div className={classes.divStatusBotoes}>
            <PowerSettingsNewIcon
              className={
                props.idSenha || props.idDemanda
                  ? classes.iconEmAtendimento
                  : classes.iconOcioso
              }
            />
            {isOcupado ? (
              <Typography variant="h6" className={classes.titleStatus}>
                {props.nomeStatusGuiche}
              </Typography>
            ) : (
              <Typography variant="h6" className={classes.titleStatus}>
                {props.nomeStatusGuiche}
              </Typography>
            )}
            <BotoesAcao />
          </div>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Card elevation={0} style={{ border: "1px solid #bebebe" }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Senha número:
                  </Typography>
                  <div
                    style={{
                      borderRadius: 15,
                      padding: 5,
                      width: "50%",
                      textAlign: "center",
                      backgroundColor: "#018fd7",
                    }}
                  >
                    <Typography variant="h3" component="div" color={"white"}>
                      {props.numeroSenha}
                    </Typography>
                  </div>
                  <Typography
                    sx={{ fontSize: 14, marginTop: 2 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Nome usuário:
                  </Typography>
                  <Typography variant="body2" component={"span"}>
                    <Paper
                      elevation={0}
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Insira um nome."
                        inputProps={{ "aria-label": "search google maps" }}
                        value={props.nomeCidadao}
                      />
                      <IconButton type="button" sx={{ p: "10px" }}>
                        <Edit sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </Paper>
                  </Typography>
                  <div>
                    <Typography
                      sx={{ fontSize: 14, marginTop: 2 }}
                      color="text.secondary"
                      gutterBottom
                      display={"inline"}
                    >
                      Serviço:
                    </Typography>
                    <Typography
                      fontWeight={"600"}
                      sx={{ fontSize: 14, marginTop: 2, marginLeft: 1 }}
                      color="text.secondary"
                      gutterBottom
                      display={"inline"}
                    >
                      {props.nomeServico
                        ? props.nomeServico
                        : "Nenhum serviço selecionado"}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{ fontSize: 14, marginTop: 2 }}
                      color="text.secondary"
                      gutterBottom
                      display={"inline"}
                    >
                      Emissão:
                    </Typography>
                    <Typography
                      fontWeight={"600"}
                      sx={{ fontSize: 14, marginTop: 2, marginLeft: 1 }}
                      color="text.secondary"
                      gutterBottom
                      display={"inline"}
                    >
                      {props.dataEmissao + " - " + props.horaEmissao}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{ fontSize: 14, marginTop: 2 }}
                      color="text.secondary"
                      gutterBottom
                      display={"inline"}
                    >
                      Duração:
                    </Typography>
                    <Typography
                      fontWeight={"600"}
                      sx={{ fontSize: 14, marginTop: 2, marginLeft: 1 }}
                      color="text.secondary"
                      gutterBottom
                      display={"inline"}
                    >
                      {moment
                        .utc(
                          moment
                            .duration(tempoStatus, "seconds")
                            .asMilliseconds()
                        )
                        .format("HH:mm:ss")}
                    </Typography>
                  </div>

                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Prioridade:
                  </Typography>
                  <FormControl variant="standard">
                    <Select
                      displayEmpty
                      value={props.idPrioridade ? props.idPrioridade : ""}
                      onChange={handleChangePrioridade}
                    >
                      <MenuItem value="">
                        <FormHelperText>
                          Selecione uma prioridade
                        </FormHelperText>
                      </MenuItem>
                      {prioridades.map((res) => (
                        <MenuItem
                          key={res.id}
                          sx={{ fontSize: 14 }}
                          value={res.id}
                        >
                          {res.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    sx={{ fontSize: 14, marginTop: 2 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Categoria:
                  </Typography>
                  <FormControl variant="standard">
                    <Select
                      value={props.idCategoria ? props.idCategoria : ""}
                      onChange={handleChangeCategoria}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <FormHelperText>Selecione uma Categoria</FormHelperText>
                      </MenuItem>
                      {categorias.map((res) => (
                        <MenuItem
                          key={res.id}
                          sx={{ fontSize: 14 }}
                          value={res.id}
                        >
                          {res.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card elevation={0} style={{ border: "1px solid #bebebe" }}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "reverse",
                      background: "white",
                      overflowX: "auto",
                      width: "auto",
                    }}
                  >
                    {Array.from(getClasses(atividadesBk).values())
                      ?.filter((i) => i.group == true)
                      .map((item, index) => {
                        return (
                          <List
                            key={index}
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                            }}
                            aria-labelledby="nested-list-subheader"
                          >
                            <ItemGrouped key={index} item={item} />
                          </List>
                        );
                      })}
                    <List
                      sx={{ width: "100%", bgcolor: "background.paper" }}
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItemButton
                        style={{
                          marginBottom: 15,
                          borderBottom: "1px solid #bebebe",
                        }}
                        onClick={handleClickSemClasse}
                      >
                        <ListItemText
                          primary=" SEM CLASSE"
                          primaryTypographyProps={{ fontSize: "10px" }}
                        />
                        {/* {openSemClasse ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                      <Collapse in={openSemClasse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {Array.from(getClasses(atividades).values())
                            ?.filter((i) => i.group == false)
                            .map((item, index) => {
                              return (
                                <Chip
                                  component="button"
                                  style={{
                                    minWidth: 150,
                                    marginBottom: 5,
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                  label={item?.nome}
                                  color="default"
                                  onClick={() => {
                                    setAtividades(
                                      atividades.filter((a) => a.id !== item.id)
                                    );
                                    setAtividadesSelecionadas([
                                      ...atividadesSelecionadas,
                                      item,
                                    ]);
                                  }}
                                  key={index}
                                  size="small"
                                  variant="outlined"
                                />
                              );
                            })}
                        </List>
                      </Collapse>
                    </List>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                sx={{ minWidth: 275 }}
                elevation={0}
                style={{ border: "1px solid #bebebe" }}
              >
                <CardHeader
                  title="Atividades selecionadas"
                  titleTypographyProps={{ fontSize: "16px", fontWeight: "400" }}
                />
                <CardContent>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <List component="div" disablePadding dense={true}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {atividadesSelecionadas.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={index}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    disablePadding
                                    secondaryAction={
                                      <>
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          onClick={() => {
                                            setAtividadesSelecionadas(
                                              atividadesSelecionadas.filter(
                                                (a) => a.id !== item.id
                                              )
                                            );
                                            setAtividades([
                                              ...atividades,
                                              item,
                                            ]);
                                          }}
                                        >
                                          <AccessTime
                                            sx={{ fontSize: "20px" }}
                                            color="default"
                                          />
                                        </IconButton>
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          onClick={() => {
                                            setAtividadesSelecionadas(
                                              atividadesSelecionadas.filter(
                                                (a) => a.id !== item.id
                                              )
                                            );
                                            setAtividades([
                                              ...atividades,
                                              item,
                                            ]);
                                          }}
                                        >
                                          <Delete
                                            color="default"
                                            sx={{ fontSize: "20px" }}
                                          />
                                        </IconButton>
                                      </>
                                    }
                                  >
                                    <ListItemText primary={item?.nome} />
                                  </ListItem>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </List>
                  </DragDropContext>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  guicheReducer,
  backofficeReducer,
  loginReducer,
}) => {
  const {
    statusGuiche,
    idGuiche,
    idSenha,
    nomeStatusGuiche,
    idUnidadeSenha,
    idCategoria,
    idPrioridade,
    popupSenhas,
    listaAtividades,
    idAtendente,
    listaGuichesApoiando,
    idCidadao,
    nomeCidadao,
    qteCidadaos,
    nomeCategoria,
    nomePrioridade,
    corPrioridade,
    nomeServico,
    nomeAtividade,
    numeroSenha,
    horaEmissao,
    dataEmissao,
    tempoStatusGuiche,
  } = guicheReducer;
  const {
    task,
    idEstacao,
    idDemanda,
    nomeStatusEstacao,
    statusEstacao,
    despachante,
    imprimirFinalizar,
  } = backofficeReducer;
  const { idUnidade, idUsuario, permissoesBackoffice } = loginReducer;
  return {
    statusGuiche,
    idGuiche,
    idSenha,
    nomeStatusGuiche,
    idUnidadeSenha,
    popupSenhas,
    task,
    idEstacao,
    idDemanda,
    nomeStatusEstacao,
    statusEstacao,
    despachante,
    imprimirFinalizar,
    idUnidade,
    idUsuario,
    permissoesBackoffice,
    listaAtividades,
    idAtendente,
    listaGuichesApoiando,
    idCidadao,
    nomeCidadao,
    qteCidadaos,
    nomeCategoria,
    nomePrioridade,
    corPrioridade,
    nomeServico,
    nomeAtividade,
    numeroSenha,
    horaEmissao,
    dataEmissao,
    tempoStatusGuiche,
    idCategoria,
    idPrioridade,
  };
};

export default connect(mapStateToProps, {
  getStatusEstacao,
  websocketToken,
  avaliacaoGuiche,
  iniciaDemandaEspecifica,
  internalizaDemanda,
  alteraPrioridadeGuiche,
  alteraCategoriaGuiche,
})(withStyles(styles)(Recepcao));
