import React, { Fragment, useEffect, useState } from "react";
import { successMessage, errorMessage } from "../../helpers/observables";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = () => {
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setMsgError] = useState("");

  useEffect(() => {
    successMessage.subscribe((msg) => {
      if (msg) {
        setOpenSuccessMessage(true);
        setMsgSuccess(msg);
      }
    });
    errorMessage.subscribe((msg) => {
      if (msg) {
        setOpenErrorMessage(true);
        if (typeof msg === "object" && "message" in msg) {
          setMsgError(msg.message);
        } else {
          setMsgError(msg);
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <Snackbar
        open={openSuccessMessage}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => setOpenSuccessMessage(false)}
        >
          {msgSuccess}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openErrorMessage}
        autoHideDuration={5000}
        onClose={() => setOpenErrorMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setOpenErrorMessage(false)}>
          {msgError}
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
};

export default Alert;
