import React, { Fragment } from "react";
import { connect } from "react-redux";
import styles from "../InfoClienteStyle";
import { Divider, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import MessageIcon from "@mui/icons-material/Message";

const ComentariosGuiche = (props) => {
  const { classes } = props;
  return (
    <Fragment>
      {props.comentarios && props.comentarios.length > 0 && (
        <Paper className={classes.paper} style={{ marginTop: "0.2rem" }}>
          <div className={classes.divServico}>
            <MessageIcon className={classes.icon} />
            <Typography variant="h6" className={classes.labelServico}>
              Comentários
            </Typography>
          </div>
          <Divider />
          <ul>
            {props.comentarios.map((comentario) => {
              return (
                <li key={comentario.id_comentario}>
                  <div className={classes.divAtividade}>
                    <Typography variant="h6" className={classes.labelDemandaAtual}>
                      {comentario.texto_comentario} - {comentario.nome_atendente} -{" "}
                      {comentario.hora_comentario}
                    </Typography>
                  </div>
                </li>
              );
            })}
          </ul>
        </Paper>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ guicheReducer }) => {
  const { comentarios } = guicheReducer;
  return {
    comentarios,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ComentariosGuiche));
