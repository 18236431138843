import React, { useEffect } from "react";
import Loading from "./components/Utils/Loading";
import Alert from "./components/Utils/Alert";
import Login from "./components/Login/Login";
import { ConnectedRouter } from "connected-react-router";
import { Redirect, Route, Switch } from "react-router";
import Home from "./components/Home/Home";

import { AuthDespachante } from "./components/Utils/AuthDespachante";
import SseService from "service/sse/SseService";

function App(props) {
  const routes = [
    { exact: true, path: "/", component: Login },
    { exact: true, path: "/despachante", component: AuthDespachante },
  ];
  const privateRoutes = [{ exact: true, path: "/home", component: Home }];
  const { history } = props;

  SseService();

  return (
    <>
      <Loading />
      <Alert />
      <ConnectedRouter history={history}>
        <Switch>
          {routes.map((route, i) => (
            <Route
              key={i}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          {privateRoutes.map((route, i) => (
            <PrivateRoute
              key={i}
              path={route.path}
              component={route.component}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </ConnectedRouter>
    </>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const authed = sessionStorage.getItem("bo.token");
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default App;
