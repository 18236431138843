import { errorMessage, successMessage } from "./observables";
// import videoAtendimento from "@visual/video-atendimento-js";

export function removeEncoding(string) {
  const parser = new DOMParser();
  const convertedValue = parser.parseFromString(string, "text/html").body.innerText;
  return convertedValue;
}

export function removeEncodingJson(json) {
  return JSON.parse(removeEncoding(JSON.stringify(json)));
}

export function validarEnvioArquivo(event) {
  if (event.target && event.target.files) {
    const file = event.target.files[0];
    const split = file.name.split(".");
    if (split) {
      let extension = split.pop();
      console.log(extension);
      if (extension) {
        extension = extension.toLowerCase();
      }
      if (
        extension !== "pdf" &&
        extension !== "jpg" &&
        extension !== "jpeg" &&
        extension !== "png" &&
        extension !== "bmp" &&
        extension !== "txt" &&
        extension !== "doc" &&
        extension !== "docx" &&
        extension !== "xls" &&
        extension !== "xlsx" &&
        extension !== "csv"
      ) {
        errorMessage.next("A extensão do arquivo não é permitida.");
      } else if (file.size <= 15000000) {
        return true;
      } else {
        errorMessage.next("O tamanho máximo permitido do arquivo é 15 MB.");
      }
    } else {
      errorMessage.next("A extensão do arquivo não é permitida.");
    }
  }
  return false;
}

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
