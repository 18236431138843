import {
  LISTA_TERMINAIS_FAILED,
  LISTA_TERMINAIS_SUCCESS,
} from "../constants/types";

const INITIAL_STATE = {
  terminais: [],
};

const terminaisActionSuccess = (action) => {
  const terminais = action.payload;
  return {
    terminais: terminais,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTA_TERMINAIS_SUCCESS:
      return {
        ...state,
        ...terminaisActionSuccess(action),
      };
    case LISTA_TERMINAIS_FAILED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
