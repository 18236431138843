import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./InfoDemandaStyle";
import { Typography, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";

const InfoDemanda = (props) => {
  const { classes } = props;
  const [tempoStatus, setTempoStatus] = useState(0);

  useEffect(() => {
    const tempoStatusInterval = setInterval(() => {
      setTempoStatus((tempoStatus) => tempoStatus + 1);
    }, 1000);
    return () => clearInterval(tempoStatusInterval);
  }, []);

  const criacao = props.task?.creation;
  useEffect(() => {
    if (props.tempoStatusGuiche && props.tempoStatus) {
      setTempoStatus(Math.min(props.tempoStatusGuiche, props.tempoStatus));
    } else if (props.tempoStatusGuiche) {
      setTempoStatus(props.tempoStatusGuiche);
    } else if (props.tempoStatus) {
      setTempoStatus(props.tempoStatus);
    }
  }, [props.tempoStatus, props.tempoStatusGuiche]);

  return (
    <div className={classes.divPrincipal}>
      {props.numeroSenha && (
        <Paper className={classes.paperSenha}>
          <Typography variant="h4" className={classes.labelSenha}>
            {props.numeroSenha}
          </Typography>
        </Paper>
      )}
      {props.protocoloDemandaAtual && (
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.labelInfo}>
            {props.protocoloDemandaAtual}
          </Typography>
          <Typography variant="body1" className={classes.labelInfoTitle}>
            Protocolo
          </Typography>
        </Paper>
      )}
      {props.horarioDemandaAtual && (
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.labelInfo}>
            {props.horarioDemandaAtual}
          </Typography>
          <Typography variant="body1" className={classes.labelInfoTitle}>
            Solicitação
          </Typography>
        </Paper>
      )}
      {props.horaEmissao && (
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.labelInfo}>
            {props.dataEmissao}
          </Typography>
          <Typography variant="subtitle1" className={classes.labelInfo}>
            {props.horaEmissao}
          </Typography>
          <Typography variant="body1" className={classes.labelInfoTitle}>
            Emissão
          </Typography>
        </Paper>
      )}
      {!!tempoStatus && (
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.labelInfo}>
            {moment
              .utc(moment.duration(tempoStatus, "seconds").asMilliseconds())
              .format("HH:mm:ss")}
          </Typography>
          <Typography variant="body1" className={classes.labelInfoTitle}>
            Duração
          </Typography>
        </Paper>
      )}
      {!!criacao && (
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.labelInfo}>
            {Math.max(
              0,
              moment
                .duration(
                  new moment().diff(moment.utc(criacao), "milliseconds")
                )
                .days()
            )}
            d
            {Math.max(
              0,
              moment
                .duration(
                  new moment().diff(moment.utc(criacao), "milliseconds")
                )
                .hours()
            )}
            h
            {Math.max(
              0,
              moment
                .duration(
                  new moment().diff(moment.utc(criacao), "milliseconds")
                )
                .minutes()
            )}
            m
          </Typography>
          <Typography variant="body1" className={classes.labelInfoTitle}>
            Espera
          </Typography>
        </Paper>
      )}
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer, guicheReducer }) => {
  const { tempoStatus, protocoloDemandaAtual, horarioDemandaAtual, task } =
    backofficeReducer;
  const { numeroSenha, horaEmissao, dataEmissao, tempoStatusGuiche } =
    guicheReducer;
  return {
    tempoStatus,
    protocoloDemandaAtual,
    horarioDemandaAtual,
    task,
    numeroSenha,
    horaEmissao,
    dataEmissao,
    tempoStatusGuiche,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(InfoDemanda));
