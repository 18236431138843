import { connect } from "react-redux";
import styles from "../AtendimentoStyle";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Fragment } from "react";

const DemandasDespachante = (props) => {
  return (
    <Fragment>
      {props.despachante && props.despachante.filter((it) => it.internalizada).length > 0 && (
        <TableContainer style={{ maxHeight: 440 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Protocolo</TableCell>
                <TableCell align="center">Serviço</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.despachante
                .filter((it) => it.internalizada)
                .map((it) => (
                  <TableRow key={it.protocol}>
                    <TableCell align="center">{it.taskBackOffice.protocol}</TableCell>
                    <TableCell align="center">{it.taskBackOffice.serviceName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { despachante } = backofficeReducer;
  return {
    despachante,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(DemandasDespachante));
