const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingBottom: theme.spacing(4),
  },
  divStatusBotoes: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  iconEmAtendimento: {
    color: "white",
    background: "green",
    padding: "0.5rem",
    fontSize: "3rem",
    marginRight: "0.5rem",
  },
  iconOcioso: {
    color: "white",
    background: "#a21b05",
    padding: "0.5rem",
    fontSize: "3rem",
    marginRight: "0.5rem",
  },
  titleStatus: {
    flexGrow: 1,
  },
});

export default useStyles;
