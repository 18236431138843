import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { inserirComentario } from "actions/BackofficeActions";
import { errorMessage } from "helpers/observables";
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const Comentarios = (props) => {
  const [comentario, setComentario] = useState("");

  const enviarComentario = () => {
    if (comentario.trim() !== "") {
      props.inserirComentario(comentario);
      setComentario("");
    } else {
      errorMessage.next("Comentário não pode ser vazio.");
    }
  };

  return (
    <Card variant="outlined">
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          value={comentario}
          multiline
          rows={4}
          variant={"outlined"}
          placeholder="Digite aqui o comentário..."
          style={{ marginBottom: "1rem" }}
          onChange={(event) => setComentario(event.target.value)}
        />
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" color="primary" onClick={() => enviarComentario()}>
            Enviar comentário
          </Button>
          {props.listaComentariosDemanda && props.listaComentariosDemanda.length > 0 && (
            <Fragment>
              <Typography variant="h6" style={{ marginTop: "1rem" }}>
                Comentários
              </Typography>
              <Table size="small" style={{ marginTop: "1rem" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Texto</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell>Data/Hora</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.listaComentariosDemanda?.map((comentario, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{comentario.texto}</TableCell>
                        <TableCell>{comentario.nomeUsuario}</TableCell>
                        <TableCell>{comentario.horario}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Fragment>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { listaComentariosDemanda } = backofficeReducer;
  return { listaComentariosDemanda };
};

export default connect(mapStateToProps, { inserirComentario })(Comentarios);
