const useStyles = (theme) => ({
  dialogTitle: {
    background: "var(--theme-primary)",
    color: "white",
  },
  fontTitle: {
    fontWeight: 400,
  },
});

export default useStyles;
