export default {
  body: {
    noMatch: "Não foram encontrados resultados",
    toolTip: "Ordenar",
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: "Próxima",
    previous: "Anterior",
    rowsPerPage: "Resultados por página:",
    displayRows: "de",
  },
  toolbar: {
    search: "Pesquisar",
    downloadCsv: "Download CSV",
    print: "Imprimir",
    viewColumns: "Ver colunas",
    filterTable: "Filtrar",
  },
  filter: {
    all: "Todos",
    title: "Filtros",
    reset: "Reiniciar",
  },
  viewColumns: {
    title: "Mostrar colunas",
    titleAria: "Mostrar/esconder colunas",
  },
  selectedRows: {
    text: "linha(s) selecionada(s)",
    delete: "Excluir",
    deleteAria: "Excluir linhas selecionadas",
  },
};
