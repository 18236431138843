import { DADOS_SENHAS_FAILED, DADOS_SENHAS_SUCCESS } from "../constants/types";

const INITIAL_STATE = {
  senha: null,
};

const dadosSenhaActionSuccess = (action) => {
  const senha = action.payload;
  return {
    senha: senha,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DADOS_SENHAS_SUCCESS:
      return {
        ...state,
        ...dadosSenhaActionSuccess(action),
      };
    case DADOS_SENHAS_FAILED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
