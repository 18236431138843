import React, { useEffect, useState } from "react";
import MenuSupervisor from "./components/MenuSupervisor";
import SenhasFila from "./components/SenhasFila";
import { matchPath, Route, Switch } from "react-router";
import { connect } from "react-redux";
import styles from "./SupervisorStyle";
import TitleBar from "./components/TitleBar";
import { useHistory } from "react-router";
import TransferenciaLiveChat from "./components/TransferenciaLiveChat";
import PanoramaAtendimento from "./components/PanoramaAtendimento/PanoramaAtendimento";
import Categoria from "./components/Categoria";
import Fila from "./components/Fila";
import Avaliacao from "./components/Avaliacao";
import EditarSenhas from "./components/EditarSenhas";
import EditarAvaliacao from "./components/EditarAvaliacao";
import Cancelamento from "./components/Cancelamento";
import Alarmes from "./components/Alarmes";
import CodigoAtendente from "./components/CodigoAtendente";
import PadraoAtendimento from "./components/PadraoAtendimento";
import AtivarAgendamento from "./components/AtivarAgendamento";
import DescongelarSenhas from "./components/DescongelarSenhas";
import AtivarTransferencia from "./components/AtivarTransferencia";
import ReativarSenha from "./components/ReativarSenha";
import DesligarServidor from "./components/DesligarServidor";
import EliminarSenhas from "./components/EliminarSenhas";
import PresetContadores from "./components/PresetContadores";
import LoginManual from "./components/LoginManual ";
import ListaSenhas from "./components/ListaSenhas";
import AgendaDia from "./components/AgendaDia";
import Senhas from "./components/Senhas";
import Geral from "./components/Geral";
import Previsoes from "./components/Previsoes";
import EficienciaUnidade from "./components/EficienciaUnidade";
import EficienciaAtendentes from "./components/EficienciaAtendentes";
import EficienciaAtividades from "./components/EficienciaAtividades";
import Auditoria from "./components/Auditoria";
import DesativarEmissao from "./components/DesativarEmissao";
import EmissaoSenha from "./components/EmissaoSenha/EmissaoSenha";
import EmissaoSenhaEdicao from "./components/EmissaoSenha/EmissaoSenhaEdicao";
import SenhaSecao from "./components/SenhaSecao";
import GuicheSecao from "./components/GuicheSecao";
import StatusEmissores from "./components/StatusEmissores";
import Guiche from "./components/Guiche";
import Atendente from "./components/Atendente";
import Atividade from "./components/Atividade";
import DemandaAtividade from "./components/DemandaAtividade";
import Servico from "./components/Servico";
import Suspensao from "./components/Suspensao";
import Prioridade from "./components/Prioridade";
import { getStatusEstacao } from "actions/BackofficeActions";
import SubMenuSupervisao from "components/Utils/SubMenuSupervisao";
import { withStyles } from "@mui/styles";

const Supervisor = (props) => {
  const history = useHistory();

  useEffect(() => {
    props.getStatusEstacao();
    history.listen(() => {
      setSelectedRoute(currentRoute());
    });
  }, []);

  const routes = [
    {
      path: "/home/supervisor",
      title: "Supervisor",
      component: MenuSupervisor,
    },
    {
      path: "/home/supervisor/panoramaAtendimento",
      title: "Panorama Atendimento",
      component: PanoramaAtendimento,
    },
    {
      path: "/home/supervisor/transferenciaLiveChat",
      title: "Transferência de Live Chat",
      component: TransferenciaLiveChat,
    },
    {
      path: "/home/supervisor/senhasFila",
      title: "Senhas na Fila",
      component: SenhasFila,
    },
    {
      path: "/home/supervisor/senha",
      title: "Emitir Senha",
      component: EmissaoSenha,
    },
    {
      path: "/home/supervisor/senhaEdicao/:id",
      title: "Edição de senha",
      component: EmissaoSenhaEdicao,
    },
    {
      path: "/home/supervisor/panoramaSenhaSecao",
      title: "Senha seção",
      component: SenhaSecao,
    },
    {
      path: "/home/supervisor/panoramaGuicheSecao",
      title: "Guiche seção",
      component: GuicheSecao,
    },
    {
      path: "/home/supervisor/statusEmissores",
      title: "Status emissores",
      component: StatusEmissores,
    },
    {
      path: "/home/supervisor/panoramaGuiche",
      title: "Guiche",
      component: Guiche,
    },
    {
      path: "/home/supervisor/panoramaAtendente",
      title: "Atendente",
      component: Atendente,
    },
    {
      path: "/home/supervisor/panoramaAtividade",
      title: "Atividade",
      component: Atividade,
    },
    {
      path: "/home/supervisor/demandaAtividade",
      title: "Demanda Atividade",
      component: DemandaAtividade,
    },
    {
      path: "/home/supervisor/panoramaServico",
      title: "Serviços",
      component: Servico,
    },
    {
      path: "/home/supervisor/panoramaSuspensao",
      title: "Suspensão",
      component: Suspensao,
    },
    {
      path: "/home/supervisor/panoramaPrioridade",
      title: "Prioridades",
      component: Prioridade,
    },
    {
      path: "/home/supervisor/panoramaCategoria",
      title: "Categorias",
      component: Categoria,
    },
    {
      path: "/home/supervisor/panoramaFila",
      title: "Filas",
      component: Fila,
    },
    {
      path: "/home/supervisor/panoramaAvaliacao",
      title: "Avaliações",
      component: Avaliacao,
    },
    {
      path: "/home/supervisor/editarSenhas",
      title: "Editar senhas",
      component: EditarSenhas,
    },
    {
      path: "/home/supervisor/editarAvaliacao",
      title: "Editar avaliação",
      component: EditarAvaliacao,
    },
    {
      path: "/home/supervisor/cancelamento",
      title: "Cancelamentos",
      component: Cancelamento,
    },
    {
      path: "/home/supervisor/alarmes",
      title: "Alarmes",
      component: Alarmes,
    },
    {
      path: "/home/supervisor/codigoAtendente",
      title: "Código atendentes",
      component: CodigoAtendente,
    },
    {
      path: "/home/supervisor/padraoAtendimento",
      title: "Padrão atendimento",
      component: PadraoAtendimento,
    },
    {
      path: "/home/supervisor/ativarAgendamento",
      title: "Ativar agendamento",
      component: AtivarAgendamento,
    },
    {
      path: "/home/supervisor/descongelarSenhas",
      title: "Descongelar senhas",
      component: DescongelarSenhas,
    },
    {
      path: "/home/supervisor/ativarTransferencia",
      title: "Ativar transferência",
      component: AtivarTransferencia,
    },
    {
      path: "/home/supervisor/reativarSenha",
      title: "Reativar senha",
      component: ReativarSenha,
    },
    {
      path: "/home/supervisor/desligarServidor",
      title: "Desligar servidor",
      component: DesligarServidor,
    },
    {
      path: "/home/supervisor/eliminarSenhas",
      title: "Eliminar senhas",
      component: EliminarSenhas,
    },
    {
      path: "/home/supervisor/presetContadores",
      title: "Preset contadores",
      component: PresetContadores,
    },
    {
      path: "/home/supervisor/senhasFila",
      title: "Senhas fila",
      component: Fila,
    },
    {
      path: "/home/supervisor/loginManual",
      title: "Login manual",
      component: LoginManual,
    },
    {
      path: "/home/supervisor/listaSenhas",
      title: "Lista senhas",
      component: ListaSenhas,
    },
    {
      path: "/home/supervisor/agendaDia",
      title: "Agenda dia",
      component: AgendaDia,
    },
    {
      path: "/home/supervisor/panoramaSenhas",
      title: "Panorâma senhas",
      component: Senhas,
    },
    {
      path: "/home/supervisor/panoramaGeral",
      title: "Panorâma geral",
      component: Geral,
    },
    {
      path: "/home/supervisor/panoramaPrevisoes",
      title: "Panorama previsões",
      component: Previsoes,
    },
    {
      path: "/home/supervisor/eficienciaUnidade",
      title: "Eficiência unidade",
      component: EficienciaUnidade,
    },
    {
      path: "/home/supervisor/eficienciaAtendentes",
      title: "Eficiência atendentes",
      component: EficienciaAtendentes,
    },
    {
      path: "/home/supervisor/eficienciaAtividades",
      title: "Eficiência atividades",
      component: EficienciaAtividades,
    },
    {
      path: "/home/supervisor/auditoria",
      title: "Auditoria",
      component: Auditoria,
    },
    {
      path: "/home/supervisor/desativarEmissao",
      title: "Desativar emissão",
      component: DesativarEmissao,
    },
  ];

  const currentRoute = () =>
    routes.find((route) =>
      matchPath(history.location.pathname, {
        path: route.path,
        exact: true,
        strict: true,
      })
    );

  const [selectedRoute, setSelectedRoute] = useState(currentRoute());

  return (
    <>
      {selectedRoute.path !== "/home/supervisor/" && (
        <TitleBar title={selectedRoute.title} />
      )}
      <div style={{ display: "flex", paddingTop: "10px" }}>
        <div className="row">
          <div className="double-column">
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={true}
                />
              ))}
            </Switch>
          </div>
          {selectedRoute.path !== "/home/supervisor/" && (
            <SubMenuSupervisao supervisor={props.supervisor} />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { supervisor } = backofficeReducer;
  return {
    supervisor,
  };
};
export default connect(mapStateToProps, { getStatusEstacao })(
  withStyles(styles)(Supervisor)
);
