import React, { Component, Fragment } from "react";
import CustomCard from "../../../../../Utils/CustomCard";
import GlobalizadorService from "../../../../../../service/GlobalizadorService";
import { errorMessage, successMessage } from "../../../../../../helpers/observables";
import moment from "moment";
import LiveChatService from "../../../../../../service/LiveChatService";
// import { Chat } from "@visual/video-atendimento-react";
import Servers from "../../../../../../constants/Servers";
import Peer from "peerjs";
import { createMediaStreamFake } from "../../../../../Utils/MediaStream";
import IntegradorService from "../../../../../../service/IntegradorService";
import styles from "../../../../../Utils/Dialogs/DialogStyle";
import { removeEncoding } from "../../../../../../helpers/util";
import NumberFormat from "react-number-format";
import { CopyToClipboard } from "react-copy-to-clipboard";

import BtnAtualizacao from "../BtnAtualizacao";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import VisibilityIcon from "@mui/icons-material/Visibility";

const tableComponent = (props) => {
  return <Paper {...props} style={{ borderRadius: 0, margin: "0.2rem" }} />;
};

class PanoramaAtendimento extends Component {
  constructor(props) {
    super(props);
    this.atendenteVideoRef = React.createRef();
    this.clienteVideoRef = React.createRef();
    this.liveChatService = new LiveChatService();
    this.globalizadorService = new GlobalizadorService();
    this.integradorService = new IntegradorService();
    this.styles = {
      headerWrapper: {},
    };
    this.state = {
      dadosVideo: null,
      servicosVideo: null,
      servicosTotemVideo: null,
      guichesVideo: null,
      atendimentos: [],
      atendimento: null,
      mensagensVideo: [],
      openDialogVideo: false,
      isVideoAtendenteEnabled: false,
      isAudioAtendenteEnabled: false,
      isVideoClienteEnabled: false,
      isAudioClienteEnabled: false,
      ultimaAtualizacao: null,

      dadosChat: null,
      dashboardNps: null,
      qteChatsEmEspera: 0,
      listaGuichesChat: null,
      chats: [],
      chat: null,
      openDialogChat: false,
      mensagensChat: [],
    };
    this.customStyle = {
      mainDiv: {
        display: "flex",
        flexDirection: "column",
      },
      divCards: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        flexWrap: "wrap",
      },
      card: {
        flex: 1,
        width: "15rem",
        margin: "0.2rem",
        padding: "0.5rem 0.25rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      divTitleInfoGuiche: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      },
      labelTitleInfoGuiche: {
        fontWeight: "bold",
        marginRight: "0.2rem",
      },
      accordionSummary: {
        minHeight: "3rem",
        height: "3rem",
      },
      toolbar: {
        margin: "0.2rem",
        minHeight: "3rem",
      },
    };
  }

  componentDidMount() {
    this.atualizar();
    this.intervalAtualizacao = setInterval(() => this.atualizar(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalAtualizacao);
  }

  atualizar() {
    Promise.all([
      this.getPanoramaCategoriasVideo(),
      this.getPanoramaGuichesVideo(),
      this.getPanoramaCategoriasServicoVideo(),
      this.getPanoramaLiveChat(),
      this.getQteChatsEmEspera(),
      this.getListaGuichesLiveChat(),
    ]).then(() => {
      const chat = this.state.dadosChat;
      const video = this.state.dadosVideo.videoAtendimento;
      const totem = this.state.dadosVideo.videoTotem;
      const tme =
        (chat.tempoMedioEspera * chat.qteChatsFinalizados +
          video.tempoMediaEspera * video.qteAtendimentos +
          totem.tempoMediaEspera * totem.qteAtendimentos) /
        (chat.qteChatsFinalizados + video.qteAtendimentos + totem.qteAtendimentos);
      const tma =
        (chat.tempoMedioAtendimento * chat.qteChatsFinalizados +
          video.tempoMedioAtendimento * video.qteAtendimentos +
          totem.tempoMedioAtendimento * totem.qteAtendimentos) /
        (chat.qteChatsFinalizados + video.qteAtendimentos + totem.qteAtendimentos);
      this.setState({
        totais: {
          finalizados: chat.qteChatsFinalizados + video.qteAtendimentos + totem.qteAtendimentos,
          emAtendimento: chat.qteChatsAtendimento + video.emAtendimento + totem.emAtendimento,
          emEspera: this.state.qteChatsEmEspera + video.senhasFila + totem.senhasFila,
          atendendo: chat.qteGuichesAtendendo + video.guichesComSenha,
          logados: chat.qteGuichesLogados + video.guichesLogados,
          tme: tme ? tme : 0,
          tma: tma ? tma : 0,
        },
      });
    });
  }

  async getPanoramaCategoriasVideo() {
    try {
      const resp = await this.globalizadorService.panoramaCategoriasVideo();
      this.setState({ dadosVideo: resp.panoramaVideo });
    } catch (err) {
      errorMessage.next(err);
    }
  }

  async getPanoramaGuichesVideo() {
    try {
      const resp = await this.globalizadorService.panoramaGuichesVideo();
      const respHorario = await this.globalizadorService.consultaRelogio();
      const atendimentos = await this.liveChatService.getVideoAtendimentos();
      if (atendimentos && resp.panoramaGuichesVideo.guichesVideo) {
        for (const atendimento of atendimentos) {
          const guiche = resp.panoramaGuichesVideo.guichesVideo.find(
            (g) => g.idSenha === atendimento.idSenha
          );
          if (guiche) {
            guiche.id = atendimento.id;
          }
        }
      }
      this.setState({
        guichesVideo: resp.panoramaGuichesVideo.guichesVideo,
        ultimaAtualizacao: moment(respHorario.horario).format("DD/MM/YYYY HH:mm:ss"),
      });
    } catch (err) {
      errorMessage.next(err);
    }
  }

  async getPanoramaCategoriasServicoVideo() {
    try {
      const resp = await this.globalizadorService.panoramaCategoriasServicoVideo();
      this.setState({
        servicosVideo: resp.panoramaVideoServico.videoAtendimento,
        servicosVideoTotem: resp.panoramaVideoServico.videoTotem,
      });
    } catch (err) {
      errorMessage.next(err);
    }
  }

  async getPanoramaLiveChat() {
    try {
      const resp = await this.globalizadorService.panoramaLiveChat();
      const respHorario = await this.globalizadorService.consultaRelogio();
      this.setState({
        dadosChat: resp.panoramaLiveChat,
        ultimaAtualizacao: moment(respHorario.horario).format("DD/MM/YYYY HH:mm:ss"),
      });

      this.getDashboardNps(resp.panoramaLiveChat.nps);
    } catch (err) {
      errorMessage.next(err);
    }
  }

  async getQteChatsEmEspera() {
    try {
      const resp = await this.liveChatService.getTempoEsperaChats();
      if (resp && resp.quantidade) {
        this.setState({ qteChatsEmEspera: resp.quantidade });
      } else {
        this.setState({ qteChatsEmEspera: 0 });
      }
    } catch (err) {
      console.log(err);
      errorMessage.next(err);
    }
  }

  async getListaGuichesLiveChat() {
    try {
      const resp = await this.globalizadorService.panoramaGuichesLiveChat();
      const chats = [];
      for (const guiche of resp.panoramaGuichesLiveChat.guichesChat) {
        for (const chat of guiche.chats) {
          chats.push({
            id: chat.id,
            nomeGuiche: guiche.nomeGuiche,
            nomeAtendente: guiche.nomeAtendente,
            nomeCliente: chat.nome,
            cpf: chat.cpf,
            email: chat.email,
            telefone: chat.telefone,
            servico: chat.tags.join(" - "),
          });
        }
      }
      this.setState({ chats });
    } catch (err) {
      console.log(err);
      errorMessage.next(err);
    }
  }

  getDashboardNps(resp) {
    try {
      if (resp) {
        let group = {};
        for (const item of resp) {
          if (!group[item.id_questionario]) {
            group[item.id_questionario] = [];
          }
          if (!group[item.id_questionario][item.pergunta]) {
            group[item.id_questionario][item.pergunta] = [];
          }
          group[item.id_questionario][item.pergunta].push({
            nps: item.nps,
            quantidade: item.quantidade,
          });
        }
        let perguntas = [];
        Object.keys(group).forEach((questionario) => {
          Object.keys(group[questionario]).forEach((pergunta) => {
            let detratores = 0;
            let neutros = 0;
            let promotores = 0;
            group[questionario][pergunta].forEach((item) => {
              if (item.nps >= 1 && item.nps <= 6) {
                detratores += item.quantidade;
              } else if (item.nps === 7 || item.nps === 8) {
                neutros += item.quantidade;
              } else if (item.nps === 9 || item.nps === 10) {
                promotores += item.quantidade;
              }
            });
            let total = detratores + neutros + promotores;
            /*perguntas.push({
              label: pergunta,
              data: [promotores, neutros, detratores],
            });*/
            perguntas.push({
              label: pergunta,
              nps: (promotores / total) * 100 - (detratores / total) * 100,
              total,
            });
          });
        });
        this.setState({ dashboardNps: perguntas });
      }
    } catch (err) {
      errorMessage.next(err);
    }
  }

  connectVideo(atendimento) {
    this.liveChatService.login(atendimento.idUnidade, atendimento.idSenha, atendimento.data).then((resp) => {
      this.websocket = new WebSocket(Servers.url.WSVIDEOATENDIMENTO + "/" + resp.token);
      this.websocket.onopen = () => {
        console.log("on open");
        const ping = () => {
          if (this.websocket && this.websocket.readyState === 1) {
            this.websocket.send(JSON.stringify({ type: "PING" }));
          }
          this.pingTimeout = setTimeout(() => ping(), 4000);
        };
        ping();
      };
      this.websocket.onerror = (e) => {
        console.log("on error", e);
      };
      this.websocket.onclose = () => {
        console.log("on close");
        if (this.pingTimeout) {
          clearTimeout(this.pingTimeout);
        }
      };
      this.websocket.onmessage = (evt) => {
        const msg = JSON.parse(evt.data);
        if (msg.type === "PEER_ID_ATENDENTE") {
          this.peerIdAtendente = msg.message;
          this.connectPeer();
        } else if (msg.type === "PEER_ID_CLIENTE") {
          this.peerIdCliente = msg.message;
        } else if (msg.type === "PREVIOUS_CHAT_MESSAGES") {
          this.setState({ mensagensVideo: JSON.parse(msg.message) });
        } else if (msg.type === "FINISH") {
          successMessage.next("O atendimento foi finalizado.");
          this.closeDialog();
        } else if (msg.type === "ATENDENTE_VIDEO_STATUS") {
          this.setState({
            isVideoAtendenteEnabled: msg.message === "true",
          });
        } else if (msg.type === "CLIENTE_VIDEO_STATUS") {
          this.setState({
            isVideoClienteEnabled: msg.message === "true",
          });
        } else if (msg.type === "ATENDENTE_AUDIO_STATUS") {
          this.setState({
            isAudioAtendenteEnabled: msg.message === "true",
          });
        } else if (msg.type === "CLIENTE_AUDIO_STATUS") {
          this.setState({
            isAudioClienteEnabled: msg.message === "true",
          });
        }
      };
      this.peerIdAtendente = atendimento.peerIdAtendente;
      this.peerIdCliente = atendimento.peerIdCliente;
      this.connectPeer();
    });
  }

  connectPeer() {
    if (this.peerjs && !this.peerjs.destroyed) {
      this.peerjs.destroy();
    }
    setTimeout(() => {
      let options = {
        host: Servers.url.PEERJS,
        path: Servers.url.PEERJS_PATH,
        secure: true,
        proxied: true,
        config: { iceServers: this.props.iceServers },
        pingInterval: 1000,
      };
      if (Servers.url.PEERJS_PORT !== 0) {
        options.port = Servers.url.PEERJS_PORT;
      }
      this.peerjs = new Peer(options);
      this.peerjs.on("open", (id) => {
        this.connectAtendente(this.peerIdAtendente);
      });
      this.peerjs.on("error", (err) => {
        console.log("peer error", err);
      });
      this.peerjs.on("disconnected", () => {
        console.log("peer disconnected");
      });
    }, 1000);
  }

  connectAtendente(peerIdAtendente) {
    console.log("call atendente " + peerIdAtendente);
    this.callAtendente = this.peerjs.call(peerIdAtendente, createMediaStreamFake(), {
      metadata: { type: "observer" },
    });
    this.callAtendente.on("stream", (stream) => {
      console.log("stream atendente");
      this.atendenteVideoRef.current.srcObject = stream;
      setTimeout(() => {
        this.connectCliente(this.peerIdCliente);
      }, 1000);
    });
    this.callAtendente.on("close", () => {
      console.log("call atendente close");
    });
    this.callAtendente.on("error", (err) => {
      console.log("call atendente error", err);
    });
  }

  connectCliente(peerIdCliente) {
    console.log("call cliente " + peerIdCliente);
    this.callCliente = this.peerjs.call(peerIdCliente, createMediaStreamFake(), {
      metadata: { type: "observer" },
    });
    this.callCliente.on("stream", (stream) => {
      console.log("stream cliente");
      this.clienteVideoRef.current.srcObject = stream;
    });
    this.callCliente.on("close", () => {
      console.log("call cliente close");
    });
    this.callCliente.on("error", (err) => {
      console.log("call cliente error", err);
    });
  }

  isVideoEnabled(stream) {
    if (stream) {
      const videoTracks = stream.getVideoTracks();
      if (videoTracks && videoTracks.length > 0) {
        return videoTracks[0].enabled;
      }
    }
    return false;
  }

  isAudioEnabled(stream) {
    if (stream) {
      const audioTracks = stream.getAudioTracks();
      if (audioTracks && audioTracks.length > 0) {
        return audioTracks[0].enabled;
      }
    }
    return false;
  }

  closeDialog() {
    if (this.peerjs && !this.peerjs.destroyed) {
      this.peerjs.destroy();
    }
    this.websocket.close();
    this.setState({ openDialogVideo: false });
    this.atualizar();
  }

  openDialog(guiche) {
    this.liveChatService
      .getVideoAtendimento(guiche.id)
      .then((resp) => {
        resp = { ...resp, ...guiche };
        this.setState({
          atendimento: resp,
          chat: null,
          openDialogVideo: true,
        });
        this.connectVideo(resp);
      })
      .catch((err) => {
        errorMessage.next(err);
        this.atualizar();
      });
  }

  connectChat(chat) {
    this.setState({ chat, atendimento: null, openDialogChat: true });

    this.liveChatService
      .loginLiveChat(chat.id, this.props.idAtendente)
      .then((resp) => {
        this.websocket = new WebSocket(Servers.url.WSLIVECHAT + "/" + resp.token);
        this.websocket.onopen = () => {
          console.log("on open");
          const ping = () => {
            if (this.websocket && this.websocket.readyState === 1) {
              this.websocket.send(JSON.stringify({ type: "PING" }));
            }
            this.pingTimeout = setTimeout(() => ping(), 4000);
          };
          ping();
        };
        this.websocket.onerror = (e) => {
          console.log("on error", e);
        };
        this.websocket.onclose = () => {
          console.log("on close");
          if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
          }
        };
        this.websocket.onmessage = (evt) => {
          const msg = JSON.parse(evt.data);
          if (msg.type === "PREVIOUS_CHAT_MESSAGES") {
            this.setState({ mensagensChat: JSON.parse(msg.message) });
          }
        };
      })
      .catch((err) => {
        console.log(err);
        errorMessage.next("Erro ao conectar no chat, tente novamente mais tarde.");
        this.setState({ openDialogChat: false });
      });
  }

  getCopyIcon(texto) {
    return (
      <CopyToClipboard
        text={texto}
        onCopy={() => successMessage.next("Texto copiado para a área de transferência.")}
      >
        <IconButton style={{ padding: 0, marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
      </CopyToClipboard>
    );
  }

  render() {
    const cardsDados = (info) => (
      <div style={this.customStyle.divCards}>
        <CustomCard style={this.customStyle.card}>
          <Typography variant="h6" style={{ color: "gray" }}>
            TME
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {moment
              .utc(moment.duration(info.tempoMediaEspera, "seconds").asMilliseconds())
              .format("HH:mm:ss")}
          </Typography>
        </CustomCard>
        <CustomCard style={this.customStyle.card}>
          <Typography variant="h6" style={{ color: "gray" }}>
            TMA
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {moment
              .utc(moment.duration(info.tempoMedioAtendimento, "seconds").asMilliseconds())
              .format("HH:mm:ss")}
          </Typography>
        </CustomCard>
        <CustomCard style={this.customStyle.card}>
          <Typography variant="h6" style={{ color: "gray" }}>
            Finalizados
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {info.qteAtendimentos}
          </Typography>
        </CustomCard>
        <CustomCard style={this.customStyle.card}>
          <Typography variant="h6" style={{ color: "gray" }}>
            Em Atendimento
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {info.emAtendimento}
          </Typography>
        </CustomCard>
        <CustomCard style={this.customStyle.card}>
          <Typography variant="h6" style={{ color: "gray" }}>
            Em Espera
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {info.senhasFila}
          </Typography>
        </CustomCard>
        <CustomCard style={this.customStyle.card}>
          <Typography variant="h6" style={{ color: "gray" }}>
            Atendendo/Logados
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {info.guichesComSenha}/{info.guichesLogados}
          </Typography>
        </CustomCard>
      </div>
    );

    const tableServicos = (servicos) => (
      <TableContainer component={tableComponent} va style={{ margin: "0.1rem", maxHeight: "75vh" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Serviço</TableCell>
              <TableCell>Categoria</TableCell>
              <TableCell align="right">TME</TableCell>
              <TableCell align="right">TMA</TableCell>
              <TableCell align="right">Finalizados</TableCell>
              <TableCell align="right">Em Atendimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicos.map((row, index) => (
              <TableRow key={`row-servico-video-${index}`}>
                <TableCell component="th" scope="row">
                  {removeEncoding(row.nomeServico)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {removeEncoding(row.nomeCategoria)}
                </TableCell>
                <TableCell align="right">
                  {moment
                    .utc(moment.duration(row.tempoMediaEspera, "seconds").asMilliseconds())
                    .format("HH:mm:ss")}
                </TableCell>
                <TableCell align="right">
                  {moment
                    .utc(moment.duration(row.tempoMedioAtendimento, "seconds").asMilliseconds())
                    .format("HH:mm:ss")}
                </TableCell>
                <TableCell align="right">{row.qteAtendimentos}</TableCell>
                <TableCell align="right">{row.emAtendimento}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

    return (
      <div style={this.customStyle.mainDiv}>
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={this.state.openDialogVideo}
          onClose={() => this.closeDialog()}
        >
          <DialogTitle className={this.props.classes.dialogTitle}>
            <span className={this.props.classes.fontTitle}>Vídeo Atendimento</span>
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}
          >
            <Paper
              style={{
                backgroundColor: "var(--theme-grey-light)",
                marginBottom: "1rem",
                width: "100%",
                padding: "0.2rem",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <Typography variant="body1">Cliente: {this.state.atendimento?.nomeCliente}</Typography>
                {this.getCopyIcon(this.state.atendimento?.nomeCliente)}
                <Typography variant="body1">
                  {this.state.atendimento?.cpfCliente?.length > 11 ? "- CNPJ" : "- CPF"}:{" "}
                  {this.state.atendimento?.cpfCliente}
                </Typography>
                {this.getCopyIcon(this.state.atendimento?.cpfCliente)}
                <Typography variant="body1">
                  - Telefone:{" "}
                  <NumberFormat
                    value={this.state.atendimento?.telefoneCliente}
                    displayType={"text"}
                    format={
                      this.state.atendimento?.telefoneCliente &&
                      this.state.atendimento?.telefoneCliente.length <= 10
                        ? "(##) ####-#####"
                        : "(##) #####-####"
                    }
                  />
                </Typography>
                {this.getCopyIcon(this.state.atendimento?.telefoneCliente)}
                <Typography variant="body1">- Email: {this.state.atendimento?.emailCliente}</Typography>
                {this.getCopyIcon(this.state.atendimento?.emailCliente)}
              </div>
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <Typography variant="body1">Guichê: {this.state.atendimento?.nomeGuiche}</Typography>
                {this.getCopyIcon(this.state.atendimento?.nomeGuiche)}
                <Typography variant="body1"> - Atendente: {this.state.atendimento?.nomeAtendente}</Typography>
                {this.getCopyIcon(this.state.atendimento?.nomeAtendente)}
                <Typography variant="body1">- Serviço: {this.state.atendimento?.nomeServico}</Typography>
                {this.getCopyIcon(this.state.atendimento?.nomeServico)}
              </div>
            </Paper>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">Atendente</Typography>
                <Paper style={{ backgroundColor: "var(--theme-grey-light)", marginBottom: "1rem" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="body1"
                      style={{ marginTop: "0.35rem", marginBottom: "0.35rem", fontWeight: "bold" }}
                    >
                      {this.state.atendimento?.nomeAtendente}
                    </Typography>
                    <Button variant="text" color="primary" disabled>
                      <VideocamIcon />
                      {!this.state.isVideoAtendenteEnabled && <span className="slashed"></span>}
                    </Button>
                    <Button variant="text" color="primary" disabled>
                      <MicIcon />
                      {!this.state.isAudioAtendenteEnabled && <span className="slashed"></span>}
                    </Button>
                  </div>
                  <video ref={this.atendenteVideoRef} autoPlay={true} playsInline={true} />
                </Paper>
                <Typography variant="h5">Cliente</Typography>
                <Paper style={{ backgroundColor: "var(--theme-grey-light)" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="body1"
                      style={{ marginTop: "0.35rem", marginBottom: "0.35rem", fontWeight: "bold" }}
                    >
                      {this.state.atendimento?.nomeCliente}
                    </Typography>
                    <Button variant="text" color="primary" disabled>
                      <VideocamIcon />
                      {!this.state.isVideoClienteEnabled && <span className="slashed"></span>}
                    </Button>
                    <Button variant="text" color="primary" disabled>
                      <MicIcon />
                      {!this.state.isAudioClienteEnabled && <span className="slashed"></span>}
                    </Button>
                  </div>
                  <video ref={this.clienteVideoRef} autoPlay={true} playsInline={true} />
                </Paper>
              </div>
              <div style={{ flex: 1 }}>
                <Typography variant="h5">Mensagens</Typography>
                {/* <Chat
                  type="ATENDENTE"
                  mensagens={this.state.mensagensVideo}
                  readOnly={true}
                  nomeCliente={this.state.atendimento?.nomeCliente}
                  nomeAtendente={this.state.atendimento?.nomeAtendente}
                  linkDownloadArquivo={(fileId) => this.integradorService.getLinkDownloadArquivo(fileId)}
                /> */}
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={this.state.openDialogChat}
          onClose={() => {
            if (this.websocket) {
              this.websocket.close();
            }
            this.setState({ openDialogChat: false });
          }}
        >
          <DialogTitle className={this.props.classes.dialogTitle}>
            <span className={this.props.classes.fontTitle}>Live Chat</span>
          </DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
            <Card style={{ background: "var(--theme-grey-light)", padding: "0.2rem 0.2rem 1rem 0.2rem" }}>
              <div>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Typography variant="body1">Cliente: {this.state.chat?.nomeCliente}</Typography>
                  {this.getCopyIcon(this.state.chat?.nomeCliente)}
                </div>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Typography variant="body1">Guichê: {this.state.chat?.nomeGuiche}</Typography>
                  {this.getCopyIcon(this.state.chat?.nomeGuiche)}
                  <Typography variant="body1"> - Atendente: {this.state.chat?.nomeAtendente}</Typography>
                  {this.getCopyIcon(this.state.chat?.nomeAtendente)}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography variant="body1">Email: {this.state.chat?.email}</Typography>
                  {this.getCopyIcon(this.state.chat?.email)}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography variant="body1">Serviço: {this.state.chat?.servico}</Typography>
                  {this.getCopyIcon(this.state.chat?.servico)}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography variant="body1">
                    {this.state.chat?.cpf.length > 11 ? "CNPJ" : "CPF"}: {this.state.chat?.cpf}
                  </Typography>
                  {this.getCopyIcon(this.state.chat?.cpf)}
                  <Typography variant="body1">
                    - Telefone:{" "}
                    <NumberFormat
                      value={this.state.chat?.telefone}
                      displayType={"text"}
                      format={
                        this.state.chat?.telefone && this.state.chat?.telefone.length <= 10
                          ? "(##) ####-#####"
                          : "(##) #####-####"
                      }
                    />
                  </Typography>
                  {this.getCopyIcon(this.state.chat?.telefone)}
                </div>
              </div>
            </Card>
            <Typography variant="h5" style={{ marginTop: "0.35rem", marginBottom: "0.35rem" }}>
              Mensagens
            </Typography>
            {/* <Chat
              type="ATENDENTE"
              mensagens={this.state.mensagensChat}
              readOnly={true}
              nomeCliente={this.state.chat?.nomeCliente}
              linkDownloadArquivo={(fileId) => this.integradorService.getLinkDownloadArquivo(fileId)}
            /> */}
          </DialogContent>
        </Dialog>

        <BtnAtualizacao onClick={() => this.atualizar()} ultimaAtualizacao={this.state.ultimaAtualizacao} />

        {this.state.dadosChat && (
          <Fragment>
            <Toolbar style={{ ...this.customStyle.toolbar, background: "var(--theme-grey-dark)" }}>
              <Typography variant="h6" style={{ color: "white" }}>
                Live Chat
              </Typography>
            </Toolbar>
            <div style={this.customStyle.divCards}>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  TME
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {moment
                    .utc(moment.duration(this.state.dadosChat.tempoMedioEspera, "seconds").asMilliseconds())
                    .format("HH:mm:ss")}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  TMA
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {moment
                    .utc(
                      moment.duration(this.state.dadosChat.tempoMedioAtendimento, "seconds").asMilliseconds()
                    )
                    .format("HH:mm:ss")}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Finalizados
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.dadosChat.qteChatsFinalizados}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Em Atendimento
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.dadosChat.qteChatsAtendimento}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Em Espera
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.qteChatsEmEspera}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Atendendo/Logados
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.dadosChat.qteGuichesAtendendo}/{this.state.dadosChat.qteGuichesLogados}
                </Typography>
              </CustomCard>
              {this.state.dashboardNps && this.state.dashboardNps.length > 0 ? (
                this.state.dashboardNps.map((pergunta) => {
                  return (
                    <CustomCard style={this.customStyle.card}>
                      <Typography variant="h6" style={{ color: "gray", fontSize: "1rem" }}>
                        NPS - {pergunta.label}
                      </Typography>
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        {pergunta.nps.toFixed(2)}%
                      </Typography>
                      <Typography variant="h6" style={{ color: "gray", fontSize: "1rem" }}>
                        Avaliados/Total
                      </Typography>
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        {pergunta.total} / {this.state.dadosChat.qteChatsFinalizados}
                      </Typography>
                    </CustomCard>
                  );
                })
              ) : (
                <CustomCard style={this.customStyle.card}>
                  <Typography variant="h6" style={{ color: "gray" }}>
                    NPS
                  </Typography>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    -
                  </Typography>
                </CustomCard>
              )}
            </div>
            {this.state.dadosChat.tags && this.state.dadosChat.tags.length > 0 && (
              <Accordion style={{ margin: "0.2rem" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={this.customStyle.accordionSummary}>
                  <Typography variant="h6">Serviços Live Chat</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer
                    component={tableComponent}
                    va
                    style={{ margin: "0.1rem", maxHeight: "75vh" }}
                  >
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Serviço</TableCell>
                          <TableCell align="right">TME</TableCell>
                          <TableCell align="right">TMA</TableCell>
                          <TableCell align="right">Finalizados</TableCell>
                          <TableCell align="right">Em Atendimento</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.dadosChat.tags.map((row, index) => (
                          <TableRow key={`row-live-chat-${index}`}>
                            <TableCell component="th" scope="row">
                              {removeEncoding(row.tag)}
                            </TableCell>
                            <TableCell align="right">
                              {moment
                                .utc(moment.duration(row.tempoMedioEspera, "seconds").asMilliseconds())
                                .format("HH:mm:ss")}
                            </TableCell>
                            <TableCell align="right">
                              {moment
                                .utc(moment.duration(row.tempoMedioAtendimento, "seconds").asMilliseconds())
                                .format("HH:mm:ss")}
                            </TableCell>
                            <TableCell align="right">{row.qteChatsFinalizados}</TableCell>
                            <TableCell align="right">{row.qteChatsAtendimento}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}
          </Fragment>
        )}

        {this.state.chats && this.state.chats.length > 0 && (
          <Accordion style={{ margin: "0.2rem" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={this.customStyle.accordionSummary}>
              <Typography variant="h6">Chats em Atendimento</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper style={{ marginTop: "0.2rem", marginBottom: "1rem", width: "100%" }}>
                <Table size="small">
                  <TableHead>
                    <TableCell>Guichê</TableCell>
                    <TableCell>Atendente</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Ver</TableCell>
                  </TableHead>
                  <TableBody>
                    {this.state.chats.map((chat) => {
                      return (
                        <TableRow>
                          <TableCell>{chat.nomeGuiche}</TableCell>
                          <TableCell>{chat.nomeAtendente}</TableCell>
                          <TableCell>{chat.nomeCliente}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => this.connectChat(chat)} style={{ padding: 0 }}>
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </AccordionDetails>
          </Accordion>
        )}

        {this.state.dadosVideo && (
          <Fragment>
            <Toolbar style={{ ...this.customStyle.toolbar, background: "#008001" }}>
              <Typography variant="h6" style={{ color: "white" }}>
                Vídeo Atendimento
              </Typography>
            </Toolbar>
            {cardsDados(this.state.dadosVideo.videoAtendimento)}
            {this.state.servicosVideo && this.state.servicosVideo.length > 0 && (
              <Accordion style={{ margin: "0.2rem" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={this.customStyle.accordionSummary}>
                  <Typography variant="h6">Serviços Vídeo Atendimento</Typography>
                </AccordionSummary>
                <AccordionDetails>{tableServicos(this.state.servicosVideo)}</AccordionDetails>
              </Accordion>
            )}
          </Fragment>
        )}

        {this.state.guichesVideo && this.state.guichesVideo.length > 0 && (
          <Accordion style={{ margin: "0.2rem", borderRadius: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={this.customStyle.accordionSummary}>
              <Typography variant="h6">Guichês de Vídeo Atendimento</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper style={{ marginTop: "0.2rem", marginBottom: "1rem", width: "100%" }}>
                <Table size="small">
                  <TableHead>
                    <TableCell>Guichê</TableCell>
                    <TableCell>Atendente</TableCell>
                    <TableCell>Senha</TableCell>
                    <TableCell>Serviço</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>Tempo de Atendimento</TableCell>
                    <TableCell>Ver</TableCell>
                  </TableHead>
                  <TableBody>
                    {this.state.guichesVideo.map((guiche) => {
                      return (
                        <TableRow>
                          <TableCell>{guiche.nomeGuiche}</TableCell>
                          <TableCell>{guiche.nomeAtendente}</TableCell>
                          <TableCell>{guiche.numeroSenha}</TableCell>
                          <TableCell>{guiche.nomeServico}</TableCell>
                          <TableCell>{guiche.nomeCategoria}</TableCell>
                          <TableCell>
                            {guiche.tempoAtendimento
                              ? moment
                                  .utc(moment.duration(guiche.tempoAtendimento, "seconds").asMilliseconds())
                                  .format("HH:mm:ss")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {guiche.id && (
                              <IconButton
                                onClick={() => {
                                  this.openDialog(guiche);
                                }}
                                style={{ padding: 0 }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </AccordionDetails>
          </Accordion>
        )}

        {this.state.dadosVideo && (
          <Fragment>
            <Toolbar style={{ ...this.customStyle.toolbar, background: "#cd631a" }}>
              <Typography variant="h6" style={{ color: "white" }}>
                Totem Vídeo Atendimento
              </Typography>
            </Toolbar>
            {cardsDados(this.state.dadosVideo.videoTotem)}
            {this.state.servicosVideoTotem && this.state.servicosVideoTotem.length > 0 && (
              <Accordion style={{ margin: "0.2rem" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={this.customStyle.accordionSummary}>
                  <Typography variant="h6">Serviços Totem Vídeo Atendimento</Typography>
                </AccordionSummary>
                <AccordionDetails>{tableServicos(this.state.servicosVideoTotem)}</AccordionDetails>
              </Accordion>
            )}
          </Fragment>
        )}

        {this.state.totais && (
          <Fragment>
            <Toolbar style={{ ...this.customStyle.toolbar, background: "var(--theme-primary-dark)" }}>
              <Typography variant="h6" style={{ color: "white" }}>
                Totais
              </Typography>
            </Toolbar>
            <div style={this.customStyle.divCards}>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  TME
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {moment
                    .utc(moment.duration(this.state.totais.tme, "seconds").asMilliseconds())
                    .format("HH:mm:ss")}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  TMA
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {moment
                    .utc(moment.duration(this.state.totais.tma, "seconds").asMilliseconds())
                    .format("HH:mm:ss")}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Finalizados
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.totais.finalizados}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Em Atendimento
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.totais.emAtendimento}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Em Espera
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.totais.emEspera}
                </Typography>
              </CustomCard>
              <CustomCard style={this.customStyle.card}>
                <Typography variant="h6" style={{ color: "gray" }}>
                  Atendendo/Logados
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {this.state.totais.atendendo}/{this.state.totais.logados}
                </Typography>
              </CustomCard>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => {
  const { iceServers } = loginReducer;
  return {
    iceServers,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PanoramaAtendimento));
