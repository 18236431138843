import React, { Fragment, PureComponent } from "react";
import { Tab, Tabs, Container } from "@mui/material";
import { withStyles } from "@mui/styles";
import LiveChatConversa from "./components/LiveChatConversa/LiveChatConversa";
import LiveChatService from "../../../../../../service/LiveChatService";
import IntegradorService from "../../../../../../service/IntegradorService";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { connect } from "react-redux";
import styles from "../../AtendimentoStyle";
import {
  finalizaAtendimentoLiveChat,
  solicitaSairModoLiveChat,
} from "../../../../../../actions/GuicheActions";
import CustomButton from "../../../../../Utils/CustomButton";
import { desconectarLiveChats, errorMessage } from "../../../../../../helpers/observables";
import GlobalizadorService from "../../../../../../service/GlobalizadorService";
import "./LiveChat.css";

const liveChatService = new LiveChatService();
const integradorService = new IntegradorService();
const globalizadorService = new GlobalizadorService();

class LiveChat extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      chatsList: [],
      titleList: [],
      mensagemBoasVindas: null,
      mensagensProntas: [],
    };
    this.backgroundColorList = [
      "#00598b",
      "#39461b",
      "#7b4040",
      "#5d5d5d",
      "#406a73",
      "#424141",
      "#018fd7",
      "#372678",
      "#75674d",
      "#73122f",
    ];
  }

  componentDidMount() {
    this.load().then();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.listaChats) !== JSON.stringify(this.props.listaChats)) {
      this.load().then();
    }
  }

  async load() {
    try {
      if (this.props.listaChats) {
        const list = [];
        const listTitle = [];
        for (const chat of this.props.listaChats) {
          const resp = await liveChatService.getChat(chat.idLiveChat);
          if (resp) {
            console.log(resp);
            list.push(resp);
            listTitle.push({ id: resp.id, title: resp.cliente.nome, mensagensNaoLidas: false });
          }
        }
        const msgs = await globalizadorService.listaMensagensPadrao();
        const msg = msgs?.mensagens?.find((m) => m.nome === "Mensagens Live Chat");
        this.setState({
          chatsList: list,
          titleList: listTitle,
          selectedTab: 0,
          mensagensProntas: msg?.mensagensPadrao?.mensagensPadrao,
          mensagemBoasVindas: msg?.mensagensPadrao?.mensagemBoasVindas,
          mensagemAguardeUmMomento: msg?.mensagensPadrao?.mensagemAguardeUmMomento,
        });
      }
    } catch (err) {
      console.log(err);
      errorMessage.next("Erro ao carregar informações de chat.");
    }
  }

  render() {
    return (
      <Fragment>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1rem" }}>
          <CustomButton
            onClick={() => {
              desconectarLiveChats.next();
              this.props.finalizaAtendimentoLiveChat(this.props.idGuiche);
            }}
          >
            Sair
          </CustomButton>
          {!this.props.saidaLiveChatSolicitada && (
            <CustomButton onClick={() => this.props.solicitaSairModoLiveChat(this.props.idGuiche)}>
              Solicitar Saída
            </CustomButton>
          )}
        </div>
        <Tabs
          value={this.state.selectedTab}
          indicatorColor="primary"
          textColor="primary"
          centered
          onChange={(event, newValue) => this.setState({ selectedTab: newValue })}
        >
          {this.state.titleList.map((item, index) => (
            <Tab
              className={item.mensagensNaoLidas ? "tab-mensagens-nao-lidas" : ""}
              label={
                <div style={{ display: "flex" }}>
                  {item.mensagensNaoLidas ? (
                    <Fragment>
                      <PriorityHighIcon style={{ color: "red" }} />
                      <span className="mensagens-nao-lidas">
                        {item.title.length > 20 ? item.title.slice(0, 20) + "..." : item.title}
                      </span>
                    </Fragment>
                  ) : (
                    <span
                      style={{
                        color: this.backgroundColorList[index]
                          ? this.backgroundColorList[index]
                          : this.backgroundColorList[0],
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {item.title.length > 20 ? item.title.slice(0, 20) + "..." : item.title}
                    </span>
                  )}
                </div>
              }
              style={{ flexDirection: "row !important" }}
            />
          ))}
        </Tabs>
        <Container maxWidth="md" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {this.state.chatsList.map((chat, index) => (
            <LiveChatConversa
              index={index}
              key={chat.id}
              idChat={chat.id}
              cliente={chat.cliente}
              tempoAtendimento={chat.tempoAtendimento}
              tag={chat?.tags[0]}
              selected={this.state.selectedTab === index}
              integradorService={integradorService}
              handleMensagensNaoLidas={(status) => {
                const list = this.state.titleList;
                list[index].mensagensNaoLidas = status;
                this.setState({ titleList: [] });
                setTimeout(() => this.setState({ titleList: list }), 1);
              }}
              mensagensProntas={this.state.mensagensProntas}
              mensagemBoasVindas={this.state.mensagemBoasVindas}
              mensagemAguardeUmMomento={this.state.mensagemAguardeUmMomento}
              backgroundColorList={this.backgroundColorList}
            />
          ))}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ guicheReducer }) => {
  const { idGuiche, listaChats, saidaLiveChatSolicitada } = guicheReducer;
  return {
    idGuiche,
    listaChats,
    saidaLiveChatSolicitada,
  };
};

export default connect(mapStateToProps, {
  finalizaAtendimentoLiveChat,
  solicitaSairModoLiveChat,
})(withStyles(styles)(LiveChat));
