import React, { useState } from "react";
import InputMask from "react-input-mask";
import { Button, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const EditarCliente = (props, data) => {
  const classes = useStyles();
  return (
    <div style={{ paddingTop: "10 px", paddingLeft: "30px" }}>
      <form noValidate autoComplete="off">
        <Grid container>
          <Grid item>
            <TextField
              defaultValue={data?.nome}
              className={classes.inputGrid}
              variant="outlined"
              size="small"
              label="Nome"
              id="input-with-icon-grid"
            />
          </Grid>
          <Grid item>
            <InputMask mask="99/99/9999" value={data?.nascimento} onChange={props.onChange}>
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  className={classes.inputGrid}
                  style={{ width: "120px" }}
                  variant="outlined"
                  size="small"
                  label="Data Nasc."
                  id="input-with-icon-grid"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item>
            <TextField
              className={classes.inputGrid}
              variant="outlined"
              size="small"
              label="Email"
              id="input-with-icon-grid"
              defaultValue={data?.email}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <TextField
              className={classes.inputGrid}
              variant="outlined"
              size="small"
              label="Cartão"
              id="input-with-icon-grid"
              defaultValue={data?.cartao}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.inputGrid}
              variant="outlined"
              size="small"
              label="Titular"
              id="input-with-icon-grid"
              defaultValue={data?.titular}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Button color="secondary" variant="contained">
              Editar cliente
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditarCliente;

const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "8px",
    marginRight: "8px",
  },
}));
