import { createHashHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware as createRouterMiddleware } from "connected-react-router";
import ReduxThunk from "redux-thunk";
import ReduxMulti from "redux-multi";

import createRootReducer from "./reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createHashHistory();

export default function configureStore() {
  const store = createStore(
    createRootReducer(history),
    composeEnhancer(
      applyMiddleware(createRouterMiddleware(history), ReduxThunk, ReduxMulti)
    )
  );
  return store;
}
