import React, { useEffect, useState } from "react";
import GlobalizadorService from "../../../../../service/GlobalizadorService";
import { errorMessage, successMessage } from "../../../../../helpers/observables";
import DialogConfirmation from "../../../../Utils/Dialogs/DialogConfirmation";
import { connect } from "react-redux";
import { Button, Card, CardContent, List, ListItem, ListItemText, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const globalizadorService = new GlobalizadorService();

const styles = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
  },
};

const TransferenciaLiveChat = (props) => {
  const [chatsAtivos, setChatsAtivos] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [guichesDisponiveis, setGuichesDisponiveis] = useState([]);
  const [selectedGuiche, setSelectedGuiche] = useState(null);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);

  useEffect(() => {
    getListaChatsAtivos().then();
  }, []);

  const getListaChatsAtivos = async () => {
    try {
      const resp = await globalizadorService.getListaAtivosLiveChat();
      setChatsAtivos(resp.listaChats);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const selectChat = async (chat) => {
    try {
      setSelectedChat(chat);
      const resp = await globalizadorService.getListaGuichesDisponiveisLiveChat(chat.tags);
      setGuichesDisponiveis(resp.listaGuiches.filter((guiche) => guiche.idGuiche !== chat.idGuiche));
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const selectGuiche = async (guiche) => {
    try {
      setSelectedGuiche(guiche);
      setOpenDialogConfirmation(true);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const transferirChat = async () => {
    try {
      await globalizadorService.transfereLiveChat(
        selectedChat.id,
        props.idUsuario,
        selectedGuiche.idGuiche,
        selectedChat.idUsuario,
        selectedGuiche.idUsuario
      );
      await refresh();
      successMessage.next("Chat transferido com sucesso!");
    } catch (err) {
      errorMessage.next(err);
    }
  };

  const refresh = async () => {
    try {
      setOpenDialogConfirmation(false);
      setGuichesDisponiveis(null);
      setSelectedChat(null);
      setSelectedGuiche(null);
      await getListaChatsAtivos();
    } catch (err) {
      errorMessage.next(err);
    }
  };

  return (
    <div style={styles.mainDiv}>
      {openDialogConfirmation && (
        <DialogConfirmation
          open={openDialogConfirmation}
          options={{
            title: "Confirmação",
            description: `Confirma a transferência do chat do cliente ${selectedChat.nomeCliente} para o guichê ${selectedGuiche.nomeGuiche}?`,
            handleClose: () => {
              setSelectedGuiche(null);
              setOpenDialogConfirmation(false);
            },
            handleConfirm: () => transferirChat().then(),
          }}
        />
      )}
      <Button startIcon={<RefreshIcon />} onClick={() => refresh().then()}>
        Atualizar
      </Button>
      <Card>
        <CardContent>
          {chatsAtivos && chatsAtivos.length > 0 ? (
            <Typography variant="h6">Selecione o chat que deseja transferir:</Typography>
          ) : (
            <Typography variant="h6">Não existem chats em atendimento no momento</Typography>
          )}
          <List>
            {chatsAtivos.map((chat) => {
              return (
                <ListItem button onClick={() => selectChat(chat)} selected={selectedChat?.id === chat.id}>
                  <ListItemText
                    primary={chat.nomeCliente}
                    secondary={chat.nomeGuiche + " - " + chat.nomeUsuario}
                  />
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>

      {selectedChat && guichesDisponiveis && (
        <Card style={{ marginTop: "0.2rem" }}>
          <CardContent>
            {guichesDisponiveis && guichesDisponiveis.length > 0 ? (
              <Typography variant="h6">Selecione para qual guichê deseja transferir o chat:</Typography>
            ) : (
              <Typography variant="h6">
                Não existem guichês disponíveis no momento para transferir este chat
              </Typography>
            )}
            <List>
              {guichesDisponiveis.map((guiche) => {
                return (
                  <ListItem
                    button
                    onClick={() => selectGuiche(guiche)}
                    selected={selectedGuiche?.idGuiche === guiche.idGuiche}
                  >
                    <ListItemText primary={guiche.nomeUsuario} secondary={guiche.nomeGuiche} />
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = ({ loginReducer }) => {
  const { idUsuario } = loginReducer;
  return { idUsuario };
};

export default connect(mapStateToProps, {})(TransferenciaLiveChat);
