export const telas = [
  {
    id: 15,
    nome: "TERMINAL 2",
    telas: [
      {
        id: 51,
        nome: "Serviços 0",
        principal: false,
        porcentagemTopo: 0,
        porcentagemRodape: 0,
        posicaoFixaBotoes: false,
        tamanhoFonte: 20,
        espacoBotoes: 10,
        idImagemFundo: 0,
        timeout: 0,
        alturaBotoes: 0,
        larguraBotoes: 0,
        quantidadeBotoesLinha: 0,
        botoes: [
          {
            id: 221,
            nome: "SERVIÇO COMUM",
            idServico: 1,
            funcionalidade: 0,
            telaSeginteId: 52,
            posicao: 0,
            cor: "#0400FF",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 223,
            nome: "SERVIÇO GENÉRICO",
            idServico: 3,
            funcionalidade: 1,
            telaSeginteId: 52,
            posicao: 2,
            cor: "#FFFF00",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
        ],
      },
      {
        id: 52,
        nome: "Prioridades 0",
        principal: false,
        porcentagemTopo: 0,
        porcentagemRodape: 0,
        posicaoFixaBotoes: false,
        tamanhoFonte: 20,
        espacoBotoes: 10,
        idImagemFundo: 0,
        timeout: 0,
        alturaBotoes: 0,
        larguraBotoes: 0,
        quantidadeBotoesLinha: 0,
        botoes: [
          {
            id: 237,
            nome: "Navega",
            idPrioridade: 1,
            funcionalidade: 0,
            telaSeginteId: 53,
            posicao: 0,
            cor: "#A3A300",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 238,
            nome: "Altera parametros",
            idPrioridade: 2,
            funcionalidade: 1,
            telaSeginteId: 53,
            posicao: 1,
            cor: "#EE00FF",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 239,
            nome: "Adiciona Serviço",
            idPrioridade: 3,
            funcionalidade: 2,
            telaSeginteId: 53,
            posicao: 2,
            cor: "#FF0000",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 240,
            nome: "Imprime",
            idPrioridade: 4,
            funcionalidade: 4,
            telaSeginteId: 53,
            posicao: 3,
            cor: "#01FE90",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 241,
            nome: "4-Baixa Prioridade",
            idPrioridade: 5,
            funcionalidade: 1,
            telaSeginteId: 53,
            posicao: 4,
            cor: "#FFFFFF",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
        ],
      },
      {
        id: 53,
        nome: "Categorias 0",
        principal: false,
        porcentagemTopo: 0,
        porcentagemRodape: 0,
        posicaoFixaBotoes: false,
        tamanhoFonte: 20,
        espacoBotoes: 10,
        idImagemFundo: 0,
        timeout: 0,
        alturaBotoes: 0,
        larguraBotoes: 0,
        quantidadeBotoesLinha: 0,
        botoes: [
          {
            id: 242,
            nome: "PRESENCIAL",
            idCategoria: 1,
            funcionalidade: 4,
            telaSeginteId: 51,
            posicao: 0,
            cor: "#949494",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 243,
            nome: "Video Atendimento",
            idCategoria: 2,
            funcionalidade: 0,
            telaSeginteId: 51,
            posicao: 1,
            cor: "#00FF6E",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    nome: "TERMINAL GENERICO",
    telas: [
      {
        id: 8,
        nome: "Prioridades 0",
        principal: false,
        porcentagemTopo: 0,
        porcentagemRodape: 0,
        posicaoFixaBotoes: false,
        tamanhoFonte: 20,
        espacoBotoes: 10,
        idImagemFundo: 0,
        timeout: 0,
        alturaBotoes: 0,
        larguraBotoes: 0,
        quantidadeBotoesLinha: 0,
        botoes: [
          {
            id: 31,
            nome: "Agendado",
            idPrioridade: 1,
            funcionalidade: 1,
            telaSeginteId: 9,
            posicao: 0,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 32,
            nome: "Preferencial +80",
            idPrioridade: 2,
            funcionalidade: 1,
            telaSeginteId: 9,
            posicao: 1,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 33,
            nome: "Preferencial",
            idPrioridade: 3,
            funcionalidade: 1,
            telaSeginteId: 9,
            posicao: 2,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 34,
            nome: "Comum",
            idPrioridade: 4,
            funcionalidade: 1,
            telaSeginteId: 9,
            posicao: 3,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
        ],
      },
      {
        id: 7,
        nome: "Serviços 0",
        principal: false,
        porcentagemTopo: 0,
        porcentagemRodape: 0,
        posicaoFixaBotoes: false,
        tamanhoFonte: 20,
        espacoBotoes: 10,
        idImagemFundo: 0,
        timeout: 0,
        alturaBotoes: 0,
        larguraBotoes: 0,
        quantidadeBotoesLinha: 0,
        botoes: [
          {
            id: 25,
            nome: "SERVIÇO COMUM",
            idServico: 1,
            funcionalidade: 1,
            telaSeginteId: 8,
            posicao: 0,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 27,
            nome: "SERVIÇO GENÉRICO",
            idServico: 3,
            funcionalidade: 1,
            telaSeginteId: 8,
            posicao: 2,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
        ],
      },
      {
        id: 9,
        nome: "Categorias 0",
        principal: false,
        porcentagemTopo: 0,
        porcentagemRodape: 0,
        posicaoFixaBotoes: false,
        tamanhoFonte: 20,
        espacoBotoes: 10,
        idImagemFundo: 0,
        timeout: 0,
        alturaBotoes: 0,
        larguraBotoes: 0,
        quantidadeBotoesLinha: 0,
        botoes: [
          {
            id: 35,
            nome: "PRESENCIAL",
            idCategoria: 1,
            funcionalidade: 4,
            telaSeginteId: 7,
            posicao: 0,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
          {
            id: 36,
            nome: "VIDEOATENDIMENTO",
            idCategoria: 2,
            funcionalidade: 4,
            telaSeginteId: 7,
            posicao: 1,
            cor: "#FFFFCC",
            scriptFuncionalidadesJson: "",
            tipoPainel: 0,
            jsonConfigBotao: '{"horarios":[]}',
          },
        ],
      },
    ],
  },
];
