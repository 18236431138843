import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginReducer from "./LoginReducer";
import guicheReducer from "./GuicheReducer";
import backofficeReducer from "./BackofficeReducer";
import supervisorReducer from "./SupervisorReducer";
import emissorReducer from "./EmissorReducer";
import websocketReducer from "../service/websocket/WebSocketReducer.js";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    loginReducer,
    guicheReducer,
    backofficeReducer,
    websocketReducer,
    supervisorReducer,
    emissorReducer,
  });
