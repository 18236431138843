import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  excluiAtividadeSenha,
  getListaCidadaosSenha,
} from "actions/GuicheActions";
import styles from "./InfoClienteStyle";
import Atividades from "./components/Atividades";
import ComentariosGuiche from "./components/ComentariosGuiche";
import GuichesApoiando from "./components/GuichesApoiando";
import GlobalizadorService from "service/GlobalizadorService";
import DialogCliente from "./components/DialogCliente";
import { errorMessage } from "helpers/observables";

import { Button, Divider, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const globalizadorService = new GlobalizadorService();

const InfoCliente = (props) => {
  const { classes } = props;
  const [openDialogCliente, setOpenDialogCliente] = useState(false);
  const [listaCamposCliente, setListaCamposCliente] = useState([]);
  const nomeCliente = props.cliente?.nomeCliente || props.nomeCidadao;
  const divCategoriaPrioridade = (
    <div style={{ display: "flex" }}>
      {props.nomeCategoria && (
        <div className={classes.tag} style={{ border: "1px solid black" }}>
          {props.nomeCategoria}
        </div>
      )}
      {props.nomePrioridade && (
        <div
          className={classes.tag}
          style={{
            border: "1px solid " + props.corPrioridade,
            color: props.corPrioridade,
          }}
        >
          {props.nomePrioridade}
        </div>
      )}
    </div>
  );

  const vincularClientes = async () => {
    try {
      const resp = await globalizadorService.listaCamposClientes();
      setListaCamposCliente(
        resp.listaCamposCliente.filter((campo) => campo.exibir)
      );

      if (props.qteCidadaos > 0) {
        props.getListaCidadaosSenha(props.idSenha);
      }

      setOpenDialogCliente(true);
    } catch (err) {
      errorMessage.next(err);
    }
  };

  return (
    <Fragment>
      <DialogCliente
        open={openDialogCliente}
        handleClose={() => setOpenDialogCliente(false)}
        listaCamposCliente={listaCamposCliente}
      />
      <Paper className={classes.paper}>
        <div className={classes.divCliente}>
          <Fragment>
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              {!props.idSenha && nomeCliente && (
                <Fragment>
                  <PersonIcon className={classes.icon} />
                  <Typography variant="h5" className={classes.labelCliente}>
                    {nomeCliente}
                  </Typography>
                </Fragment>
              )}

              {!!props.idSenha && nomeCliente && (
                <Fragment>
                  <Button
                    className={classes.labelCliente}
                    startIcon={<PersonIcon />}
                    endIcon={<EditIcon />}
                    onClick={vincularClientes}
                    style={{ fontSize: "1.25rem" }}
                  >
                    {nomeCliente}
                    {props.qteCidadaos > 1 && (
                      <Fragment>(+{props.qteCidadaos})</Fragment>
                    )}
                  </Button>
                </Fragment>
              )}

              {!!props.idSenha && props.qteCidadaos === 0 && (
                <Button
                  className={classes.labelCliente}
                  startIcon={<AddIcon />}
                  onClick={vincularClientes}
                >
                  Vincular Cliente
                </Button>
              )}
            </div>
            {divCategoriaPrioridade}
          </Fragment>
        </div>
        <div className={classes.divServico}>
          <DescriptionIcon className={classes.icon} />
          <Typography variant="h6" className={classes.labelServico}>
            {props.task?.serviceName || props.nomeServico}
          </Typography>
        </div>
        <Divider />
        <ul>
          {props.nomeAtividadeDemanda ? (
            <li>
              <Typography variant="h6" className={classes.labelDemandaAtual}>
                {props.nomeAtividadeDemanda || props.nomeAtividade}
              </Typography>
            </li>
          ) : (
            <Atividades />
          )}
        </ul>
      </Paper>
      <ComentariosGuiche />
      <GuichesApoiando />
    </Fragment>
  );
};
const mapStateToProps = ({
  guicheReducer,
  backofficeReducer,
  loginReducer,
}) => {
  const {
    idSenha,
    idAtendente,
    listaGuichesApoiando,
    idCidadao,
    nomeCidadao,
    qteCidadaos,
    nomeCategoria,
    nomePrioridade,
    corPrioridade,
    nomeServico,
    nomeAtividade,
  } = guicheReducer;
  const { task, cliente, nomeAtividadeDemanda } = backofficeReducer;
  const { permissoesAtendente, permissoesBackoffice } = loginReducer;
  return {
    idSenha,
    idAtendente,
    listaGuichesApoiando,
    idCidadao,
    nomeCidadao,
    qteCidadaos,
    nomeCategoria,
    nomePrioridade,
    corPrioridade,
    nomeServico,
    nomeAtividade,
    task,
    cliente,
    permissoesBackoffice,
    nomeAtividadeDemanda,
    permissoesAtendente,
  };
};
export default connect(mapStateToProps, {
  excluiAtividadeSenha,
  getListaCidadaosSenha,
})(withStyles(styles)(InfoCliente));
