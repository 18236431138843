import React, { useEffect } from "react";
import styles from "./DialogStyle";
import { DialogContent, Dialog, DialogTitle, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/pt-br";
import { Login, Queue } from "@mui/icons-material";

const DialogSenhas = (props) => {
  moment.locale("pt-br");
  const { options, classes } = props;
  const title = options ? options.title : props.title;
  const lista = options ? options.lista : props.lista;
  const handleClose = options ? options.handleClose : props.handleClose;
  const handleSelect = options ? options.handleSelect : props.handleSelect;

  const columns = [
    { field: "numero", headerName: "Nº", width: 20 },
    {
      field: "dataHoraEmissao",
      headerName: "Emissão",
      width: 100,
      valueGetter: (params) => {
        return moment(new Date(params?.row?.dataHoraEmissao)).format("LTS");
      },
    },
    {
      field: "atividades",
      headerName: "Atividades",
      width: 290,
      valueGetter: (params) => {
        return params.row.atividades[params.row.indiceAtividade].atividade.nome;
      },
    },
    {
      width: 100,
      renderCell: (params) => {
        return options.statusGuiche != 1 ? (
          <IconButton
            key={params.row.id}
            color="primary"
            title="Enviar para fila"
            onClick={() => {
              handleSelect(params.row, false);
            }}
          >
            <Login color="action" />
          </IconButton>
        ) : (
          <>
            <IconButton
              key={params.row.id}
              color="primary"
              title="Enviar para fila"
              onClick={() => {
                handleSelect(params.row, false);
              }}
            >
              <Queue color="action" />
            </IconButton>
            <IconButton
              key={params.row.id}
              color="primary"
              title="Chamar"
              onClick={() => {
                handleSelect(params.row, true);
              }}
            >
              <Login color="action" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth={true}>
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <div style={{ height: 300, width: "100%", paddingTop: 19 }}>
          <DataGrid
            rows={lista}
            columns={columns}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            disableColumnMenu
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DialogSenhas);
