class WebSocketChatInterno {
  isClosed = false;
  constructor(
    url,
    idUsuario,
    isSupervisor,
    handleListaDestinatarios,
    handleMensagensInicial,
    handleNovaMensagem,
    handleStatusWebsocket
  ) {
    this.url = url;
    this.idUsuario = idUsuario;
    this.isSupervisor = isSupervisor;
    this.handleListaDestinatarios = handleListaDestinatarios;
    this.handleMensagensInicial = handleMensagensInicial;
    this.handleNovaMensagem = handleNovaMensagem;
    this.handleStatusWebsocket = handleStatusWebsocket;
    this.carregamentoInicial = true;
    this.connect();
  }

  connect() {
    if (!this.isClosed) {
      this.websocket = new WebSocket(this.url);
      this.websocket.onopen = () => {
        console.log("ws chatinterno open");
        this.handleStatusWebsocket(true);
        this.websocket.send(
          JSON.stringify({
            comando: "chatWebsocket",
            parametros: { idUsuario: this.idUsuario, supervisor: this.isSupervisor },
          })
        );
      };
      this.websocket.onmessage = (e) => {
        const msg = JSON.parse(e.data);
        if (msg.comando === "listaDestinatariosUsuarioChat") {
          this.handleListaDestinatarios(msg.resposta);
        } else if (msg.comando === "listaMsgChat") {
          if (this.carregamentoInicial) {
            this.carregamentoInicial = false;
            this.handleMensagensInicial(msg.resposta);
          } else {
            this.handleNovaMensagem(msg.resposta);
          }
        }
      };
      this.websocket.onerror = (e) => console.log("ws chat interno error", e);
      this.websocket.onclose = () => {
        console.log("ws chat interno close");
        this.handleStatusWebsocket(false);
        setTimeout(() => this.connect(), 1000);
      };
    }
  }

  close() {
    this.isClosed = true;
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }
  }
}

export default WebSocketChatInterno;
