import React, { useEffect, useState } from "react";
import { errorMessage, successMessage, solicitarAvaliacao } from "helpers/observables";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
// import Servers from "constants/Servers";
import IntegradorService from "service/IntegradorService";
// import moment from "moment";
// import { Chat, ScreenShare, Video } from "@visual/video-atendimento-react";
// import VideoAtendimentoJs from "@visual/video-atendimento-js";
import { validarEnvioArquivo } from "../../../../../../helpers/util";
import GlobalizadorService from "../../../../../../service/GlobalizadorService";
import { mensagensChat } from "../../../../../../actions/GuicheActions";

const integradorService = new IntegradorService();
// const videoAtendimento = new VideoAtendimentoJs();
const globalizadorService = new GlobalizadorService();

const VideoAtendimento = (props) => {
  const [mensagem, setMensagem] = useState("");
  const [mensagens, setMensagens] = useState([]);
  const [webrtcIniciado, setWebrtcIniciado] = useState(false);
  const [screenShareIniciado, setScreenShareIniciado] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [conexaoAtiva, setConexaoAtiva] = useState(false);
  const [peerConnectedWs, setPeerConnectedWs] = useState(false);
  const [isVideoExists, setIsVideoExists] = useState(false);
  const [isAudioExists, setIsAudioExists] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [senderStarted, setSenderStarted] = useState(false);
  const [avaliacaoFinalizada, setAvaliacaoFinalizada] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [videoAudioDisabled, setVideoAudioDisabled] = useState(false);
  const [slowNetworkSpeed, setSlowNetworkSpeed] = useState(false);
  const [isPeerTyping, setIsPeerTyping] = useState(false);
  const [isChatDisabled, setIsChatDisabled] = useState(false);
  const [mensagensProntas, setMensagensProntas] = useState([]);
  const urlVirtualBackground = props.isVirtualBackground
    ? process.env.PUBLIC_URL + "/config/virtual_background.png"
    : null;

  useEffect(() => {
    globalizadorService
      .listaMensagensPadrao()
      .then((resp) => {
        const msg = resp?.mensagens?.find((m) => m.nome === "Mensagens Live Chat");
        setMensagensProntas(msg?.mensagensPadrao?.mensagensPadrao);
      })
      .catch((err) => {
        console.log(err);
        errorMessage.next("Erro ao carregar mensagens padrão");
      })
      .finally(() => {
        initWebrtc();
        if (props.task) {
          import("./VideoAtendimentoBackoffice.css");
        } else {
          import("./VideoAtendimentoGuiche.css");
        }
      });
  }, []);

  useEffect(() => {
    if (props.isFinalizaAtendimento) {
      finalizaAtendimento();
    }
  }, [props.isFinalizaAtendimento]);

  const initWebrtc = () => {
    // videoAtendimento
    //   .init({
    //     peerjsServer: Servers.url.PEERJS,
    //     peerjsPath: Servers.url.PEERJS_PATH,
    //     peerjsPort: Servers.url.PEERJS_PORT,
    //     urlVideoAtendimento: Servers.url.VIDEOATENDIMENTO,
    //     wsVideoAtendimento: Servers.url.WSVIDEOATENDIMENTO,
    //     wsRecorder: props.wsVideoAtendimentoRecorder,
    //     iceServers: props.iceServers,
    //     type: "ATENDENTE",
    //     videoAudioDisabled: videoAudioDisabled,
    //     atendimentoInfo: {
    //       numeroSenha: props.numeroSenha,
    //       nomeGuiche: props.nomeGuiche,
    //       nomeAtendente: props.nomeAtendente,
    //       nomeCliente: props.nomeCidadao,
    //     },
    //     isVirtualBackground: props.isVirtualBackground,
    //     handlePeerjsStatus: (status) => setWebrtcIniciado(status),
    //     handleRemoteStream: (stream) => setRemoteStream(stream),
    //     handleLocalStream: (stream) => setLocalStream(stream),
    //     handleScreenShareStream: (stream) => setScreenShareStream(stream),
    //     handleScreenShareStatus: (status) => setScreenShareIniciado(status),
    //     handleVideoEnabled: (isEnabled) => setIsVideoEnabled(isEnabled),
    //     handleAudioEnabled: (isEnabled) => setIsAudioEnabled(isEnabled),
    //     handleVideoExists: (isExists) => setIsVideoExists(isExists),
    //     handleAudioExists: (isExists) => setIsAudioExists(isExists),
    //     handleWsStatus: (status) => setConexaoAtiva(status),
    //     handlePeerConnectedWs: (status) => setPeerConnectedWs(status),
    //     handleAvaliacaoFinalizada: () => setAvaliacaoFinalizada(avaliacaoFinalizada),
    //     handleChatMessages: (messages) => {
    //       setMensagens([]);
    //       setMensagens(messages);
    //       props.mensagensChat(messages);
    //     },
    //     handlePeerTyping: (isPeerTyping) => setIsPeerTyping(isPeerTyping),
    //     handleSlowNetworkSpeed: (slowNetworkSpeed) => {
    //       if (slowNetworkSpeed === true || slowNetworkSpeed === "true") {
    //         setSlowNetworkSpeed(true);
    //         setVideoAudioDisabled(true);
    //         errorMessage.next("Cliente com conexão lenta, somente o chat será habilitado.");
    //       } else {
    //         setSlowNetworkSpeed(false);
    //         if (!videoAudioDisabled) {
    //           setVideoAudioDisabled(false);
    //         }
    //       }
    //     },
    //     nomeAtendente: props.nomeAtendente,
    //     handleChatDisabled: () => setIsChatDisabled(true),
    //   })
    //   .then(() => {
    //     setTimeout(() => {
    //       videoAtendimento.start(
    //         props.idUnidade,
    //         props.idSenha,
    //         moment(props.horaEmissao, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD")
    //       );
    //       solicitarAvaliacao.subscribe(() => {
    //         solicitaAvaliacao();
    //       });
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const enviarArquivo = (event) => {
    if (validarEnvioArquivo(event)) {
      const file = event.target.files[0];
      setSenderStarted(true);
      integradorService
        .uploadArquivo(file, file.name, file.name.split(".").pop(), props.idUnidade, props.idSenha)
        .then((resp) => {
          // videoAtendimento.sendFile({
          //   fileId: resp.id_arquivo,
          //   fileName: file.name,
          // });
          setSenderStarted(false);
          successMessage.next("Arquivo enviado com sucesso.");
        })
        .catch((err) => {
          errorMessage.next("Erro ao enviar arquivo.");
          console.log(err);
        });
      event.target.value = null;
    }
  };

  const finalizaAtendimento = () => {
    try {
      // videoAtendimento.finish();
      integradorService.excluirArquivos(props.idUnidade, props.idSenha).then();
      //window.onbeforeunload = null;
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => window.location.reload(), 200);
    }
  };

  const enviarMensagem = (msg) => {
    // videoAtendimento.sendChatMessage(msg);
    setMensagem("");
  };

  const solicitaAvaliacao = () => {
    // videoAtendimento.solicitarAvaliacao();
  };

  return (
    <div
      style={{ flex: 1, marginLeft: "0.2rem", display: "flex", flexDirection: props.task ? "column" : "row" }}
    >
      {slowNetworkSpeed && <Typography variant="h6">Conexão lenta</Typography>}
      {/* {!videoAudioDisabled && (
        // <Video
        //   type="ATENDENTE"
        //   isAudioExists={isAudioExists}
        //   isVideoExists={isVideoExists}
        //   isVideoEnabled={isVideoEnabled}
        //   isAudioEnabled={isAudioEnabled}
        //   startStopVideo={(status) => videoAtendimento.startStopVideo(status)}
        //   muteUnmuteAudio={(status) => videoAtendimento.muteUnmuteAudio(status)}
        //   screenShareIniciado={screenShareIniciado}
        //   startScreenShare={() => videoAtendimento.startScreenShare()}
        //   stopScreenShare={() => videoAtendimento.stopScreenShare()}
        //   localStream={localStream}
        //   remoteStream={remoteStream}
        //   webrtcIniciado={webrtcIniciado}
        //   urlVirtualBackground={urlVirtualBackground}
        // />
      )} */}
      {/* {!isChatDisabled && (
        <Chat
          type="ATENDENTE"
          conexaoAtiva={conexaoAtiva && peerConnectedWs}
          slowNetworkSpeed={slowNetworkSpeed}
          senderStarted={senderStarted}
          mensagem={mensagem}
          mensagemChange={(event) => setMensagem(event.target.value)}
          mensagens={mensagens}
          enviarArquivo={enviarArquivo}
          enviarMensagem={enviarMensagem}
          linkDownloadArquivo={(fileId) => integradorService.getLinkDownloadArquivo(fileId)}
          isTypingChange={(status) => videoAtendimento.chatTypingStatus(status)}
          isPeerTyping={isPeerTyping}
          mensagensProntas={mensagensProntas}
          handleSelectMensagemPronta={(msg) => videoAtendimento.sendChatMessage(msg)}
        />
      )} */}
      {/* <ScreenShare screenShareStream={screenShareStream} /> */}
    </div>
  );
};

const mapStateToProps = ({ guicheReducer, backofficeReducer, loginReducer }) => {
  const {
    idSenha,
    nomeAtendente,
    horaEmissao,
    isFinalizaAtendimento,
    nomeGuiche,
    numeroSenha,
    nomeCidadao,
    wsVideoAtendimentoRecorder,
  } = guicheReducer;
  const { task } = backofficeReducer;
  const { isVirtualBackground, iceServers } = loginReducer;
  return {
    idSenha,
    nomeAtendente,
    horaEmissao,
    isFinalizaAtendimento,
    nomeGuiche,
    numeroSenha,
    nomeCidadao,
    wsVideoAtendimentoRecorder,
    task,
    isVirtualBackground,
    iceServers,
  };
};

export default connect(mapStateToProps, { mensagensChat })(VideoAtendimento);
