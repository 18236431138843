import GlobalizadorService from "../service/GlobalizadorService";
import { GUICHE_BACKOFFICE_SUCCESS } from "../constants/types";
import { errorMessage, successMessage } from "../helpers/observables";

const globalizadorService = new GlobalizadorService();

export const iniciarAtendimentoDespachante = (owner) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .iniciarAtendimentoDespachante(owner)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Sessão do despachante iniciada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const finalizarAtendimentoDespachante = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .finalizarAtendimentoDespachante()
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento ao despachante finalizado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const chamadaEspecifica = (idAtividadeDemanda, demandaId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .demandaEspecifica(idAtividadeDemanda, demandaId)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Chamada específica realizada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const imprimirDemandaAtual = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .imprimeDemandaAtual(localStorage.getItem("impressora"))
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda impressa com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const imprimirDemandasDespachante = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .imprimeDemandasDespachante(localStorage.getItem("impressora"))
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Lista de demanda impressa com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const internalizaDemanda = (status, taskId, impressoraId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .internalizaDemanda(status, taskId, impressoraId)
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Demanda internalizada com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};

export const cancelaDespachante = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .cancelaDespachante()
        .then((resp) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: resp });
          successMessage.next("Atendimento ao despachante cancelado com sucesso.");
        })
        .catch((err) => {
          dispatch({ type: GUICHE_BACKOFFICE_SUCCESS, payload: err });
          errorMessage.next(err.mensagem);
          reject(err.mensagem);
        });
    });
  };
};
