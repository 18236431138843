const styles = () => ({
  icon: {
    color: "var(--theme-grey-light)",
  },
  iconSelected: {
    color: "var(--theme-grey)",
  },
  titleItemSelected: {
    color: "var(--theme-grey-light)",
    borderLeft: "1px solid",
    paddingLeft: "1.5rem",
    marginLeft: "1rem",
  },
  circuloQuantidadeItens: {
    fontSize: "13px",
    width: "1px",
    height: "1px",
    padding: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginBottom: "6px",
  },
  circuloQuantidadeItensReprovados: {
    background: "red",
    fontSize: "13px",
    border: "2px solid red",
    borderRadius: "1rem",
    width: "1px",
    height: "1px",
    padding: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginBottom: "6px",
  },
});

export default styles;
