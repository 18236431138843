//Login types

export const LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS =
  "LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS";
export const LOGOFF_SUCCESS = "LOGOFF_SUCCESS";
export const STATUS_USUARIO_SUCCESS = "STATUS_USUARIO_SUCCESS";

export const GUICHE_BACKOFFICE_SUCCESS = "GUICHE_BACKOFFICE_SUCCESS";
export const GUICHE_BACKOFFICE_ERROR = "GUICHE_BACKOFFICE_ERROR";
export const MENSAGENS_CHAT = "MENSAGENS_CHAT";

export const FINALIZA_ATENDIMENTO_SUCCESS = "FINALIZA_ATENDIMENTO_SUCCESS";
export const CANCELA_ATENDIMENTO_SUCCESS = "CANCELA_ATENDIMENTO_SUCCESS";
export const AVALIA_FORMULARIO_SUCCESS = "AVALIA_FORMULARIO_SUCCESS";
export const AVALIA_ARQUIVO_SUCCESS = "AVALIA_ARQUIVO_SUCCESS";
export const AVALIA_ARQUIVO_EXTERNO_SUCCESS = "AVALIA_ARQUIVO_EXTERNO_SUCCESS";
export const APROVA_TODOS_FORMULARIO_SUCCESS =
  "APROVA_TODOS_FORMULARIO_SUCCESS";
export const INSERIR_COMENTARIO_SUCCESS = "INSERIR_COMENTARIO_SUCCESS";

export const GET_LISTA_CIDADAOS_SENHA_SUCCESS =
  "GET_LISTA_CIDADAOS_SENHA_SUCCESS";
export const GET_LISTA_SENHAS = "GET_LISTA_SENHAS";

export const WEBSOCKET_REQUEST = "WEBSOCKET_REQUEST";
export const WEBSOCKET_SUCCESS = "WEBSOCKET_SUCCESS";
export const WEBSOCKET_FAILURE = "WEBSOCKET_FAILURE";
export const WEBSOCKET_CLOSE = "WEBSOCKET_CLOSE";
export const WEBSOCKET_MESSAGE = "WEBSOCKET_MESSAGE";
export const WEBSOCKET_SEND = "WEBSOCKET_SEND";
export const WEBSOCKET_TOKEN = "WEBSOCKET_TOKEN";

export const DADOS_SENHAS_SUCCESS = "DADOS_SENHAS_SUCCESS";
export const DADOS_SENHAS_FAILED = "DADOS_SENHAS_FAILED";

export const LISTA_TERMINAIS_SUCCESS = "LISTA_TERMINAIS_SUCCESS";
export const LISTA_TERMINAIS_FAILED = "LISTA_TERMINAIS_FAILED";
