const styles = () => ({
  divBotoes: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  divDialogPendencia: {
    display: "flex",
    flexDirection: "column",
  },
  inputComentario: {
    marginTop: "1rem",
  },
});

export default styles;
