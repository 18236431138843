import React from "react";
import {
  Box,
  Button,
  CardActions,
  Divider,
  ListItemSecondaryAction,
  MenuItem,
  Modal,
  TextField,
  styled,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
const itensSelect = [
  {
    value: 1,
    label: "Todos",
  },
  {
    value: 2,
    label: "Administrador",
  },
  {
    value: 3,
    label: "Atendente",
  },
];

const itensSelectGenero = [
  {
    value: 1,
    label: "Todos",
  },
  {
    value: 2,
    label: "Feminino",
  },
  {
    value: 3,
    label: "Masculino",
  },
];

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 45 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalAtendente = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [left, setLeft] = React.useState([
    {
      id: 1,
      title: "serviço 1",
      categoria: 1,
      genero: 1,
    },
    {
      id: 2,
      title: "serviço 2",
      categoria: 1,
      genero: 1,
    },
    {
      id: 3,
      title: "serviço 3",
      categoria: 1,
      genero: 1,
    },
  ]);

  const handleChange = (e, id) => {
    left?.forEach((res) => {
      if (id == res?.id) {
        res[e.target.name] = e.target.value;
      }
    });
    setLeft([...left]);
  };

  const listLeft = (items) => (
    <Paper className={classes.paperTransfer}>
      <List dense component="div" role="list">
        {items?.map((value, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                key={"listitem" + index}
                role="listitem"
                className={classes.listItem}
              >
                <ListItemText primary={value?.title} />
                <ListItemSecondaryAction>
                  <form className={classes.root} noValidate autoComplete="off">
                    <div>
                      <TextField
                        className={classes.inputSelection}
                        size="small"
                        select
                        name="categoria"
                        value={value?.categoria}
                        onChange={(e) => handleChange(e, value?.id)}
                        variant="outlined"
                      >
                        {itensSelect.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        className={classes.inputSelection}
                        size="small"
                        select
                        name="genero"
                        value={value?.genero}
                        onChange={(e) => handleChange(e, value?.id)}
                        variant="outlined"
                      >
                        {itensSelectGenero.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </form>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider
                key={"divider" + index}
                light
                hidden={index == items.length - 1}
              />
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );

  const mensagemSemItens = (items) => (
    <Paper className={classes.paperTransfer}>
      <List dense component="div" role="list">
        <Typography className={classes.msgNaoHaItens}>
          Não há atividades
        </Typography>
      </List>
    </Paper>
  );

  return (
    <>
      <Modal
        children
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Card color="primary" variant="elevation">
            <CardHeader
              style={{ subheader: "color: white" }}
              className={classes.cardHeader}
              title={
                <Typography className={classes.titleCard}>
                  ATENDENTES DAS ATIVIDADES
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="center"
                className={classes.root}
              >
                <Grid item>
                  {left.length > 0 ? listLeft(left) : mensagemSemItens()}
                </Grid>
              </Grid>
              <small style={{ width: "200px" }}></small>
              <Box component="div" className={classes.obsPaper}>
                As atividades marcadas com * já foram executadas e não podem ser
                agendadas! As atividades só podem ser agendadas para o mesmo
                dia!
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions} disableSpacing>
              <ColorButton
                onClick={handleChange}
                size="small"
                color="secondary"
                variant="contained"
              >
                Salvar
              </ColorButton>
              <ColorButton
                onClick={props?.handleClose}
                size="small"
                color="primary"
                variant="contained"
              >
                Fechar
              </ColorButton>
            </CardActions>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ModalAtendente;

const useStyles = makeStyles((theme) => ({
  buttonTransfer: {
    margin: theme.spacing(0.5, 0),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      maxWidth: "140px",
    },
    margin: "auto",
    justifyContent: "center",
  },
  paperTransfer: {
    overflow: "auto",
    minWidth: "32rem",
    width: "100%",
    maxWidth: "38rem",
  },
  paper: {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    minHeight: "8rem",
    left: "50%",
    padding: "2rem",
    paddingLeft: "0",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "23rem",
    width: "80%",
    maxWidth: "40rem",
  },

  cardHeader: {
    margin: "-2px",
    borderTopRightRadius: "9px",
    borderTopLefttRadius: "9px",
    background: "#00598b",
  },
  subColor: {
    color: "rgb(255 255 255 / 65%)",
    fontWeight: "300",
    fontSize: "0.9rem",
  },
  titleCard: {
    fontSize: "1.2rem",
    color: "white",
  },
  cardActions: {
    flexDirection: "row-reverse",
    paddingRight: "15px",
  },
  msgNaoHaItens: {
    fontSize: "0.7rem",
    paddingLeft: "10px",
    paddingTop: "10px",
    fontWeight: "200",
  },
  obsPaper: {
    minWidth: "20rem",
    width: "80%",
    maxWidth: "38rem",
    margin: "0 auto",
    fontWeight: "200",
    fontSize: "0.7rem",
  },
  inputSelection: {
    width: "180px",
  },
  listItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  width: "130px",
  fontSize: "0.7rem",
  fontWeight: "400",
  textAlign: "center",
  textTransform: "lowercase",
  borderRadius: "3px",
  justifyContent: "center",
  marginRight: "5px",
  boxShadow: "none",
  marginBottom: "5px",
  marginRight: "5px",
}));
