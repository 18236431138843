import React from "react";
import { Typography, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import styles from "./InfoAtendenteStyle";

const InfoAtendente = (props) => {
  const { classes } = props;

  const paperClassName = props.isVideoAtendimento ? classes.paperHorizontal : classes.paper;

  return (
    <div className={props.isVideoAtendimento ? classes.mainDivHorizontal : classes.mainDiv}>
      <Paper className={paperClassName} style={{ background: "#c86500" }}>
        <Typography variant="h4" className={classes.labelTitle}>
          {parseFloat(props.desempenho.indiceSatisfacao).toFixed(2) + "%"}
        </Typography>
        <Typography variant="subtitle2" className={classes.labelInfo}>
          Índice de Satisfação
        </Typography>
      </Paper>
      <Paper className={paperClassName} style={{ background: "#76339d" }}>
        <Typography variant="h4" className={classes.labelTitle}>
          {parseFloat(props.desempenho.metaAtendimento).toFixed(2)}
        </Typography>
        <Typography variant="subtitle2" className={classes.labelInfo}>
          Meta de Atendimento
        </Typography>
      </Paper>
      <Paper className={paperClassName} style={{ background: "#25808d" }}>
        <Typography variant="h4" className={classes.labelTitle}>
          {props.desempenho.atendimentos}
        </Typography>
        <Typography variant="subtitle2" className={classes.labelInfo}>
          Atendimentos
        </Typography>
      </Paper>
      <Paper className={paperClassName} style={{ background: "#1d598b" }}>
        <Typography variant="h4" className={classes.labelTitleYellow}>
          {parseFloat(props.desempenho.desempenho).toFixed(1)}
        </Typography>
        <Typography variant="subtitle2" className={classes.labelInfo}>
          Desempenho
        </Typography>
      </Paper>
      <Paper className={paperClassName} style={{ background: "#ab3558" }}>
        <Typography variant="h4" className={classes.labelTitle}>
          {props.desempenho.cancelamentos}
        </Typography>
        <Typography variant="subtitle2" className={classes.labelInfo}>
          Cancelamentos
        </Typography>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { desempenho } = backofficeReducer;
  return { desempenho };
};

export default connect(mapStateToProps, {})(withStyles(styles)(InfoAtendente));
