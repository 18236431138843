import { push } from "connected-react-router";
import {
  LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS,
  LOGOFF_SUCCESS,
  STATUS_USUARIO_SUCCESS,
  WEBSOCKET_CLOSE,
} from "../constants/types";
import GlobalizadorService from "../service/GlobalizadorService";
import { errorMessage } from "../helpers/observables";

const globalizadorService = new GlobalizadorService();

export const loginGuicheEstacao = (idEstacao, idGuiche) => {
  return async (dispatch) => {
    try {
      let resp;

      if (idEstacao) {
        resp = await globalizadorService.loginEstacao(idEstacao);
      }

      if (idGuiche) {
        resp = await globalizadorService.loginGuiche(idGuiche);
      }

      dispatch({
        type: LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS,
        payload: resp,
      });
    } catch (err) {
      errorMessage.next(err);
    }
  };
};

export const logoff = (idEstacao, idGuiche) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      globalizadorService
        .logoffGuiche(idGuiche)
        .then((resp) => {
          dispatch({ type: LOGOFF_SUCCESS });
          dispatch({ type: WEBSOCKET_CLOSE });
          sessionStorage.clear();
          dispatch(push("/login"));
        })
        .catch((err) => {
          errorMessage.next(err.Message);
        });
    });
  };
};

export const logoffGuiche = (idGuiche) => {
  return async (dispatch) => {
    try {
      const resp = await globalizadorService.logoffGuiche(idGuiche);
      dispatch({ type: LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS, payload: resp });
    } catch (err) {
      errorMessage.next(err.Message);
    }
  };
};

export const logoffEstacao = () => {
  return async (dispatch) => {
    try {
      const resp = await globalizadorService.logoffEstacao();
      dispatch({ type: LOGIN_LOGOFF_GUICHE_BACKOFFICE_SUCCESS, payload: resp });
    } catch (err) {
      errorMessage.next(err.Message);
    }
  };
};

export const statusUsuario = () => {
  return async (dispatch) => {
    try {
      const resp = await globalizadorService.statusUsuario();
      if (resp.isVirtualBackground) {
        try {
          const xhr = new XMLHttpRequest();
          xhr.open("HEAD", "config/virtual_background.png", false);
          xhr.send();
        } catch (e) {}
      }

      dispatch({ type: STATUS_USUARIO_SUCCESS, payload: resp });
    } catch (err) {
      errorMessage.next(err);
    }
  };
};
