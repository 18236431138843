import React, { useState } from "react";
import { connect } from "react-redux";
import GlobalizadorService from "service/GlobalizadorService";
import { useEffect } from "react";
import SubMenuSupervisaoFiltros from "components/Utils/SubMenuSupervisaoFiltros";
import SubMenuSupervisaoAtividades from "components/Utils/SubMenuSupervisaoAtividades";
import { statusUsuario } from "actions/LoginActions";
import DialogSelecao from "components/Utils/Dialogs/DialogSelecao";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  TableCell,
} from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PrintIcon from "@mui/icons-material/Print";
import CancelIcon from "@mui/icons-material/Cancel";
const globalizadorService = new GlobalizadorService();

function createDataAtividades(atividade, servico, secao) {
  return { atividade, servico, secao };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "92%",
  },
  table: {
    width: "100%",
  },
  tableContainer: {
    maxHeight: "200px",
    boxShadow: "none",
  },
  icoBtn: {
    color: "#ffffffeb",
  },
  icoBtnDark: {
    color: "inherit",
  },
  cardHeader: {
    borderTopRightRadius: "9px",
    borderTopLefttRadius: "9px",
    paddingBottom: "0",
  },
}));

const EmissaoSenhaEdicao = (props) => {
  const classes = useStyles();
  const {
    match: {
      params: { id },
    },
  } = props;

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.grey[200],
    },
    body: {
      fontSize: 15,
    },
  }))(TableCell);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDialogMotivosReprovacao, setOpenDialogMotivosReprovacao] =
    useState(false);
  const [respServicos, setRespServicos] = useState([]);
  const [respPrioridades, setRespPrioridades] = useState(null);
  const [respCategorias, setRespCategorias] = useState(null);
  const [senha, setreSenha] = useState({});
  const [rows, setRows] = useState(null);
  let [allValues, setAllValues] = useState({
    servico: null,
    prioridade: null,
    categoria: null,
    checked: true,
    proximaAtividade: null,
    atividades: null,
  });

  const carregaDados = async () => {
    const path = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    try {
      setRespServicos(await globalizadorService.supervisorListaServicos());
      setRespPrioridades(
        await globalizadorService.supervisorListaPrioridades()
      );
      setRespCategorias(await globalizadorService.supervisorListaCategorias());
      globalizadorService
        .supervisorGetSenhaById(path)
        .then((resp) => {
          setreSenha(resp);
          setAllValues({
            servico: resp?.atividades[resp?.indiceAtividade].servico?.id,
            prioridade: resp?.prioridade?.id,
            categoria: resp?.categoria?.id,
            proximaAtividade: resp?.proximaAtividade,
            atividades: resp?.atividades,
          });

          let arr = [];
          resp?.atividades.map((res) => {
            console.log(res);
            arr.push(
              createDataAtividades(
                res.atividade.nome,
                res.atividade.tag,
                res.atividade?.secao?.nome
              )
            );
          });
          setRows(arr);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    props.statusUsuario();
    carregaDados();
  }, []);

  const abreModalServicos = () => {
    setOpenDialogMotivosReprovacao(true);
  };

  return (
    <>
      <Paper>
        <Toolbar>
          <SubMenuSupervisaoAtividades />
          <SubMenuSupervisaoFiltros />
        </Toolbar>
      </Paper>

      <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
        <form noValidate autoComplete="off">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid container spacing={1}>
              <Grid item>
                <FormControl fullWidth style={{ flexDirection: "row" }}>
                  <TextField
                    variant="outlined"
                    size={"small"}
                    placeholder="Número"
                    id="input-with-icon-grid"
                    value={senha.numero ? senha.numero : ""}
                  />
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="split button"
                    disableElevation
                  >
                    <Button size="small" style={{ background: "#f44336" }}>
                      <CancelIcon color="error" />
                    </Button>
                    <Button size="small" color="inherit">
                      <AcUnitIcon color="secondary" />
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  size={"small"}
                  label="Status"
                  id="input-with-icon-grid"
                  value={senha.status ? senha.status : ""}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Box width={"300px"} paddingTop={"30px"}>
                  <FormControl fullWidth style={{ flexDirection: "row" }}>
                    <TextField
                      size="small"
                      name="categoria"
                      variant="outlined"
                      placeholder="Serviços"
                      label="Serviços"
                      style={{ width: "300px" }}
                      value={
                        allValues.servico
                          ? respServicos.find(
                              (res) => allValues.servico == res.id
                            ).nome
                          : ""
                      }
                    ></TextField>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="split button"
                      disableElevation
                    >
                      <Button
                        size="small"
                        color="secondary"
                        onClick={abreModalServicos}
                      >
                        <AddBoxIcon
                          className={classes.icoBtn}
                          color="secondary"
                        />
                      </Button>
                    </ButtonGroup>
                    <DialogSelecao
                      open={openDialogMotivosReprovacao}
                      lista={respServicos}
                      title="Selecione um serviço"
                      id="id"
                      descricao="nome"
                      handleClose={() => setOpenDialogMotivosReprovacao(false)}
                      handleSelect={(escolha) => {
                        console.log(escolha);
                        setOpenDialogMotivosReprovacao(false);
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item>
                <Box width={"300px"} paddingTop={"30px"}>
                  <FormControl fullWidth style={{ flexDirection: "row" }}>
                    <TextField
                      size="small"
                      select
                      name="prioridade"
                      variant="outlined"
                      placeholder="Prioridade"
                      label="Prioridade"
                      style={{ width: "300px" }}
                      value={allValues.prioridade ? allValues.prioridade : ""}
                    >
                      {respPrioridades ? (
                        respPrioridades?.map((prioridade, index) => (
                          <MenuItem key={index} value={prioridade.id}>
                            {prioridade.nome}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={null} value={0}></MenuItem>
                      )}
                    </TextField>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="split button"
                      disableElevation
                    >
                      <Button size="small" color="secondary">
                        <EditIcon
                          className={classes.icoBtn}
                          color="secondary"
                        />
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item>
                <Box width={"300px"} paddingTop={"30px"}>
                  <FormControl fullWidth style={{ flexDirection: "row" }}>
                    <TextField
                      size="small"
                      style={{ width: "300px" }}
                      select
                      name="categoria"
                      variant="outlined"
                      label="Categoria"
                      value={allValues.categoria ? allValues.categoria : ""}
                    >
                      {respCategorias ? (
                        respCategorias?.map((categoria, index) => (
                          <MenuItem key={index} value={categoria.id}>
                            {categoria.nome}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={null} value={0}></MenuItem>
                      )}
                    </TextField>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="split button"
                      disableElevation
                    >
                      <Button size="small" color="secondary">
                        <EditIcon
                          className={classes.icoBtn}
                          color="secondary"
                        />
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box width={"300px"} paddingTop={"30px"}>
            <FormControl fullWidth style={{ flexDirection: "row" }}>
              <TextField
                size={"small"}
                variant="outlined"
                label="Próxima atividade"
                id="input-with-icon-grid"
                value={
                  allValues.proximaAtividade ? allValues.proximaAtividade : ""
                }
              />
            </FormControl>
          </Box>
          <Grid container spacing={1}>
            <Grid item>
              <Box width={"300px"} paddingTop={"30px"}>
                <FormControl fullWidth style={{ flexDirection: "row" }}>
                  <TextField
                    size="small"
                    select
                    name="categoria"
                    variant="outlined"
                    label="Imprimir senha"
                    style={{ width: "300px" }}
                  >
                    <MenuItem key={0} value={0}>
                      Nenhuma
                    </MenuItem>
                  </TextField>
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="split button"
                    disableElevation
                  >
                    <Button size="small" color="secondary">
                      <PrintIcon className={classes.icoBtn} color="secondary" />
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box width={"300px"} paddingTop={"30px"}>
                <FormControl fullWidth style={{ flexDirection: "row" }}>
                  <TextField
                    size="small"
                    select
                    name="rastreamento"
                    variant="outlined"
                    label="Imprimir rastreamento"
                    style={{ width: "300px" }}
                  >
                    <MenuItem key={0} value={0}>
                      Nenhuma
                    </MenuItem>
                  </TextField>
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="split button"
                    disableElevation
                  >
                    <Button size="small" color="secondary">
                      <PrintIcon className={classes.icoBtn} color="secondary" />
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Box paddingTop={"30px"} maxWidth={"850px"}>
          <Card color="primary" variant="elevation">
            <CardHeader
              style={{ subheader: "color: white" }}
              className={classes.cardHeader}
              title={
                <Typography className={classes.titleCard}>
                  Atividades
                </Typography>
              }
            />
            <CardContent>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Atividade</StyledTableCell>
                      <StyledTableCell align="right">Serviço</StyledTableCell>
                      <StyledTableCell align="right">Seção</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows ? (
                      rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.atividade}
                          </TableCell>
                          <TableCell align="right">{row.servico}</TableCell>
                          <TableCell align="right">{row.secao}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={""}>
                        <TableCell component="th" scope="row">
                          Não há registros
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <small
            style={{ float: "right", marginRight: "5px", marginTop: "5px" }}
          >
            (*) Atividades atendidas
          </small>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = ({ supervisorReducer }) => {
  const { senha } = supervisorReducer;
  return { senha };
};

export default connect(mapStateToProps, { EmissaoSenhaEdicao, statusUsuario })(
  EmissaoSenhaEdicao
);
