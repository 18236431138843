import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./DialogStyle";
import { errorMessage } from "../../../helpers/observables";
import CustomButton from "../CustomButton";
import InputMask from "react-input-mask";

const DialogTextField = (props) => {
  const [text, setText] = useState("");
  const { classes } = props;
  const handleConfirm = props.options?.handleConfirm
    ? props.options.handleConfirm
    : () => {};
  const emptyMessage = props.options?.emptyMessage
    ? props.options.emptyMessage
    : "";
  const handleClose = props.options?.handleClose
    ? props.options.handleClose
    : () => {};
  const title = props.options?.title ? props.options.title : "";
  const textPlaceholder = props.options?.textPlaceholder
    ? props.options.textPlaceholder
    : "";
  const confirmTitle = props.options?.confirmTitle
    ? props.options.confirmTitle
    : "";
  const type = props.options?.type ? props.options.type : "TEXTAREA";

  useEffect(() => {
    if (props.open && props.options.previousValue) {
      setText(props.options.previousValue);
    }
  }, [props.open]);

  const confirm = () => {
    if (text) {
      handleConfirm(text);
      setText("");
    } else {
      errorMessage.next(emptyMessage);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        setText("");
        handleClose();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.fontTitle}>{title}</span>
      </DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {type === "TEXTAREA" && (
          <TextField
            label={textPlaceholder}
            variant="outlined"
            multiline
            rows="6"
            value={text}
            onChange={(event) => setText(event.target.value)}
            style={{ marginTop: "1rem" }}
          />
        )}
        {type === "TIME" && (
          <InputMask
            mask="99:99"
            value={text}
            onChange={(event) => setText(event.target.value)}
          >
            {() => (
              <TextField
                label={textPlaceholder}
                variant="outlined"
                style={{ marginTop: "1rem" }}
              />
            )}
          </InputMask>
        )}
        {type === "TEXT" && (
          <TextField
            label={textPlaceholder}
            variant="outlined"
            value={text}
            onChange={(event) => setText(event.target.value)}
            style={{ marginTop: "1rem" }}
          />
        )}
        <div style={{ textAlign: "center", paddingTop: "0.5rem" }}>
          <CustomButton primarycolor="true" onClick={() => confirm()}>
            {confirmTitle}
          </CustomButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DialogTextField);
