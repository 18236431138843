import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = (props) =>
  makeStyles({
    button: {
      background: props.primaryColor
        ? "var(--theme-primary-dark)"
        : props.background || "var(--theme-primary)",
      "&:hover": {
        background: props.primaryColor ? "var(--theme-primary)" : props.hover || "var(--theme-secondary)",
      },
      color: "var(--theme-grey-light)",
      marginTop: "0.2rem",
      marginBottom: "0.2rem",
      marginLeft: "0.2rem",
      marginRight: "0.2rem",
      borderRadius: 2,
      width: "12rem",
      height: "2rem",
      fontWeight: "200",
      textTransform: "inherit",
    },
  });

const MenuButton = (props) => {
  const classes = useStyles(props)();
  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      disableElevation
      className={classes.button}
      onClick={() => {
        props.onClick();
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default MenuButton;
