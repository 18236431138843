import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = (props) =>
  makeStyles({
    button: {
      background: props.primarycolor
        ? "var(--theme-primary)"
        : props.background || "var(--theme-secondary)",
      "&:hover": {
        background: props.primarycolor
          ? "var(--theme-primary-dark)"
          : props.hover || "var(--theme-primary)",
      },
      color: "#ffffff",
      margin: "0.1rem",
      borderRadius: 0,
      width: "11rem",
    },
  });

const CustomButton = (props) => {
  const classes = useStyles(props)();
  return (
    <Button
      color="primary"
      variant="contained"
      size="large"
      disableElevation
      className={classes.button}
      onClick={() => {
        props.onClick();
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default CustomButton;
