export default {
  TIPO_BOTAO_EMISSOR_NAVEGACAO: 0,
  TIPO_BOTAO_EMISSOR_ALTERA_PARAMETROS: 1,
  TIPO_BOTAO_EMISSOR_ADICIONA_SERVICO: 2,
  TIPO_BOTAO_EMISSOR_VINCULA_CIDADAO: 3,
  TIPO_BOTAO_EMISSOR_EMITE_IMPRIME: 4,
  TIPO_BOTAO_EMISSOR_AGENDAMENTO: 5,
  TIPO_BOTAO_EMISSOR_LIMPA_SENHA: 6,
  TIPO_BOTAO_EMISSOR_NAO_EMISSAO: 7,
  TIPO_BOTAO_EMISSOR_VINCULA_ATENDENTE: 8,
  TIPO_BOTAO_EMISSOR_VISUALIZAR_DOCUMENTO: 9,
  TIPO_BOTAO_EMISSOR_IMPRIMIR_DOCUMENTO: 10,
  TIPO_BOTAO_EMISSOR_FAZER_COMENTARIO: 11,
  TIPO_BOTAO_EMISSOR_IMPRIMIR_COMENTARIO: 12,
  TIPO_BOTAO_EMISSOR_PANORAMA_SECOES: 13,
  TIPO_BOTAO_EMISSOR_PANORAMA_GUICHE: 14,
  TIPO_BOTAO_EMISSOR_PANORAMA_ATIVIDADES: 15,
  TIPO_BOTAO_EMISSOR_DESCONGELAR_SENHAS: 16,
  TIPO_BOTAO_EMISSOR_ATIVAR_AGENDAMENTO: 17,
  TIPO_BOTAO_EMISSOR_ATIVAR_TRANSFERENCIA: 18,
  TIPO_BOTAO_EMISSOR_ATENDER_SENHA: 19,
  TIPO_BOTAO_EMISSOR_LISTA_SENHAS: 20,
  TIPO_BOTAO_EMISSOR_CIDADAO_EXTERNO: 21,
  TIPO_BOTAO_EMISSOR_PANORAMA_PRIORIDADE: 22,
  TIPO_BOTAO_EMISSOR_PANORAMA_CATEGORIA: 23,
  TIPO_BOTAO_EMISSOR_CONFIRMA_IMPRESSAO: 24,
  TIPO_BOTAO_EMISSOR_EMITE_DESATIVADA: 25,
  TIPO_BOTAO_EMISSOR_ATIVA_SENHAS: 26,
  TIPO_BOTAO_EMISSOR_GERA_DEMANDA_BO: 27,
  TIPO_BOTAO_EMISSOR_REIMPRESSAO_SENHA: 28,
  TIPO_BOTAO_EMISSOR_CANCELAR_SENHA: 29,
  TIPO_BOTAO_EMISSOR_AGENDAMENTO_SENHA: 30,
  TIPO_BOTAO_EMISSOR_FUNCIONALIDADES: 31,
  TIPO_BOTAO_EMISSOR_CHAMA_NOME_EMISSOR: 32,
  TIPO_BOTAO_EMISSOR_AGENDAMENTO_SENHA_LIVRE: 33,
  TIPO_BOTAO_EMISSOR_EXIBIR_PAINEL_LED: 34,
  TIPO_BOTAO_EMISSOR_EXIBIR_PAINELWEB: 35,
  TIPO_BOTAO_EMISSOR_PANORAMA_SENHAS: 36,
  TIPO_BOTAO_EMISSOR_PANORAMA_GERAL: 37,
  TIPO_BOTAO_EMISSOR_KQUALITY: 38, //Nao existe no net (15/04/2020)
  TIPO_BOTAO_EMISSOR_CLIENTE_COMPLETO: 39, //Nao existe no net (15/04/2020)
  TIPO_BOTAO_EMISSOR_ENVIAR_DADOS_TOKEN: 40,
  TIPO_BOTAO_EMISSOR_ENVIAR_SENHA_CELULAR: 41,
  TIPO_BOTAO_EMISSOR_DESATIVAR_EMISSAO_SENHAS: 42,
  TIPO_BOTAO_EMISSOR_QRCODE_TEXTO_COMPLEMENTAR: 43,
  TIPO_BOTAO_EMISSOR_ALTERAR_PRIORIDADE: 44,
  TIPO_BOTAO_EMISSOR_CONSULTAR_CPF: 45,
  TIPO_BOTAO_EMISSOR_EXIBE_SENHA_EMITIDA: 46,
};
