const styles = (theme) => ({
  paper: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
  },
  logo: {
    width: 100,
  },
  input: {
    width: 250,
  },
});

export default styles;
