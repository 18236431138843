/*const iceServers = [
  {
    url: "stun:stun.visual.com.br:5349",
  },
  {
    url: "turns:turn.visual.com.br:5349",
    credential: "testevisual123",
    username: "testevisual",
  },
];*/

const prod = {
  url: {
    INTEGRADOR: window.location.origin + "/API",
    WSVIDEOATENDIMENTO: "wss://" + window.location.host + "/wsvideo/ws",
    VIDEOATENDIMENTO: window.location.origin + "/wsvideo/v1",
    LIVECHAT: window.location.origin + "/wsvideo/v1",
    WSLIVECHAT:
      (window.location.protocol.includes("https") ? "wss://" : "ws://") +
      window.location.host +
      "/wsvideo/wsLiveChat",
    WSCHATINTERNO:
      (window.location.protocol.includes("https") ? "wss://" : "ws://") +
      window.location.host +
      "/cemig/wschatinterno",
    PEERJS: window.location.hostname,
    PEERJS_PORT: window.location.port ? parseInt(window.location.port) : 0,
    PEERJS_PATH: "peerjs",
    GLOBALIZADOR: window.location.href.split("#")[0] + "api/v1",
    STREAM: window.location.href.split("#")[0],
  },
};

const dev = {
  url: {
    INTEGRADOR: "http://localhost:3000",
    WSVIDEOATENDIMENTO: "wss://pedteste.visual.com.br:1043/wsvideo/ws",
    VIDEOATENDIMENTO: "https://pedteste.visual.com.br:1043/wsvideo/v1",
    LIVECHAT: "https://pedteste.visual.com.br:1043/wsvideo/v1",
    WSLIVECHAT: "wss://pedteste.visual.com.br:1043/wsvideo/wsLiveChat",
    WSCHATINTERNO: "wss://pedteste.visual.com.br:1043/cemig/wschatinterno",
    PEERJS: "pedteste.visual.com.br",
    PEERJS_PORT: 1043,
    PEERJS_PATH: "peerjs",
    GLOBALIZADOR: "http://localhost:8081/api/v1",
    STREAM: "http://localhost:8081/",

    /*INTEGRADOR: "https://atendimento.omnisiga.com/API",
    WSVIDEOATENDIMENTO: "wss://atendimento.omnisiga.com/wsvideo/ws",
    VIDEOATENDIMENTO: "https://atendimento.omnisiga.com/wsvideo/v1",
    LIVECHAT: "https://atendimento.omnisiga.com/wsvideo/v1",
    WSLIVECHAT: "wss://atendimento.omnisiga.com/wsvideo/wsLiveChat",
    PEERJS: "atendimento.omnisiga.com",
    PEERJS_PORT: 0,
    PEERJS_PATH: "peerjs",
    GLOBALIZADOR: "https://atendimento.omnisiga.com/GLOBAL/v1",*/

    /*INTEGRADOR: "http://localhost:3000",
    WSVIDEOATENDIMENTO: "ws://localhost:8081/ws",
    VIDEOATENDIMENTO: "http://localhost:8081/v1",
    LIVECHAT: "http://localhost:8081/v1",
    WSLIVECHAT: "ws://localhost:8081/wsLiveChat",*/
  },
};

export default process.env.NODE_ENV === "development" ? dev : prod;
