import { statusFilaUpdated } from "actions/GuicheActions";
import config from "../../constants/Servers";

export default function SseService(key_) {
  const key = sessionStorage.getItem("key");
  if (key == undefined) {
    return;
  } else {
    const eventSource = new EventSource(config.url.STREAM + "stream/" + key);

    eventSource.addEventListener("open", (e) => {
      console.log("The connection has been established.");
    });

    eventSource.addEventListener(
      "statusUsuario",
      (event) => {
        statusFilaUpdated(JSON.parse(event.data));
      },
      false
    );

    eventSource.addEventListener(
      "erro",
      (event) => {
        console.log(event);
      },
      false
    );

    eventSource.addEventListener(
      "error",
      (event) => {
        console.log(event);
      },
      false
    );
  }
}
