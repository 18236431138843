import { getStatusEstacao } from "actions/BackofficeActions";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import MenuButton from "../../../../Utils/MenuButton";
import MenuSupervisorRoutesListBtn from "../../../../../constants/MenuSupervisorRoutesListBtn";

const MenuSupervisor = (props) => {
  const history = useHistory();

  useEffect(() => {
    props.getStatusEstacao();
  }, []);

  return (
    <div style={{ paddingTop: "10px" }}>
      {props.supervisor?.listaBotoesSupervisor.map((res, index) => {
        return (
          <MenuButton
            key={index}
            onClick={() =>
              history.push(
                `/home/supervisor/${MenuSupervisorRoutesListBtn[res.tipo]}`
              )
            }
          >
            {res.nome}
          </MenuButton>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ backofficeReducer }) => {
  const { supervisor } = backofficeReducer;
  return {
    supervisor,
  };
};
export default connect(mapStateToProps, {
  getStatusEstacao,
})(MenuSupervisor);
