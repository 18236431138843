import React, { useState } from "react";
import { connect } from "react-redux";
import GlobalizadorService from "service/GlobalizadorService";
import { useEffect } from "react";
import { emitirSenha } from "actions/SupervisorActions";
import { useHistory } from "react-router";
import { errorMessage, successMessage } from "helpers/observables";
import { Box, Button } from "@mui/material";
import {
  FormContainer,
  CheckboxElement,
  SelectElement,
} from "react-hook-form-mui";

const globalizadorService = new GlobalizadorService();

const EmissaoSenha = (props) => {
  const history = useHistory();
  const [respServicos, setRespServicos] = useState([]);
  const [respPrioridades, setRespPrioridades] = useState(null);
  const [respCategorias, setRespCategorias] = useState(null);

  useEffect(() => {
    carregaDados();
    return () => {
      carregaDados();
    };
  }, [props.senha]);

  const emitirSenhaS = async (data) => {
    const params = {
      servicos: [data.servico],
      prioridadeId: data.prioridade,
      categoriaId: data.categoria,
      senhaLivre: !data.checked,
    };

    try {
      globalizadorService
        .supervisorEmiteSenha(params)
        .then((resp) => {
          console.log(resp);
          history.push("/home/supervisor/senhaEdicao/" + resp?.id);
          successMessage.next("Senha emitida com sucesso.");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      errorMessage.next("Formulário com erros");
    }
  };

  const carregaDados = async () => {
    try {
      setRespServicos(await globalizadorService.supervisorListaServicos());
      setRespPrioridades(
        await globalizadorService.supervisorListaPrioridades()
      );
      setRespCategorias(await globalizadorService.supervisorListaCategorias());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer
      defaultValues={{
        servico: null,
        prioridade: null,
        categoria: null,
        checked: true,
      }}
      onSuccess={emitirSenhaS}
    >
      <div
        style={{
          paddingTop: "30px",
          paddingLeft: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SelectElement
          label="Serviço"
          validation={{ required: "Este campo é obrigatório" }}
          name="servico"
          style={{ width: "300px", marginBottom: 15 }}
          size="small"
          options={respServicos?.map(({ nome: label, ...rest }) => ({
            label,
            ...rest,
          }))}
        />
        <SelectElement
          label="Prioridade"
          name="prioridade"
          style={{ width: "300px", marginBottom: 15 }}
          size="small"
          options={respPrioridades?.map(({ nome: label, ...rest }) => ({
            label,
            ...rest,
          }))}
        />
        <SelectElement
          label="Categoria"
          name="categoria"
          style={{ width: "300px", marginBottom: 15 }}
          size="small"
          options={respCategorias?.map(({ nome: label, ...rest }) => ({
            label,
            ...rest,
          }))}
        />
        <CheckboxElement
          label="Enviar para fila"
          color="primary"
          name="checked"
        />
      </div>
      <Box width={"300px"} paddingLeft={"20px"} paddingTop={"20px"}>
        <Button type="submit" variant="contained" color="primary">
          Salvar
        </Button>
      </Box>
    </FormContainer>
  );
};

const mapStateToProps = ({ supervisorReducer }) => {
  const { senha } = supervisorReducer;
  return { senha };
};

export default connect(mapStateToProps, { emitirSenha })(EmissaoSenha);
